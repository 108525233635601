import React, { PureComponent } from 'react'
import { ErrorAlert, InformationAlert } from '../../lib/alerts';
import LeftOptions from './LeftOptions'
import numeral from 'numeral'
import PropertiesApi from '../../api/PropertiesApi';
import { buildFormFromRequest } from '../../lib/Ammenities';
import { buildNearbyPlacesFromRequest } from '../../containers/EditProperty/NearbyPlaces';
import { NON_DIGITS_REGEXP } from '../../lib/Regexp';
import { EditPropertyFirst, PropertyEditSubmit, PropertyGeneralInformationSubmit } from '../../lib/FormSubmit';
import GooglePlacesApi from '../../lib/GooglePlacesApi';
import AddressForm from '../ModalNewProperty/AddressForm';
import MoreInformation from '../ModalNewProperty/MoreInformation';
import BasicForm from './BasicForm';
import GeneralDetailsForm from './GeneralDetailsForm';
import LoadingModal from '../Loading/LoadingModal';
import { compressImages } from '../../lib/compressImages';

class EditPropertyModal extends PureComponent {

  state = {
    step:0,
    finishFirstSection:false,
    finishSecondSection:false,
    finishThirdSection:false,


    activeSection: 0,
    age: -1,
    ammenities: [],
    buildingArea: '',
    city: '',
    dealType: 0,
    deleteImages: [],
    deleteImagesUrl: [],
    description: '',
    disabledButton: false,
    floors: '',
    images: [],
    imagesUrl: [],
    landArea: '',
    latitude: 23.181385167349607,
    latitudeDelta: 24.92818706505762,
    legalStatus: 0,
    longitude: -102.41049645468593,
    longitudeDelta: 31.527897454798236,
    nearbyPlaces: [],
    neighborhood: '',
    number: '',
    price: '',
    state: '',
    street: '',
    type: 0,
    zipCode: '',
    isRecommendedPostsModalVisible: false,
    numberOfRecommendedPosts: 0,
    maintenanceFee: '',
    viewOptionalFields: false,
    requiredFields: [],
    isLoadingModalVisible: false,
    loadingProperty: true,
    uploadedImages: 0,
    loadingMessage: '',
    newImages: [],
    zoom:16,
    loadingCompressFiles:false,
    imagesError:0
  }

  _setStep = step => this.setState({step}, () => {
    document.getElementById('modal-new-property').scroll({top:0})
  })

  map = React.createRef(null)


  componentDidMount() {
    const { id } = this.props;

    PropertiesApi.get(id).then(result => {
      this.setStateFromRequest(result.data);
    }).catch(error => {
      return ErrorAlert(
        'Hubo un error. Intena de nuevo más tarde.',
        () => this.props.close()
      );
    });
  }


  getAgeValue = (age) => {
    switch(age) {
      case 'Pre-venta':
        return 0;
      case 'En construcción':
        return 1;
      case 'Nuevo':
        return 2;
      case 'Hasta 5 años':
        return 3;
      case '5-10 años':
        return 4;
      case '10-20 años':
        return 5;
      case '20-50 años':
        return 6;
      case '50+ años':
        return 7;
      default:
        return 0;
    }
  }

  getLegalStatus = (legalStatus) => {
    switch(legalStatus) {
      case 'Libre Gravamen':
        return 0;
      case 'Intestada':
        return 1;
      case 'Remate Bancario':
        return 2;
      case 'Gravada':
        return 3;
      case 'Traspaso':
          return 4;
      case 'Otro':
          return 5;
      default:
        return 0;
    }
  }

  getType = (type) => {
    switch(type) {
      case 'Casa':
        return 0;
      case 'Departamento':
        return 1;
      case 'Terreno':
        return 2;
      case 'Local':
        return 3;
      case 'Oficina':
        return 4;
      case 'Bodega':
        return 5;
      default:
        return 0;
    }
  }

  getDealType = (dealType) => {
    switch(dealType) {
      case 'Venta':
        return 0;
      case 'Renta':
        return 1;
      default:
        return 0;
    }
  }

  getImages(images) {

    images = images.filter( img => img.id > 0 );

    return Array.from(images, image => ({
      id: image.id,
      uri: image.path
    }));
  }
  getImagesUrl(images) {
    return Array.from(images, image => ({
      id: image.id,
      uri: image.url
    }));
  }

  setStateFromRequest = (data) => {
    
    const age = this.getAgeValue(data.attributes.age);
    const ammenities = buildFormFromRequest(data.attributes.ammenities);
    const dealType = this.getDealType(data.attributes.dealType);
    const images = this.getImages(data.attributes.images);
    const imagesUrl = this.getImagesUrl(data.attributes.imagesUrl);
    const legalStatus = this.getLegalStatus(data.attributes.legalStatus);
    const nearbyPlaces = buildNearbyPlacesFromRequest(data.attributes.nearbyPlaces);
    const price = numeral(data.attributes.price.replace(NON_DIGITS_REGEXP, "")).format('$0,0[.]00');
    const type = this.getType(data.attributes.type);
    const buildingArea = data.attributes.buildingArea === '0' ? '' : data.attributes.buildingArea.replace(NON_DIGITS_REGEXP, '');
    const floors = data.attributes.floors === 0 ? '0' : data.attributes.floors;
    const maintenanceFee = numeral(data.attributes.maintenanceFee.replace(NON_DIGITS_REGEXP, "")).format('$0,0[.]00');
    
    this.setState({
      age: age,
      ammenities: ammenities,
      buildingArea: buildingArea,
      city: data.attributes.address.city,
      dealType: dealType,
      description: data.attributes.description,
      floors: floors,
      images: images,
      imagesUrl: imagesUrl,
      landArea: data.attributes.landArea.replace(NON_DIGITS_REGEXP, ''),
      latitude: Number(data.attributes.latitude),
      latitudeDelta: 0.015,
      legalStatus: legalStatus,
      longitude: Number(data.attributes.longitude),
      longitudeDelta: 0.015,
      nearbyPlaces: nearbyPlaces,
      neighborhood: data.attributes.address.neighborhood,
      number: data.attributes.address.number,
      price: price,
      state: data.attributes.address.state,
      street: data.attributes.address.street,
      type: type,
      zipCode: data.attributes.address.zipCode || '',
      maintenanceFee: maintenanceFee,
      loadingProperty: false,
    },()=>{
      this.map.current?.testChange(this.state.latitude,this.state.longitude)
    });
  }
  parsedData = () => {
    let propertyObject;

    if(this.state.type === 2) {
      propertyObject = {
        price: this.state.price,
        newImages: this.state.newImages,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        buildingArea: this.state.buildingArea,
        legalStatus: this.state.legalStatus,
        nearbyPlaces: this.state.nearbyPlaces,
        state: this.state.state,
        type: this.state.type,
        city: this.state.city,
        zipCode: this.state.zipCode,
        neighborhood: this.state.neighborhood,
        street: this.state.street,
        number: this.state.number,
        description: this.state.description,
        __method: 'PATCH'
      };
    }
    else {
      propertyObject = {
        age: this.state.age,
        ammenities: this.state.ammenities,
        buildingArea: this.state.buildingArea,
        city: this.state.city,
        zipCode: this.state.zipCode,
        dealType: this.state.dealType,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        description: this.state.description,
        floors: this.state.floors,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        latitude: this.state.latitude,
        legalStatus: this.state.legalStatus,
        longitude: this.state.longitude,
        nearbyPlaces: this.state.nearbyPlaces,
        neighborhood: this.state.neighborhood,
        newImages: this.state.newImages,
        number: this.state.number,
        price: this.state.price,
        state: this.state.state,
        type: this.state.type,
        street: this.state.street,
        maintenanceFee: this.state.maintenanceFee,
        __method: 'PATCH'
      }
    }

    return propertyObject;
  }

  _setRequiredFields = requiredFields => {

    this.setState({ requiredFields,disabledButton: false }, () => {

      
      let activeSection = this.state.activeSection;
      let alreadyRequired = false;
      switch (requiredFields[0]) {

        case 'price':
          alreadyRequired = true
          break;
        case 'images':
          alreadyRequired = true
          break;

        default:
          break;
      }

      if(
        (requiredFields.indexOf('landArea') >= 0 ||
        requiredFields.indexOf('buildingArea') >= 0 ||
        requiredFields.indexOf('bedrooms') >= 0 ||
        requiredFields.indexOf('bathrooms') >= 0 ||
        requiredFields.indexOf('floors') >= 0 ||
        requiredFields.indexOf('age') > 0) && !alreadyRequired
        )
      {
        alreadyRequired = true
        activeSection = 1
      }

      if(
        (requiredFields.indexOf('city') >= 0 ||
        requiredFields.indexOf('neighborhood') >= 0 ||
        requiredFields.indexOf('zipCode') >= 0 ||
        requiredFields.indexOf('description') >= 0) && !alreadyRequired
        )
      {
        alreadyRequired = true
        activeSection = 6
      }

      if (this.listView) {
        this.setState({
          activeSection,
        },()=>{

        });

      }
    })
  }

  submitForm = () => {
    this.setState({
      disabledButton: true,
    }, () => {
      PropertyEditSubmit(
        this.parsedData(),
        (requiredFields) => this._setRequiredFields(requiredFields),
        formData => this.sendData(formData)
      );
    });
  }

  setStatePromise = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  showErrorMessage = () => {
    this.setState({ disabledButton: false, isLoadingModalVisible: false });
    let msg = [] // 'Hubo un error. Intenta de nuevo más tarde';
    if (this.state.netInfoState && !this.state.netInfoState.isInternetReachable) {
      msg.push('Hubo un error con la conexión a internet.');
    } else {
      msg.push('Hubo un error.');
    }
    if (this.state.uploadedImages > 0) {
      msg.push(`Sólamente se han subido ${this.state.uploadedImages} de las imagenes.`);
    }
    msg.push('Intenta de nuevo más tarde');
    ErrorAlert(msg.join(' '), () => false);
  }

  sendData = async(entries) => {
    const images = entries.filter(([key]) => (key === 'new_images[]')).map(([_, image]) => image);
    const totalImagesCount = images.length;
    let currentImageCount = 1;
    let uploadedImages = 0;
    try {
      await this.setStatePromise({ isLoadingModalVisible: true, loadingMessage: '' });

      let { id:propertyId } = this.props;
      let numberOfRecommendedPosts;

      if (images.length <= 1) {
        const formData = new FormData();
        entries.forEach(([name, value]) => formData.append(name, value));
        const result = await PropertiesApi.update(propertyId, formData);
        numberOfRecommendedPosts = result.data.data.attributes.numberOfRecommendedPosts;
        images.shift();
      } else {
        while (images.length > 0) {
          const image = images[0];
          const formData = new FormData();
          entries.filter(([key]) => (key !== 'new_images[]')).forEach(([name, value]) => formData.append(name, value));
  
          formData.append('new_images[]', image);
  
          this.setState({ loadingMessage: `Subiendo imagen ${currentImageCount} de ${totalImagesCount}` });
          const result = await PropertiesApi.update(propertyId, formData);
  
          numberOfRecommendedPosts = result.data.data.attributes.numberOfRecommendedPosts;
          await this.setStatePromise(({ propertyId, uploadedImages }));
          
          images.shift(); ++currentImageCount; ++uploadedImages
        }  
      }

      if (images.length > 0) {
        this.showErrorMessage();      
      } else {
        if (numberOfRecommendedPosts && (numberOfRecommendedPosts > 0) && this.props.isPremier) {
          this.setState({
            isLoadingModalVisible: false,
            isRecommendedPostsModalVisible: true,
            numberOfRecommendedPosts,
          });
        } else {

          const finish = () => {
            this.setState({
              isLoadingModalVisible: false
            }, () => {
              this.props.refetch()
              this.props.close()
            });
          }

          InformationAlert(
            'Listo',
            'Tu propiedad se actualizó correctamente',
            () => {finish()},
            () => {finish()}
          );
        }
      }
    } catch(error) {
      console.log(error);
      console.log(error?.response?.data)
      this.showErrorMessage();
    }
    // this.props.dispatch(setEditPropertyImages(images));
  }

  toggleAmmenity = (id) => {
    let ammenity = {
      id: id
    };

    if(this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      this.setState(prevState => ({
        ammenities: prevState.ammenities.filter(x => x.id !== id)
      }));
    }
  }

  toggleNearbyPlace = (id) => {
    if(this.state.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === id) === undefined) {
      this.setState(prevState => ({
        nearbyPlaces: [...prevState.nearbyPlaces, id]
      }));
    }
    else {
      this.setState(prevState => ({
        nearbyPlaces: prevState.nearbyPlaces.filter(nearbyPlaceId => nearbyPlaceId !== id)
      }));
    }
  }
  
  changeAmmenityValue = (id, value) => {
    let ammenity = {
      id: id,
      value: value
    };

    if(this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      let ammenities = [...this.state.ammenities];
      const ammenityValue = this.state.ammenities.find(x => x.id === ammenity.id)?.value;
      const ammenityIndex = this.state.ammenities.findIndex(x => x.id === ammenity.id);
      if(ammenityValue !== ammenity.value) {
        ammenities[ammenityIndex] = ammenity;
        this.setState({ammenities: ammenities});
      }
      else {
        this.setState({
          ammenities: ammenities.filter(x => x.id !== ammenity.id)
        });
      }
    }
  }
  
  onChangeState = (state) => {
    this.setState(prevState => {
      if(prevState.state !== state) {
        return {state: state, city: ''};
      }
    });
  }
  
  updateMap = () => {
    let state = this.state.state.split(' ').join('+');
    let city = this.state.city.split(' ').join('+');
    let neighborhood = this.state.neighborhood.split(' ').join('+');
    let street = this.state.street.split(' ').join('+');
    let number = this.state.number.split(' ').join('+');

    let query = '';

    if(state !== '') {
      query = `${query}+${state}`;
    }
    if(city !== '') {
      query = `${query}+${city}`;
    }
    if(neighborhood !== '') {
      query = `${query}+${neighborhood}`;
    }
    if(street !== '') {
      query = `${query}+calle+${street}`;
    }
    if(number !== '') {
      query = `${query}+${number}`;
    }

    GooglePlacesApi.getPlace(query).then(result => {
      if(result.data.result) {
        this.setState({
          latitude: result.data.result.geometry.location.lat,
          longitude: result.data.result.geometry.location.lng,
        }, () => {
          this.map.current.testChange(this.state.latitude,this.state.longitude)
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  changePrice = (value, field) => {
    let price = value.replace(NON_DIGITS_REGEXP, "");
    price = price === '0' || price === '' ? '' : parseInt(price);
    price = price === '' ? '' : numeral(price).format('$0,0[.]00');
    this.setState({
      [field]: price
    });
  }
  
  toggleSection = (sectionId) => {
    if(this.state.activeSection === sectionId) {
      this.setState({
        activeSection: 0
      });
    }
    else {
      this.setState({
        activeSection: sectionId
      });
    }
  }
  
  deleteNewImage = (index) => {
    let newImages = [...this.state.newImages].filter((_, i) => (i !== index));
    this.setState({newImages})
  }
  deleteImage = (index) => {
    let images = [...this.state.images];
    const imageId = {...this.state.images[index]}.id;

    images.splice(index, 1);
    this.setState(prevState => ({
      images: images,
      deleteImages: [...new Set([...prevState.deleteImages, imageId])]
    }));
  }
  deleteImageUrl(index) {
    let imagesUrl = [...this.state.imagesUrl];
    const imageId = {...this.state.imagesUrl[index]}.id;

    imagesUrl.splice(index, 1);
    this.setState(prevState => ({
      imagesUrl,
      deleteImagesUrl:[...new Set([...prevState.deleteImagesUrl, imageId])]
    }));
  }
  changeFloors = (value) => {
    this.setState(prevState => {
      if(prevState.floors === value) {
        return {floors: ''};
      }
      else {
        return {floors: value};
      }
    });
  }


  handleChangeImages = (files,inputRef) => {

    this.setState({loadingCompressFiles:true}, async() => {

      const maxFiles = 10 - this.state.imagesUrl.length;
      
      const {images:newImages,imagesError,showMaxFiles} = await compressImages(files,maxFiles,this.state.newImages,this.state.images)
      
      
      if(showMaxFiles)
      {
        ErrorAlert('El limite de imágenes es de 10.', () =>{})        
      }

      if(inputRef)
      {
        inputRef.current.value = ""
      }
      this.setState({
        newImages: newImages,
        loadingCompressFiles:false,
        imagesError
      });
    })

  }

  finishFirstSection = () => {
    const data = {
      newImages   : this.state.newImages,
      deleteImages: this.state.deleteImages,
      images      : this.state.images,
      imagesUrl   : this.state.imagesUrl,
      price       : this.state.price,
    }

    return new Promise( (resolve,reject) => {
      EditPropertyFirst(
        data,
        (requiredFields) => {
          this.setState({ step:0, requiredFields })
          reject(false)
        },
        () => {
          this.setState({finishFirstSection:true,step:1}, () => {
            document.getElementById('modal-new-property').scroll({top:0})
          })
          resolve(true)
        }
      )
    }) 
  }

  finishSecondSection = () => {
    const data = {
      type          : this.state.type,
      dealType      : this.state.dealType,
      price         : this.state.price.replace(NON_DIGITS_REGEXP, ""),
      images        : this.state.images,
      landArea      : this.state.landArea,
      legalStatus   : this.state.legalStatus,
      nearbyPlaces  : this.state.nearbyPlaces,
      state         : this.state.state,
      city          : this.state.city,
      zipCode       : this.state.zipCode,
      neighborhood  : this.state.neighborhood,
      street        : this.state.street,
      number        : this.state.number,
      latitude      : this.state.latitude,
      longitude     : this.state.longitude,
      description   : this.state.description,
      maintenanceFee: this.state.maintenanceFee.replace(NON_DIGITS_REGEXP, ""),
    }

    if (this.state.buildingArea !== '') {
      data.buildingArea = this.state.buildingArea;
    }

    if (data.type !== 2) {
      data.floors     = this.state.floors;
      data.ammenities = this.state.ammenities;
      data.age        = this.state.age;
    }

    return new Promise( (resolve,reject) => {
      PropertyGeneralInformationSubmit(
        data,
        () => {
          this.setState({finishSecondSection:true,step:2}, () => {
            document.getElementById('modal-new-property').scroll({top:0})
          })
          resolve(true)
        },
        (requiredFields) => {
          this.setState({ step:1, requiredFields })
          reject(false)
        }
      )
    }) 
  }

  _finishThirdSection = () => {

    let propertyObject;

    if(this.state.type === 2) {
      propertyObject = {
        price: this.state.price,
        newImages: this.state.newImages,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        buildingArea: this.state.buildingArea,
        legalStatus: this.state.legalStatus,
        nearbyPlaces: this.state.nearbyPlaces,
        state: this.state.state,
        type: this.state.type,
        city: this.state.city,
        zipCode: this.state.zipCode,
        neighborhood: this.state.neighborhood,
        street: this.state.street,
        number: this.state.number,
        description: this.state.description,
        __method: 'PATCH'
      };
    }
    else {
      propertyObject = {
        age: this.state.age,
        ammenities: this.state.ammenities,
        buildingArea: this.state.buildingArea,
        city: this.state.city,
        zipCode: this.state.zipCode,
        dealType: this.state.dealType,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        description: this.state.description,
        floors: this.state.floors,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        latitude: this.state.latitude,
        legalStatus: this.state.legalStatus,
        longitude: this.state.longitude,
        nearbyPlaces: this.state.nearbyPlaces,
        neighborhood: this.state.neighborhood,
        newImages: this.state.newImages,
        number: this.state.number,
        price: this.state.price,
        state: this.state.state,
        type: this.state.type,
        street: this.state.street,
        maintenanceFee: this.state.maintenanceFee,
        __method: 'PATCH'
      }
    }
    
    PropertyEditSubmit(
      propertyObject,
      (requiredFields) => this._setRequiredFields(requiredFields),
      formData => this.sendData(formData)
    );

    
    
  }

  goBackSecondSection = () => {
    this.setState({step:0})
  }

  submitFinish = () => {
    this.finishFirstSection()
      .then( () => 
        this.finishSecondSection()
          .then( () => this._finishThirdSection())
      )
  }

  render() {
    const { visible, close } = this.props
    if (!visible)
      return null
    return (
      <div className="bg-white" style={{
        zIndex: 20,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}>
        <div id="modal-new-property" style={{
          zIndex: 30,
          position: "relative",
          overflow: "auto",
          height: "100%"
        }}>

          <div onClick={e => e.stopPropagation()} className="bg-white h-full z-30 flex flex-col"
            style={{
              borderRadius: 9,
              width: '100%',
              paddingLeft: 33,
            }}>

            <div className="grid grid-cols-12">
              <LeftOptions
                close={close}
                step={this.state.step}
                setStep={this._setStep}
                viewOptionalFields={this.state.viewOptionalFields}
                parentSetState={(v) => this.setState(v)}
                finishFirstSection={this.state.finishFirstSection}
                finishSecondSection={this.state.finishSecondSection}
                finishThirdSection={this.state.finishThirdSection}
              />
              <div className="col-span-8" style={{ backgroundColor: '#F8F8F8',paddingLeft: 79 }}>
                {
                  this.state.step === 0 &&
                  <React.Fragment>
                    <BasicForm
                      dealType={this.state.dealType}
                      parentSetState={ s => this.setState(s)}
                      handleChangeImages={this.handleChangeImages}
                      deleteImage={this.deleteImage}
                      deleteImageUrl={index => this.deleteImageUrl(index)}
                      deleteNewImage={this.deleteNewImage}
                      images={this.state.images}
                      imagesUrl={this.state.imagesUrl}
                      imagesError={this.state.imagesError}
                      newImages={this.state.newImages}
                      requiredFields={this.state.requiredFields}
                      changePrice={this.changePrice}
                      price={this.state.price}
                      maintenanceFee={this.state.maintenanceFee}
                      finishFirstSection={this.finishFirstSection}
                    />
                  </React.Fragment>
                }
                {
                  this.state.step === 1 &&
                  <GeneralDetailsForm
                    age={Number(this.state.age)}
                    ammenities={this.state.ammenities}
                    floors={this.state.floors}
                    changeAmmenityValue={this.changeAmmenityValue}
                    parentSetState={ s => this.setState(s)}
                    changeFloors={this.changeFloors}
                    propertyType={this.state.type}
                    landArea={this.state.landArea}
                    buildingArea={this.state.buildingArea}
                    requiredFields={this.state.requiredFields}
                    description={this.state.description}
                    legalStatus={this.state.legalStatus}
                    goBackSecondSection={this.goBackSecondSection}
                    finishSecondSection={this.finishSecondSection}
                  />
                }
                {
                  this.state.step === 2 &&
                  <AddressForm
                    zoom={this.state.zoom}
                    onFocusDescription={this._onFocusDescription}
                    active={this.state.activeSection === 6}
                    city={this.state.city}
                    description={this.state.description}
                    latitude={this.state.latitude}
                    latitudeDelta={Number(this.state.latitudeDelta)}
                    longitude={this.state.longitude}
                    longitudeDelta={Number(this.state.longitudeDelta)}
                    ref={this.map}
                    neighborhood={this.state.neighborhood}
                    number={this.state.number}
                    onChangeCity={city => this.setState({city})}
                    onChangeTextInput={inputObject => this.setState(inputObject)}
                    onChangeState={state => this.onChangeState(state)}
                    onChangeNeighborhood={neighborhood => {
                        this.setState({neighborhood});
                        this.updateMap();
                    }}
                    onChangeStreet={street => {
                      this.setState({street});
                      this.updateMap();
                    }}
                    onChangeNumber={number => {
                      this.setState({number});
                      this.updateMap();
                    }}
                    onRegionChangeComplete={region => this.setState(region)}
                    state={this.state.state}
                    street={this.state.street}
                    zipCode={this.state.zipCode}
                    requiredFields={this.state.requiredFields}
                    submitFinish={this.submitFinish}
                    goBack={ () => this.setState({step:1})}
                  />
                }
                 {
                  this.state.step === 3 &&
                  <MoreInformation
                    toggleAmmenity={this.toggleAmmenity}
                    nearbyPlaces={this.state.nearbyPlaces}
                    toggleNearbyPlace={this.toggleNearbyPlace}
                    submitForm={this.submitForm}
                    propertyType={this.state.type}
                    toggleSection={this.toggleSection}
                    activeSection={this.state.activeSection}
                    ammenities={this.state.ammenities}
                    submitFinish={this.submitFinish}
                    goBack={ () => this.setState({step:2})}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <LoadingModal
          visible={this.state.isLoadingModalVisible || this.state.loadingProperty || this.state.loadingCompressFiles}
          message={this.state.loadingMessage}
          />
      </div>
    )
  }
}

export default EditPropertyModal