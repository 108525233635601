import React from 'react';

export default function Floor({fill,className}) {
  return (
    <svg fill={ fill ? fill : '#2b77b7'} viewBox='0 0 29 29' className={className ? className :"w-5"}>
      <g id="departamento_svg__prefix__Group_24" data-name="Group 24">
        <path
          id="departamento_svg__prefix__Clip_14"
          d="M0 29h29V0H0z"
          className="departamento_svg__prefix__cls-1"
          data-name="Clip 14"
          fill="none"
        />
        <g id="departamento_svg__prefix__Grupo_1568" data-name="Grupo 1568">
          <path
            id="departamento_svg__prefix__Fill_17"
            d="M28.559 29H.442a.448.448 0 010-.9H3.3V9.073a.445.445 0 01.442-.448h2.767V.448A.445.445 0 016.951 0h15.1a.445.445 0 01.442.448v8.177h2.774a.445.445 0 01.442.448V28.1h2.852a.445.445 0 01.442.448.451.451 0 01-.444.452zM22.491 9.521V28.1h2.333V9.521zm-9.507 13.835h3.045a.445.445 0 01.442.448v4.3h5.142V.9H7.4v27.2h5.144v-4.3a.445.445 0 01.44-.444zm.442.9V28.1h2.162v-3.848zM4.182 9.521V28.1h2.327l.006-18.579z"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 17"

          />
          <path
            id="departamento_svg__prefix__Fill_19"
            d="M10.511.989H.488a.494.494 0 010-.989h10.023a.494.494 0 010 .989"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 19"
            transform="translate(9.004 4.304)"

          />
          <path
            id="departamento_svg__prefix__Fill_20"
            d="M10.511.989H.488a.494.494 0 010-.989h10.023a.494.494 0 010 .989"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 20"
            transform="translate(9.004 7.728)"

          />
          <path
            id="departamento_svg__prefix__Fill_21"
            d="M10.511.989H.488a.494.494 0 010-.989h10.023a.494.494 0 010 .989"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 21"
            transform="translate(9.004 11.574)"

          />
          <path
            id="departamento_svg__prefix__Fill_22"
            d="M10.511.989H.488a.494.494 0 010-.989h10.023a.494.494 0 010 .989"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 22"
            transform="translate(9.004 15.42)"

          />
          <path
            id="departamento_svg__prefix__Fill_23"
            d="M10.511.989H.488a.494.494 0 010-.989h10.023a.494.494 0 010 .989"
            className="departamento_svg__prefix__cls-3"
            data-name="Fill 23"
            transform="translate(9.004 19.266)"

          />
        </g>
        <path
          id="departamento_svg__prefix__Fill_1"
          d="M15.685 0v27.023H10.01v-4.272a.467.467 0 00-.488-.445h-3.36a.467.467 0 00-.488.445v4.271H0V0z"
          className="departamento_svg__prefix__cls-1"
          data-name="Fill 1"
          transform="translate(6.663 .989)"
          fill="none"
        />
      </g>
    </svg>
  )
}
export function FloorGrey({fill,className}) {
  return (
    <svg viewBox='0 0 18.175 20.229' className={className ? className :"w-7"}>
      <g id="company_svg__prefix__company" transform="translate(-26)">
        <path
          id="company_svg__prefix__Trazado_5529"
          d="M43.78 4.741h-7.507V.4a.4.4 0 0 0-.4-.4H26.4a.4.4 0 0 0-.4.4v19.434a.4.4 0 0 0 .4.4h17.38a.4.4 0 0 0 .4-.4V5.137a.4.4 0 0 0-.4-.396zm-7.507.79h7.112v9.956h-7.112zm-6.322 13.908v-3.161h2.371v3.161zm2.766-3.951h-3.161a.4.4 0 0 0-.4.4v3.556H26.79V.79h8.692v18.649h-2.37v-3.556a.4.4 0 0 0-.395-.395zm3.556 3.951v-3.161h7.112v3.161z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5529"
        />
        <path
          id="company_svg__prefix__Trazado_5530"
          d="M136.394 41.581h.79a.4.4 0 0 0 .4-.4V40.4a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.391z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5530"
          transform="translate(-105.653 -38.421)"
        />
        <path
          id="company_svg__prefix__Trazado_5531"
          d="M136.394 111.581h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5531"
          transform="translate(-105.653 -105.655)"
        />
        <path
          id="company_svg__prefix__Trazado_5532"
          d="M136.394 181.581h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5532"
          transform="translate(-105.653 -172.889)"
        />
        <path
          id="company_svg__prefix__Trazado_5533"
          d="M136.394 251.58h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5533"
          transform="translate(-105.653 -240.122)"
        />
        <path
          id="company_svg__prefix__Trazado_5534"
          d="M136.394 321.58h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5534"
          transform="translate(-105.653 -307.357)"
        />
        <path
          id="company_svg__prefix__Trazado_5535"
          d="M66.4 41.581h.79a.4.4 0 0 0 .4-.4V40.4a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.391z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5535"
          transform="translate(-38.421 -38.421)"
        />
        <path
          id="company_svg__prefix__Trazado_5536"
          d="M66.4 111.581h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5536"
          transform="translate(-38.421 -105.655)"
        />
        <path
          id="company_svg__prefix__Trazado_5537"
          d="M66.4 181.581h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5537"
          transform="translate(-38.421 -172.889)"
        />
        <path
          id="company_svg__prefix__Trazado_5538"
          d="M66.4 251.58h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5538"
          transform="translate(-38.421 -240.122)"
        />
        <path
          id="company_svg__prefix__Trazado_5539"
          d="M66.4 321.58h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5539"
          transform="translate(-38.421 -307.357)"
        />
        <path
          id="company_svg__prefix__Trazado_5540"
          d="M207.186 40h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5540"
          transform="translate(-172.889 -38.421)"
        />
        <path
          id="company_svg__prefix__Trazado_5541"
          d="M207.186 110h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5541"
          transform="translate(-172.889 -105.654)"
        />
        <path
          id="company_svg__prefix__Trazado_5542"
          d="M207.186 180h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5542"
          transform="translate(-172.889 -172.888)"
        />
        <path
          id="company_svg__prefix__Trazado_5543"
          d="M207.186 250h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5543"
          transform="translate(-172.889 -240.122)"
        />
        <path
          id="company_svg__prefix__Trazado_5544"
          d="M207.186 320h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5544"
          transform="translate(-172.889 -307.357)"
        />
        <path
          id="company_svg__prefix__Trazado_5545"
          d="M392.185 180h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5545"
          transform="translate(-350.579 -172.888)"
        />
        <path
          id="company_svg__prefix__Trazado_5546"
          d="M392.185 250h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5546"
          transform="translate(-350.579 -240.122)"
        />
        <path
          id="company_svg__prefix__Trazado_5547"
          d="M392.185 320h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5547"
          transform="translate(-350.579 -307.357)"
        />
        <path
          id="company_svg__prefix__Trazado_5548"
          d="M322.187 180h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5548"
          transform="translate(-283.346 -172.888)"
        />
        <path
          id="company_svg__prefix__Trazado_5549"
          d="M322.187 250h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5549"
          transform="translate(-283.346 -240.122)"
        />
        <path
          id="company_svg__prefix__Trazado_5550"
          d="M322.187 320h-.79a.4.4 0 0 0-.4.4v.79a.4.4 0 0 0 .4.4h.79a.4.4 0 0 0 .4-.4v-.79a.4.4 0 0 0-.4-.4z"
          className="company_svg__prefix__cls-1"
          data-name="Trazado 5550"
          transform="translate(-283.346 -307.357)"
        />
      </g>
    </svg>
  )
}