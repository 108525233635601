import React, {PureComponent} from 'react';
import Select, { components} from 'react-select'
import { CloseIndicator } from '../../lib/Icon/icons/Indicators';

const locationIconStyles = {
  container:(provided,state) => ({
    ...provided,
    border:'transparent !important',
    backgroundColor: "rgb(248, 248, 248)",
    fontSize:20,
    fontFamily:'Poppins-Regular'
  }),
  control: (provided,state) => ({
    ...provided,
    borderTop:'transparent !important',
    borderLeft:'transparent !important',
    borderRight:'transparent !important',
    backgroundColor: "rgb(248, 248, 248)",
    borderRadius:0,
    // none of react-select's styles are passed to <Control />
    borderBottom: '2px solid black',
  }),
  indicatorsContainer: () => ({

  }),
  valueContainer: (provided,state) => ({
    ...provided,
    paddingBottom:7,
  }),
  singleValue: (provided,state) => ({
    ...provided,
  }),
  placeholder: (provided,state) => ({
    ...provided,
  })


}

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <CloseIndicator fill="#112233" className="w-4"/>
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {children}
  </components.ValueContainer>
);

export class SelectInput extends PureComponent {
  render() {
    return (
      <div onClick={this.props.onPress}>
         <Select
          components={{ DropdownIndicator,ValueContainer:(vprops) => <ValueContainer showLocationIcon={this.props.showLocationIcon} {...vprops}/> }}
          styles={locationIconStyles}
          placeholder={this.props.placeholder}
          options={this.props.options}
          onChange={ e => this.props.onChange(e.value)}
          value={this.props.value}
        />
      </div>
    );
  }
}
