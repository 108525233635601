import React, { PureComponent } from 'react'
import Agency from '../Agency/Agency';
import CertificatesList from './CertificatesList';
import ProfileActionLinks from './ProfileActionLinks';
export default class ProfileCard extends PureComponent {
  render() {
    const {
        photoUrl,name,type,isPremier,hasAgency,agencyName,agencyPhotoUrl,
        me,numberOfFollowers,numberOfFollowing,reviews,certificates,
        setEditProfileModalVisible,onCertificatePress,onNewCertificatePress,refresh} = this.props
    
    let userType = type;
    if(isPremier)
      userType = 'Asesor Premier'

    return (
      <div className="shadow-lg flex flex-col" style={{ borderRadius:11, background:'#FFFFFF 0% 0% no-repeat padding-box',width:300 }}>
        <div className="flex flex-col items-center justify-center flex-wrap mt-5">

            <img alt="user" className="bg-redaria-blue p-1 rounded-full flex items-center justify-center avatar" style={{ width:164,height:164 }} src={photoUrl}/>

          <div className="flex flex-col justify-between mt-5 w-full">
            <p className="poppins-semi-bold text-redaria-black text-center" style={{ fontSize:20 }}>{name}</p>
            <p className="questrial text-center" style={{ color:'#4A4A4ACC',fontSize:13 }}>{userType}</p>

            {
              hasAgency &&
              <Agency
                refresh={refresh}
                name={agencyName}
                photoUrl={agencyPhotoUrl}
              />
            }
          </div>

          {
            type === 'Asesor' &&
            <CertificatesList
              certificates={certificates}
              onCertificatePress={onCertificatePress}
              onNewCertificatePress={onNewCertificatePress}
            />
          }

          <ProfileActionLinks
            me={me}
            numberOfFollowers={numberOfFollowers}
            numberOfFollowing={numberOfFollowing}
            reviews={reviews}
          />

                    
          <div className="mt-10 w-10/12 bg-gray-300 self-center" style={{ height:1 }}/>

          <div onClick={()=>setEditProfileModalVisible(true)} className="w-10/12 flex items-center justify-center self-center my-5">
            <button className="redaria-button-white">Editar Perfil</button>
          </div>
        </div>
      </div>
    )
  }
}
