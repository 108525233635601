import React from 'react';

export default function Search({viewBox,className}) {
  return (
    <svg 
    viewBox={viewBox ? viewBox :'0 0 18 18'}
    className={className ? className :"w-4"} xmlns="http://www.w3.org/2000/svg">
      <g data-name="Grupo 1468">
        <path
          d="M18.34 17.246l-5.28-5.28a7.363 7.363 0 1 0-1.094 1.094l5.28 5.28a.774.774 0 1 0 1.094-1.094zM7.349 13.151a5.8 5.8 0 1 1 5.8-5.8 5.808 5.808 0 0 1-5.8 5.8z"
          data-name="Trazado 5590"
          fill="#243443"
        />
      </g>
    </svg>
  )
}

export function SearchIconBlack({fill,className}) {
  return(
    <svg viewBox="0 0 20.946 20.946" className={className ? className :"w-4"}>
      <path
        d="M20.69 19.456l-5.956-5.959a8.307 8.307 0 10-1.234 1.237l5.957 5.957a.873.873 0 101.234-1.234zM8.291 14.837a6.546 6.546 0 116.546-6.546 6.552 6.552 0 01-6.546 6.546z"
        fill="#123"
      />
    </svg>
  )
}