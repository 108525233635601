import React from 'react';

export default function Google() {
  return (
    <svg
      className="w-5"
      viewBox="0 0 23.109 23.109"
    >
      <defs>
        <style>
          {
            ".a{fill:none;}.b{clip-path:url(#a);}.c{clip-path:url(#b);}.d{fill:#faba00;}.e{clip-path:url(#c);}.f{fill:#518df7;}.g{clip-path:url(#e);}.h{fill:#27b445;}.i{clip-path:url(#g);}.j{fill:#f04335;}"
          }
        </style>
        <clipPath id="a">
          <path
            className="a"
            d="M44.122-74.415l-.8,3-2.94.062A11.5,11.5,0,0,1,39-76.825a11.5,11.5,0,0,1,1.292-5.314h0l2.618.48,1.147,2.6a6.868,6.868,0,0,0-.371,2.232A6.839,6.839,0,0,0,44.122-74.415Z"
            transform="translate(-39 82.139)"
          />
        </clipPath>
        <clipPath id="b">
          <path
            className="a"
            d="M-35,437.331H446.445V-419H-35Z"
            transform="translate(35 419)"
          />
        </clipPath>
        <clipPath id="c">
          <path
            className="a"
            d="M59.3-79.681a11.567,11.567,0,0,1,.2,2.159,11.577,11.577,0,0,1-.253,2.413,11.552,11.552,0,0,1-4.068,6.6h0l-3.3-.168-.467-2.913a6.887,6.887,0,0,0,2.963-3.517H48.2v-4.571H59.3Z"
            transform="translate(-48.198 79.681)"
          />
        </clipPath>
        <clipPath id="e">
          <path
            className="a"
            d="M57.483-69.523h0a11.506,11.506,0,0,1-7.234,2.544,11.553,11.553,0,0,1-10.178-6.079l3.744-3.065a6.87,6.87,0,0,0,6.433,4.458,6.832,6.832,0,0,0,3.47-.94Z"
            transform="translate(-40.073 76.122)"
          />
        </clipPath>
        <clipPath id="g">
          <path
            className="a"
            d="M57.644-84.34,53.9-81.275a6.83,6.83,0,0,0-3.632-1.039,6.872,6.872,0,0,0-6.5,4.636l-3.764-3.082h0A11.552,11.552,0,0,1,50.269-87,11.506,11.506,0,0,1,57.644-84.34Z"
            transform="translate(-40.006 87)"
          />
        </clipPath>
      </defs>
      <g transform="translate(-39 87)">
        <g className="b" transform="translate(39 -80.759)">
          <g className="c" transform="translate(-95.005 -432.48)">
            <path
              className="d"
              d="M34-87.139H51.96v23.628H34Z"
              transform="translate(54.586 513.2)"
            />
          </g>
        </g>
        <g className="e" transform="translate(50.809 -77.604)">
          <g className="c" transform="translate(-106.814 -435.636)">
            <path
              className="f"
              d="M43.2-84.681H67.337v24.008H43.2Z"
              transform="translate(57.197 513.898)"
            />
          </g>
        </g>
        <g className="g" transform="translate(40.377 -73.035)">
          <g className="c" transform="translate(-96.382 -440.205)">
            <path
              className="h"
              d="M35.073-81.122h30.25V-59.14H35.073Z"
              transform="translate(54.89 514.908)"
            />
          </g>
        </g>
        <g className="i" transform="translate(40.292 -87)">
          <g className="c" transform="translate(-96.297 -426.24)">
            <path
              className="j"
              d="M35.006-92H65.483v22.161H35.006Z"
              transform="translate(54.872 511.82)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}