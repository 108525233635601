import AuthenticatedApi from './AuthenticatedApi';

export default class AgencyApi {
  
  static update(params) {
    let headerParams = {'Content-Type': 'multipart/form-data'};
    return AuthenticatedApi.post(`/api/update/agency`, headerParams, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

}
