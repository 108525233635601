import React from 'react';

export default function Local({active,className,variant}) {
  return (
      active ?

      <svg fill="#fff"  viewBox='0 0 29 25' className={className ? className :"w-5"}>
        <g id="local_svg__prefix__Grupo_1594" data-name="Grupo 1594">
          <path
            id="local_svg__prefix__Trazado_5487"
            d="M-7614.772-8805.54h1.936s-.779 2.847-2.007 3.051.071-3.051.071-3.051z"
            className="local_svg__prefix__cls-1"
            data-name="Trazado 5487"
            transform="translate(7641.308 8812.158)"
            fill="#2b77b7"
          />
          <path
            id="local_svg__prefix__Trazado_5488"
            d="M.6 0h1.935S1.757 2.847.528 3.051.6 0 .6 0z"
            className="local_svg__prefix__cls-1"
            data-name="Trazado 5488"
            transform="rotate(132 .251 4.779)"
            fill="#2b77b7"
          />
          <g id="local_svg__prefix__shop" transform="translate(.628)">
            <g id="local_svg__prefix__Grupo_1179" data-name="Grupo 1179">
              <g id="local_svg__prefix__Grupo_1178" data-name="Grupo 1178">
                <path
                  id="local_svg__prefix__Trazado_5401"
                  d="M26.837 70.117h-.469V57.892a3.825 3.825 0 001.762-3.218.412.412 0 00-.054-.2l-3.27-5.722a1.3 1.3 0 00-1.123-.651H4.447a1.3 1.3 0 00-1.123.651l-3.27 5.717a.413.413 0 00-.054.2 3.825 3.825 0 001.762 3.218v12.23h-.469a.412.412 0 000 .824h25.544a.412.412 0 000-.824zM.853 55.086h1.321a.412.412 0 000-.824H1.122l2.918-5.106a.47.47 0 01.407-.236h19.236a.47.47 0 01.407.236l2.917 5.105h-1.051a.412.412 0 000 .824h1.321a3 3 0 01-5.945 0h2.862a.412.412 0 100-.824H3.935a.412.412 0 000 .824H6.8a3 3 0 01-5.945 0zm19.6 0a3 3 0 01-5.944 0zm-6.826 0a3 3 0 01-5.944 0zm9.718 15.031h-4.464v-1.378h4.461zm0-2.2h-4.464v-7.546h4.461zm2.2 2.2h-1.379V59.959a.412.412 0 00-.412-.412h-5.285a.412.412 0 00-.412.412v10.158H2.586V58.292A3.821 3.821 0 007.238 56.4a3.825 3.825 0 006.826 0 3.825 3.825 0 006.826 0 3.822 3.822 0 004.653 1.892z"
                  className="local_svg__prefix__cls-2"
                  data-name="Trazado 5401"
                  transform="translate(0 -48.096)"
                />
              </g>
            </g>
            <g
              id="local_svg__prefix__Grupo_1181"
              data-name="Grupo 1181"
              transform="translate(3.964 11.451)"
            >
              <g id="local_svg__prefix__Grupo_1180" data-name="Grupo 1180">
                <path
                  id="local_svg__prefix__Trazado_5402"
                  d="M84.448 256.516H72.557a.412.412 0 00-.412.412v8.372a.412.412 0 00.412.412h11.891a.412.412 0 00.412-.412v-8.368a.412.412 0 00-.412-.416zm-.412 8.368H72.969v-7.544h11.067z"
                  className="local_svg__prefix__cls-2"
                  data-name="Trazado 5402"
                  transform="translate(-72.145 -256.516)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      :
      <svg fill="#2b77b7"  viewBox='0 0 29 29' className={className ? className :"w-5"}>
        <g id="local_svg__prefix__Grupo_1594" data-name="Grupo 1594">
          <path
            id="local_svg__prefix__Trazado_5487"
            d="M-7614.772-8805.54h1.936s-.779 2.847-2.007 3.051.071-3.051.071-3.051z"
            className="local_svg__prefix__cls-1"
            data-name="Trazado 5487"
            transform="translate(7641.308 8812.158)"
            fill="#fff"
          />
          <path
            id="local_svg__prefix__Trazado_5488"
            d="M.6 0h1.935S1.757 2.847.528 3.051.6 0 .6 0z"
            className="local_svg__prefix__cls-1"
            data-name="Trazado 5488"
            transform="rotate(132 .251 4.779)"
            fill="#fff"
          />
          <g id="local_svg__prefix__shop" transform="translate(.628)">
            <g id="local_svg__prefix__Grupo_1179" data-name="Grupo 1179">
              <g id="local_svg__prefix__Grupo_1178" data-name="Grupo 1178">
                <path
                  id="local_svg__prefix__Trazado_5401"
                  d="M26.837 70.117h-.469V57.892a3.825 3.825 0 001.762-3.218.412.412 0 00-.054-.2l-3.27-5.722a1.3 1.3 0 00-1.123-.651H4.447a1.3 1.3 0 00-1.123.651l-3.27 5.717a.413.413 0 00-.054.2 3.825 3.825 0 001.762 3.218v12.23h-.469a.412.412 0 000 .824h25.544a.412.412 0 000-.824zM.853 55.086h1.321a.412.412 0 000-.824H1.122l2.918-5.106a.47.47 0 01.407-.236h19.236a.47.47 0 01.407.236l2.917 5.105h-1.051a.412.412 0 000 .824h1.321a3 3 0 01-5.945 0h2.862a.412.412 0 100-.824H3.935a.412.412 0 000 .824H6.8a3 3 0 01-5.945 0zm19.6 0a3 3 0 01-5.944 0zm-6.826 0a3 3 0 01-5.944 0zm9.718 15.031h-4.464v-1.378h4.461zm0-2.2h-4.464v-7.546h4.461zm2.2 2.2h-1.379V59.959a.412.412 0 00-.412-.412h-5.285a.412.412 0 00-.412.412v10.158H2.586V58.292A3.821 3.821 0 007.238 56.4a3.825 3.825 0 006.826 0 3.825 3.825 0 006.826 0 3.822 3.822 0 004.653 1.892z"
                  className="local_svg__prefix__cls-2"
                  data-name="Trazado 5401"
                  transform="translate(0 -48.096)"
                />
              </g>
            </g>
            <g
              id="local_svg__prefix__Grupo_1181"
              data-name="Grupo 1181"
              transform="translate(3.964 11.451)"
            >
              <g id="local_svg__prefix__Grupo_1180" data-name="Grupo 1180">
                <path
                  id="local_svg__prefix__Trazado_5402"
                  d="M84.448 256.516H72.557a.412.412 0 00-.412.412v8.372a.412.412 0 00.412.412h11.891a.412.412 0 00.412-.412v-8.368a.412.412 0 00-.412-.416zm-.412 8.368H72.969v-7.544h11.067z"
                  className="local_svg__prefix__cls-2"
                  data-name="Trazado 5402"
                  transform="translate(-72.145 -256.516)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
  )
}