import React from 'react';

export function Mall({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 27 27' className={className ? className : "w-5"}>
      <g id="mall_svg__prefix__mall" transform="translate(-.02)">
        <g
          id="mall_svg__prefix__Grupo_1711"
          data-name="Grupo 1711"
          transform="translate(7.1 3.985)"
        >
          <g id="mall_svg__prefix__Grupo_1710" data-name="Grupo 1710">
            <path
              id="mall_svg__prefix__Trazado_23851"
              d="M148.467 80.661s0-.009-.005-.014a6.226 6.226 0 00-2.426-2.851c-.008 0-.014-.01-.022-.013a6.14 6.14 0 00-6.533 0c-.008 0-.014.009-.022.013a6.227 6.227 0 00-2.426 2.851v.014a6.159 6.159 0 00-.475 2.376V87.9a.442.442 0 00.442.442h11.5a.442.442 0 00.442-.442v-4.863a6.157 6.157 0 00-.475-2.376zm-9.259 6.8h-1.77v-2.654h1.77zm0-3.54h-1.77v-.885a5.275 5.275 0 01.31-1.77h1.46zm0-3.54h-1.052a5.338 5.338 0 011.052-1.292zm3.1 7.08h-2.212v-2.654h2.212zm0-3.54h-2.212v-2.654h2.212zm0-3.54h-2.212v-1.935a5.266 5.266 0 012.212-.7zm3.1 7.08h-2.218v-2.654h2.21zm0-3.54h-2.218v-2.654h2.21zm0-3.54h-2.218V77.75a5.266 5.266 0 012.212.7zm.885-1.292a5.338 5.338 0 011.052 1.292h-1.052zm1.77 8.372h-1.77v-2.654h1.77zm0-3.54h-1.77v-2.654h1.46a5.274 5.274 0 01.31 1.77z"
              className="mall_svg__prefix__cls-1"
              data-name="Trazado 23851"
              transform="translate(-136.553 -76.842)"
            />
          </g>
        </g>
        <g
          id="mall_svg__prefix__Grupo_1713"
          data-name="Grupo 1713"
          transform="translate(.02)"
        >
          <g id="mall_svg__prefix__Grupo_1712" data-name="Grupo 1712">
            <path
              id="mall_svg__prefix__Trazado_23852"
              d="M25.684 13.277h-1.327v-1.409a1.324 1.324 0 00-.442-2.573H21.7v-1.88a1.32 1.32 0 00.63-.526 1.305 1.305 0 00.057-1.28 10.18 10.18 0 00-18.19 0 1.306 1.306 0 00.057 1.28 1.32 1.32 0 00.63.526v1.88H2.675a1.324 1.324 0 00-.443 2.573v1.409H.9a.886.886 0 00-.885.885v11.947a.442.442 0 00.442.442h25.67a.442.442 0 00.442-.442V14.162a.886.886 0 00-.885-.885zM2.233 25.667H.905v-11.5h1.328zm2.758-19.66a9.294 9.294 0 0116.608 0 .424.424 0 01-.02.418.465.465 0 01-.77-.012 8.4 8.4 0 00-15.028 0 .429.429 0 01-.381.227.447.447 0 01-.382-.215.425.425 0 01-.027-.418zm-.1 19.66H3.118V11.95h1.77zm.442-14.6H2.675a.442.442 0 010-.885H5.33a.442.442 0 110 .885zm4.867 14.6H7.542v-6.638H10.2zm5.31 0h-4.428v-5.31h4.425zm3.54 0h-2.658v-6.638h2.655zm1.77 0h-.885v-6.638a.886.886 0 00-.885-.885h-2.658a.886.886 0 00-.885.885v.442h-4.425v-.442a.886.886 0 00-.885-.885H7.542a.886.886 0 00-.885.885v6.637h-.885v-8.407h15.045v8.407zm0-9.292H5.772v-4.507a1.32 1.32 0 000-2.492V7.471a1.291 1.291 0 00.8-.66 7.518 7.518 0 0113.447 0 1.29 1.29 0 00.8.66v1.905a1.319 1.319 0 000 2.492zm2.655 9.292H21.7V11.949h1.77zm.442-14.6H21.26a.442.442 0 010-.885h2.655a.442.442 0 110 .885zm1.77 14.6h-1.33v-11.5h1.327z"
              className="mall_svg__prefix__cls-1"
              data-name="Trazado 23852"
              transform="translate(-.02)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
