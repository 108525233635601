import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Input from '../Login/Input'
import { withRouter } from 'react-router-dom'
import { CameraPlusGray } from '../../lib/Icon/icons/Camera'
import CertificatesApi from '../../api/CertificatesApi'
import { ConfirmationAlert, ErrorAlert, InformationAlert } from '../../lib/alerts'
import { editCertificateSubmit } from '../../lib/FormSubmit'
import { CloseDark } from '../../lib/Icon/icons/Close'
import LoadingModal from '../Loading/LoadingModal'
import { compressImages } from '../../lib/compressImages'
import { InfoRed } from '../../lib/Icon/icons/Info'

class EditCertificateModal extends PureComponent {


  state ={
    id: this.props.certificate.id,
    name:this.props.certificate.name ? this.props.certificate.name : '' ,
    serialNumber:this.props.certificate.serialNumber,
    loadingCertificate:false,
    file:null,
    imagesError:0
  }
  hiddenFileInput = React.createRef()


  submitEditCertificate = () => {

    editCertificateSubmit(
      this.state,
      () => this.setState({ disableEditCertificateButtons: false }),
      (data, id) => {
        this.setState({loadingCertificate:true},()=>{
          CertificatesApi.update(id, data).then(result => {
            this.setState({loadingCertificate:false},()=>{
              const finish = () => {
                this.props.refresh()
                this.props.close()
              }
              InformationAlert(
                'Listo',
                'Se ha actualizado la información de tu certificado',
                () => {finish()},
                () => {finish()},
              );
            })
          }).catch(error => {
            console.log(error.response?.data)
            ErrorAlert(
              'Hubo un error actualizando tu certificado. Intenta de nuevo más tarde.',
              this.setState({
                loadingCertificate:false,
              })
            );
          });
        })
      }
    );

  }


  _deleteCertificate = () => {
    ConfirmationAlert(
      '¿Estás seguro que deseas eliminar este certificado?',
      () => {
        this.setState({loadingCertificate:true},()=>{
          CertificatesApi.delete(this.state.id).then(result => {
            this.setState({loadingCertificate:false},()=>{
              const finish = () => {
                this.props.refresh()
                this.props.close()
              }
              InformationAlert(
                'Listo',
                'Tu certificado ha sido eliminado.',
                () => {finish()},
                () => {finish()},
              );
            })
          }).catch(error => {
            ErrorAlert(
              'Hubo un error eliminando tu certificado. Intenta de nuevo más tarde',
              this.setState({
                loadingCertificate:false,
                disableEditCertificateButtons: false
              })
            );
          });
        })
      }
    );
  }


  _handleFile = e => {

    if(e.target.files[0])
    {
      this.setState({loadingCertificate:true}, async() => {
  
        const {images,imagesError} = await compressImages([e.target.files[0]],1)
        
        this.setState({file:images.length > 0 ? images[0] : null,loadingCertificate:false,imagesError})
  
      })
    }


  }


  render() {
    const {visible,close} = this.props
    if (!visible)
      return null
    return (
      <div className="absolute flex mx-auto left-0 right-0 items-center justify-center top-20">
        <div className="z-30">
          <LoadingModal visible={this.state.loadingCertificate}/>
        </div>
        <input 
          ref={this.hiddenFileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={this._handleFile}
          maxLength="1"/>
        <div className="fixed z-10  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>
        </div>
        <div onClick={ e => e.stopPropagation()} className="bg-white z-20 flex flex-col" 
          style={{ 
            borderRadius:9,
            width:742,
            paddingLeft:41,
            paddingRight:41,
            paddingTop:24,
            paddingBottom:60
          }}>
          <div className="flex flex-row justify-end" style={{ marginBottom:34 }}>
            <div onClick={close} className="cursor-pointer">
              <CloseDark style={{widht:20,height:20}}/>
            </div>
          </div>
           <div className="flex flex-row justify-between items-center">
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:23 }}>Editar certificado</p>
            <div className="flex flex-row items-center">
              <p onClick={this._deleteCertificate} className="cursor-pointer text-redaria-blue poppins-medium mr-10" style={{ fontSize:19 }}>Eliminar</p>
              <button onClick={this.submitEditCertificate} className="redaria-button-white" style={{ width:193 }}>Guardar</button>
            </div>
          </div>
          
          <div className="mt-5 w-full" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>
          
          <p className="text-redaria-black poppins-semi-bold " style={{ fontSize:16,marginTop:13 }}>Imagen:</p>
          
          {
            this.state.file ?
            <div onClick={ e => {
              e.stopPropagation()
              this.hiddenFileInput.current.click()
              }} className="cursor-pointer flex flex row items-center justify-center">
              <div className="flex relative flex-col items-end justify-center">
                <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
                  <CameraPlusGray style={{width:144}}/>
                </div>
                <img alt="edit certificate" className="rounded-full avatar" style={{ width:164,height:164 }} src={ URL.createObjectURL(this.state.file)}/>
              </div>
            </div>
            :
            <div onClick={ e => {
              e.stopPropagation()
              this.hiddenFileInput.current.click()
              }} className="cursor-pointer flex flex row items-center justify-center">
              <div className="flex relative flex-col items-end justify-center">
                <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
                  <CameraPlusGray style={{width:144}}/>
                </div>
                <img alt="edit certificate" className="rounded-full avatar" style={{ width:164,height:164 }} src={this.props.certificate.image[0].path}/>
              </div>
            </div>
          }

          {
            this.state.imagesError > 0 &&
            <div className="flex relative flex-row bg-gray-200 w-full h-24" style={{ borderRadius:9,marginTop:10,marginBottom:15 }}>
              <div className="bg-red-500 absolute left-0 top-0 bottom-0" style={{width:8,borderTopLeftRadius:9,borderBottomLeftRadius:9}}/>
              <div className="flex flex-row py-5 px-10 items-center">
                <div >
                  <InfoRed/>
                </div>
                <div className="">
                  <p className="questrial text-redaria-black ml-5" style={{ fontSize:18 }}>
                    La foto no cumplió con los requisitos. Debe ser en formato JPG, JPEG o PNG.
                  </p>  
                </div>

              </div>
            </div>
          }

          <p className="text-redaria-black poppins-semi-bold " style={{ fontSize:16 }}>Información:</p>

          <div className="flex flex-row">
            <div className="mr-5 w-full">
            <Input
              labelID="name"
              name="Nombre del certificado"
              value={this.state.name}
              onChange={name => this.setState({name})}
              type="text"
              />
            </div>
            <div className="ml-5 w-full">
            <Input
              labelID="email"
              name="No. De certificado (Opcional)"
              value={this.state.serialNumber}
              onChange={serialNumber => this.setState({serialNumber})}
              type="text"
              />
            </div>
          </div>


        </div>
        
      </div>
    )
  }
}

export default connect()(withRouter(EditCertificateModal));
