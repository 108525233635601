import React from 'react';

export function TvRoom({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 27 20" className={className ? className : "w-5"}>
      <g
        id="television_svg__prefix__television"
        transform="translate(0 -17.266)"
      >
        <path
          id="television_svg__prefix__Trazado_23774"
          d="M25.675 17.266H1.208A1.209 1.209 0 000 18.474v14.28a1.209 1.209 0 001.208 1.208h2.075l-.644.932a1.277 1.277 0 102.1 1.452l1.648-2.384H20.5l1.647 2.384a1.277 1.277 0 102.1-1.452l-.644-.932h2.076a1.209 1.209 0 001.208-1.208v-14.28a1.209 1.209 0 00-1.208-1.208zM4.135 35.928a.542.542 0 01-.892-.616l.933-1.35h1.318zm19.5-.616a.542.542 0 01-.892.616l-1.358-1.966h1.317zm2.509-2.558a.473.473 0 01-.473.473H1.208a.473.473 0 01-.473-.473v-14.28A.473.473 0 011.208 18h24.467a.473.473 0 01.473.473z"
          className="television_svg__prefix__cls-1"
          data-name="Trazado 23774"
        />
        <path
          id="television_svg__prefix__Trazado_23775"
          d="M31.155 25.266H8.368a.367.367 0 00-.368.368v12.6a.368.368 0 00.368.368h22.787a.368.368 0 00.368-.368v-12.6a.367.367 0 00-.368-.368zm-.368 12.6H8.735V26h22.052z"
          className="television_svg__prefix__cls-1"
          data-name="Trazado 23775"
          transform="translate(-6.32 -6.32)"
        />
      </g>
    </svg>
  )
}
