import React, { PureComponent } from 'react'
import { LeftArrow } from '../../lib/Icon/icons/Arrows'
import { AdditionalAmmenities } from '../Forms/AdditionalAmmenities'
import { AmmenitiesForm } from '../Forms/AmmenitiesForm'
import { NearbyPlacesForm } from '../Forms/NearbyPlacesForm'
import { SectionDivider } from '../Forms/SectionDivider'

export default class MoreInformation extends PureComponent {
  render() {
    return (
      <div className="w-11/12">

        <div>
          {
            this.props.propertyType !== 2 &&
            <div>
              <SectionDivider
                description='Agrega opciones de valor a tu propiedad'
                title='Características'
              />
              <AmmenitiesForm
                active={true}
                ammenities={this.props.ammenities}
                toggleAmmenity={id => this.props.toggleAmmenity(id)}
              />
              <SectionDivider
                description='Cuenta con...'
                title='Amenidades'
              />
              <AdditionalAmmenities
                active={true}
                ammenities={this.props.ammenities}
                toggleAmmenity={id => this.props.toggleAmmenity(id)}
              />
            </div>
          }
          <SectionDivider
            description='Lugares aledaños cool'
            title='Alrededores'
          />
          <NearbyPlacesForm
            active={true}
            nearbyPlaces={this.props.nearbyPlaces}
            toggleNearbyPlace={id => this.props.toggleNearbyPlace(id)}
          />
        </div>

        <div className="bg-gray-300 mt-10 w-full" style={{ height: 1, }} />
        <div className="flex flex-row items-center justify-end ">
          <div className="w-72 mr-10">
            <button onClick={() => {
             this.props.goBack()
            }} className="redaria-button flex flex-row items-center justify-center  remove-background mt-10 self-center mb-10">
              <LeftArrow stroke="#2b77b7" fill="#2b77b7" style={{ width:20 }} className="mr-3"/>
              <span>Atras</span>
            </button>
          </div>
          <div className="w-72">
            <button onClick={() => {
             this.props.submitFinish()
            }} className="redaria-button-blue mt-10 self-center mb-10">Publicar</button>
          </div>
        </div>

      </div>
    )
  }
}
