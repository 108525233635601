import React, {PureComponent} from 'react';
import { LocationBig } from '../../lib/Icon/icons/Location';
import GoogleMap from 'google-map-react';


export default class Map extends PureComponent {
  
  constructor(props) {
    super(props);

    this.state = {
      defaultLocation: { 
        lat: Number(this.props.latitude),
        lng: Number(this.props.longitude)
      },
      center: { 
        lat: Number(this.props.latitude),
        lng: Number(this.props.longitude)
      },
    }
  }



  render() {
    return(
      <div className="flex flex-col mt-10 mb-24">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Ubicación:</p>
        <div className="flex flex-row items-center">
          <LocationBig style={{width: 12,height: 17}}/>
          <p className="poppins-regular text-redaria-blue ml-2" style={{ fontSize:15 }}>
            {`${this.props.neighborhood}, ${this.props.city}, ${this.props.state}`}
          </p>
        </div>

        <div style={{ height: '500px', width: '100%',borderRadius:4,overflow: 'hidden',boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)" }} className="mt-4">
          <GoogleMap
            options={{ scrollwheel: false,}}
            id="mapa"
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
            zoom={14}
            center={this.state.defaultLocation}
            onGoogleApiLoaded={({map, maps}) =>
            new maps.Circle({
              strokeColor: 'rgb(43, 119, 183)',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: 'rgb(43, 119, 183)',
              fillOpacity: 0.3,      
              map,
              center: this.state.defaultLocation,
              radius: 1250
            })}
          />
        </div>
        
      </div>
    );
  }
}
