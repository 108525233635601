// Action types
export const SAVE_USER = 'SAVE_USER';
export const DELETE_USER = 'DELETE_USER';

// Actions
export const saveUser = (user) => {
  return {
    type: SAVE_USER,
    user
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER
  };
};
