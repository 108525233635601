import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LogoRedaria from '../../assets/images/icon_redaria.png';
import LeftContainer from '../../components/Welcome/LeftContainer';
import RightContainer from '../../components/Welcome/RightContainer';


export default function Welcome() {

  const history = useHistory();
  
  const user = useSelector(state => state.authenticatedUser)


  const _handleLogin = () => {
    if(user.attributes){
      history.push('/signed/me/properties');
    }
  }

  useEffect(_handleLogin, [user,history])


  return (
    <div className="min-h-full flex flex-1 flex-col">
      <div className="flex flex-row items-center mt-10 ml-10">
        <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
        <p className="questrial font-semibold text-redaria-blue" style={{ fontSize:27 }}>redaria</p>
      </div>
    <div className="grid grid-cols-2 ml-10">
      <LeftContainer/>
      <RightContainer/>
    </div>


    </div>
  )
}
