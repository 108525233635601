import States from './States';
import Cities from './Cities';
import _ from 'lodash';

export const getshorName = (name) => {
  
  const state = States.find(s => {
    return s.name === name;
  });
  return state.shortname

}

export const getState = (address) => {
  return address.state;
}

export const getCity = (address) => {
  return address.city;
}

export const getNeigborhood = (address) => {
  return address.neighborhood;
}

export const getStreet = (address) => {
  return address.street;
}

export const validateAddress = (components) => {
  const neighborhood = components.find(element => {
    return element.types.find(type => {
      return type === 'sublocality_level_1';
    });
  });

  if(neighborhood !== undefined) {
    return true;
  }
  else {
    return false;
  }
}

export const validateFullAddress = (components) => {
  const city = getValueFromGoogle(components, 'locality');
  const neighborhood = getValueFromGoogle(components, 'sublocality_level_1');
  const number = getValueFromGoogle(components, 'street_number');
  const state = getValueFromGoogle(components, 'administrative_area_level_1');
  const street = getValueFromGoogle(components, 'route');

  if(city === undefined || neighborhood === undefined || number === undefined || state === undefined || street === undefined) {
    return false;
  }
  else {
    return true;
  }
}

export const buildAddressFromGoogle = (components, geometry) => {
  const neighborhood = components.find(element => {
    return element.types.find(type => {
      return type === 'sublocality_level_1';
    });
  });
  const city = components.find(element => {
    return element.types.find(type => {
      return type === 'locality';
    });
  });
  const state = components.find(element => {
    return element.types.find(type => {
      return type === 'administrative_area_level_1';
    });
  });

  return Object.assign({
    latitude: geometry.location.lat,
    longitude: geometry.location.lng,
    neighborhood: neighborhood.long_name,
    city: city.long_name,
    state: state.long_name
  });
}

export const getValueFromGoogle = (components, componentType) => {
  return components.find(element => {
    return element.types.find(type => {
      return type === componentType;
    });
  });
}

export const getCitiesFromState = stateName => {
  if(stateName === '') {
    return [];
  }

  const stateObject = States.find(state => {
    return state.name === stateName;
  });

  if (!stateObject) return [];

  return _.orderBy(Cities.filter(city => {
    return city.state_id === stateObject.id;
  }), ['name'], ['asc']);
}
