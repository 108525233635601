import React, {PureComponent} from 'react';

export class TextInputWithPlaceholder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      borderColor: '#112233'
    };
  }

  
  _onChange = (e) => {
    this.props.onChangeText(e.target.value)
  }

  render() {

    const paddingBottom = this.props.paddingBottom ? this.props.paddingBottom : 0

    return(
      <div className={`${!this.props.removeContainer ? 'bg-white rounded-lg shadow-md p-5 mx-2 mt-5' : 'mt-5 w-full'}`}>
        {
          this.props.label &&
          <React.Fragment>
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>
              {this.props.label}
            </p>
          </React.Fragment>
        }
        
        <div className="redaria-full-input-container mt-1 flex flex-row" style={{ paddingBottom }}>
          {
            this.props.leftIcon && 
            this.props.leftIcon
          }
          <input
            
            maxLength={this.props.maxLength}
            onChange={this._onChange}
            placeholder={this.props.placeholder}
            type={this.props.type ? this.props.type : 'text'}
            // name={'input-' + name}
            className="redaria-input"
            value={this.props.value}/>
        </div>
        {
          this.props.emptyField &&
          <span className="text-red-500">
            {`\nCampo requerido`}
          </span>
        }
      </div>
    );
  }
}

