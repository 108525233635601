import React from 'react';

export default function Dimensions(props) {
  return (
    <svg viewBox="0 0 27 30" {...props}>
      <g>
        <g data-name="Group 13">
          <path
            data-name="Fill 1"
            d="M25.152 12.512v4.051h-5.123v5.071h-4.13v-9.122z"
            fill="#ebebec"
          />
          <path
            data-name="Fill 2"
            d="M25.152 12.512v4.051h-5.123v5.071h-2.34v-9.122z"
            fill="#fff"
          />
          <g data-name="Group 12">
            <g data-name="Group 5">
              <path data-name="Clip 4" d="M0 0h26.968v29.708H0z" fill="none" />
              <g data-name="Group 5" clipPath="url(#dimensiones_svg__a)">
                <path
                  data-name="Fill 3"
                  d="M25.09 29.708a.44.44 0 01-.315-.131.426.426 0 010-.618l.677-.66H3.958l.671.66a.426.426 0 010 .618.443.443 0 01-.628 0l-1.433-1.408a.426.426 0 010-.618L4 26.143a.443.443 0 01.628 0 .426.426 0 010 .618l-.671.66h21.495l-.671-.66a.426.426 0 010-.618.443.443 0 01.628 0l1.432 1.408a.412.412 0 01.085.475v.006a.443.443 0 01-.091.137L25.4 29.577a.44.44 0 01-.31.131zM1.879 24.122a.441.441 0 01-.314-.13L.133 22.584a.429.429 0 010-.618.443.443 0 01.628 0l.671.66V1.491l-.671.665a.443.443 0 01-.628 0 .426.426 0 010-.618L1.565.125A.459.459 0 011.879 0a.44.44 0 01.314.131l1.432 1.4a.425.425 0 010 .618.461.461 0 01-.314.125A.438.438 0 013 2.151l-.671-.66v21.14L3 21.972a.441.441 0 01.314-.131.431.431 0 01.314.743l-1.434 1.408a.441.441 0 01-.315.13z"
                  fill="#223242"
                />
              </g>
            </g>
            <path data-name="Clip 7" d="M0 29.708h26.968V0H0z" fill="none" />
            <g clipPath="url(#dimensiones_svg__b)">
              <path
                data-name="Fill 6"
                d="M18.001 22.346h5.97V16.41h-5.97z"
                fill="#ebebec"
              />
              <path
                data-name="Fill 8"
                d="M20.419 22.346h3.994V16.41h-3.994z"
                fill="#fff"
              />
              <path
                data-name="Fill 9"
                d="M19.144 4.224v5.835h-7.236a.468.468 0 00-.471.463V22.47H7.608V4.224z"
                fill="#ebebec"
              />
              <path
                data-name="Fill 10"
                d="M19.567 4.224v5.835h-7.233a.468.468 0 00-.471.463V22.47H9.421V4.224z"
                fill="#fff"
              />
              <path
                data-name="Fill 11"
                d="M24.394 23.41H7.137a.468.468 0 01-.472-.464V3.762a.468.468 0 01.472-.464h12.484a.468.468 0 01.47.464v6.3h4.3a.479.479 0 01.478.464v12.42a.471.471 0 01-.475.464zM12.321 10.885v11.593h4.793v-6.94h6.919v-4.653zm-4.713-6.66v18.253h3.83V10.529a.479.479 0 01.472-.47h7.233V4.225zm10.393 12.186v5.935h5.971v-5.935z"
                fill="#223242"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export function DimensionsGrey({className}) {
  return (
    <svg viewBox="0 0 25.03 20.127" className={className ? className : "w-7"}>
      <g data-name="Grupo 1441">
        <g data-name="Grupo 1440">
          <path
            d="M11.516 15.351a.329.329 0 0 0 .166-.614l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.328.328 0 0 0 .165.044z"
            data-name="Trazado 5551"
          />
          <path
            d="M10.878 14.27l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5552"
          />
          <path
            d="M10.074 13.804l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5553"
          />
          <path
            d="M9.27 13.339l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5554"
          />
          <path
            d="M8.466 12.871l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5555"
          />
          <path
            d="M7.662 12.399l-.161-.093a.33.33 0 0 0-.331.57l.161.093a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5556"
          />
          <path
            d="M6.858 11.937l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 1 0 .331-.57z"
            data-name="Trazado 5557"
          />
          <path
            d="M6.053 11.471l-.16-.093a.33.33 0 0 0-.331.569l.16.093a.33.33 0 0 0 .331-.569z"
            data-name="Trazado 5558"
          />
          <path
            d="M5.251 11.004l-.161-.094a.33.33 0 0 0-.331.569l.161.094a.33.33 0 1 0 .331-.569z"
            data-name="Trazado 5559"
          />
          <path
            d="M4.447 10.538l-.643-.373a.33.33 0 0 0-.331.569l.16.093-.2.117a.33.33 0 0 0 .339.565l.674-.4a.329.329 0 0 0 0-.567z"
            data-name="Trazado 5560"
          />
          <path
            d="M18.01 9.223l.161.093a.33.33 0 1 0 .331-.57l-.161-.093a.33.33 0 0 0-.331.57z"
            data-name="Trazado 5561"
          />
          <path
            d="M18.814 9.69l.322.187a.33.33 0 0 0 .331-.57l-.322-.187a.33.33 0 0 0-.331.57z"
            data-name="Trazado 5562"
          />
          <path
            d="M20.275 9.771l-.161-.093a.33.33 0 1 0-.331.57l.161.093a.33.33 0 1 0 .331-.57z"
            data-name="Trazado 5563"
          />
          <path
            d="M21.556 10.52l-.161-.093.2-.117a.33.33 0 1 0-.339-.565l-.674.4a.329.329 0 0 0 0 .567l.643.373a.33.33 0 1 0 .331-.57z"
            data-name="Trazado 5564"
          />
          <path
            d="M19.736 10.655l-.169.1a.33.33 0 1 0 .338.565l.169-.1a.33.33 0 0 0-.338-.565z"
            data-name="Trazado 5565"
          />
          <path
            d="M18.888 11.16l-.168.1a.33.33 0 0 0 .34.565l.169-.1a.33.33 0 0 0-.34-.565z"
            data-name="Trazado 5566"
          />
          <path
            d="M18.05 11.666l-.168.1a.33.33 0 1 0 .338.565l.168-.1a.33.33 0 0 0-.338-.565z"
            data-name="Trazado 5567"
          />
          <path
            d="M17.205 12.172l-.169.1a.33.33 0 0 0 .338.565l.169-.1a.33.33 0 1 0-.338-.565z"
            data-name="Trazado 5568"
          />
          <path
            d="M16.693 12.671a.33.33 0 0 0-.33.006l-.169.1a.33.33 0 0 0 .34.565l.169-.1a.33.33 0 0 0-.01-.571z"
            data-name="Trazado 5569"
          />
          <path
            d="M15.852 13.177a.329.329 0 0 0-.33.006l-.169.1a.33.33 0 1 0 .338.565l.169-.1a.329.329 0 0 0-.009-.571z"
            data-name="Trazado 5570"
          />
          <path
            d="M15.008 13.683a.329.329 0 0 0-.33.006l-.168.1a.33.33 0 1 0 .338.565l.168-.1a.329.329 0 0 0-.009-.571z"
            data-name="Trazado 5571"
          />
          <path
            d="M14.165 14.191a.33.33 0 0 0-.33.006l-.169.1a.33.33 0 0 0 .34.565l.169-.1a.33.33 0 0 0-.01-.571z"
            data-name="Trazado 5572"
          />
          <path
            d="M12.993 14.7l-.169.1a.33.33 0 0 0 .34.565l.168-.1a.33.33 0 0 0-.34-.565z"
            data-name="Trazado 5573"
          />
          <path
            d="M6.81 9.634a.329.329 0 0 0 .169-.047l.169-.1a.33.33 0 1 0-.34-.565l-.169.1a.329.329 0 0 0 .17.612z"
            data-name="Trazado 5574"
          />
          <path
            d="M5.798 10.241a.326.326 0 0 0 .169-.047l.337-.2a.33.33 0 1 0-.338-.565l-.337.2a.329.329 0 0 0 .169.612z"
            data-name="Trazado 5576"
          />
          <path
            d="M4.956 10.743a.329.329 0 0 0 .169-.047l.169-.1a.33.33 0 1 0-.34-.565l-.169.1a.329.329 0 0 0 .17.612z"
            data-name="Trazado 5577"
          />
          <path
            d="M13.128 15.576l-.643-.373a.33.33 0 0 0-.335 0l-.674.4a.33.33 0 0 0 .339.565l.507-.3.475.276a.33.33 0 0 0 .331-.57z"
            data-name="Trazado 5578"
          />
          <path
            d="M25 10.218v-.016a.327.327 0 0 0-.132-.149l-6.985-4.054L14.569.164a.327.327 0 0 0-.083-.094s-.011 0-.015-.008a.328.328 0 0 0-.1-.047.332.332 0 0 0-.06 0 .323.323 0 0 0-.064 0 .33.33 0 0 0-.112.037h-.012l-3.977 2.3h-.005L6.053 4.89a.329.329 0 0 0-.113.44l.767 1.376-6.546 3.926a.329.329 0 0 0-.055.044l-.012.01a.326.326 0 0 0-.058.087.315.315 0 0 0-.015.036.324.324 0 0 0-.021.084v1.999a.31.31 0 0 0 .01.052.32.32 0 0 0 .009.047.317.317 0 0 0 .036.074.312.312 0 0 0 .023.035.32.32 0 0 0 .066.058c.008.005.012.014.021.018l11.9 6.906c.007 0 .015.006.023.009l.033.014a.329.329 0 0 0 .1.02h.028a.326.326 0 0 0 .092-.018.32.32 0 0 0 .04-.017c.007 0 .015-.005.022-.009l12.475-7.482a.31.31 0 0 0 .035-.032.351.351 0 0 0 .1-.14.321.321 0 0 0 .017-.084c0-.009.005-.017.005-.027V10.333a.326.326 0 0 0-.035-.115zM14.153.778l3.816 6.719-3.406 1.967-3.812-6.718zm2.3 8.353v1.882l-3.32 1.917v-2.319l.052.088a.329.329 0 0 0 .461.11l.96-.613zM6.668 5.282l3.518-2.2L14 9.804l-.417.266-3-5.094a.329.329 0 0 0-.446-.119l-2.818 1.6zm4.4 4.636l-1.275-.736a.329.329 0 0 0-.494.285v1.623l-1.256-.724V6.803l2.14-1.214L12.5 9.514a.312.312 0 0 0-.012.06v3.355l-1.252-.723v-2a.329.329 0 0 0-.17-.288zm-.494.475v1.433l-.616-.356v-1.432zm1.326 8.832L.659 12.7v-1.214L11.9 18.011zm.326-1.787L.979 10.91l6.146-3.687a.33.33 0 0 0 .233-.032l.026-.015v3.38a.329.329 0 0 0 .165.29l1.913 1.1.088.051 3.1 1.788a.32.32 0 0 0 .057.024c.007 0 .014.007.022.009a.335.335 0 0 0 .057.007h.027a.328.328 0 0 0 .164-.044l3.977-2.3.025-.022a.32.32 0 0 0 .06-.053.454.454 0 0 0 .061-.109.318.318 0 0 0 .01-.049.313.313 0 0 0 .01-.052v-2.45l.047-.027a.31.31 0 0 0 .038.034l.161.093a.329.329 0 0 0 .424-.487l.8-.46a.329.329 0 0 0 .121-.45l-.179-.315 5.521 3.212zm12.144-5.309l-11.814 7.086v-1.208l11.814-7.086v1.208z"
            data-name="Trazado 5579"
          />
        </g>
      </g>
    </svg>
  )
}