export const buildNearbyPlacesFromRequest = (nearbyPlaces) => {
  return Array.from(nearbyPlaces, nearbyPlace => nearbyPlace.id);
}

export const NearbyPlaces = [
  {
    id: 1,
    name: 'Parque',
    slug: 'park',
    icon: 'park-blue',
    viewBox: '0 0 27 27'
  },
  {
    id: 2,
    name: 'Escuelas',
    slug: 'school',
    icon: 'school-blue',
    viewBox: '0 0 31 28'
  },
  {
    id: 3,
    name: 'Restaurantes',
    slug: 'restaurant',
    icon: 'restaurant-blue',
    viewBox: '0 0 23 28'
  },
  {
    id: 4,
    name: 'Gym',
    slug: 'gym',
    icon: 'gym-blue',
    viewBox: '0 0 32 20'
  },
  {
    id: 5,
    name: 'Centro Comercial',
    slug: 'mall',
    icon: 'mall-blue',
    viewBox: '0 0 27 27'
  },
  {
    id: 6,
    name: 'Hospital',
    slug: 'hospital',
    icon: 'hospital-blue',
    viewBox: '0 0 21 27'
  }
];
