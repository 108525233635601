// Action types
export const SET_TOKEN = 'SET_TOKEN';

// Actions
export const setLocalFirebaseToken = (token) => {
  return {
    type: SET_TOKEN,
    value:{
      token
    }
  };
};

