import React, { PureComponent } from 'react'
import DealType from './DealType'
import moment from 'moment'
require("moment/min/locales.min");
export class TimeAndType extends PureComponent {

  render() {
    moment.locale('es')
    return (
      <div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center justify-center px-3 py-2" style={{ backgroundColor:"rgba(43, 119, 183, 0.12)",borderRadius:5 }}>
            <p className="questrial" style={{ fontSize:10,color:"#2B77B7" }}>{moment.utc(this.props.createdAt.date).local().fromNow()}</p>
          </div>
          <div className="flex flex-row justify-end">
            <DealType dealType={this.props.type} className="mr-2" />
            <DealType dealType={this.props.dealType} />
          </div>
        </div>
      </div>
    )
  }
}

export default TimeAndType
