import React from 'react';

export default function ShareProperty(props) {
  return (
    <svg viewBox="0 0 42 50" {...props}>
      <g>
        <g id="boton-compartir-w-f_svg__Grupo_1262">
          <g id="boton-compartir-w-f_svg__Trazado_5477">
            <path
              d="M21.9 38.8c-5.2 0-10.1-2-13.8-5.6s-5.7-8.4-5.7-13.5 2-9.9 5.7-13.5C11.8 2.5 16.7.6 21.9.6s10.1 2 13.8 5.6 5.7 8.4 5.7 13.5-2 9.9-5.7 13.5c-3.7 3.6-8.6 5.6-13.8 5.6z"
              fill="#68d058"
            />
            <path
              fill="#fff"
              className="boton-compartir-w-f_svg__st1"
              d="M21.9 1.1c-5.1 0-9.8 1.9-13.4 5.4s-5.6 8.2-5.6 13.1 2 9.6 5.6 13.1c3.6 3.5 8.4 5.4 13.4 5.4s9.8-1.9 13.4-5.4 5.6-8.2 5.6-13.1-2-9.6-5.6-13.1C31.8 3 27 1.1 21.9 1.1m0-1.1C33 0 42 8.8 42 19.7s-9 19.7-20.1 19.7S1.8 30.5 1.8 19.7 10.8 0 21.9 0z"
            />
          </g>
        </g>
        <g
          id="boton-compartir-w-f_svg__facebook_2_"
          transform="translate(-6996.133 -14766.04)"
        >
          <path
            fill="#fff"
            id="boton-compartir-w-f_svg__Trazado_5483"
            className="boton-compartir-w-f_svg__st1"
            d="M7025.7 14779.7c3.5 0 6.4 2.9 6.4 6.4s-2.9 6.4-6.4 6.4-6.4-2.9-6.4-6.4c0-3.6 2.9-6.4 6.4-6.4z"
          />
          <path
            fill="#68d058"
            id="boton-compartir-w-f_svg__Trazado_5480"
            className="boton-compartir-w-f_svg__st2"
            d="M7027.4 14786.3h-1.1v4.2h-1.7v-4.2h-.8v-1.5h.8v-1c-.1-.9.6-1.7 1.5-1.7h1.6v1.4h-.9c-.2 0-.4.1-.4.3v1h1.3l-.3 1.5z"
          />
        </g>
        <g
          id="boton-compartir-w-f_svg__whatsapp_1_"
          transform="translate(-7010.104 -14766.04)"
        >
          <path
            fill="#fff"
            id="boton-compartir-w-f_svg__Trazado_5481"
            className="boton-compartir-w-f_svg__st1"
            d="M7024.2 14779.7c-3.5 0-6.4 2.9-6.4 6.4 0 1.4.4 2.7 1.2 3.8l-.8 2.4 2.5-.8c3 1.9 6.9 1.1 8.9-1.8s1.1-6.9-1.8-8.9c-1.1-.8-2.3-1.1-3.6-1.1z"
          />
          <path
            fill="#68d058"
            id="boton-compartir-w-f_svg__Trazado_5482"
            className="boton-compartir-w-f_svg__st2"
            d="M7028 14788.7c-.3.5-.7.8-1.3.9-.3.1-.8.1-2.2-.5-1.3-.6-2.4-1.6-3.2-2.8-.4-.6-.7-1.2-.8-1.9 0-.6.2-1.2.7-1.6.2-.2.4-.2.7-.2h.2c.2 0 .3 0 .4.3.2.4.5 1.3.6 1.4.1.1.1.2 0 .3s-.1.2-.2.3l-.3.3c-.1.1-.2.2-.1.4.3.5.6.9 1 1.3s.9.7 1.5.9c.2.1.3 0 .5-.1.2-.2.3-.4.5-.7.1-.2.3-.2.5-.1s1.1.5 1.3.6c.2.1.3.1.4.2-.1.4-.1.7-.2 1z"
          />
        </g>
      </g>
    </svg>
  )
}