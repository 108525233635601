import React from 'react';

export function Gym({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 32 20' className={className ? className : "w-5"}>
      <g id="gym_svg__prefix__gym" transform="translate(.001)">
        <path
          id="gym_svg__prefix__Trazado_23843"
          d="M29.183 6.66h-.605V3.935a2.421 2.421 0 00-3.7-2.059A2.425 2.425 0 0022.523 0 2.425 2.425 0 0020.1 2.422V6.66h-9.2V2.422a2.422 2.422 0 00-4.782-.546 2.421 2.421 0 00-3.7 2.059V6.66h-.602A1.819 1.819 0 000 8.476V10.9a1.819 1.819 0 001.816 1.816h.605v2.725a2.421 2.421 0 003.7 2.059 2.425 2.425 0 002.359 1.876 2.425 2.425 0 002.42-2.423v-4.238h9.2v4.238a2.422 2.422 0 004.782.546 2.421 2.421 0 003.7-2.059v-2.725h.605A1.819 1.819 0 0031 10.9V8.476a1.819 1.819 0 00-1.817-1.816zM2.421 11.5h-.605a.606.606 0 01-.606-.6V8.476a.606.606 0 01.605-.605h.605zm3.633 3.936a1.211 1.211 0 01-2.422 0V3.935a1.211 1.211 0 112.422 0zm3.633 1.514a1.212 1.212 0 01-1.211 1.211 1.213 1.213 0 01-1.211-1.211V2.422a1.211 1.211 0 012.423 0zM20.1 9.082h-4.238a.605.605 0 000 1.211H20.1V11.5h-9.2V7.871h9.2zm3.637 7.871a1.211 1.211 0 01-2.423 0V9.718a.356.356 0 010 .039v-.14a.356.356 0 010 .039V2.422a1.212 1.212 0 011.211-1.211 1.213 1.213 0 011.211 1.211zm3.633-1.514a1.211 1.211 0 11-2.422 0V3.935a1.211 1.211 0 012.422 0zm2.418-4.539a.606.606 0 01-.605.605h-.605V7.871h.605a.606.606 0 01.605.605zm0 0"
          className="gym_svg__prefix__cls-1"
          data-name="Trazado 23843"
        />
        <path
          id="gym_svg__prefix__Trazado_23844"
          d="M212.622 151.211a.605.605 0 010-1.211.605.605 0 010 1.211zm0 0"
          className="gym_svg__prefix__cls-1"
          data-name="Trazado 23844"
          transform="translate(-199.18 -140.918)"
        />
      </g>
    </svg>
  )
}
