import React, { PureComponent } from 'react'

export class QuestionOption extends PureComponent {
  render() {
    let containerStyle = ""
    if(this.props.isActive)
    {
      containerStyle = "redaria-button-blue"
    }
    else
    {
      containerStyle = "redaria-button-white items-center flex justify-center"
    }

    return (
      <div style={{ width:166 }}>
        <div className={containerStyle} onClick={this.props.onPress} >
          {this.props.text}
        </div>
      </div>
    )
  }
}

export default QuestionOption
