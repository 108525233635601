import React, { PureComponent } from 'react'
import { ErrorAlert, InformationAlert, } from '../../lib/alerts'
import { CloseDark } from '../../lib/Icon/icons/Close'
import LoadingModal from '../Loading/LoadingModal'
import PropertiesApi from '../../api/PropertiesApi'
import uniqid from 'uniqid';
import { MassiveAlert } from '../../lib/alerts/MassiveAlert'
import Table from 'react-tailwind-table';
import 'react-tailwind-table/dist/index.css';
import LoadingSmall from '../Loading/LoadingSmall'
import { InfoBlue } from '../../lib/Icon/icons/Info'

export default class ModalPropertyMasive extends PureComponent {

  state = {
    disabledForm: false,
    document: {},
    documentString: '',
    alreadadyUploaded: false,
    activeTab: 0,
    loadingXml: true,
    xmlHistory: []
  }

  hiddenFileInput = React.createRef()

  handleClick = () => {
    this.hiddenFileInput.current.click();
  };

  dragOver = (e) => {
    e.preventDefault();
  }

  dragEnter = (e) => {
    this.setState({ dragEnter: true })
    e.preventDefault();
  }

  dragLeave = (e) => {
    this.setState({ dragEnter: false })
    e.preventDefault();
  }

  fileSelect = (e) => {
    e.preventDefault();
    const files = e.target.files
    this.onChangeFile(files, this.hiddenFileInput)
  }

  fileDrop = (e) => {
    e.preventDefault();
    this.setState({ dragEnter: false })
    const files = e.dataTransfer.files;
    this.onChangeFile(files)
  }

  onChangeFile = (files) => {
    if (files && files.length > 0) {
      if (!this.validateFileExtensionXml(files[0].name)) {
        ErrorAlert('El archivo no es un XML', () => { })
        this.setState({ document: {} })
        return;
      }

      var selectedFile = files[0];
      var reader = new FileReader();
      var readXml = null;

      reader.onload = (e) => {
        readXml = e.target.result;
        this.setState({
          document: files[0],
          documentString: readXml
        })
      }

      reader.readAsText(selectedFile);
    }
  }
  onLoad = (e) => {

  }
  validateFileExtensionXml = (fileName) => {
    return fileName.endsWith('.xml')
  }



  submit = () => {

    const { document } = this.state
    if (!document.name) {
      ErrorAlert("Debes seleccionar un documento", () => { })
      return
    }

    this.setState({
      disabledForm: true,
    }, () => {
      const params = {
        documentString: this.state.documentString
      }

      PropertiesApi.uploadXml(params).then(r => {
        this.setState({ disabledForm: false, alreadadyUploaded: true }, () => {
          const data = r.data;
          if (data.status) {
            if (data.error.length > 0) {
              MassiveAlert(data.message, this.getErrorComponent(data.error))
            }
            else {
              InformationAlert('Documento enviado con éxito.', 'Recibiras un correo al finalizar el proceso de validación.')
            }
          }
          else {
            ErrorAlert(data.message, () => { })
          }
        })
      })
        .catch(e => {
          this.setState({ disabledForm: false }, () => {
            ErrorAlert("Ha ocurrido un error, por favor intenta más tarde.", () => { })
          })
        })

    });
  }

  getErrorComponent = (errors) => {
    if (errors) {
      return <ErrorComponent errors={errors} />
    }
    return <div></div>;
  }

  getSuccessComponent = (success, updates) => {
    return <SuccessComponent success={success} updates={updates} />
  }

  close = () => {
    const needRefresh = this.state.alreadadyUploaded
    this.setState({
      disabledForm: false,
      document: {},
      documentString: '',
      alreadadyUploaded: false,
    }, () => {
      if (needRefresh) {
        this.props.refetch()
      }
      this.props.close()
    })
  }

  handleTabs = (index) => {
    if (index === 1) {
      this.getXmlHistory()
    }
    this.setState({
      activeTab: index
    })
  }

  getXmlHistory = () => {
    this.setState({ loadingXml: true }, () => {
      PropertiesApi.xmlHistory().then(r => {
        this.setState({
          xmlHistory: r.data,
          loadingXml: false
        })
      }).catch(e => {
        this.setState({
          loadingXml: false
        })
      })
    })
  }

  rowcheck = (row, column, display_value) => {
    if (column.field === "attributes.errors") {
      const data = JSON.parse(row.attributes.details)
      return (
         data.length > 0 ?
          <button className="p-2" onClick={ () => this.openErrorDetails(data)}>
            <InfoBlue />
          </button>
          : <></>
      )
    }
    return display_value
  }

  openErrorDetails = (details) => {
    MassiveAlert('Detalles', this.getErrorComponent(details))
  }

  render() {

    const { visible } = this.props

    if (!visible)
      return null
    return (
      <div className="absolute z-50 flex mx-auto left-0 right-0 items-center justify-center top-10">
        <div onClick={this.close} className="fixed z-20  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor: "rgba(100, 107, 115, 0.6)" }}>

        </div>
        <LoadingModal visible={this.state.disabledForm} />
        <div className="bg-white z-20 flex flex-col w-full"
          style={{
            borderRadius: 9,
            width: 616,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 24,
            paddingBottom: 64,
          }}>
          <div className="flex flex-row justify-between items-center">
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 35 }}>Publicación masiva</p>
            <div onClick={this.close} className="cursor-pointer">
              <CloseDark style={{ widht: 25, height: 25 }} />
            </div>
          </div>

          <div className="flex flex-row">
            <button className={`tabLeft ${this.state.activeTab === 0 ? 'bg-redaria-blue text-white' : 'bg-white text-redaria-blue'}`} onClick={() => this.handleTabs(0)}>
              Nuevo
            </button>
            <button className={`tabRight ${this.state.activeTab === 1 ? 'bg-redaria-blue text-white' : 'bg-white text-redaria-blue'}`} onClick={() => this.handleTabs(1)}>
              Historial
            </button>
          </div>

          {
            this.state.activeTab === 0 ?
              <>
                <div className="mt-10 mb-5">
                  <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 21 }}>
                    Documento
                  </p>
                  <a className='questrial cursor-pointer link text-redaria-blue mt-2' href="/masiva/Manual%20de%20carga%20masiva.docx" download>Manual de carga masiva</a>
                  <br/>
                  <a className='questrial cursor-pointer link text-redaria-blue mt-2' href="/masiva/Plantilla%20de%20propiedades.xml" download>Plantilla de propiedades</a>
                </div>


                <input
                  ref={this.hiddenFileInput}
                  hidden
                  multiple
                  type="file"
                  accept=".xml"
                  onChange={this.fileSelect}
                />



                <div
                  onClick={this.handleClick}
                  className="flex w-11/12 flex-col items-center justify-center self-center relative cursor-pointer"
                  style={{ backgroundColor: "#DEDEDE", border: "1px dashed #223242", borderRadius: 10, height: 181 }}
                  onDragOver={this.dragOver}
                  onDragEnter={this.dragEnter}
                >
                  {
                    this.state.dragEnter &&
                    <div
                      onDragOver={this.dragOver}
                      onDragEnter={this.dragEnter}
                      onDragLeave={this.dragLeave}
                      onDrop={this.fileDrop}
                      className="absolute left-0 right-0 bottom-0 top-0" style={{ borderRadius: 10, backgroundColor: "rgba(0, 0, 0, 0.2)" }}>

                    </div>
                  }
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex flex-row my-2 mx-4 cursor-pointer">

                      <div className="flex items-center justify-center">
                        <div className="flex flex-row">
                          <p className="questrial text-redaria-black" style={{ fontSize: 21, textAlign: 'center' }}>
                            Arrastra el documento xml aquí o presiona el siguiente botón
                          </p>
                        </div>
                      </div>
                    </div>
                    <button className="questrial" style={{ fontSize: 20, backgroundColor: '#BFBEBE', borderRadius: 7, boxShadow: "0px 0px 5px #00000084", paddingTop: 12, paddingBottom: 12, paddingRight: 16, paddingLeft: 16 }}>Seleccionar Archivo</button>
                  </div>
                </div>
                {
                  this.state.document.name &&
                  <>
                    <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 18 }}>
                      Documento seleccionado
                    </p>
                    <p>{this.state.document.name}</p>
                  </>
                }


                <div className="flex flex-row justify-between mt-10">
                  <div className="w-5/12">
                    <button onClick={this.close} className="redaria-button-white">Cancelar</button>
                  </div>
                  <div className="w-5/12">
                    <button onClick={this.submit} className="redaria-button-blue">Publicar</button>
                  </div>
                </div>
              </>
              :
              <>
                {
                  this.state.loadingXml
                    ?
                    <LoadingSmall />
                    :
                    <Table
                      row_render={this.rowcheck}
                      should_export={false}
                      show_search={false}
                      per_page={5}
                      styling={{
                        table_head: {
                          table_row:'text-center'
                        },
                        table_body: {
                          table_row: 'text-center',
                        },
                        footer: {
                          statistics: { // those shiny numbers like **Showing 1 to 5 of 58 entries**
                            main: 'hidden',
                          },
                        }
                      }}
                      columns={[
                        {
                          field: "id",
                          use: "Id",
                        },
                        {
                          field: "attributes.success",
                          use: "Nuevas",
                        },
                        {
                          field: "attributes.updates",
                          use: "Actualizadas",
                        },
                        {
                          field: "attributes.propertyErrors",
                          use: "Errores",
                        },
                        {
                          field: "attributes.errors",
                          use: "Detalles",
                        },
                        {
                          field: "attributes.status",
                          use: "Estatus",
                        },
                      ]} rows={
                        this.state.xmlHistory
                      } />
                }
              </>
          }


        </div>
      </div>
    )
  }
}

class ErrorComponent extends PureComponent {
  render() {
    if (this.props.errors.length === 0) return <div></div>;
    this.props.errors.forEach(error => {
      if (error.extra) {
        const extras = [];
        for (var key in error.extra) {
          extras.push(error.extra[key])
        }
        error.extras = extras;
      }
    })

    return (
      <div>
        {
          this.props.errors.map((error, index) => {
            return <div className="mx-5" key={uniqid()}>
              <p>{error.message}</p>
              <ul className="list-disc ml-10">
                {
                  error.extras && error.extras.map((extra, index) => {
                    return <li key={uniqid()}>{extra}</li>
                  })
                }
              </ul>
            </div>
          })
        }
      </div>
    )
  }
}
class SuccessComponent extends PureComponent {
  render() {

    return (
      <div>
        {
          this.props.success > 0 &&
          <div>
            <p className="poppins-semi-bold text-redaria-black">Propiedades craedas: <span className="poppins-regular">{this.props.success}</span></p>
          </div>
        }
        {
          this.props.updates > 0 &&
          <div>
            <p className="poppins-semi-bold text-redaria-black">Propiedades actualizadas: <span className="poppins-regular">{this.props.updates}</span></p>
          </div>
        }
      </div>
    )
  }
}

