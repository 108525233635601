import React from 'react';

export function Pool({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 27 20' className={className ? className : "w-5"}>
      <g id="pool_svg__prefix__hot-pool" transform="translate(0 -59.7)">
        <path
          id="pool_svg__prefix__Trazado_23806"
          d="M.972 75.248c9.882-2.809 13.494 2.868 24.463 0a.762.762 0 10-.385-1.474 22.447 22.447 0 01-5.711.772l-2.209-3.56a3.409 3.409 0 10-.437-.7l-3.022-4.871 4.485-1.294a2.254 2.254 0 00-1.25-4.331l-6.789 1.959a2.763 2.763 0 00-1.582 4.112l2.052 3.307-6.221 3.863a22.549 22.549 0 00-3.811.751.762.762 0 00.417 1.466zm17.446-7.689a1.892 1.892 0 11.082 2.675 1.892 1.892 0 01-.082-2.676zm-6.135 1.454L9.83 65.058a1.24 1.24 0 01.71-1.845l6.789-1.959a.73.73 0 11.405 1.4l-5.451 1.576a.762.762 0 00-.436 1.134l5.653 9.109c-3.554-.3-6.443-1.37-10.1-1.533l4.639-2.878a.762.762 0 00.246-1.049z"
          className="pool_svg__prefix__cls-1"
          data-name="Trazado 23806"
          transform="translate(-.002)"
        />
        <path
          id="pool_svg__prefix__Trazado_23807"
          d="M25.048 390.749c-10.495 2.74-14.177-2.929-24.5 0a.762.762 0 10.417 1.466c9.882-2.809 13.494 2.868 24.463 0a.762.762 0 00-.385-1.474z"
          className="pool_svg__prefix__cls-1"
          data-name="Trazado 23807"
          transform="translate(0 -313.416)"
        />
      </g>
    </svg>
  )
}
