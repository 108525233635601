import React from 'react';

export function OpenIndicator({ className, fill }) {
  return (
   <svg viewBox='0 0 100 100' className={className} fill={fill}>
     <path d="M94.6,76.7H5.4c-5,0-7.2-6.2-3.7-9.6l45.7-43.6c2.1-2.1,5.3-1.9,7.3,0.1l43.5,43.6
      C101.8,70.6,99.4,76.7,94.6,76.7z"/>
   </svg>
  )
}
export function CloseIndicator({ className, fill }) {
  return (
   <svg viewBox='0 0 100 100' className={className} fill={fill}>
     <path d="M5.4,22.6h89.2c5,0,7.2,6.2,3.7,9.6L52.5,75.9c-2.1,2.1-5.3,1.9-7.3-0.1L1.6,32.2
      C-1.8,28.7,0.6,22.6,5.4,22.6z"/>
   </svg>
  )
}
