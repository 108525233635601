import React, { PureComponent } from 'react'
import { CustomConfirmationAlert } from '../../lib/alerts'
import { CloseDark } from '../../lib/Icon/icons/Close'
import { PremierHide, PremierShow } from '../../lib/Icon/icons/PremierFeatures'
import { OptionalDataSection } from '../Forms/OptionalDataSection'
import StepCard from './StepCard'
import Steps from './Steps'

export default class LeftOptions extends PureComponent {

  requestClose = () => {
    CustomConfirmationAlert(
      'Continúa publicando',
      '¿Estas seguro de salir de esta pantalla? Los datos ingresados se borrarán.', 
      'Salir',
      'Continuar',
      () => this.props.close(),
      () => {}
      )
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-span-4">
          <div className="flex flex-row" style={{ marginBottom: 27, paddingTop: 31, }}>
            <div onClick={this.requestClose} className="cursor-pointer">
              <CloseDark style={{ widht: 20, height: 20 }} />
            </div>
          </div>
          <p className="text-redaria-black poppins-semi-bold" style={{ fontSize: 31 }}>Publicar Propiedad</p>
        </div>
        <div className="col-span-8" style={{ backgroundColor: '#F8F8F8' }}></div>
        <div className="col-span-4">
          <div className="bg-gray-300 w-full" style={{
            height: 1,
            marginTop: 28,
            marginBottom: 32
          }} />
        </div>
        <div className="col-span-8" style={{ backgroundColor: '#F8F8F8' }}></div>
        <div className="col-span-4">
          <Steps
            finishFirstSection={this.props.finishFirstSection}
            finishSecondSection={this.props.finishSecondSection}
            finishThirdSection={this.props.finishThirdSection}
            step={this.props.step}
            setStep={this.props.setStep}
          />
          <OptionalDataSection
            icon={ this.props.viewOptionalFields ? <PremierHide/> : <PremierShow/> }
            active={false}
            description='Estos campos son opcionales, si deseas puedes hacer tu publicación'
            onPress={() => this.props.parentSetState({ viewOptionalFields: !this.props.viewOptionalFields })}
            title={ this.props.viewOptionalFields ? 'Ocultar campos opcionales' : 'Agregar más información'}
          />
          {
            this.props.viewOptionalFields &&
            <StepCard
              onClick={() => this.props.setStep(3)}
              title="Más Información"
              description="Estos campos son opcionales"
              active={this.props.step === 3 ? true : false}
              />
          }
        </div>
      </React.Fragment>
    )
  }
}
