import React from 'react';

export default function Bedrooms({className}) {
  return (
    <svg viewBox='0 0 23 27' className={className ? className : "w-7"}>
      <g>
        <g data-name="bed (1)">
          <path
            d="M22.435 9.258a2.019 2.019 0 00-.778-2.351V2.021A2.024 2.024 0 0019.636 0H3.365a2.024 2.024 0 00-2.021 2.021v4.886a2.019 2.019 0 00-.778 2.351H0v8.625h1.348v-2.062h20.3v2.062H23V9.258zM3.365 1.347h16.27a.675.675 0 01.674.674v4.541h-1.346v-.674a2.024 2.024 0 00-2.021-2.021h-4.094a2.013 2.013 0 00-1.348.516 2.013 2.013 0 00-1.348-.516H6.058a2.024 2.024 0 00-2.021 2.021v.674H2.691V2.021a.675.675 0 01.674-.674zm7.461 5.215H5.385v-.674a.675.675 0 01.674-.674h4.094a.675.675 0 01.674.674zm1.348-.674a.675.675 0 01.674-.674h4.094a.675.675 0 01.674.674v.674h-5.442zM1.8 8.584a.675.675 0 01.674-.674h18.055a.674.674 0 110 1.348H2.471a.675.675 0 01-.671-.674zm-.449 2.021h20.3v3.867H1.348z"
            data-name="Trazado 23702"
            fill="#223242"
          />
        </g>
      </g>
    </svg>
  )
}
export function BedroomsGrey({className}) {
  return (
    <svg viewBox='0 0 21.268 14.376' className={className ? className : "w-7"}>
      <g data-name="Grupo 1435">
        <path
          d="M19.2 5.573V2.115A1.973 1.973 0 0 0 17.082 0H4.117A1.961 1.961 0 0 0 2 2.115v3.457a2.166 2.166 0 0 0-2 2.366v2.684a.815.815 0 0 0 .819.819h.273v2.115a.815.815 0 0 0 .819.819h.773a.815.815 0 0 0 .819-.819v-2.137h14.262v2.138a.815.815 0 0 0 .819.819h.773a.815.815 0 0 0 .819-.819v-2.138h.273a.815.815 0 0 0 .819-.819V7.938A2.26 2.26 0 0 0 19.2 5.573zM2.775 2.115h.045a1.163 1.163 0 0 1 1.3-1.3h12.962a1.163 1.163 0 0 1 1.3 1.3V5.55h-.614V3.594a.815.815 0 0 0-.819-.819h-5a.815.815 0 0 0-.819.819V5.55h-.955V3.594a.815.815 0 0 0-.819-.819h-5a.815.815 0 0 0-.819.819V5.55h-.762zm14.171 1.479V5.55h-5V3.594zm-7.552 0V5.55h-5V3.594zm-6.733 9.963h-.773v-2.138h.773v2.138zm16.65 0h-.773v-2.138h.773zm1.069-2.934H.8V9.417h19.581zm0-2.024H.8v-.66A1.43 1.43 0 0 1 2.369 6.37h16.442a1.421 1.421 0 0 1 1.569 1.569z"
          data-name="Trazado 5527"
          fill="#123"
        />
      </g>
    </svg>
  )
}