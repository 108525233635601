import React from 'react';

export default function Transfer({ className, fill }) {
  return (
    <svg viewBox="0 0 31 23" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g>
        <path
          id="on_assessment_svg__prefix__Trazado_5311"
          d="M193.441 92.011c0-.078.006-.154.012-.23a4.37 4.37 0 00-.012.23zm0 0"
          data-name="Trazado 5311"
          transform="translate(-182.017 -86.361)"
          fill="#ffd4be"
        />
        <path
          id="on_assessment_svg__prefix__Trazado_5312"
          d="M139.78 180.934h-3.5a.591.591 0 00-.591.591v.5h-3.678a.588.588 0 00-.226.045l-4.711 1.95a2.2 2.2 0 00-1.183 2.886l.016.038a2.265 2.265 0 00.582.814 4.526 4.526 0 01-2.2.043l-4.625-1.059a2.143 2.143 0 00-1.006 4.166l5.519 1.4a5.693 5.693 0 003.5-.219l7.019-2.753h.995v.311a.591.591 0 00.591.591h3.5a.591.591 0 00.591-.591v-8.122a.591.591 0 00-.593-.591zm-5.2 7.221a.593.593 0 00-.215.041l-7.123 2.794a4.516 4.516 0 01-2.776.173l-5.519-1.4a.962.962 0 01.451-1.869l4.625 1.059a5.7 5.7 0 003.33-.237l1.342-.516.083-.03 3.031-1.17.063-.024a.591.591 0 10-.424-1.1l-.069.027-3.078 1.184a1.06 1.06 0 01-1.3-.6l-.016-.038a1.016 1.016 0 01.547-1.334l4.6-1.905h3.561v4.95zm4.6.9h-2.317v-6.941h2.317zm0 0"
          className="on_assessment_svg__prefix__cls-2"
          data-name="Trazado 5312"
          transform="translate(-110.132 -170.248)"
        />
        <path
          id="on_assessment_svg__prefix__Trazado_5313"
          d="M5.751 10.439H4.679V4.921h.164a2.776 2.776 0 001.261-.3.582.582 0 00.053-.031l3.825-2.5a1.592 1.592 0 011.644.153l.276.206-.049.072a5.794 5.794 0 00-.209.325 5.73 5.73 0 00-.767 2.232 1.781 1.781 0 00.193 3.552h4.372a.9.9 0 01.365 1.733.769.769 0 01-.1.038h-.009a.9.9 0 01-.251.035h-4.972a.591.591 0 000 1.181h4.968a2.1 2.1 0 00.449-.049 5.9 5.9 0 00.749.049 5.81 5.81 0 10-3.959-10.059l-.35-.261a2.78 2.78 0 00-2.915-.245.608.608 0 00-.053.031L5.54 3.578a1.589 1.589 0 01-.7.161h-.161v-.213a.591.591 0 00-.591-.591H.591A.591.591 0 000 3.526v8.122a.591.591 0 00.591.591h3.5a.591.591 0 00.591-.591v-.028h1.069a.591.591 0 000-1.181zm4.719-3.59a.6.6 0 01.38-.558 5.745 5.745 0 00.215 1.158.6.6 0 01-.595-.6zm10.8-1.039a4.636 4.636 0 01-3.922 4.575 2.1 2.1 0 00.181-.851 2.075 2.075 0 00-.259-1.007 1.235 1.235 0 001.043-1.221v-.873A1.239 1.239 0 0017.075 5.2h-.867a.057.057 0 01-.057-.057v-.829a.056.056 0 01.057-.056h.867a.056.056 0 01.057.056.591.591 0 101.181 0 1.239 1.239 0 00-1.08-1.226v-.2a.591.591 0 10-1.181 0v.2a1.239 1.239 0 00-1.08 1.226v.825a1.239 1.239 0 001.238 1.238h.867a.057.057 0 01.057.057v.873a.056.056 0 01-.057.056h-.867a.057.057 0 01-.057-.056v-.08a.591.591 0 10-1.181 0v.08a1.26 1.26 0 00.009.142H12.31a4.637 4.637 0 01.412-4.1 4.714 4.714 0 01.447-.6 4.628 4.628 0 018.1 3.06zM3.5 11.058H1.181V4.116H3.5zm0 0"
          className="on_assessment_svg__prefix__cls-2"
          data-name="Trazado 5313"
        />
        <path
          id="on_assessment_svg__prefix__Trazado_5314"
          d="M128.163 177.032a.59.59 0 10-.073.745.594.594 0 00.073-.745zm0 0"
          className="on_assessment_svg__prefix__cls-2"
          data-name="Trazado 5314"
          transform="translate(-119.577 -166.33)"
        />
      </g>
    </svg>
  )
}
export function TransferBlack({ className }) {
  return (
    <svg viewBox="0 0 30.238 22.237" className={className ? className : "w-5"}>
      <g>
        <path
          data-name="Trazado 5311"
          d="M11.424 5.65c0-.078.006-.154.012-.23a4.37 4.37 0 00-.012.23zm0 0"
          fill="#223242"
        />
        <path
          data-name="Trazado 5312"
          d="M29.648 10.686h-3.5a.591.591 0 00-.591.591v.5h-3.678a.588.588 0 00-.226.045l-4.711 1.95a2.2 2.2 0 00-1.183 2.886l.016.038a2.265 2.265 0 00.582.814 4.526 4.526 0 01-2.2.043l-4.625-1.059a2.143 2.143 0 00-1.006 4.166l5.519 1.4a5.693 5.693 0 003.5-.219l7.019-2.753h.995v.311a.591.591 0 00.591.591h3.5a.591.591 0 00.591-.591v-8.122a.591.591 0 00-.593-.591zm-5.2 7.221a.593.593 0 00-.215.041l-7.123 2.794a4.516 4.516 0 01-2.776.173l-5.519-1.4a.962.962 0 01.451-1.869l4.625 1.059a5.7 5.7 0 003.33-.237l1.342-.516.083-.03 3.031-1.17.063-.024a.591.591 0 10-.424-1.1l-.069.027-3.078 1.184a1.06 1.06 0 01-1.3-.6l-.016-.038a1.016 1.016 0 01.547-1.334l4.6-1.905h3.561v4.95zm4.6.9h-2.317v-6.941h2.317zm0 0"
          fill="#223242"
        />
        <path
          data-name="Trazado 5313"
          d="M5.751 10.439H4.679V4.921h.164a2.776 2.776 0 001.261-.3.582.582 0 00.053-.031l3.825-2.5a1.592 1.592 0 011.644.153l.276.206-.049.072a5.794 5.794 0 00-.209.325 5.73 5.73 0 00-.767 2.232 1.781 1.781 0 00.193 3.552h4.372a.9.9 0 01.365 1.733.769.769 0 01-.1.038h-.009a.9.9 0 01-.251.035h-4.972a.591.591 0 000 1.181h4.968a2.1 2.1 0 00.449-.049 5.9 5.9 0 00.749.049 5.81 5.81 0 10-3.959-10.059l-.35-.261a2.78 2.78 0 00-2.915-.245.608.608 0 00-.053.031L5.54 3.578a1.589 1.589 0 01-.7.161h-.161v-.213a.591.591 0 00-.591-.591H.591A.591.591 0 000 3.526v8.122a.591.591 0 00.591.591h3.5a.591.591 0 00.591-.591v-.028h1.069a.591.591 0 000-1.181zm4.719-3.59a.6.6 0 01.38-.558 5.745 5.745 0 00.215 1.158.6.6 0 01-.595-.6zm10.8-1.039a4.636 4.636 0 01-3.922 4.575 2.1 2.1 0 00.181-.851 2.075 2.075 0 00-.259-1.007 1.235 1.235 0 001.043-1.221v-.873A1.239 1.239 0 0017.075 5.2h-.867a.057.057 0 01-.057-.057v-.829a.056.056 0 01.057-.056h.867a.056.056 0 01.057.056.591.591 0 101.181 0 1.239 1.239 0 00-1.08-1.226v-.2a.591.591 0 10-1.181 0v.2a1.239 1.239 0 00-1.08 1.226v.825a1.239 1.239 0 001.238 1.238h.867a.057.057 0 01.057.057v.873a.056.056 0 01-.057.056h-.867a.057.057 0 01-.057-.056v-.08a.591.591 0 10-1.181 0v.08a1.26 1.26 0 00.009.142H12.31a4.637 4.637 0 01.412-4.1 4.714 4.714 0 01.447-.6 4.628 4.628 0 018.1 3.06zM3.5 11.058H1.181V4.116H3.5zm0 0"
          fill="#223242"
        />
        <path
          data-name="Trazado 5314"
          d="M8.586 10.702a.59.59 0 10-.073.745.594.594 0 00.073-.745zm0 0"
          fill="#223242"
        />
      </g>
    </svg>
  )
}
