import React, { useEffect } from 'react'
import {  useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import Welcome from './containers/Welcome/Welcome';
import _ from 'lodash'

import AppleLogin from './containers/AppleLogin/AppleLogin';
import CreateAccount from './containers/CreateAccount/CreateAccount';
import RecoverPassword from './containers/RecoverPassword/RecoverPassword';
import LoginStack from './navigation/LoginStack';
import ScrollToTop from './ScrollToTop';
import { fetchToken, onMessageListener } from './firebase';
import { toast } from 'react-toastify';
import UserRequest from './api/UserRequest';
import 'react-toastify/dist/ReactToastify.css';
import { setLocalFirebaseToken } from './actions/localFirebaseToken';


const Msg = ({ closeToast, toastProps, title,body }) => (
  <div>
    <h1 className='poppins-medium text-redaria-black' style={{fontSize:22}}>{title}</h1>
    <h1 className='questrial text-redaria-black' style={{fontSize:18}}>{body}</h1>
  </div>
)

export function SignedContainer({ children }) {

  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector(state => state.authenticatedUser)
  const localToken = useSelector(state => state.localFirebaseToken)

  const handleFirebaseToken = async () => {
    try {
      const firebaseToken = await fetchToken()
      if(firebaseToken.length > 0) {
        let nedSaveToken = true
        if (localToken.token.length > 0) {
          if (firebaseToken !== localToken.token) {
            await UserRequest.deleteFirebaseToken(localToken.token)
          } else {
            nedSaveToken = false
          }
        }
        if (nedSaveToken) {
          await UserRequest.saveFirebaseToken(firebaseToken)
          dispatch(setLocalFirebaseToken(firebaseToken))
        }
      }
    } catch(error) {
      console.log(error)
    }
    setNotifications()
  }
  useEffect(() => {
    handleFirebaseToken()
    // eslint-disable-next-line
  }, [])

  const setNotifications = () => {
    onMessageListener().then(payload => {
      toast(<Msg title={payload.notification.title} body={payload.notification.body} />)
      setNotifications()
    }).catch(err => console.log('failed: ', err));
  }


  if (_.isEmpty(user)) {
    history.push('/');
    return null
  }

  return (children)
}




export default function App() {
  return (
    <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/sigin" component={CreateAccount} />
        <Route exact path="/recoverPassword" component={RecoverPassword} />
        <Route path="/signed" component={LoginStack} />
        <Route path="/applelogin/:token" component={AppleLogin} />
      </Switch>
    </ScrollToTop>
    </BrowserRouter>
  );
}