import React, { PureComponent } from 'react'

class StepCard extends PureComponent {
  render() {

    return (
      <div onClick={this.props.onClick} className={`cursor-pointer py-3 mr-5 shadow-lg flex flex-row items-center ${this.props.active ? 'border-redaria-blue' : ''} ${(this.props.finish && !this.props.active) ? 'bg-redaria-blue' : 'bg-white'}`} style={{ borderRadius:11,boxShadow: "2px 2px 10px #0000001A",marginBottom:16 }}>
        <div className={`${this.props.active ? 'bg-redaria-blue' : ''}`} style={{width:7,height:59,marginRight:15,marginLeft:-1}}/>
        <div className="flex flex-col">
          <p className={`poppins-semi-bold ${(this.props.finish && !this.props.active) ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>{this.props.title}</p>
          <p className={`questrial ${(this.props.finish && !this.props.active) ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:16 }}>{this.props.description}</p>
        </div>
      </div>
    )
  }
}

export default StepCard
