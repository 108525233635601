import React from 'react';

export function InfoBlue(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 13.75h-2.5v-7.5h2.5m0 12.5h-2.5v-2.5h2.5M12.5 0C5.596 0 0 5.596 0 12.5A12.5 12.5 0 0012.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0z"
        fill="#2B77B7"
        fillRule="nonzero"
      />
    </svg>
  )
}

export function InfoRed(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 13.75h-2.5v-7.5h2.5m0 12.5h-2.5v-2.5h2.5M12.5 0C5.596 0 0 5.596 0 12.5A12.5 12.5 0 0012.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0z"
        fill="#EF4444"
        fillRule="nonzero"
      />
    </svg>
  )
}