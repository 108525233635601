import imageCompression from 'browser-image-compression';


export const compressImages = async(files,maxFiles,anotherFilesReturn = [],anotherFiles = []) => {

  var options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  var re = /(?:\.([^.]+))?$/;
  let imagesError = 0
  let showMaxFiles = false
  
  let newFiles = [...files]

  newFiles = newFiles.filter( f => {
    var ext = re.exec(f.name)[1];
    f.error = true
    if(ext)
    {
      const lwcs = ext.toLowerCase()
      if( lwcs === 'png' || lwcs === 'jpg' || lwcs === 'jpeg')
      {
        f.error = false
        return true
      }
      else
      {
        imagesError++
        return false
      }
    }
    else
    {
      imagesError++
      return false
    }
  })

  if(maxFiles)
  {
    if((anotherFilesReturn.length + anotherFiles.length + newFiles.length) > maxFiles)
    {
      showMaxFiles = true
      while ((anotherFilesReturn.length + anotherFiles.length + newFiles.length) > maxFiles) {
        newFiles.pop()  
      }
    }
  }

  const reduceFiles = []

  for (let index = 0; index < newFiles.length; index++) {
        
    let f = newFiles[index]
    
    try {
      const compressedFile = await imageCompression(f, options);
      reduceFiles.push(compressedFile)
    } catch (error) {
      console.log(error)
      reduceFiles.push(f)
    }

  }

  const images = [...anotherFilesReturn, ...reduceFiles]

  const response = {
    images,
    imagesError,
    showMaxFiles
  }

  return response



}