import AuthenticatedApi from './AuthenticatedApi';

export default class TemporaryLinksApi {
  static create(properties) {
    let params = {properties: properties};
    return AuthenticatedApi.post('/api/temporary_links', {}, params).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
}
