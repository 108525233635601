import React from 'react';

export function CheckBlack(props) {
  return (
    <svg
      viewBox="0 0 86 86"
      {...props}>
      <g transform="translate(-912 -334.703)">
        <circle
          cx={43}
          cy={43}
          r={43}
          transform="translate(912 334.703)"
          fill="#123"
        />
        <path
          d="M940.857 379.805l11.79 9.82 18.84-25.193"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={7}
        />
      </g>
    </svg>
  )
}