import React from 'react'

const OptionsButton = ({openOptions,setAsAvailable}) => {
  return (

    <div className="flex flex-row items-center w-full justify-between">

    <div onClick={setAsAvailable} className="shadow-lg bg-redaria-blue flex items-center justify-center cursor-pointer" style={{ width:280,height:33,borderRadius:9,borderWidth:1,borderColor:'#fff' }}>
      <p className="text-white poppins-semi-bold text-center">Reactivar</p>
    </div>

    <div className="cursor-pointer border-redaria-blue rounded shadow-lg flex flex-col items-center justify-center" onClick={openOptions} style={{ width:43,height:33 }}>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
      </div>
    </div>
    

  )
}

export default OptionsButton
