import React from 'react';

export function WalkInCloset({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 24 24" className={className ? className : "w-5"}>
      <g>
        <g
          id="closet_svg__prefix__Grupo_1614"
          data-name="Grupo 1614"
          transform="translate(10.593 1.569)"
        >
          <g id="closet_svg__prefix__Grupo_1613" data-name="Grupo 1613">
            <path
              id="closet_svg__prefix__Trazado_23776"
              d="M230.792 34.133a.392.392 0 00-.392.392v1.569a.392.392 0 10.785 0v-1.569a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23776"
              transform="translate(-230.4 -34.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1616"
          data-name="Grupo 1616"
          transform="translate(21.579 1.569)"
        >
          <g id="closet_svg__prefix__Grupo_1615" data-name="Grupo 1615">
            <path
              id="closet_svg__prefix__Trazado_23777"
              d="M469.726 34.133a.392.392 0 00-.392.392v1.569a.392.392 0 00.785 0v-1.569a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23777"
              transform="translate(-469.334 -34.133)"
            />
          </g>
        </g>
        <g id="closet_svg__prefix__Grupo_1618" data-name="Grupo 1618">
          <g id="closet_svg__prefix__Grupo_1617" data-name="Grupo 1617">
            <path
              id="closet_svg__prefix__Trazado_23778"
              d="M8.239 0H.392A.392.392 0 000 .392v22.757a.393.393 0 00.392.392h7.847a.392.392 0 00.392-.392V.392A.392.392 0 008.239 0zm-.392 22.756H.785V.785h7.062z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23778"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1620"
          data-name="Grupo 1620"
          transform="translate(7.847 16.479)"
        >
          <g id="closet_svg__prefix__Grupo_1619" data-name="Grupo 1619">
            <path
              id="closet_svg__prefix__Trazado_23779"
              d="M185.969 358.4h-14.91a.392.392 0 00-.392.392v6.278a.392.392 0 00.392.392h14.909a.392.392 0 00.392-.392v-6.278a.392.392 0 00-.391-.392zm-.392 6.278h-14.125v-5.493h14.125z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23779"
              transform="translate(-170.667 -358.4)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1622"
          data-name="Grupo 1622"
          transform="translate(7.847)"
        >
          <g id="closet_svg__prefix__Grupo_1621" data-name="Grupo 1621">
            <path
              id="closet_svg__prefix__Trazado_23780"
              d="M185.969 0h-14.91a.392.392 0 00-.392.392v1.57a.392.392 0 00.392.392h14.909a.392.392 0 00.392-.392V.392a.392.392 0 00-.391-.392zm-.392 1.569h-14.125V.785h14.125z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23780"
              transform="translate(-170.667)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1624"
          data-name="Grupo 1624"
          transform="translate(4.708 11.378)"
        >
          <g id="closet_svg__prefix__Grupo_1623" data-name="Grupo 1623">
            <path
              id="closet_svg__prefix__Trazado_23781"
              d="M103.969 247.467h-1.177a.392.392 0 000 .785h1.177a.392.392 0 100-.785z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23781"
              transform="translate(-102.4 -247.467)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1626"
          data-name="Grupo 1626"
          transform="translate(4.708 11.378)"
        >
          <g id="closet_svg__prefix__Grupo_1625" data-name="Grupo 1625">
            <path
              id="closet_svg__prefix__Trazado_23782"
              d="M102.792 247.467a.392.392 0 00-.392.392v1.177a.392.392 0 00.785 0v-1.177a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23782"
              transform="translate(-102.4 -247.467)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1628"
          data-name="Grupo 1628"
          transform="translate(14.125 19.225)"
        >
          <g id="closet_svg__prefix__Grupo_1627" data-name="Grupo 1627">
            <path
              id="closet_svg__prefix__Trazado_23783"
              d="M309.946 418.133h-2.354a.392.392 0 000 .785h2.354a.392.392 0 000-.785z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23783"
              transform="translate(-307.2 -418.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1630"
          data-name="Grupo 1630"
          transform="translate(15.302 19.225)"
        >
          <g id="closet_svg__prefix__Grupo_1629" data-name="Grupo 1629">
            <path
              id="closet_svg__prefix__Trazado_23784"
              d="M333.192 418.133a.392.392 0 00-.392.392v.785a.392.392 0 00.785 0v-.785a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23784"
              transform="translate(-332.8 -418.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1632"
          data-name="Grupo 1632"
          transform="translate(12.948 1.569)"
        >
          <g id="closet_svg__prefix__Grupo_1631" data-name="Grupo 1631">
            <path
              id="closet_svg__prefix__Trazado_23785"
              d="M281.992 34.133a.392.392 0 00-.392.392v2.354a.392.392 0 00.785 0v-2.354a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23785"
              transform="translate(-281.6 -34.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1634"
          data-name="Grupo 1634"
          transform="translate(9.809 5.493)"
        >
          <g id="closet_svg__prefix__Grupo_1633" data-name="Grupo 1633">
            <path
              id="closet_svg__prefix__Trazado_23786"
              d="M216.864 119.467c-2.112 0-3.531.788-3.531 1.962v.785a.392.392 0 00.392.392h.785v3.531a.392.392 0 00.392.392h3.924a.392.392 0 00.392-.392v-3.531H220a.393.393 0 00.392-.392v-.785c.008-1.174-1.416-1.962-3.528-1.962zm2.746 2.354h-.785a.392.392 0 00-.392.392v3.531h-3.139v-3.531a.392.392 0 00-.392-.392h-.785v-.392c0-.57.962-1.177 2.746-1.177s2.746.607 2.746 1.177v.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23786"
              transform="translate(-213.333 -119.467)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1636"
          data-name="Grupo 1636"
          transform="translate(14.321 5.493)"
        >
          <g id="closet_svg__prefix__Grupo_1635" data-name="Grupo 1635">
            <path
              id="closet_svg__prefix__Trazado_23787"
              d="M313.233 119.467a6.838 6.838 0 00-1.457.147.392.392 0 000 .767c.885.194 1.457.605 1.457 1.048v.392h-.785a.392.392 0 00-.392.392v3.923a.392.392 0 00.392.392h2.746a.392.392 0 00.392-.392v-3.531h.785a.392.392 0 00.392-.392v-.785c.001-1.173-1.418-1.961-3.53-1.961zm2.746 2.354h-.785a.392.392 0 00-.392.392v3.531h-1.962v-3.139h.785a.392.392 0 00.392-.392v-.785a1.5 1.5 0 00-.615-1.175c1.675.037 2.577.623 2.577 1.175z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23787"
              transform="translate(-311.467 -119.467)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1638"
          data-name="Grupo 1638"
          transform="translate(17.067 5.493)"
        >
          <g id="closet_svg__prefix__Grupo_1637" data-name="Grupo 1637">
            <path
              id="closet_svg__prefix__Trazado_23788"
              d="M372.966 119.467a6.837 6.837 0 00-1.457.147.392.392 0 000 .767c.885.194 1.457.605 1.457 1.048v.392h-.785a.392.392 0 00-.392.392v3.923a.392.392 0 00.392.392h2.746a.392.392 0 00.392-.392v-3.531h.785a.392.392 0 00.392-.392v-.785c.004-1.173-1.418-1.961-3.53-1.961zm2.746 2.354h-.785a.392.392 0 00-.392.392v3.531h-1.962v-3.139h.785a.392.392 0 00.392-.392v-.785a1.5 1.5 0 00-.615-1.175c1.675.037 2.577.623 2.577 1.175z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23788"
              transform="translate(-371.2 -119.467)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1640"
          data-name="Grupo 1640"
          transform="translate(12.948 3.923)"
        >
          <g id="closet_svg__prefix__Grupo_1639" data-name="Grupo 1639">
            <path
              id="closet_svg__prefix__Trazado_23789"
              d="M281.992 85.333a.392.392 0 000 .785.392.392 0 110 .785.392.392 0 000 .785 1.177 1.177 0 100-2.354z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23789"
              transform="translate(-281.6 -85.333)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1642"
          data-name="Grupo 1642"
          transform="translate(16.086 1.569)"
        >
          <g id="closet_svg__prefix__Grupo_1641" data-name="Grupo 1641">
            <path
              id="closet_svg__prefix__Trazado_23790"
              d="M350.259 34.133a.392.392 0 00-.392.392v2.354a.392.392 0 00.785 0v-2.354a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23790"
              transform="translate(-349.867 -34.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1644"
          data-name="Grupo 1644"
          transform="translate(16.086 3.923)"
        >
          <g id="closet_svg__prefix__Grupo_1643" data-name="Grupo 1643">
            <path
              id="closet_svg__prefix__Trazado_23791"
              d="M350.259 85.333a.392.392 0 000 .785.392.392 0 110 .785.392.392 0 000 .785 1.177 1.177 0 100-2.354z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23791"
              transform="translate(-349.867 -85.333)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1646"
          data-name="Grupo 1646"
          transform="translate(19.225 1.569)"
        >
          <g id="closet_svg__prefix__Grupo_1645" data-name="Grupo 1645">
            <path
              id="closet_svg__prefix__Trazado_23792"
              d="M418.526 34.133a.392.392 0 00-.392.392v2.354a.392.392 0 00.785 0v-2.354a.392.392 0 00-.393-.392z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23792"
              transform="translate(-418.134 -34.133)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1648"
          data-name="Grupo 1648"
          transform="translate(19.225 3.923)"
        >
          <g id="closet_svg__prefix__Grupo_1647" data-name="Grupo 1647">
            <path
              id="closet_svg__prefix__Trazado_23793"
              d="M418.526 85.333a.392.392 0 000 .785.392.392 0 110 .785.392.392 0 000 .785 1.177 1.177 0 100-2.354z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23793"
              transform="translate(-418.134 -85.333)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1650"
          data-name="Grupo 1650"
          transform="translate(7.847 14.909)"
        >
          <g id="closet_svg__prefix__Grupo_1649" data-name="Grupo 1649">
            <path
              id="closet_svg__prefix__Trazado_23794"
              d="M185.184 324.267h-14.125a.392.392 0 00-.392.392v1.569a.392.392 0 00.392.392h14.125a.392.392 0 00.392-.392v-1.569a.392.392 0 00-.392-.392zm-.392 1.569h-13.34v-.785h13.34v.785z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23794"
              transform="translate(-170.667 -324.267)"
            />
          </g>
        </g>
        <g
          id="closet_svg__prefix__Grupo_1652"
          data-name="Grupo 1652"
          transform="translate(9.416 18.048)"
        >
          <g id="closet_svg__prefix__Grupo_1651" data-name="Grupo 1651">
            <path
              id="closet_svg__prefix__Trazado_23795"
              d="M216.963 392.533h-11.771a.392.392 0 00-.392.392v3.139a.392.392 0 00.392.392h11.771a.392.392 0 00.392-.392v-3.139a.392.392 0 00-.392-.392zm-.392 3.139h-10.986v-2.354h10.986z"
              className="closet_svg__prefix__cls-1"
              data-name="Trazado 23795"
              transform="translate(-204.8 -392.533)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
