import React from 'react';

export default function Close() {
  return (
    <svg viewBox='0 0 512 512' className="w-4" xmlns="http://www.w3.org/2000/svg">
      <path fill='#9d9d9d' d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z" />
    </svg>
  )
}

export function CloseDark(props) {
  return (
    <svg
      viewBox="0 0 27.1 27.1"
      {...props}>
      <path
        fill="none"
        stroke="#123"
        strokeWidth="3px"
        className="prefix__a"
        d="M26.04 1.061L1.061 26.04M1.061 1.061L26.04 26.04"
      />
    </svg>
  )
}

export function CloseCircleBlue(props) {
  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={62.067}
      height={62.067}
      viewBox="0 0 62.067 62.067"
      {...props}
    >
      <defs>
        <style>{".closeBlueC{filter:url(#closeBlue);}"}</style>
        <filter
          id="closeBlue"
          x={0}
          y={0}
          width={62.067}
          height={62.067}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={3} result="b" />
          <feFlood floodOpacity={0.251} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-464.545 -1178.232)">
        <circle
          fill="#fff"
          cx={15}
          cy={15}
          r={15}
          transform="translate(481 1191)"
        />
        <g transform="translate(495.67 1184.323) rotate(45)">
          <g
            className="closeBlueC"
            fill="#2b77b7"
            transform="matrix(0.71, -0.71, 0.71, 0.71, -26.32, 17.7)"
          >
            <path
              d="M15.58,0A15.58,15.58,0,1,0,31.16,15.58,15.6,15.6,0,0,0,15.58,0Zm7.49,16.779H16.779V23.37a1.2,1.2,0,0,1-2.4,0V16.779H8.09a1.2,1.2,0,1,1,0-2.4h6.292V8.389a1.2,1.2,0,1,1,2.4,0v5.992h6.292a1.2,1.2,0,1,1,0,2.4Z"
              transform="translate(31.03 6) rotate(45)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}