export const buildFormFromRequest = (ammenities) => {
  return Array.from(ammenities, ammenity => {
    let ammenityObject = {id: ammenity.id};

    if(ammenity.pivot.value !== null) {
      ammenityObject.value = ammenity.pivot.value;
    }

    return ammenityObject;
  });
}

export const getBasicAmmenities = (ammenities) => {
  return ammenities.filter(ammenity => {
    if(ammenity.id === 16 || ammenity.id === 17 || ammenity.id === 19 || ammenity.id === 18 || ammenity.id === 21 || ammenity.id === 20) {
      return true;
    }
    else {
      return false;
    }
  });
}

export const getAdditionalAmmenities = (ammenities) => {
  return ammenities.filter(ammenity => {
    if(ammenity.id === 5
       || ammenity.id === 7
       || ammenity.id === 8
       || ammenity.id === 9
       || ammenity.id === 10
       || ammenity.id === 12
       || ammenity.id === 11
       || ammenity.id === 13
       || ammenity.id === 14
       || ammenity.id === 15
       ){
      return true;
    }
    else {
      return false;
    }
  });
}

export const getAmmenityValue = (ammenities = [], id) => {
  if(ammenities.find(ammenity => ammenity.id === id) !== undefined) {
    return ammenities.find(ammenity => {
      return ammenity.id === id;
    }).pivot.value;
  }
  else {
    return '∞';
  }
}

export const getPropertyAmmenityValue = (ammenities, id) => {
  if(ammenities.find(ammenity => ammenity.id === id) !== undefined) {
    return ammenities.find(ammenity => {
      return ammenity.id === id;
    }).pivot.value;
  }
  else {
    return '0';
  }
}

export const getAmmenityValueWithoutPivot = (ammenities, id) => {
  if(ammenities.find(ammenity => ammenity.id === id) !== undefined) {
    return ammenities.find(ammenity => {
      return ammenity.id === id;
    }).value;
  }
  else {
    return '';
  }
}

export const BasicAmmenities = [
  {
    id: 1,
    slug: 'bedrooms',
    name: 'Recámaras'
  },
  {
    id: 2,
    slug: 'bathrooms',
    name: 'Baños'
  },
  {
    id: 3,
    slug: 'restrooms',
    name: 'Medios baños'
  },
  {
    id: 4,
    slug: 'parking_spots',
    name: 'Cochera'
  }
]

export const AdditionalAmmenities = [
  {
    id: 5,
    slug: 'furniture',
    name: 'Amueblado'
  },
  {
    id: 6,
    slug: 'equipped',
    name: 'Equipado'
  },
  {
    id: 7,
    slug: 'pet_friendly',
    name: 'Pet friendly'
  },
  {
    id: 8,
    slug: 'service_room',
    name: 'Cuarto de servicio'
  },
  {
    id: 9,
    slug: 'air_conditioner',
    name: 'Aire acondicionado'
  },
  {
    id: 10,
    slug: 'heater',
    name: 'Calefacción'
  },
  {
    id: 11,
    slug: 'walk_in_closet',
    name: 'Walk-in closet'
  },
  {
    id: 12,
    slug: 'tv_room',
    name: 'Cuarto TV'
  },
  {
    id: 13,
    slug: 'solar_energy',
    name: 'Energía solar'
  },
  {
    id: 14,
    slug: 'elevator',
    name: 'Elevador'
  },
  {
    id: 15,
    slug: 'private_security',
    name: 'Seguridad privada'
  },
  {
    id: 16,
    slug: 'pool',
    name: 'Alberca'
  },
  {
    id: 17,
    slug: 'grill',
    name: 'Asador'
  },
  {
    id: 18,
    slug: 'yard',
    name: 'Jardín'
  },
  {
    id: 19,
    slug: 'chimney',
    name: 'Chimenea'
  },
  {
    id: 20,
    slug: 'palapa',
    name: 'Palapa'
  },
  {
    id: 21,
    slug: 'gym',
    name: 'Gimnasio privado'
  }
]

export const CharacteristicsAmmenities = [
  {
    id: 5,
    slug: 'furniture',
    name: 'Amueblado'
  },
  {
    id: 6,
    slug: 'equipped',
    name: 'Equipado'
  },
  {
    id: 7,
    slug: 'pet_friendly',
    name: 'Pet friendly'
  },
  {
    id: 8,
    slug: 'service_room',
    name: 'Cuarto de servicio'
  },
  {
    id: 9,
    slug: 'air_conditioner',
    name: 'Aire acondicionado'
  },
  {
    id: 10,
    slug: 'heater',
    name: 'Calefacción'
  },
  {
    id: 11,
    slug: 'walk_in_closet',
    name: 'Walk-in closet'
  },
  {
    id: 12,
    slug: 'tv_room',
    name: 'Cuarto TV'
  },
  {
    id: 13,
    slug: 'solar_energy',
    name: 'Energía solar'
  },
  {
    id: 14,
    slug: 'elevator',
    name: 'Elevador'
  },
  {
    id: 15,
    slug: 'private_security',
    name: 'Seguridad privada'
  }
];

export const AdditionalCharacteristicsAmmenities = [
  {
    id: 16,
    slug: 'pool',
    name: 'Alberca',
    icon: 'pool-blue',
    viewBox: '0 0 27 20'
  },
  {
    id: 17,
    slug: 'grill',
    name: 'Asador',
    icon: 'grill-blue',
    viewBox: '0 0 25 28'
  },
  {
    id: 18,
    slug: 'yard',
    name: 'Jardín',
    icon: 'yard-blue',
    viewBox: '0 0 29 25'
  },
  {
    id: 19,
    slug: 'chimney',
    name: 'Chimenea',
    icon: 'chimney-blue',
    viewBox: '0 0 27 27'
  },
  {
    id: 20,
    slug: 'palapa',
    name: 'Palapa',
    icon: 'palapa-blue',
    viewBox: '0 0 25 27'
  },
  {
    id: 21,
    slug: 'gym',
    name: 'Gimnasio privado',
    icon: 'gym-blue',
    viewBox: '0 0 32 20'
  }
];
