import React from 'react'
import logo from '../../assets/loading.gif'

export default function LoadingComponent() {
  return (
    <div className="bg-white bg-opacity-75 flex justify-center items-center h-screen	">
      <img src={logo} alt="loading..." className="w-28" />
    </div>
  )
}
