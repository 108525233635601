import React, { PureComponent } from 'react'

export class BottomModal extends PureComponent {


  state = {
    modalVisible:false
  }

  open = () => {
    this.setState({
      modalVisible:true
    })
  }

  close = () => {
    this.setState({modalVisible:false})
  }

  render() {
    if(!this.state.modalVisible)
    {
      return null
    }
    return (
      <div onClick={this.close} className="fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-75 flex justify-center">
      
      <div onClick={(e) => e.stopPropagation()} className="fixed bottom-0 max-w-lg self-center flex flex-col items-center">
        <div className="rounded-full bg-white h-2 w-20 mb-3"/>
        <div className="bg-white rounded-t-3xl px-5 w-80 pt-8 pb-5">
          <div className="mx-auto">
            <p className="title poppins-semi-bold text-redaria-black text-center">{this.props.title}</p>
          </div>
          {
            this.props.options.map( (item,index) =>
              <div key={index} className="mt-5">
                {
                  index === 0 &&
                  <div className="w-full bg-gray-100 mb-5" style={{ height:1 }}/>
            
                }
                <div className="cursor-pointer" onClick={() => {
                  this.close();
                  setTimeout( () => {
                    item.onPress();
                  }, 200);
                }}>
                  <p >{item.title}</p>
               </div>
              </div>)
          }
        </div>
      </div>
    </div>
    )
  }
}

export default BottomModal
