import React from 'react'
import FlatList from 'flatlist-react';
import Property from './Property';
import EmptyList from '../EmptyList/EmptyList';


export default function MyProperties({isPremier,properties,hidde,refetch}) {

  const _renderProperty = ( item ) => {


    return (
        <Property
          user={item.attributes.uploadedBy.data}
          key={'profile-property' + String(item.id)}
          isPremier={isPremier}
          property={item}
          refetch={refetch}
        />
    );
  }

  if(hidde)
    return null

  
  return (
    <div className="">
      <div className="flex justify-between flex-row items-center" style={{ marginBottom:20 }}>
        <div className="flex flex-row items-center">
          <p className="poppins-medium text-redaria-black" style={{ fontSize:27 }}>Mis Propiedades</p>
          <div className="border-redaria-black px-4 ml-5" style={{ borderRadius:18 }}>
            <p className="questrial text-redaria-black" style={{ fontSize:20 }}>{properties.length}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap" style={{ marginRight:90 }}>

        <FlatList
            list={properties}
            renderItem={_renderProperty}
            renderWhenEmpty={() => <EmptyList description={"Aquí verás tus propiedades."}/>}
          />


      </div>
    </div>
  )
}
