import React from 'react'
import Search from '../../lib/Icon/icons/Search'

export default function EmptySearch() {
  return (
    <div className="flex flex-col items-center mt-10">
      <div >
        <Search
          viewBox='0 0 24 24'
          className="w-16"
        />
      </div>
      <p className="poppins-semi-bold title text-redaria-black">{`No se encontraron\npublicaciones`}</p>
      <p className="poppins-regular subtitle text-redaria-black max-w-sm text-center">
        No se encontraron publicaciones,
        intenta cambiando tu búsqueda.
      </p>
    </div>
  )
}
