import React, { PureComponent } from 'react'
import LogoRedaria from '../../assets/images/icon_redaria.png'
import Disponible_AppStore from '../../assets/images/Disponible_AppStore.png'
import Disponible_GooglePlay from '../../assets/images/Disponible_GooglePlay.png'
import FondoMobile from '../../assets/images/fondo_mobile.png'

export default class MobileBanner extends PureComponent {
  render() {
    return (
      <div className="h-screen w-screen" 
        style={{ 
          backgroundImage: `url(${FondoMobile})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
          <div 
          className="h-screen w-screen backgroun-mobile flex flex-col items-center px-5"
          style={{ 
            paddingTop:70,
           }}>
              <div onClick={this.goHome} className="cursor-pointer flex flex-row items-center">
                <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
                <p className="questrial font-semibold text-white" style={{ fontSize:27 }}>redaria</p>
              </div>
              <p className="poppins-semi-bold text-white text-center" style={{ marginTop:72,fontSize:21,marginBottom:20 }}>¡Descarga nuestra app!</p>
              
              <a href="https://bit.ly/RedariaPlayStore" target="blank">
                <img src={Disponible_GooglePlay} className="mr-1" style={{ width:215 }} alt="logo google" />
              </a>
              <a href="https://bit.ly/RedariaAppStore" target="blank">
                <img src={Disponible_AppStore} className="mr-1" style={{ width:215 }} alt="logo appstore" />
              </a>
              <p className="questrial text-white text-center" style={{ fontSize:21,marginTop:20}}>Aprovecha todos los beneficios que te ofrece <span className="poppins-semi-bold">Redaria</span>…</p>
          </div>
        
      </div>
    )
  }
}
