import React, {  PureComponent } from 'react';
import Select, { components } from "react-select";
import { CloseIndicator } from '../../lib/Icon/icons/Indicators';
import { LocationBig } from '../../lib/Icon/icons/Location';

const customStyles = {
  container:(provided,state) => ({
    ...provided,
    border:'transparent !important',
    backgroundColor: "white",
    fontSize:20,
    fontFamily:'Poppins-SemiBold'
  }),
  control: (provided,state) => ({
    ...provided,
    borderTop:'transparent !important',
    borderLeft:'transparent !important',
    borderRight:'transparent !important',
    backgroundColor: "white",
    borderRadius:0,
    // none of react-select's styles are passed to <Control />
    borderBottom: '2px solid black',
  }),
  indicatorsContainer: () => ({

  }),
  valueContainer: (provided,state) => ({
    ...provided,
    paddingBottom:7,
  }),
  singleValue: (provided,state) => ({
    ...provided,
  }),
  placeholder: (provided,state) => ({
    ...provided,
    color: "rgba(17, 34, 51, 0.32)",
  })
}

const locationIconStyles = {
  container:(provided,state) => ({
    ...provided,
    border:'transparent !important',
    backgroundColor: "rgb(248, 248, 248)",
    fontSize:20,
    fontFamily:'Poppins-Regular'
  }),
  control: (provided,state) => ({
    ...provided,
    borderTop:'transparent !important',
    borderLeft:'transparent !important',
    borderRight:'transparent !important',
    backgroundColor: "rgb(248, 248, 248)",
    borderRadius:0,
    // none of react-select's styles are passed to <Control />
    borderBottom: '2px solid black',
  }),
  indicatorsContainer: () => ({

  }),
  valueContainer: (provided,state) => ({
    ...provided,
    paddingBottom:7,
  }),
  singleValue: (provided,state) => ({
    ...provided,
    marginLeft:30,
  }),
  placeholder: (provided,state) => ({
    ...provided,
    marginLeft:30,
  })


}


const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <CloseIndicator fill="#112233" className="w-4"/>
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {
      props.showLocationIcon &&
      <LocationBig fill="#112233" className="w-4"/>
    }
    {children}
  </components.ValueContainer>
);

export class AddressDropdown extends PureComponent {


  render(){
    const options = this.props.data.map( d => {
      return { value: d.name, label: d.name }
    })
    let value =''
    if(this.props.value.length > 0)
    {
      const v = this.props.data.find( d => d.name === this.props.value)
      if(v)
      {
        value = {value:v.name,label:v.name}
      }
    }
    
    return(
      <div className="mt-2 w-full">
        <Select
          showLocationIcon={this.props.showLocationIcon}
          components={{ DropdownIndicator,ValueContainer:(vprops) => <ValueContainer showLocationIcon={this.props.showLocationIcon} {...vprops}/> }}
          styles={this.props.showLocationIcon ? locationIconStyles : customStyles}
          placeholder={this.props.modalTitle}
          options={options}
          onChange={ e => this.props.onSelectOption(e.label)}
          value={value}
        />

      
      </div>
    )
  }
}
