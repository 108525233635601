import React, { PureComponent } from 'react'
import { getCitiesFromState } from '../../lib/Address';
import States from '../../lib/States'
import { AddressDropdown } from './AddressDropdown'
import { TextInputWithPlaceholder } from './TextInputWithPlaceholder';
import GoogleMap from 'google-map-react';
import { TextBox } from './TextBox';

export class AddressForm extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      defaultLocation: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      center: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      zoom:4,
    }
  }

  mapRef = React.createRef(null)

  getCities = () => {
    if (this.props.state === '') {
      return [];
    }
    else {
      return getCitiesFromState(this.props.state);
    }
  }


  onMouseUp = (e) => {
    this.setState({
      center: e.center,
    })
    
    this.props.onRegionChangeComplete({
      latitude:Number(e.center.lat),
      longitude:Number(e.center.lng)
    })
  }

  onDrag = (e) => {
    this.setState({
      center: {
        lat:e.center.lat(),
        lng:e.center.lng(),
      },
    })
  }


  testChange = (lat,lng) => {
    this.setState({
      defaultLocation: {
        lat:lat,
        lng:lng,
      },
      center: {
        lat:lat,
        lng:lng,
      },
      zoom:16
    })
  }


  getCities = () => {
    if (this.props.state === '') {
      return [];
    }
    else {
      return getCitiesFromState(this.props.state);
    }
  }


  onMouseUp = (e) => {
    this.setState({
      center: e.center,
    })
    
    this.props.onRegionChangeComplete({
      latitude:Number(e.center.lat),
      longitude:Number(e.center.lng)
    })
  }

  onDrag = (e) => {
    this.setState({
      center: {
        lat:e.center.lat(),
        lng:e.center.lng(),
      },
    })
  }


  testChange = (lat,lng) => {
    this.setState({
      defaultLocation: {
        lat:lat,
        lng:lng,
      },
      center: {
        lat:lat,
        lng:lng,
      },
      zoom:16
    })
  }

  render() {
    if(!this.props.active)
    {
      return null
    }
        
    return (
      <div className="bg-white rounded-lg padding-5 shadow-md mt-5 p-5 mx-2">
        <p className="poppins-semi-bold title text-redaria-black">Dirección</p>
        <p className="questrial text-redaria-black text-sm">La calle y el número no serán mostrados</p>
       
        <AddressDropdown
          data={States}
          onSelectOption={this.props.onChangeState}
          modalTitle='Selecciona un Estado'
          value={this.props.state}
        />
          {
            (this.props.requiredFields.find(element => element === 'state') && this.props.state.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }
        <AddressDropdown
          data={this.getCities()}
          onSelectOption={this.props.onChangeCity}
          placeholder='Ciudad'
          modalTitle='Selecciona una Ciudad'
          value={this.props.city}
        />
        {
          (this.props.requiredFields.find(element => element === 'city') && this.props.city.length === 0) &&
          <p className="text-red-500">
            Campo requerido
          </p>
        }
          <TextInputWithPlaceholder
            removeContainer
            maxLength={100}
            onChangeText={this.props.onChangeNeighborhood}
            placeholder='Colonia*'
            value={this.props.neighborhood}
          />
          {
            (this.props.requiredFields.find(element => element === 'neighborhood') && this.props.neighborhood.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }
          <TextInputWithPlaceholder
            removeContainer
            type="number"
            maxLength={100}
            onChangeText={zipCode => this.props.onChangeTextInput({ zipCode })}
            placeholder='Código Postal*'
            value={this.props.zipCode}
          />
          {
            (this.props.requiredFields.find(element => element === 'zipCode') && this.props.zipCode.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }
          <TextInputWithPlaceholder
            removeContainer
            maxLength={100}
            onChangeText={this.props.onChangeStreet}
            placeholder='Calle (opcional)'
            value={this.props.street}
          />
          
          <TextInputWithPlaceholder
            removeContainer
            maxLength={10}
            onChangeText={this.props.onChangeNumber}
            placeholder='Número (opcional)'
            value={this.props.number}
          />

        <div style={{ height: '300px', width: '300px' }} className="mt-4">
          <GoogleMap
            options={{ scrollwheel: false,}}
            id="mapa"
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
            zoom={this.state.zoom}
            center={this.state.defaultLocation}
            onChange={this.onMouseUp}
            onDrag={this.onDrag}
            ref={(ref) => this.mapRef = ref}
          >
            <img
            alt="picker"
            style={{ width:35 }}
            src='https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png'
            lat={this.state.center.lat}
            lng={this.state.center.lng}
          />
          </GoogleMap>
          <p className="questrial text-xs text-redaria-black">Mueve el mapa para ajustar el pin</p>
        </div>

        <p className="poppins-semi-bold text-redaria-black title mt-8">Detalles adicionales*</p>
        {
          (this.props.requiredFields.find(element => element === 'description') && this.props.description.length === 0) &&
          <p className="text-red-500">
            Campo requerido
          </p>
        }
        <TextBox
          maxLength={280}
          onChangeText={description => this.props.onChangeTextInput({ description })}
          placeholder={`¿Cuál es la mejor cualidad de tu propiedad?\nMáx. 280 caracteres.`}
          value={this.props.description}
        />
      </div>
    )
  }
}

export default AddressForm
