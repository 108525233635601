import React from 'react';

export function ServiceRoom({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 25 20" className={className ? className : "w-5"}>
      <g data-name="Grupo 1611">
        <g data-name="Grupo 1610">
          <path
            d="M23.275 6.533c-.721 0-1.225.322-1.225.782v1.4a3.413 3.413 0 00-2.858-2.186H7.758a.408.408 0 00-.408.408V9.4a2.45 2.45 0 10-4.261 1.64 1.192 1.192 0 00-.639.274V.98A1.111 1.111 0 001.225 0 1.111 1.111 0 000 .98v17.64a1.111 1.111 0 001.225.98 1.111 1.111 0 001.225-.98v-2.7h19.6v2.893c0 .46.5.782 1.225.782s1.225-.322 1.225-.782v-11.5c0-.458-.5-.78-1.225-.78zM1.633 18.62c0 .033-.142.163-.408.163s-.4-.13-.408-.163V.98c0-.033.14-.163.408-.163s.4.13.408.163zM8.166 7.35h11.026c1.54 0 2.714 2.293 2.846 4.492H8.167zM7.35 9.4v1.968a1.2 1.2 0 00-.643-.324A2.441 2.441 0 007.35 9.4zM4.9 7.758a1.633 1.633 0 11-1.633 1.633A1.633 1.633 0 014.9 7.758zm17.15 7.35H2.45v-2.451a.409.409 0 00.376-.407.4.4 0 01.393-.408h3.3a.4.4 0 01.393.408.408.408 0 00.408.408h14.73zm1.633 3.6a1.2 1.2 0 01-.817 0V7.418a1.2 1.2 0 01.817 0z"
            data-name="Trazado 23736"
          />
        </g>
      </g>
    </svg>
  )
}
