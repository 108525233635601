import React, { PureComponent } from 'react'
import Disponible_AppStore from '../../assets/images/Disponible_AppStore.png'
import Disponible_GooglePlay from '../../assets/images/Disponible_GooglePlay.png'

export default class StoreOptions extends PureComponent {
  render() {
    return (
      <div className="flex flex-col" style={{ borderRadius: 11, marginTop: 46 }}>
        <p className="poppins-medium text-redaria-black" style={{ fontSize: 15 }}>¡Descarga nuestra app!</p>
        <a href="https://bit.ly/RedariaPlayStore" target="blank" className="w-72 -ml-3">
          <img src={Disponible_GooglePlay} style={{ width:193 }} alt="logo google" />
        </a>
        <a href="https://bit.ly/RedariaAppStore" target="blank" className="w-72 -ml-3">
          <img src={Disponible_AppStore} style={{ width:193 }} alt="logo appstore" />
        </a>
      </div>
    )
  }
}
