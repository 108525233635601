import React from 'react'

export default function IconButton({ id, icon, onPress, label,active }) {

  return (
    <div onClick={() => onPress(id)} className="flex flex-col items-center justify-start mt-5 cursor-pointer" style={{ marginRight:41,width:82 }}>
      <div  className={`border-redaria-blue rounded-full flex items-center justify-center ${active ? 'bg-redaria-blue' : 'bg-gray-new-property'}`} style={{ width:65,height:65,borderWidth: 2 }}>
        {icon}
      </div>
      <div className={`poppins-semi-bold mt-1 text-center ${active ? 'text-redaria-blue' : 'text-blue-300'}`} style={{ fontSize:15 }}>
        {label}
      </div>
    </div>
  )
}
