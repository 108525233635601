import React, { PureComponent } from 'react'
import { InformationAlert } from '../../lib/alerts'
import { CloseDark } from '../../lib/Icon/icons/Close'
import LoadingModal from '../Loading/LoadingModal'
import { TextBox } from '../Forms/TextBox'
import PremierApi from '../../api/PremierApi'

export default class ModalContact extends PureComponent {

  state = {
    loadingSubmit: false,
    message:''
  }



  sendData = () => {
    const { message } = this.state
    this.setState({loadingSubmit:true}, () => {
      PremierApi.support(message).then(response => {
        this.setState({loadingSubmit:false}, () => {
          const finish = () => this.props.close()
          InformationAlert('Tu mensaje se envió correctamente','',
          ()=>{finish()},
          ()=>{finish()}
          )
        })
      });
    })
  }

  render() {
    
    const { visible, close } = this.props

    if (!visible)
      return null
    return (
      <div className="absolute z-30 flex mx-auto left-0 right-0 items-center justify-center top-10">
        <div onClick={close} className="fixed z-20  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>

        </div>
        <LoadingModal visible={this.state.loadingSubmit}/>
        <div className="bg-white z-20 flex flex-col " 
          style={{ 
            borderRadius:9,
            width:616,
            paddingLeft:50,
            paddingRight:50,
            paddingTop:24,
            paddingBottom:64,
            }}>
          <div className="flex flex-row justify-between items-center">
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:35 }}>Contáctanos</p>
            <div onClick={close} className="cursor-pointer">
              <CloseDark style={{widht:25,height:25}}/>
            </div>
          </div>

          <div>
            <p className="questrial text-redaria-black" style={{ fontSize:23,marginTop:47 }}>Cuéntanos como podemos apoyarte. Se creará un ticket de soporte para tu solicitud.</p>
            <br/>
            <p className="questrial text-redaria-black" style={{ fontSize:23 }}>Tiempo de respuesta:</p>
            <p className="questrial text-redaria-black" style={{ fontSize:23 }}>~72 horas.</p>
          </div>

          <div className="mt-5">
            <TextBox
              removeTitle
              onChangeText={message => this.setState({ message })}
              value={this.state.message}
            />
          </div>
          
          <div className="flex flex-row justify-between mt-10">
            <div className="w-5/12">
              <button onClick={close} className="redaria-button-white">Cancelar</button>
            </div>
            <div className="w-5/12">
              <button disabled={this.state.message.length < 5 } onClick={this.sendData} className={`${this.state.message.length < 5 ? 'redaria-button-gray' : 'redaria-button-blue'}`}>Enviar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
