import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import ModalContact from '../ModalChangePassword/ModalChangePassword'
import ModalChangePassword from '../ModalChangePassword/ModalChangePassword'

class OptionsMenu extends PureComponent {
  
  state = {
    showChangePassword:false,
    showContactModal:false
  }

  goArchive = () => this.props.history.push('/signed/me/archive')
  goTerms = () => this.props.history.push('/signed/terms')
  goPrivacy = () => this.props.history.push('/signed/privacy')

  openChangePassword = () => this.setState({showChangePassword:true}, () => window.scrollTo(0, 0))
  closeChangePassword = () => this.setState({showChangePassword:false})

  openContact = () => this.setState({showContactModal:true}, () => window.scrollTo(0, 0))
  closeContact = () => this.setState({showContactModal:false})

  render() {
    return (
      <div className="flex flex-col" style={{ borderRadius: 11, marginTop: 45 }}>
        <p onClick={this.goArchive} className="questrial cursor-pointer link text-redaria-black mt-2" style={{ fontSize: 15 }}>Archivo</p>
        <p onClick={this.openChangePassword} className="questrial cursor-pointer link text-redaria-black mt-2" style={{ fontSize: 15 }}>Cambiar contraseña</p>
        <p onClick={this.goPrivacy} className="questrial cursor-pointer link text-redaria-black mt-2" style={{ fontSize: 15 }}>Políticas de Privacidad</p>
        <p onClick={this.goTerms} className="questrial cursor-pointer link text-redaria-black mt-2" style={{ fontSize: 15 }}>Términos y Condiciones</p>
        <p onClick={this.openContact} className="questrial cursor-pointer link text-redaria-black mt-2" style={{ fontSize: 15 }}>Contáctanos</p>
        <ModalChangePassword visible={this.state.showChangePassword} close={this.closeChangePassword}/>
        <ModalContact visible={this.state.showContactModal} close={this.closeContact}/>
      </div>
    )
  }
}


export default withRouter(OptionsMenu)