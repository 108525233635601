import React from 'react'
import Cellar from '../../lib/Icon/icons/Cellar'
import Floor from '../../lib/Icon/icons/Floor'
import House from '../../lib/Icon/icons/House'
import Land from '../../lib/Icon/icons/Land'
import Local from '../../lib/Icon/icons/Local'
import Office from '../../lib/Icon/icons/Office'
import IconButton from './IconButton'

export default function PropertyTypeForm(props) {
  return (
    <div className="">
    <div className="flex flex-col">
      <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>
        Tipo de propiedad*
      </p>
      {
        props.emptyField &&
        <span className="text-red-500">
          {`\nCampo requerido`}
        </span>
      }
    </div>
    <div className="flex flex-row flex-wrap" style={{ marginTop:30 }}>
      <IconButton
        active={props.value === 0}
        icon={<House active={ props.value === 0 } className="w-8"/>}
        id={0}
        label='Casa'
        onPress={props.onSelectType}
      />
      <IconButton
        active={props.value === 1}
        icon={<Floor fill={ props.value === 1 ? '#fff' : '#2b77b7'} className="w-8"/>}
        id={1}
        label='Depa'
        onPress={props.onSelectType}
      />
      <IconButton
        active={props.value === 2}
        icon={<Land active={props.value === 2} className="w-8"/>}
        id={2}
        label='Terreno'
        onPress={props.onSelectType}
      />
      <IconButton
        active={props.value === 3}
        icon={<Local className="w-8" active={props.value === 3} variant={props.value === 3 ? 'white' : 'blue'}/>}
        id={3}
        label='Local'
        onPress={type => props.onSelectType(type)}
      />
      <IconButton
        active={props.value === 4}
        icon={<Office active={props.value === 4} className="w-8"/>}
        id={4}
        label='Oficina'
        onPress={type => props.onSelectType(type)}
      />

      <IconButton
        active={props.value === 5}
        icon={<Cellar fill={props.value === 5 ? '#fff' : '#2b77b7'} className="w-8"/>}
        id={5}
        label='Bodega'
        onPress={type => props.onSelectType(type)}
      /> 
    </div>
  </div>
  )
}
