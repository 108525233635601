import React from 'react';

export default function EmptyFeed({ fill, className }) {
  return (
    <svg viewBox='0 0 55.733 55.732' className={className ? className : "w-5"}>
      <g
        id="Grupo-1555_svg__prefix__Grupo_1555"
        data-name="Grupo 1555"
        transform="translate(-160 -152.268)"
      >
        <g id="Grupo-1555_svg__prefix__find" transform="translate(160 152.268)">
          <path
            id="Grupo-1555_svg__prefix__Trazado_23678"
            d="M37.8.9H6.655A5.9 5.9 0 00.75 6.808v39.739a5.9 5.9 0 005.908 5.908H37.8a5.9 5.9 0 005.908-5.908V6.808A5.908 5.908 0 0037.8.9z"
            data-name="Trazado 23678"
            transform="translate(.992 1.19)"
            fill="#eceff1"
          />
          <path
            id="Grupo-1555_svg__prefix__Trazado_23685"
            d="M40.057 55.732H6.386A6.391 6.391 0 010 49.346V6.386A6.391 6.391 0 016.386 0h18.67a1.742 1.742 0 010 3.483H6.386a2.907 2.907 0 00-2.9 2.9v42.96a2.907 2.907 0 002.9 2.9h33.671a2.907 2.907 0 002.9-2.9V28.075a1.742 1.742 0 113.483 0v21.271a6.391 6.391 0 01-6.383 6.386z"
            className="Grupo-1555_svg__prefix__cls-2"
            data-name="Trazado 23685"
            fill="#223242"
          />
          <path
            id="Grupo-1555_svg__prefix__Trazado_23686"
            d="M23.611 23.222a11.611 11.611 0 1111.611-11.611 11.624 11.624 0 01-11.611 11.611zm0-19.738a8.128 8.128 0 108.128 8.128 8.136 8.136 0 00-8.128-8.129z"
            className="Grupo-1555_svg__prefix__cls-2"
            data-name="Trazado 23686"
            transform="translate(15.866)"
            fill="#223242"
          />
          <path
            id="Grupo-1555_svg__prefix__Trazado_23687"
            d="M28.538 18.281a1.728 1.728 0 01-1.231-.511l-7.547-7.547a1.742 1.742 0 012.464-2.463l7.547 7.547a1.743 1.743 0 01-1.233 2.975z"
            className="Grupo-1555_svg__prefix__cls-2"
            data-name="Trazado 23687"
            transform="translate(25.452 9.585)"
            fill="#223242"
          />
        </g>
        <path
          id="Grupo-1555_svg__prefix__L\xEDnea_30"
          d="M0 0h17"
          className="Grupo-1555_svg__prefix__cls-3"
          data-name="L\xEDnea 30"
          transform="translate(167.5 168.5)"
          fill="none"
          stroke="#223242"
          strokeLinecap="round"
          strokeWidth={3}
        />
        <path
          id="Grupo-1555_svg__prefix__L\xEDnea_31"
          d="M0 0h26"
          className="Grupo-1555_svg__prefix__cls-3"
          data-name="L\xEDnea 31"
          transform="translate(167.5 177.5)"
          fill="none"
          stroke="#223242"
          strokeLinecap="round"
          strokeWidth={3}
        />
        <path
          id="Grupo-1555_svg__prefix__L\xEDnea_32"
          d="M0 0h31"
          className="Grupo-1555_svg__prefix__cls-3"
          data-name="L\xEDnea 32"
          transform="translate(167.5 186.5)"
          fill="none"
          stroke="#223242"
          strokeLinecap="round"
          strokeWidth={3}
        />
        <path
          id="Grupo-1555_svg__prefix__L\xEDnea_33"
          d="M0 0h31"
          className="Grupo-1555_svg__prefix__cls-3"
          data-name="L\xEDnea 33"
          transform="translate(167.5 195.5)"
          fill="none"
          stroke="#223242"
          strokeLinecap="round"
          strokeWidth={3}
        />
      </g>
    </svg>
  )
}