import React from 'react'

export default function DealType({dealType,className}) {
  let dealTypeBackgroundColor = ''
  switch (dealType) {
    case 'Renta':
      dealTypeBackgroundColor = 'deal-type-orange'
      break;
    case 'Venta':
      dealTypeBackgroundColor = 'deal-type-pink'
      break;

    default:
      dealTypeBackgroundColor = 'deal-type-blue'
      break;
  }
  
  let dt = dealType
  if(dealType === 'Departamento'){
    dt = 'Depa'
  }
  return (
    <div className={`px-5 flex items-center justify-center ${dealTypeBackgroundColor} ${className ? className : ''}`} style={{ borderRadius:15,height:26 }}>
      <p className="text-white poppins-medium" style={{ fontSize:11 }}>{dt}</p>
    </div>
  )
}
