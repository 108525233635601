import React from 'react';

export function Exit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 20.93"
      {...props}
    >
      <defs>
        <style>{".prefix__exitIcon{fill:#123}"}</style>
      </defs>
      <path
        className="prefix__exitIcon"
        d="M10.465 19.186H2.616a.872.872 0 01-.872-.872V2.616a.872.872 0 01.872-.872h7.849a.872.872 0 000-1.744H2.616A2.619 2.619 0 000 2.616v15.7a2.619 2.619 0 002.616 2.614h7.849a.872.872 0 000-1.744z"
      />
      <path
        className="prefix__exitIcon"
        d="M20.74 9.844l-5.3-5.233a.872.872 0 00-1.224 1.242l3.789 3.74H7.849a.872.872 0 000 1.744h10.154l-3.789 3.74a.872.872 0 101.224 1.242l5.3-5.233a.872.872 0 000-1.242z"
      />
    </svg>
  )
}
