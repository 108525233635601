import React, {PureComponent} from 'react';

import ExperienceArea from './ExperienceArea';

export default class ExperienceAreasForm extends PureComponent {
  render() {
    return(
      <div className="mt-5 w-full">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:20 }}>
          Áreas de experiencia
        </p>
        {
          this.props.emptyField &&
          <span className="text-red-500">
            Campo requerido
          </span>
        }
        <div className="flex flex-row w-full mt-2">
          <ExperienceArea isActive={this.props.experienceAreas.includes(1)} onPress={() => this.props.changeExperienceArea(1)} text='Residencial' />
          <ExperienceArea isActive={this.props.experienceAreas.includes(2)} onPress={() => this.props.changeExperienceArea(2)} text='Comercial' />
          <ExperienceArea isActive={this.props.experienceAreas.includes(3)} onPress={() => this.props.changeExperienceArea(3)} text='Industrial' />
        </div>
      </div>
    );
  }
}

