import React, {PureComponent} from 'react';
import { Chimney } from '../../lib/Icon/icons/Chimney';
import { Grill } from '../../lib/Icon/icons/Grill';
import { Gym } from '../../lib/Icon/icons/Gym';
import { Palapa } from '../../lib/Icon/icons/Palapa';
import { Pool } from '../../lib/Icon/icons/Pool';
import { Yard } from '../../lib/Icon/icons/Yard';
import AmmenityValue from './AmmenityValue';
import {getAmmenityValue, getBasicAmmenities} from '../../lib/Ammenities';
import Separator from './Separator';


export default class BasicAmmenities extends PureComponent {
  

  getIcon = (name) => {
    switch (name) {
      case 'pool':
        return <Pool fill="#112233" className="w-10"/>;
      case 'grill':
        return <Grill fill="#112233" className="w-10"/>;
      case 'yard':
        return <Yard fill="#112233" className="w-10"/>;
      case 'chimney':
        return <Chimney fill="#112233" className="w-10"/>;
      case 'palapa':
        return <Palapa fill="#112233" className="w-10"/>;
      case 'private_gym':
        return <Gym fill="#112233" className="w-10"/>;
    
      default:
          <Gym/>
        break;
    }
  }

  render() {
    const basicAmmenities = getBasicAmmenities(this.props.ammenities)
    if(basicAmmenities.length === 0)
      return null
    return(
      <React.Fragment>
        <div className="flex flex-col my-5">
          <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Amenidades:</p>
          <div className="flex flex-row flex-wrap mt-5">
          {
              basicAmmenities.map((ammenity, index) =>
                <AmmenityValue
                  icon={this.getIcon(ammenity.slug)}
                  key={index}
                  label={ammenity.name}
                  value={getAmmenityValue(this.props.ammenities, ammenity.id)}
                />
              )
            }
          </div>
        </div>
        <Separator />
      </React.Fragment>
    );
  }
}
