/* eslint-disable */
import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Image } from './Image';
import update from 'immutability-helper';
import { useEffect } from 'react';
export const ItemTypes = {
  CARD: 'card',
}

export default function ImagesList({ images, updateImagesOrder, deleteImage }) {
  const [cards, setCards] = useState(images);

  const moveCard = (id, atIndex) => {
    const { card, index } = findCard(id);
    const newOrder = update(cards, {
      $splice: [
        [index, 1],
        [atIndex, 0, card],
      ],
    })
    setCards(newOrder);
  };


  useEffect(() => {
    setCards(images)
  }, [images])

  const onFinishDrop = () => {
    updateImagesOrder(cards)
  }

  const findCard = (id) => {
    const card = cards.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  const [, drop] = useDrop({ accept: ItemTypes.CARD });

  return (<>
    <div className="flex flex-wrap mt-5 relative" ref={drop}>
      <div className="absolute z-40 pointer-events-none">
        {
          cards.length > 0 &&
          <React.Fragment>
            <div className="my-2" >
              <div className="flex" style={{ borderRadius: 10, height: 80, width: 85 }}>
                <div className="self-end w-full" style={{ backgroundColor:'rgba(255,255,255,0.7)',borderBottomLeftRadius:10,borderBottomRightRadius:10 }}>
                  <p className="questrial text-redaria-black text-center" style={{ fontSize: 16, }} >Portada*</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      </div>
      {cards.map((card, index) => (<Image deleteImage={deleteImage} onFinishDrop={onFinishDrop} key={'img-drag' + index} id={`${card.id}`} img={card} moveCard={moveCard} findCard={findCard} />))}
    </div>
  </>);
}
