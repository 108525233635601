import React from 'react';

export function Yard({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 29 25' className={className ? className : "w-5"}>
      <g id="yard_svg__prefix__garden" transform="translate(0 -32.039)">
        <g
          id="yard_svg__prefix__Grupo_1656"
          data-name="Grupo 1656"
          transform="translate(0 32.039)"
        >
          <g id="yard_svg__prefix__Grupo_1655" data-name="Grupo 1655">
            <path
              id="yard_svg__prefix__Trazado_23815"
              d="M27.779 45.3a1.162 1.162 0 001.162-1.162v-3.807a1.162 1.162 0 00-1.162-1.162h-1.261v-3.175a.553.553 0 00-.133-.36l-2.9-3.4a.553.553 0 00-.841 0l-2.9 3.4a.553.553 0 00-.133.36v3.175h-1.667v-3.175a.553.553 0 00-.133-.36l-2.921-3.4a.553.553 0 00-.841 0l-2.9 3.4a.553.553 0 00-.133.36v3.175H9.359v-3.175a.553.553 0 00-.133-.36L6.3 32.233a.553.553 0 00-.841 0l-2.9 3.4a.553.553 0 00-.133.36v3.175H1.162A1.162 1.162 0 000 40.331v3.811A1.162 1.162 0 001.162 45.3h1.261v1.842H1.162A1.162 1.162 0 000 48.307v3.811a1.162 1.162 0 001.162 1.162h1.261V56a.553.553 0 00.553.553h5.8A.553.553 0 009.332 56v-2.726h1.659v2.716a.553.553 0 00.553.553h5.83a.553.553 0 00.553-.553v-2.716h1.659v2.716a.553.553 0 00.553.553h5.825a.553.553 0 00.553-.553v-2.716h1.261a1.162 1.162 0 001.162-1.162V48.3a1.162 1.162 0 00-1.162-1.162h-1.26V45.3zm-7.064-9.1l2.351-2.766 2.351 2.766v2.97h-4.7zm-8.59 0l2.351-2.766 2.351 2.766v2.97h-4.708zm-8.59 0l2.351-2.766 2.35 2.766v2.97H3.529zm-.006 19.243v-2.169h4.7l.006 2.168h-4.7zm8.59 0v-2.169h4.7l.006 2.168h-4.7zm13.292 0h-4.7v-2.169h4.7v2.168zm2.367-7.191a.055.055 0 01.055.055v3.811a.055.055 0 01-.055.055H1.162a.055.055 0 01-.055-.055V48.3a.055.055 0 01.055-.055zM3.529 47.146V45.3h4.7v1.842zm5.8 0V45.3h1.659v1.842zm2.766 0V45.3h4.7v1.842zm5.8 0V45.3h1.659v1.842zm2.766 0V45.3h4.7v1.842zM1.162 44.2a.055.055 0 01-.055-.055v-3.814a.055.055 0 01.055-.055h26.617a.055.055 0 01.055.055l-.044 3.811a.055.055 0 01-.055.055z"
              className="yard_svg__prefix__cls-1"
              data-name="Trazado 23815"
              transform="translate(0 -32.039)"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1658"
          data-name="Grupo 1658"
          transform="translate(5.072 41.426)"
        >
          <g id="yard_svg__prefix__Grupo_1657" data-name="Grupo 1657">
            <circle
              id="yard_svg__prefix__Elipse_78"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 78"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1660"
          data-name="Grupo 1660"
          transform="translate(5.072 49.402)"
        >
          <g id="yard_svg__prefix__Grupo_1659" data-name="Grupo 1659">
            <circle
              id="yard_svg__prefix__Elipse_79"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 79"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1662"
          data-name="Grupo 1662"
          transform="translate(13.663 41.426)"
        >
          <g id="yard_svg__prefix__Grupo_1661" data-name="Grupo 1661">
            <circle
              id="yard_svg__prefix__Elipse_80"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 80"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1664"
          data-name="Grupo 1664"
          transform="translate(13.663 49.402)"
        >
          <g id="yard_svg__prefix__Grupo_1663" data-name="Grupo 1663">
            <circle
              id="yard_svg__prefix__Elipse_81"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 81"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1666"
          data-name="Grupo 1666"
          transform="translate(22.253 41.426)"
        >
          <g id="yard_svg__prefix__Grupo_1665" data-name="Grupo 1665">
            <circle
              id="yard_svg__prefix__Elipse_82"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 82"
            />
          </g>
        </g>
        <g
          id="yard_svg__prefix__Grupo_1668"
          data-name="Grupo 1668"
          transform="translate(22.253 49.402)"
        >
          <g id="yard_svg__prefix__Grupo_1667" data-name="Grupo 1667">
            <circle
              id="yard_svg__prefix__Elipse_83"
              cx={0.808}
              cy={0.808}
              r={0.808}
              className="yard_svg__prefix__cls-1"
              data-name="Elipse 83"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
