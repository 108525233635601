import React from 'react'

export default function LabeledIcon({icon,label,span}) {
  return (
    <div className="flex flex-row items-center">
      <div>
        {icon}
      </div>
      <div className="ml-2">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>{label} <span className="poppins-regular" style={{ fontSize:17 }}>{span}</span></p>
      </div>
    </div>
  )
}
