import React from 'react';

export function WhatsappSimple(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 36.004 36.005">
      <path
        d="M18.006 0H18A17.991 17.991 0 003.427 28.554l-2.243 6.688L8.1 33.03A18 18 0 1018.006 0z"
        fill="#7ad06d"
      />
      <path
        d="M28.479 25.422c-.434 1.226-2.158 2.244-3.533 2.541-.941.2-2.169.36-6.305-1.355-5.29-2.192-8.7-7.568-8.963-7.917s-2.138-2.847-2.138-5.43A5.747 5.747 0 019.381 8.88a2.615 2.615 0 011.841-.646c.223 0 .423.011.6.02.529.023.794.054 1.143.889.434 1.046 1.492 3.63 1.618 3.9a1.071 1.071 0 01.076.974 3.109 3.109 0 01-.583.826c-.266.306-.518.54-.783.869-.243.286-.517.592-.211 1.121a15.981 15.981 0 002.921 3.63 13.233 13.233 0 004.221 2.6 1.138 1.138 0 001.269-.2A21.784 21.784 0 0022.899 21a1 1 0 011.292-.392c.486.169 3.058 1.44 3.587 1.7s.878.392 1.006.614a4.484 4.484 0 01-.305 2.5z"
        fill="#fff"
      />
    </svg>
  )
}

export function ShareBig(props) {
  return (
    <svg
      viewBox="0 0 313.55 78.517"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          x={8.935}
          y={0}
          width={304.616}
          height={78.517}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="b" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
        <style>
          {
            ".prefix__a{fill:#68d058}.prefix__c{fill:#fff}.prefix__d{fill:#7ad06d}.prefix__e,.prefix__f{stroke:none}.prefix__f{fill:#fff}"
          }
        </style>
      </defs>
      
      <g className="prefix__a">
        <path
          className="prefix__e"
          d="M30.677 65.587c-4.075 0-8.028-.782-11.75-2.323a30.122 30.122 0 01-9.592-6.334 29.42 29.42 0 01-6.465-9.39A28.826 28.826 0 01.5 36.042c0-3.987.797-7.855 2.37-11.496a29.419 29.419 0 016.465-9.39 30.121 30.121 0 019.593-6.334A30.545 30.545 0 0130.678 6.5c4.075 0 8.028.782 11.75 2.323a30.121 30.121 0 019.592 6.334 29.42 29.42 0 016.465 9.39 28.826 28.826 0 012.37 11.496c0 3.988-.797 7.855-2.37 11.496a29.42 29.42 0 01-6.465 9.391 30.122 30.122 0 01-9.593 6.334 30.545 30.545 0 01-11.75 2.323z"
        />
        <path
          className="prefix__f"
          d="M30.677 7c-4.009 0-7.898.769-11.558 2.285a29.624 29.624 0 00-9.434 6.229 28.922 28.922 0 00-6.356 9.232A28.329 28.329 0 001 36.043c0 3.919.784 7.72 2.329 11.298a28.922 28.922 0 006.356 9.232 29.624 29.624 0 009.434 6.229 30.047 30.047 0 0011.558 2.285c4.01 0 7.899-.769 11.559-2.285a29.624 29.624 0 009.434-6.23 28.921 28.921 0 006.356-9.23 28.329 28.329 0 002.329-11.299c0-3.918-.784-7.72-2.329-11.297a28.921 28.921 0 00-6.356-9.232 29.624 29.624 0 00-9.434-6.23A30.047 30.047 0 0030.677 7m0-1C47.62 6 61.355 19.45 61.355 36.043S47.62 66.087 30.677 66.087C13.735 66.087 0 52.636 0 36.043 0 19.451 13.735 6 30.677 6z"
        />
      </g>

      <g>
        <path
          className="prefix__c"
          d="M42.433 26.805a9.79 9.79 0 11-9.79 9.79 9.79 9.79 0 019.79-9.79z"
        />
        <path
          className="prefix__d"
          d="M44.894 36.978h-1.747v6.4h-2.646v-6.4h-1.259v-2.249h1.259v-1.455a2.482 2.482 0 012.67-2.671l1.96.008v2.183h-1.422a.539.539 0 00-.561.613v1.324h1.982z"
        />
      </g>
      <g>
        <path
          className="prefix__c"
          d="M18.796 26.805a9.784 9.784 0 00-7.928 15.528l-1.22 3.637 3.763-1.2a9.788 9.788 0 105.385-17.965z"
        />
        <path
          className="prefix__d"
          d="M24.493 40.63a2.762 2.762 0 01-1.921 1.382c-.512.109-1.18.2-3.429-.737a12.264 12.264 0 01-4.874-4.305 5.592 5.592 0 01-1.163-2.953 3.125 3.125 0 011-2.383 1.422 1.422 0 011-.351c.121 0 .23.006.328.011.288.012.432.029.622.483.236.569.811 1.974.88 2.118a.583.583 0 01.042.53 1.692 1.692 0 01-.317.449c-.144.166-.281.294-.426.472-.132.155-.281.322-.115.609a8.693 8.693 0 001.588 1.974 7.2 7.2 0 002.3 1.416.619.619 0 00.69-.109 11.855 11.855 0 00.765-1.013.546.546 0 01.7-.213c.264.092 1.663.783 1.951.926s.477.213.547.334a2.438 2.438 0 01-.168 1.36z"
        />
      </g>
    </svg>
  )
}