import React, { PureComponent } from 'react'
import Disponible_AppStore from '../../assets/images/Disponible_AppStore.png'
import Disponible_GooglePlay from '../../assets/images/Disponible_GooglePlay.png'
import House from '../../assets/images/welcome/house.png'

export class LeftContainer extends PureComponent {
  static propTypes = {

  }

  render() {
    return (
      <div className="">

        <p className="poppins-medium mt-14" style={{ fontSize:39 }}>¡Publica tus propiedades desde tu ordenador con <span className="text-redaria-blue poppins-semi-bold">Redaria web</span>!</p>

        <p className="poppins-medium mt-20" style={{ fontSize:23 }}>Descarga nuestra app y aprovecha</p>
        <p className="poppins-medium" style={{ fontSize:23 }}>todos los beneficios que te ofrecemos...</p>

        <div className="flex flex-row items-center">
          <a href="https://bit.ly/RedariaPlayStore" target="blank">
            <img src={Disponible_GooglePlay} className="mr-1" style={{ width:260 }} alt="logo google" />
          </a>
          <a href="https://bit.ly/RedariaAppStore" target="blank">
            <img src={Disponible_AppStore} className="mr-1" style={{ width:260 }} alt="logo appstore" />
          </a>
        </div>
          <img src={House} className="mt-24" style={{ width:574 }} alt="house" />
      </div>
    )
  }
}

export default LeftContainer
