import React, { PureComponent } from 'react'
import { DealTypeForm } from '../../components/Forms/DealTypeForm';
import PropertyTypeForm from '../../components/Forms/PropertyTypeForm'
import { TextInputWithPlaceholder } from '../../components/Forms/TextInputWithPlaceholder';
import ImagesForm from '../../components/Forms/ImagesForm';
import { NON_DIGITS_REGEXP } from '../../lib/Regexp';

export default class NewPropertyBasicForm extends PureComponent {


  parsedData = () => {
    return {
      dealType: this.props.dealType,
      images: this.props.images,
      price: this.props.price.replace(NON_DIGITS_REGEXP, ""),
      type: this.props.type,
      maintenanceFee: this.props.maintenanceFee.replace(NON_DIGITS_REGEXP, "")
    }
  }

  render() {
    const { 
      dealType, images, price, type, maintenanceFee, requiredFields,
      changePrice,handleChange,_deleteImage,
      parentSetState,
      imagesError
    } = this.props
    return (
      <React.Fragment>
        <PropertyTypeForm
          onSelectType={(type) => parentSetState({type, maintenanceFee: ''})}
          value={type}
          emptyField={requiredFields.find(element => element === 'type') && type === null}
        />
        <DealTypeForm
          dealType={dealType}
          changeDealType={value => parentSetState({dealType: value})}
          emptyField={requiredFields.find(element => element === 'dealType') && dealType === null}
        />

        <ImagesForm
          imagesError={imagesError}
          updateImagesOrder={this.props.updateImagesOrder}
          deleteImage={_deleteImage}
          images={images}
          emptyField={requiredFields.find(element => element === 'image') && images.length === 0}
          onChange={handleChange}
        />

        <div className="w-11/12 mt-10">
          <TextInputWithPlaceholder
            removeContainer
            type='text'
            label='Precio*'
            maxLength={12}
            onChangeText={price => changePrice(price, 'price')}
            placeholder='Ejemplo: $120,000'
            value={price}
            emptyField={requiredFields.find(element => element === 'price') && price.length === 0}
            />
            {
              type !== 0 && type !== 1 && type !== 2 && 
              <TextInputWithPlaceholder
                removeContainer
                type="text"
                label='Cuota mantenimiento'
                maxLength={12}
                onChangeText={maintenanceFee => changePrice(maintenanceFee, 'maintenanceFee')}
                placeholder='Ejemplo: $5,000 (opcional)'
                value={maintenanceFee}
              />
            }

          <div className="bg-gray-300 mt-10 w-full" style={{height: 1,}} />
          <div className="flex flex-row items-center justify-end ">
            <div className="w-72">
              <button onClick={()=>{
                this.props.finishFirstSection()
              }} className="redaria-button-white  remove-background mt-10 self-center mb-10">Siguiente</button>
            </div>
          </div>
        </div>
        
        

      </React.Fragment>
    )
  }
}
