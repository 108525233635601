import React from 'react';

export default function Office({active,className}) {
  return (
    <svg fill={ active ? '#fff' : '#2b77b7'} viewBox='0 0 30 25' className={className ? className :"w-5"}>
      <g id="oficina_svg__prefix__Grupo_1249" data-name="Grupo 1249">
        <g id="oficina_svg__prefix__university" transform="translate(0 3.28)">
          <g id="oficina_svg__prefix__Grupo_1228" data-name="Grupo 1228">
            <g id="oficina_svg__prefix__Grupo_1227" data-name="Grupo 1227">
              <path
                id="oficina_svg__prefix__Trazado_5425"
                d="M187.733 259.483a.5.5 0 00.5.5h1.99a.5.5 0 00.5-.5V256.5a.5.5 0 00-.5-.5h-1.99a.5.5 0 00-.5.5v2.985zm1-2.488h1v1.99h-1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5425"
                transform="translate(-176.788 -250.03)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5426"
                d="M188.231 157.581h1.99a.5.5 0 00.5-.5V154.1a.5.5 0 00-.5-.5h-1.99a.5.5 0 00-.5.5v2.985a.5.5 0 00.5.496zm.5-2.985h1v1.99h-1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5426"
                transform="translate(-176.789 -153.601)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5428"
                d="M53.688 259.981a.5.5 0 00.5-.5V256.5a.5.5 0 00-.5-.5H51.7a.5.5 0 00-.5.5v1a.5.5 0 00.995 0v-.5h1v2.488a.5.5 0 00.493.493z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5428"
                transform="translate(-48.215 -250.031)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5430"
                d="M102.9 365.367h16.916a.5.5 0 000-1h-4.478V359.4h.5a.5.5 0 000-.995h-8.955a.5.5 0 100 .995h.5v4.975H102.9a.5.5 0 000 1zm8.955-2.985a.5.5 0 100-.995V359.4h2.488v4.975h-2.488zm-3.485-2.982h2.488v1.99a.5.5 0 100 .995v1.99h-2.488z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5430"
                transform="translate(-96.43 -346.462)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5431"
                d="M412.089 366.933H410.1a.5.5 0 00-.5.5v2.985a.5.5 0 00.5.5h1.99a.5.5 0 00.5-.5v-2.988a.5.5 0 00-.501-.497zm-.5 2.985h-1v-1.99h1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5431"
                transform="translate(-385.72 -354.495)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5432"
                d="M412.089 264.533H410.1a.5.5 0 00-.5.5v2.985a.5.5 0 00.5.5h1.99a.5.5 0 00.5-.5v-2.988a.5.5 0 00-.501-.497zm-.5 2.985h-1v-1.99h1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5432"
                transform="translate(-385.72 -258.065)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5433"
                d="M89.66 494.933h-20.9a.5.5 0 000 1h20.9a.5.5 0 000-1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5433"
                transform="translate(-64.287 -475.032)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5434"
                d="M399 187.733a.5.5 0 00-.5.5v.5h-5.473a.5.5 0 000 1h5.473v16.418a.5.5 0 10.995 0V188.23a.5.5 0 00-.495-.497z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5434"
                transform="translate(-369.648 -185.743)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5435"
                d="M273.067 259.483a.5.5 0 00.5.5h1.99a.5.5 0 00.5-.5V256.5a.5.5 0 00-.5-.5h-1.99a.5.5 0 00-.5.5zm.995-2.488h1v1.99h-1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5435"
                transform="translate(-257.146 -250.03)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5436"
                d="M4.975 313.668c0-.065-.028-6.468-2.488-6.468S0 313.6 0 313.668a2.492 2.492 0 001.99 2.437v2.538a.5.5 0 10.995 0v-2.538a2.492 2.492 0 001.99-2.437zm-2.487 1.492A1.494 1.494 0 011 313.668c0-2.479.666-5.473 1.493-5.473s1.493 2.994 1.493 5.473a1.494 1.494 0 01-1.498 1.492z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5436"
                transform="translate(0 -298.245)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5438"
                d="M273.565 157.581h1.99a.5.5 0 00.5-.5V154.1a.5.5 0 00-.5-.5h-1.99a.5.5 0 00-.5.5v2.985a.5.5 0 00.5.496zm.5-2.985h1v1.99h-1z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5438"
                transform="translate(-257.146 -153.601)"
              />
              <path
                id="oficina_svg__prefix__Trazado_5439"
                d="M.5 194.2a.5.5 0 00.5-.5v-3.98h5.468a.5.5 0 000-.995H1v-.5a.5.5 0 00-1 0v5.473a.5.5 0 00.5.502z"
                className="oficina_svg__prefix__cls-1"
                data-name="Trazado 5439"
                transform="translate(0 -185.745)"
              />
            </g>
          </g>
        </g>
        <path
          id="oficina_svg__prefix__Trazado_5448"
          d="M149.967 0h-12.936a.457.457 0 00-.5.4v20.856a.509.509 0 00.995 0V.8h11.94v20.456a.509.509 0 00.995 0V.4a.457.457 0 00-.494-.4z"
          className="oficina_svg__prefix__cls-1"
          data-name="Trazado 5448"
          transform="translate(-128.574)"
        />
      </g>
    </svg>
  )
}