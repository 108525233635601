import React, { PureComponent } from 'react'
import NewPropertyBasicForm from '../NewPropertyBasicForm/NewPropertyBasicForm';
import { NON_DIGITS_REGEXP } from '../../lib/Regexp';
import numeral from 'numeral';
import { ErrorAlert } from '../../lib/alerts/ErrorAlert';
import NewPropertyAdditionalInformationForm from '../NewPropertyAdditionalInformationForm/NewPropertyAdditionalInformationForm';
import { getPremierStauts } from '../../selectors/premier';
import { connect } from 'react-redux';
import _ from 'lodash'
import GooglePlacesApi from '../../lib/GooglePlacesApi';
import { PropertyFormSubmit } from './../../lib/FormSubmit'
import { InformationAlert } from '../../lib/alerts';
import LoadingModal from '../../components/Loading/LoadingModal';
import PropertiesApi from '../../api/PropertiesApi';
class NewPropertyContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sttep:0,
      dealType: null,
      images: [],
      price: '',
      type: null,
      maintenanceFee: '',
      requiredFields: [],



      activeSection: 1,
      age: -1,
      ammenities: [],
      buildingArea: '',
      city: '',
      description: '',
      disabledButton: false,
      floors: '',
      landArea: '',
      latitude: 23.181385167349607,
      latitudeDelta: 24.92818706505762,
      legalStatus: 0,
      longitude: -102.41049645468593,
      longitudeDelta: 31.527897454798236,
      nearbyPlaces: [],
      neighborhood: '',
      number: '',
      numberOfRecommendedPosts: 0,
      state: this.props.defaultState,
      street: '',
      zipCode: '',
      isLoadingModalVisible: false,
      isRecommendedPostsModalVisible: false,
      viewOptionalFields: false,
      propertyId: undefined,
      uploadedImages: 0,
      loadingMessage: '',
    }

  }

  map = React.createRef(null)

  updateMap = _.debounce(this._updateMap, 1000);


  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  changePrice = (value, field) => {

    let price = value.replace(NON_DIGITS_REGEXP, "");
    price = price === '0' || price === '' ? '' : parseInt(price);
    price = price === '' ? '' : numeral(price).format('$0,0[.]00');
    this.setState({
      [field]: price
    });
  }

  handleChange = (e) => {
    
    const images = [...this.state.images,...e.target.files]
    if (Array.from(images).length > 10) {
      e.preventDefault();
      ErrorAlert(
        'El limite de imágenes es de 10.',
        ()=>{}
      );
      return;
    }
    
    this.setState({
      images: images
    });
  }

  _deleteImage = (imageName) => {
    
    const selectedImages = this.state.images.filter(({ name }) => (name !== imageName));
    this.setState({images:selectedImages})
  };

  _setRequiredFieldsFirst = requiredFields => {

    

    

    this.setState({ requiredFields })
  }


  // second
  toggleSection = (sectionId) => {
    if (this.state.activeSection === sectionId) {
      this.setState({
        activeSection: 0
      });
    }
    else {
      this.setState({
        activeSection: sectionId
      });
    }
  }

  changeAmmenityValue = (id, value) => {
    let ammenity = {
      id: id,
      value: value
    };

    if (this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      let ammenities = [...this.state.ammenities];
      
      const ammenityValue = this.state.ammenities.find(x => x.id === ammenity.id) ? this.state.ammenities.find(x => x.id === ammenity.id).value : null;

      const ammenityIndex = this.state.ammenities.findIndex(x => x.id === ammenity.id);
      if (ammenityValue !== ammenity.value) {
        ammenities[ammenityIndex] = ammenity;
        this.setState({ ammenities: ammenities });
      }
      else {
        this.setState({
          ammenities: ammenities.filter(x => x.id !== ammenity.id)
        });
      }
    }
  }

  changeFloors = (value) => {
    this.setState(prevState => {
      if (prevState.floors === value) {
        return { floors: '' };
      }
      else {
        return { floors: value };
      }
    });
  }

  toggleAmmenity = (id) => {
    let ammenity = {
      id: id
    };

    if (this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      this.setState(prevState => ({
        ammenities: prevState.ammenities.filter(x => x.id !== id)
      }));
    }
  }

  toggleNearbyPlace= (id) => {
    if (this.state.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === id) === undefined) {
      this.setState(prevState => ({
        nearbyPlaces: [...prevState.nearbyPlaces, id]
      }));
    }
    else {
      this.setState(prevState => ({
        nearbyPlaces: prevState.nearbyPlaces.filter(nearbyPlaceId => nearbyPlaceId !== id)
      }));
    }
  }

  onChangeState = (state) => {
    this.setState(prevState => {
      if (prevState.state !== state) {
        return { state: state, city: '' };
      }
    });
  }


  _updateMap() {
    let state = this.state.state.split(' ').join('+');
    let city = this.state.city.split(' ').join('+');
    let neighborhood = this.state.neighborhood.split(' ').join('+');
    let street = this.state.street.split(' ').join('+');
    let number = this.state.number.split(' ').join('+');

    let query = '';

    if (state !== '') {
      query = `${query}+${state}`;
    }
    if (city !== '') {
      query = `${query}+${city}`;
    }
    if (neighborhood !== '') {
      query = `${query}+${neighborhood}`;
    }
    if (street !== '') {
      query = `${query}+calle+${street}`;
    }
    if (number !== '') {
      query = `${query}+${number}`;
    }

    GooglePlacesApi.getPlace(query).then(result => {
      if (result.data.result) {
        this.setState({
          latitude: result.data.result.geometry.location.lat,
          longitude: result.data.result.geometry.location.lng,
        }, () => {
          this.map.current.testChange(this.state.latitude,this.state.longitude)
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  submitForm = () => {
    this.setState({
      disabledButton: true
    }, () => {
      PropertyFormSubmit(
        this.parsedData(),
        (requiredFields) => this._setRequiredfields(requiredFields),
        formData => this.sendData(formData)
      );
    });
  }

  _setRequiredfields = requiredFields => {
    this.setState({ disabledButton: false, requiredFields })
  }


  sendData = async(entries) => {
    const images = entries.filter(([key]) => (key === 'images[]')).map(([_, image]) => image);
    const totalImagesCount = images.length;
    let currentImageCount = 1;
    let uploadedImages = 0;
    try {
      await this.setStatePromise({ isLoadingModalVisible: true, loadingMessage: '' });

      let propertyId = this.state.propertyId;
      let numberOfRecommendedPosts;

      while (images.length > 0) {
        const image = images[0];
        const formData = new FormData();
        entries.filter(([key]) => (key !== 'images[]')).forEach(([name, value]) => formData.append(name, value));

        formData.append((!propertyId) ? 'images[]' : 'new_images[]', image);

        this.setState({ loadingMessage: `Subiendo imagen ${currentImageCount} de ${totalImagesCount}` });
        const result = await ((!propertyId) ? PropertiesApi.create(formData) : PropertiesApi.update(propertyId, formData));

        if (!propertyId) {
          propertyId = result.data.data.id;
          numberOfRecommendedPosts = result.data.data.attributes.numberOfRecommendedPosts;
        }
        await this.setStatePromise(({ propertyId, uploadedImages }));
        
        images.shift(); ++currentImageCount; ++uploadedImages
      }

      if (!propertyId || (images.length > 0)) {
        this.showErrorMessage();      
      } else {
        if (numberOfRecommendedPosts && (numberOfRecommendedPosts > 0) && this.props.isPremier) {
          this.setState({
            isLoadingModalVisible: false,
            isRecommendedPostsModalVisible: true,
            numberOfRecommendedPosts,
          });
        } else {
          const finish = () => {
            this.setState({
              isLoadingModalVisible: false
            }, () => {
              this.props.history.push('/signed/me/properties');
            });

          }
          InformationAlert(
            'Listo',
            'Tu propiedad fue publicada',
            () => {finish()},
            () => {finish()},
          );  
        }
      }
    } catch(error) {
      console.log(error);
      // console.log(error?.response?.data)
      // this.showErrorMessage();
    }
    this.setState({images})
    // this.props.dispatch(setSelectedImages(images));
  }

  parsedData = () => {
    let propertyObject = {
      type: this.state.type,
      dealType: this.state.dealType,
      price: this.state.price.replace(NON_DIGITS_REGEXP, ""),
      images: this.state.images,
      landArea: this.state.landArea,
      legalStatus: this.state.legalStatus,
      nearbyPlaces: this.state.nearbyPlaces,
      state: this.state.state,
      city: this.state.city,
      zipCode: this.state.zipCode,
      neighborhood: this.state.neighborhood,
      street: this.state.street,
      number: this.state.number,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      description: this.state.description,
      maintenanceFee: this.state.maintenanceFee.replace(NON_DIGITS_REGEXP, ""),
    };

    if (this.state.buildingArea !== '') {
      propertyObject.buildingArea = this.state.buildingArea;
    }

    if (propertyObject.type !== 2) {
      propertyObject.floors = this.state.floors;
      propertyObject.ammenities = this.state.ammenities;
      propertyObject.age = this.state.age;
    }

    return propertyObject;
  }


  render() {
    return (
      <div>
        {
          this.state.sttep === 0
          ? <NewPropertyBasicForm
              changePrice={this.changePrice}
              handleChange={this.handleChange}
              _deleteImage={this._deleteImage}
              dealType={this.state.dealType}
              images={this.state.images}
              price={this.state.price}
              type={this.state.type}
              maintenanceFee={this.state.maintenanceFee}
              requiredFields={this.state.requiredFields}
              parentSetState={ (v) => this.setState(v)}
              nextSttep={ () => this.setState({sttep:1})}
              setRequiredFields={this._setRequiredFieldsFirst}
            />
          :<NewPropertyAdditionalInformationForm
            activeSection={this.state.activeSection}
            toggleSection={this.toggleSection}

            age={this.state.age}
            ammenities={this.state.ammenities}
            floors={this.state.floors}
            changeAmmenityValue={this.changeAmmenityValue}
            parentSetState={ (v) => this.setState(v)}
            changeFloors={this.changeFloors}
            propertyType={this.state.type}
            landArea={this.state.landArea}
            buildingArea={this.state.buildingArea}
            requiredFields={this.state.requiredFields}
            legalStatus={this.state.legalStatus}

            city={this.state.city}
            description={this.state.description}
            latitude={this.state.latitude}
            latitudeDelta={this.state.latitudeDelta}
            longitude={this.state.longitude}
            longitudeDelta={this.state.longitudeDelta}
            map={this.map}
            updateMap={ () => this.updateMap()}
            neighborhood={this.state.neighborhood}
            number={this.state.number}
            onChangeState={this.onChangeState}
            state={this.state.state}
            street={this.state.street}
            zipCode={this.state.zipCode}
            
            viewOptionalFields={this.state.viewOptionalFields}
            toggleAmmenity={this.toggleAmmenity}
            nearbyPlaces={this.state.nearbyPlaces}
            toggleNearbyPlace={this.toggleNearbyPlace}
            submitForm={this.submitForm}
          />
        }
        <LoadingModal
          visible={this.state.isLoadingModalVisible}
          message={this.state.loadingMessage}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    defaultState: state.authenticatedUser.attributes.state,
    isPremier: getPremierStauts(state)
  };
};

export default connect(mapStateToProps)(NewPropertyContainer);
