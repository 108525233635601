import React from 'react'
import AppleSignin from 'react-apple-signin-auth';
import Apple from '../../lib/Icon/icons/Apple';
const API_HOST = process.env.REACT_APP_API_HOST

const CustomButton = (props) => {
  
  return(
    <button className="redaria-button-social bg-apple" {...props}>
      <div className="ml-8 w-7 mr-4 pointer-events-none">
        <Apple style={{width:39,height:72}}/>
      </div>
      <span className="text-white poppins-medium">
        Entrar con Apple
      </span>
    </button>
  )

}

const MyAppleSigninButton = () => {

  return(
  <div className="mt-5">
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        clientId: 'com.redaria.applelogin',
        scope: 'email name',
        redirectURI: `${API_HOST}/api/auth/web/apple`,
        state: '',
        nonce: 'nonce',
        usePopup: false,
      }}
      /** General props */
      uiType="dark"
      /** Checkout README.md for further customization props. */
      /** Spread rest props if needed */
      render={CustomButton}
    />
  </div>
  )
}
 
export default MyAppleSigninButton;