import React, { PureComponent } from 'react';
import BankAuction from '../../lib/Icon/icons/BankAuction';
import { FreeLienBlue, FreeLienWhite } from '../../lib/Icon/icons/FreeLien';
import Intestated from '../../lib/Icon/icons/Intestated';
import OnAssessment from '../../lib/Icon/icons/OnAssessment';
import { OtherBlue, OtherWhite } from '../../lib/Icon/icons/Other';
import Transfer from '../../lib/Icon/icons/Transfer';
import IconButton from './IconButton';

export class LegalStatusForm extends PureComponent {
  render() {
    return (
      <div className="">
        <p className="poppins-semi-bold text-redaria-black mt-10" style={{ fontSize:21 }}>Estatus Legal</p>
        <div className="flex flex-row flex-wrap">
          <IconButton
            active={this.props.value === 0}
            icon={this.props.value === 0 ? <FreeLienWhite className="w-9" /> : <FreeLienBlue className="w-9" />}
            id={0}
            label='Libre gravamen'
            onPress={() => this.props.onChangeValue(0)}
          />
          <IconButton
            active={this.props.value === 1}
            icon={<Intestated fill={this.props.value === 1 ? '#fff' : '#2b77b7'} className="w-7" />}
            id={1}
            label='Intestada'
            onPress={() => this.props.onChangeValue(1)}
          />
          <IconButton
            active={this.props.value === 2}
            icon={<BankAuction fill={this.props.value === 2 ? '#fff' : '#2b77b7'} className="w-9" />}
            id={2}
            label='Remate bancario'
            onPress={() => this.props.onChangeValue(2)}
          />
          <IconButton
            active={this.props.value === 3}
            icon={<OnAssessment fill={this.props.value === 3 ? '#fff' : '#2b77b7'} className="w-9" />}
            id={3}
            label='Gravada'
            onPress={() => this.props.onChangeValue(3)}
          />
          <IconButton
            active={this.props.value === 4}
            icon={<Transfer fill={this.props.value === 4 ? '#fff' : '#2b77b7'} className="w-9" />}
            id={4}
            label='Traspaso'
            onPress={() => this.props.onChangeValue(4)}
          />
          <IconButton
            active={this.props.value === 5}
            icon={this.props.value === 5 ? <OtherWhite className="w-9" /> : <OtherBlue className="w-9" />}
            id={5}
            label='Otro'
            onPress={() => this.props.onChangeValue(5)}
          />

        </div>
      </div>
    );
  }
}
