import React, {PureComponent} from 'react';

export class GroupButton extends PureComponent {
  render() {
    return(
      <div 
        onClick={this.props.onPress}
        className="flex items-center justify-center rounded-full cursor-pointer"
        style={{ backgroundColor:this.props.backgroundColor,height:52,width:232,marginRight:25 }}>
        <p className="poppins-regular text-white" style={{ fontSize:19 }}>
          {this.props.text}
        </p>
      </div>
    );
  }
}
