import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom';
import PropertiesApi from '../../api/PropertiesApi';
import { ConfirmationAlert, InformationAlert } from '../../lib/alerts';
import Archive from '../../lib/Icon/icons/Archive'
import Edit from '../../lib/Icon/icons/Edit'
import EditPropertyModal from '../EditPropertyModal/EditPropertyModal';
import LoadingModal from '../Loading/LoadingModal';

class OptionsPropertyModal extends PureComponent {



  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      showEditPropertyModal:false
    }
  }


  _changePropertyToSold = () => {
    let formData = new FormData();
    formData.append('status', '5');

    ConfirmationAlert('¿Estás seguro de que deseas marcar esta propiedad como vendida?', () => {
      PropertiesApi.update(this.props.id, formData).then(result => {
        const finish = () => {
          this.setState({loading:false},()=>{
            this.props.refetch()
          })
        }
        return InformationAlert('Listo', 'Tu propiedad se ha marcado como vendida', 
        () => {finish()},
        () => {finish()}
        );
      }).catch(error => {
        console.log(error);
      });
    });
  }

  _changePropertyToRent = () => {
    let formData = new FormData();
    formData.append('status', '4');

    ConfirmationAlert('¿Estás seguro de que deseas marcar esta propiedad como rentada?', () => {
      PropertiesApi.update(this.props.id, formData).then(result => {
        const finish = () => {
          this.setState({loading:false},()=>{
            this.props.refetch()
          })
        }
        InformationAlert('Listo', 'Tu propiedad se ha marcado como rentada', 
        () => {finish()},
        () => {finish()},
        );
      }).catch(error => {
      });
    });
  }

  _archiveProperty = () => {
    let formData = new FormData();
    formData.append('status', '2');

    ConfirmationAlert('¿Estás seguro de que deseas archivar esta propiedad', () => {
      this.setState({loading:true}, () =>{
        PropertiesApi.update(this.props.id, formData).then(result => {

          const finish = () => {
            this.setState({loading:false},()=>{
              if(this.props.goHomeWhenArcive)
              {
                this.props.history.push('/signed/me/properties')
              }
              else
              {
                this.props.refetch()
              }
            })
          }

          InformationAlert('Listo', 'Se ha archivado esta propiedad', 
          () => {finish()},
          () => {finish()}
          );
        }).catch(error => {
          console.log(error);
          this.setState({loading:false})
        });
      })
    });
  }


  _changeAvailableStatus = () => {
    const propertyStatus = this.props.status;
    const newStatus = propertyStatus === 'Disponible' ? 1 : 0;
    let formData = new FormData();
    formData.append('status', String(newStatus));

    ConfirmationAlert('¿Estás seguro que quieres cambiar la disponibilidad de tu propiedad?', () => {
      PropertiesApi.update(this.props.id, formData).then(result => {
        const finish = () => {
          this.setState({loading:false},()=>{
            this.props.refetch()
          })
        }
        InformationAlert('Listo', 'Se ha cambiado la disponibilidad de tu propiedad', 
        () => {finish()},
        () => {finish()}
        );
      }).catch(error => {
        console.log(error);
      });
    });
  }

  _editProperty = () => {
    this.setState({showEditPropertyModal:true})
  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.close()
    }
  }

  closeEditPropertyModal = () => this.setState({showEditPropertyModal:false})


  render() {

    const {status,dealType, bottom} = this.props
    
    const markHas =`Marcar como ${status === 'Apartada' ? 'disponible' : 'apartada'}`
    const b = bottom ? bottom : 54
    return (
      <div 
       className="flex flex-col absolute bg-white shadow-lg z-40" style={{ borderRadius:7,width:319,bottom:b,right:17,paddingLeft:14,paddingTop:23,paddingRight:16,paddingBottom:22 }}>
        <LoadingModal visible={this.state.loading}/>
        <div
          onClick={ e => {
            e.stopPropagation()
            this._archiveProperty()
          }}
          className="flex cursor-pointer flex-row items-center">
          <Archive style={{width: 15,height: 14,marginRight:9}}/>
          <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Archivar</p>
        </div>

        <div 
        onClick={ e => {
          e.stopPropagation()
          this._editProperty()
          }} 
        className="flex cursor-pointer flex-row items-center">
          <Edit style={{width: 15,height: 14,marginRight:9}}/>
          <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Editar</p>
        </div>

        <div className="w-full bg-gray-300 self-center" style={{ height:1,marginTop:11,marginBottom:13 }}/>

        {
          dealType !== 'Renta' &&
          <div
            onClick={ e => {
            e.stopPropagation()
            this._changePropertyToSold()
            }} 
            className="flex cursor-pointer flex-row items-center">
            <div style={{width: 15,height: 15,borderRadius:15,backgroundColor:"#7944A8",marginRight:10}}/>
            <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Marcar como vendida</p>
          </div>
        }
        {
          dealType === 'Renta' &&
          <div
            onClick={ e => {
            e.stopPropagation()
            this._changePropertyToRent()
            }} 
            className="flex cursor-pointer flex-row items-center">
            <div style={{width: 15,height: 15,borderRadius:15,backgroundColor:"#D67825",marginRight:10}}/>
            <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Marcar como rentada</p>
          </div>
        }
        


        <div
          onClick={ e => {
            e.stopPropagation()
            this._changeAvailableStatus()
            }}  
          className="flex cursor-pointer flex-row items-center">
          <div style={{width: 15,height: 15,borderRadius:15,backgroundColor:"#A84444",marginRight:10}}/>
          <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>{markHas}</p>
        </div>
          
        {
          this.state.showEditPropertyModal && 
          <EditPropertyModal
            id={this.props.id}
            visible={this.state.showEditPropertyModal}
            close={this.closeEditPropertyModal}
            refetch={this.props.refetch}
          />
        }
      </div>
    )
  }
}

export default withRouter(OptionsPropertyModal)