import React, {PureComponent} from 'react';
import {getPropertyAmmenityValue} from '../../lib/Ammenities';
import Cellar from '../../lib/Icon/icons/Cellar';
import Clock from '../../lib/Icon/icons/Clock';
import { FloorGrey } from '../../lib/Icon/icons/Floor';
import Restrooms from '../../lib/Icon/icons/Restrooms';


export default class GeneralDetails extends PureComponent {
  

  render() {
    const ageValue = this.props.age === 'N/E' ? 'Sin preferencia' : this.props.age;
    console.log(this.props.age)
    return(
      <div className="flex flex-col my-5">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Detalles generales:</p>
        <div className="flex flex-row flex-wrap mt-2">
        
          <div className="flex flex-row items-center w-1/4	mt-4">
            <Clock className="w-10"/>
            <p className="ml-2 questrial text-redaria-black" style={{ fontSize:15 }}>{ageValue}</p>
          </div>
          
          <div className="flex flex-row items-center w-1/4	mt-4">
            <Restrooms className="w-10"/>
            <p className="ml-2 questrial text-redaria-black" style={{ fontSize:15 }}>{`${getPropertyAmmenityValue(this.props.ammenities, 3)}`} medios baños</p>
          </div>
          
          <div className="flex flex-row items-center w-1/4	mt-4">
            <FloorGrey className="w-10"/>
            <p className="ml-2 questrial text-redaria-black" style={{ fontSize:15 }}>{this.props.floors || '0'} niveles</p>
          </div>
          
          {
            getPropertyAmmenityValue(this.props.ammenities, 4) !== '0' &&
            <div className="flex flex-row items-center w-1/4	mt-4">
              <Cellar fill="#112233" className="w-10"/>
              <p className="ml-2 questrial text-redaria-black" style={{ fontSize:15 }}>{`${getPropertyAmmenityValue(this.props.ammenities, 4)}`} estacionamientos</p>
            </div>
          }

        </div>
      </div>
    );
  }
}
