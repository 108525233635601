import React from 'react';

export function AirConditioner({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 29 29" className={className ? className : "w-5"}>
      <path
        d="M28.15 13.65h-1.23l.659-1.141a.85.85 0 00-1.472-.85l-1.148 1.991H21.6l.763-1.757a.85.85 0 00-.472-1.13l-1.764-.685L22.5 7.705l2.22.595a.85.85 0 00.44-1.641l-1.273-.341.869-.869a.85.85 0 00-1.2-1.2l-.87.87-.341-1.273a.85.85 0 10-1.641.44l.6 2.22-2.387 2.375-.7-1.782a.85.85 0 00-1.133-.466L15.35 7.4V4.041l1.99-1.149a.85.85 0 10-.85-1.472l-1.141.659V.85a.85.85 0 10-1.7 0v1.23l-1.141-.659a.85.85 0 00-.85 1.472l1.992 1.148V7.4l-1.757-.763a.85.85 0 00-1.13.472l-.685 1.764L7.705 6.5 8.3 4.284a.85.85 0 00-1.641-.44l-.341 1.273-.869-.87a.85.85 0 00-1.2 1.2l.869.869-1.273.341A.85.85 0 104.283 8.3L6.5 7.705l2.377 2.377-1.782.7a.85.85 0 00-.466 1.133L7.4 13.65H4.041l-1.149-1.99a.85.85 0 00-1.472.85l.659 1.141H.85a.85.85 0 000 1.7h1.23l-.66 1.14a.85.85 0 101.472.85l1.149-1.991H7.4l-.763 1.757a.85.85 0 00.472 1.13l1.764.685L6.5 21.295l-2.22-.6a.85.85 0 10-.44 1.641l1.273.341-.87.87a.85.85 0 101.2 1.2l.869-.869.341 1.273a.85.85 0 101.641-.44L7.705 22.5l2.377-2.377.7 1.782a.85.85 0 001.133.466l1.735-.771v3.354l-1.99 1.154a.85.85 0 10.85 1.472l1.141-.659v1.23a.85.85 0 001.7 0v-1.23l1.141.659a.85.85 0 10.85-1.472l-1.992-1.149V21.6l1.757.763a.85.85 0 001.13-.472l.685-1.764 2.373 2.373-.595 2.22a.85.85 0 101.641.44l.341-1.273.87.87a.85.85 0 001.2-1.2l-.87-.87 1.273-.341a.85.85 0 10-.44-1.641l-2.22.595-2.377-2.377 1.782-.7a.85.85 0 00.466-1.133l-.766-1.74h3.354l1.149 1.991a.85.85 0 001.472-.85l-.659-1.141h1.23a.85.85 0 000-1.7zm-17.959-2.258l2.258 2.258h-3.2l-.708-1.608zm0 6.217l-1.638-.636.7-1.624h3.193zm3.462 2.138l-1.608.708-.65-1.646 2.258-2.258zm0-7.3l-2.26-2.26.636-1.638 1.624.7zm5.161-1.059l1.638.636-.7 1.624h-3.201zM15.35 9.252l1.608-.708.65 1.646-2.258 2.259zm1.624 11.2l-1.624-.7v-3.201l2.26 2.26zm1.836-2.841l-2.259-2.261h3.2l.708 1.608z"
      />
    </svg>
  )
}
