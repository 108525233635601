import React from 'react';

export default function Archive(props) {
  return (
    <svg viewBox="0 0 14.917 14.007" {...props}>
      <path
        fill="#223242"
        d="M11.916 4.218H5.951l-1.3-1.02H0v9.395a1.419 1.419 0 001.372 1.409l10.329.006a1.392 1.392 0 001.341-1.025l1.862-6.861h-2.988zM1.479 13.126H1.39a.544.544 0 01-.514-.534V4.078h3.471l1.3 1.02h5.393v1.027H3.909l-1.754 6.453a.743.743 0 01-.676.548zM13.76 6.997l-1.56 5.756a.515.515 0 01-.5.379H2.874A1.638 1.638 0 003 12.806l1.578-5.809zM10.762.876a2.145 2.145 0 012.059 1.556l-.519-.4-.537.692 1.576 1.224 1.576-1.224-.537-.692-.641.5a3.017 3.017 0 00-5.994.49h.876A2.144 2.144 0 0110.762.876z"
      />
      <path fill="#223242" d="M5.473 12.249v-.876l5.288.005-.001.876z" />
    </svg>
  )
}
