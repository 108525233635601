import React from 'react';

export default function Facebook() {
  return (
    <svg viewBox="0 0 14.337 27.612" className="w-3">
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M57.337-137.8v4.381h-2.605c-2.041 0-2.423.979-2.423 2.39v3.136h4.862l-.647 4.912h-4.215v12.595h-5.078v-12.595H43v-4.912h4.231v-3.617c0-4.2 2.572-6.488 6.322-6.488a32.508 32.508 0 013.784.198z"
            transform="translate(-43 138)"
            clipRule="evenodd"
            fill="none"
          />
        </clipPath>
        <clipPath id="prefix__b">
          <path
            d="M-35 604.187h575.255V-419H-35z"
            transform="translate(35 419)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g transform="translate(-119.653 -431.058)" clipPath="url(#prefix__b)">
          <path d="M111.983 423.388h29.677v42.952h-29.677z" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}
export function FacebookSimple(props) {
  return (
    <svg viewBox="0 0 36.004 36.004" {...props}>
      <path d="M18 0A18 18 0 110 18 18 18 0 0118 0z" fill="#3a79ef" />
      <path
        d="M22.528 18.707h-3.213v11.768h-4.866V18.707h-2.315v-4.136h2.315v-2.676c0-1.914.909-4.911 4.91-4.911l3.605.015v4.015h-2.616a.991.991 0 00-1.032 1.127v2.434h3.637z"
        fill="#fff"
      />
    </svg>
  )
}