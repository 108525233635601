import React, {Component} from 'react';


export class OptionalDataSection extends Component {
  render() {
    return(
      <div onClick={() => this.props.onPress()} className="my-5 self-center flex flex-col max-w-sm mx-auto items-center justify-center cursor-pointer">
        <div className="flex flex-row justify-between items-center" >
          <div className="mr-5">
            {this.props.icon}
          </div>
          <p className="questrial text-redaria-black" style={{ fontSize:21 }}>
            {this.props.title}
          </p>
        </div>
        <p className="questrial text-gray-300 text-center" style={{ fontSize:16 }}>
          {this.props.description}
        </p>
      </div>
    );
  }
}

