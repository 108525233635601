const States = [
  {
    "id": "1",
    "key": "01",
    "name": "Aguascalientes",
    "shortname": "AG"
  },
  {
    "id": "2",
    "key": "02",
    "name": "Baja California",
    "shortname": "BC"
  },
  {
    "id": "3",
    "key": "03",
    "name": "Baja California Sur",
    "shortname": "BS"
  },
  {
    "id": "4",
    "key": "04",
    "name": "Campeche",
    "shortname": "CM"
  },
  {
    "id": "5",
    "key": "05",
    "name": "Coahuila",
    "shortname": "CO"
  },
  {
    "id": "6",
    "key": "06",
    "name": "Colima",
    "shortname": "CL"
  },
  {
    "id": "7",
    "key": "07",
    "name": "Chiapas",
    "shortname": "CS"
  },
  {
    "id": "8",
    "key": "08",
    "name": "Chihuahua",
    "shortname": "CH"
  },
  {
    "id": "9",
    "key": "09",
    "name": "Ciudad de México",
    "shortname": "DF"
  },
  {
    "id": "10",
    "key": "10",
    "name": "Durango",
    "shortname": "DG"
  },
  {
    "id": "11",
    "key": "11",
    "name": "Guanajuato",
    "shortname": "GT"
  },
  {
    "id": "12",
    "key": "12",
    "name": "Guerrero",
    "shortname": "GR"
  },
  {
    "id": "13",
    "key": "13",
    "name": "Hidalgo",
    "shortname": "HG"
  },
  {
    "id": "14",
    "key": "14",
    "name": "Jalisco",
    "shortname": "JA"
  },
  {
    "id": "15",
    "key": "15",
    "name": "Estado de México",
    "shortname": "EM"
  },
  {
    "id": "16",
    "key": "16",
    "name": "Michoacán",
    "shortname": "MI"
  },
  {
    "id": "17",
    "key": "17",
    "name": "Morelos",
    "shortname": "MO"
  },
  {
    "id": "18",
    "key": "18",
    "name": "Nayarit",
    "shortname": "NA"
  },
  {
    "id": "19",
    "key": "19",
    "name": "Nuevo León",
    "shortname": "NL"
  },
  {
    "id": "20",
    "key": "20",
    "name": "Oaxaca",
    "shortname": "OA"
  },
  {
    "id": "21",
    "key": "21",
    "name": "Puebla",
    "shortname": "PU"
  },
  {
    "id": "22",
    "key": "22",
    "name": "Querétaro",
    "shortname": "QT"
  },
  {
    "id": "23",
    "key": "23",
    "name": "Quintana Roo",
    "shortname": "QR"
  },
  {
    "id": "24",
    "key": "24",
    "name": "San Luis Potosí",
    "shortname": "SL"
  },
  {
    "id": "25",
    "key": "25",
    "name": "Sinaloa",
    "shortname": "SI"
  },
  {
    "id": "26",
    "key": "26",
    "name": "Sonora",
    "shortname": "SO"
  },
  {
    "id": "27",
    "key": "27",
    "name": "Tabasco",
    "shortname": "TB"
  },
  {
    "id": "28",
    "key": "28",
    "name": "Tamaulipas",
    "shortname": "TM"
  },
  {
    "id": "29",
    "key": "29",
    "name": "Tlaxcala",
    "shortname": "TL"
  },
  {
    "id": "30",
    "key": "30",
    "name": "Veracruz",
    "shortname": "VE"
  },
  {
    "id": "31",
    "key": "31",
    "name": "Yucatán",
    "shortname": "YU"
  },
  {
    "id": "32",
    "key": "32",
    "name": "Zacatecas",
    "shortname": "ZA"
  }
]

export default States
