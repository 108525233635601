import React, { PureComponent } from 'react'
import AgencyApi from '../../api/AgencyApi'
import { ErrorAlert, InformationAlert } from '../../lib/alerts'
import { compressImages } from '../../lib/compressImages'
import { CloseDark } from '../../lib/Icon/icons/Close'
import LoadingModal from '../Loading/LoadingModal'
import Input from '../Login/Input'
import AgencyPictureForm from './AgencyPictureForm'

export default class ModalEditAgency extends PureComponent {

  state = {
    name:this.props.name,
    agencyPicture:null,
    loading:false,
    error:false,
    imagesError:0
  }

  submitForm = () => {
    const {name,agencyPicture} = this.state
    if(name.length === 0)
    {
      ErrorAlert('Debes ingresar el nombre de la empresa.',()=>this.setState({error:true}))
      return
    }
    this.setState({loading:true}, () => {
      let formData = new FormData();
  
      formData.append(`agency_name`, name);
      
      if(agencyPicture) {
        formData.append(`agency_logo`, agencyPicture);
      }
      
      AgencyApi.update(formData).then( result => {
        this.setState({loading:false}, () => {
          const finish = () => this.props.refresh()
          InformationAlert('Datos actualizados','', () => finish(), () => finish() )
        })
      })
      .catch( e =>{
        this.setState({loading:false}, () => {
          ErrorAlert('Ocurrio un error, por favor intente más tarde.')
        })
      })
    })
  }

  changeAgencyPicture = (image) => {

    this.setState({loading:true}, async() => {

      const {images,imagesError} = await compressImages([image],1)
      
      this.setState({agencyPicture:images.length > 0 ? images[0] : null,loading:false,imagesError})

    })


  }

  render() {
    const {visible,close,photoUrl} = this.props
    if (!visible)
      return null
    return (
      <div onClick={close} className="absolute flex mx-auto left-0 right-0 items-center justify-center top-20">
        <div className="fixed z-10  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>

        </div>
        <div className="z-30">
          <LoadingModal visible={this.state.loading}/>
        </div>
        <div onClick={ e => e.stopPropagation()} className="bg-white z-20 flex flex-col" 
          style={{ 
            borderRadius:9,
            width:742,
            paddingLeft:41,
            paddingRight:41,
            paddingTop:24,
            paddingBottom:80
            }}>
          <div className="flex flex-row justify-end" style={{ marginBottom:34 }}>
            <div onClick={close} className="cursor-pointer">
              <CloseDark style={{widht:20,height:20}}/>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:23 }}>Editar Empresa</p>
            <button onClick={() => this.submitForm()} className="redaria-button-white" style={{ width:193 }}>Guardar</button>
          </div>

          <div className="mt-5 w-full" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>



          <AgencyPictureForm
            imagesError={this.state.imagesError}
            agencyPicture={this.state.agencyPicture}
            changeAgencyPicture={this.changeAgencyPicture}
            photoUrl={photoUrl}
          />


          <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Información</p>
          <div className="flex flex-row">
            <div className="mr-5 w-full">
            <Input
              labelID="name"
              name="Nombre"
              value={this.state.name}
              onChange={name => this.setState({name})}
              type="text"
              />
              {
               (this.state.error && this.state.name.length === 0) && <span className="text-red-500">Campo requerido*</span>
              }
            </div>
          </div>

          

        </div>
        
      </div>
    )
  }
}
