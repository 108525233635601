import {combineReducers} from 'redux'
// import filters from './filters'
import authenticatedUser from './authenticatedUser';
// import notifications from './notifications';
// import newProperty from './newProperty';
// import androidGallery from './androidGallery';
// import createProfile from './createProfile';
// import editProperty from './editProperty';
import premier from './premier';
// import reminders from './reminders';
// import autocomplete from './autocomplete';
// import tutorial from './tutorial'
// import leads from './leads'
import property from './property';
import welcome from './welcome';
import localFirebaseToken from './localFirebaseToken';

const cr = combineReducers({
  // androidGallery,
  authenticatedUser,
  // createProfile,
  // filters,
  // notifications,
  // newProperty,
  // editProperty,
  premier,
  // reminders,
  // autocomplete,
  // tutorial,
  // leads,
  property,
  welcome,
  localFirebaseToken
})

export default cr