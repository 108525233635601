export const SET_SELECTED_IMAGES = 'SET_SELECTED_IMAGES';
export const SET_NEW_PUBLICATION_PROPERTY = 'SET_NEW_PUBLICATION_PROPERTY';

export const setSelectedImages = (value) => ({
  type: SET_SELECTED_IMAGES,
  value,
});

export const setNewPublicationProperty = (status,newPropertyId = 0) => ({
  type: SET_NEW_PUBLICATION_PROPERTY,
  value:{
    newPublicationProperty:status,
    newPropertyId,
  }
})