import React from 'react'

export default function Input({labelID,name,value,onChange,type}) {

  const _onChange = e => onChange(e.target.value)

  return (
    <div className="redaria-input-container">
      <input type={type ? type : 'text'} onChange={_onChange} name={labelID} placeholder=" " className="redaria-input" value={value}/>
      <label htmlFor={labelID} className="redaria-input-label" >{name}</label>
    </div>
  )
}
