import React, { PureComponent } from 'react'
import StepCard from './StepCard'

class Steps extends PureComponent {
  render() {
    return (
      <div>
        <StepCard
          finish={this.props.finishFirstSection}
          onClick={() => this.props.setStep(0)}
          title="Primera Información"
          description="Agrega los primeros datos"
          active={this.props.step === 0 ? true : false}
        />
        <StepCard
          finish={this.props.finishSecondSection}
          onClick={() => this.props.setStep(1)}
          title="Detalles Generales*"
          description="Agrega información de tu propiedad"
          active={this.props.step === 1 ? true : false}
        />
        <StepCard
          finish={this.props.finishThirdSection}
          onClick={() => this.props.setStep(2)}
          title="Dirección*"
          description="¿Dónde está ubicada tu propiedad?"
          active={this.props.step === 2 ? true : false}
        />
      </div>
    )
  }
}

export default Steps
