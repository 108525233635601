// Action types
export const SET_PREMIER = 'SET_PREMIER';
export const CLEAR_PREMIER = 'CLEAR_PREMIER';
// Actions
export const setPremier = (premier) => {
  return {
    type: SET_PREMIER,
    premier
  };
};
export const clearPremier = () => {
  return {
    type: CLEAR_PREMIER,
  };
};
