import AuthenticatedApi from './AuthenticatedApi';

export default class PropertiesApi {
  static create(params) {
    let headerParams = {'Content-Type': 'multipart/form-data'};
    return AuthenticatedApi.post('/api/properties', headerParams, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static update(id, params) {
    let headerParams = {'Content-Type': 'multipart/form-data'};
    return AuthenticatedApi.post(`/api/properties/${id}`, headerParams, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static delete(id) {
    return AuthenticatedApi.delete(`/api/propertes/${id}`, {}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static get(id) {
    return AuthenticatedApi.get(`/api/properties/${id}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
  static getOutstanding(limit, page,filterUrl) {
    return AuthenticatedApi.get(`/api/properties/outstanding?limit=${limit}&page=${page}&state=${filterUrl}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
  static getOutstandingFiltered(filterUrl) {
    return AuthenticatedApi.get(`/api/properties/outstanding?page=undefined${filterUrl}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static index(page) {
    return AuthenticatedApi.get(`/api/properties?page=${page}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static filteredIndex(page, filterUrl) {
    return AuthenticatedApi.get(`/api/properties?page=${page}${filterUrl}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static myIndex() {
    return AuthenticatedApi.get('/api/me/properties', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static myArchivedIndex() {
    return AuthenticatedApi.get('/api/me/properties/archived', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static pitched() {
    return AuthenticatedApi.get('/api/me/properties/pitched', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static pitchedByMe() {
    return AuthenticatedApi.get('/api/properties/pitched/me', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static recent(stateFilter) {
    return AuthenticatedApi.get(`/api/recent_properties?state_filter=${stateFilter}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static recommended(limit, page) {
    return AuthenticatedApi.get(`/api/properties/recommended?limit=${limit}&page=${page}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
  static recommendedSolicitudes(limit, page,property_id) {
    return AuthenticatedApi.get(`/api/properties/recommendedSolicitudes/${property_id}?limit=${limit}&page=${page}`, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
  static recommendedSolicitudesMarkAsRead(property_id,solicitude_id) {
    return AuthenticatedApi.post(`/api/properties/recommended/read`,{}, {property_id,solicitude_id}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static outstanding(propertie_id) {
    return AuthenticatedApi.post(`/api/me/properties/outstanding`, {}, {propertie_id}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }
  static removeArchive(propertie_id) {
    let params = {id: propertie_id, type: 'property'};
    return AuthenticatedApi.post(`/api/me/removeArchive`, {}, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static uploadXml(params) {
    return AuthenticatedApi.post('/api/properties/massive/xml',{}, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }
  static xmlHistory() {
    return AuthenticatedApi.get('/api/properties/massive/xml/history', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }
}
