import React, {PureComponent} from 'react';
import {GroupButton} from './index';

export class DealTypeForm extends PureComponent {
  render() {
    return(
      <div className="mt-10">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>
          Tipo de operación*
        </p>
        {
          this.props.emptyField &&
          <span className="text-red-500">
            {`\nCampo requerido`}
          </span>
        }
        
        <div className="flex flex-row mt-2">
          <GroupButton
            text='Venta'
            onPress={() => this.props.changeDealType(0)}
            isSelected={this.props.dealType === 0}
            backgroundColor={
              this.props.dealType === 0
              ? 'rgb(121,68,168)'
              : 'rgba(121,68,168,0.38)'
            }
          />

          <GroupButton
            text='Renta'
            onPress={() => this.props.changeDealType(1)}
            isSelected={this.props.dealType === 1}
            backgroundColor={
              this.props.dealType === 1
              ? 'rgb(214,120,37)'
              : 'rgba(214,120,37,0.38)'
            }
          />
        </div>
      </div>
    );
  }
}
