import React from 'react';

export default function OnAssessment({ className, fill }) {
  return (
    <svg viewBox="0 0 36 22" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g>
        <path
          data-name="Trazado 5293"
          d="M35.368 0H10.714a.543.543 0 00-.539.539v1.4h-1.4a.543.543 0 00-.539.539v1.7h-1.55a.543.543 0 00-.539.539v2.147a7.245 7.245 0 108.079 9.1h17.113a.543.543 0 00.539-.539v-1.7h1.551a.543.543 0 00.539-.539v-1.4h1.4a.543.543 0 00.539-.539V.547A.544.544 0 0035.368 0zm-22.1 15.318a6.171 6.171 0 11.093-2.018v.007a6.124 6.124 0 01-.093 2.011zm-3.03-7.885h18.315v5.278H14.37a7.27 7.27 0 00-4.13-5.278zm20.56 7.454H14.434a6.742 6.742 0 00.05-.855c0-.079 0-.158-.007-.237h14.607a.538.538 0 00.539-.539V6.9a.543.543 0 00-.539-.539H8.933a.538.538 0 00-.539.517 7.592 7.592 0 00-1.149-.093h-.021V5.263h23.569v9.623zm2.09-2.241h-1.01V4.718a.543.543 0 00-.539-.539H9.314V3.016H32.89zm1.939-1.946h-.862V2.477a.543.543 0 00-.539-.539H11.26v-.862h23.569z"

        />
        <g data-name="Grupo 1046">
          <g data-name="Grupo 1045">
            <path
              data-name="Trazado 5295"
              d="M19.009 12.086a1.968 1.968 0 111.968-1.968 1.971 1.971 0 01-1.968 1.968zm0-2.858a.89.89 0 10.89.89.895.895 0 00-.89-.89z"

            />
          </g>
        </g>
        <g>
          <path
            data-name="Trazado 5303"
            d="M8.692 13.881L10 12.576a.461.461 0 000-.653l-.653-.653a.461.461 0 00-.653 0l-1.308 1.306-1.3-1.3a.462.462 0 00-.654 0l-.653.653a.461.461 0 000 .653l1.3 1.3-1.3 1.3a.461.461 0 000 .653l.653.653a.461.461 0 00.653 0l1.3-1.3 1.315 1.31a.462.462 0 00.654 0l.646-.651a.462.462 0 000-.649zM7.385 6.498a7.384 7.384 0 107.385 7.384 7.384 7.384 0 00-7.385-7.384zm0 13.384a6 6 0 116-6 6 6 0 01-6 6.004z"

          />
        </g>
      </g>
    </svg>
  )
}

export function OnAssessmentBlack({ className }) {
  return (
    <svg viewBox="0 0 35.906 21.271" className={className ? className : "w-5"}>
      <g>
        <path
          data-name="Trazado 5293"
          d="M35.368 0H10.714a.543.543 0 00-.539.539v1.4h-1.4a.543.543 0 00-.539.539v1.7h-1.55a.543.543 0 00-.539.539v2.147a7.245 7.245 0 108.079 9.1h17.113a.543.543 0 00.539-.539v-1.7h1.551a.543.543 0 00.539-.539v-1.4h1.4a.543.543 0 00.539-.539V.547A.544.544 0 0035.368 0zm-22.1 15.318a6.171 6.171 0 11.093-2.018v.007a6.124 6.124 0 01-.093 2.011zm-3.03-7.885h18.315v5.278H14.37a7.27 7.27 0 00-4.13-5.278zm20.56 7.454H14.434a6.742 6.742 0 00.05-.855c0-.079 0-.158-.007-.237h14.607a.538.538 0 00.539-.539V6.9a.543.543 0 00-.539-.539H8.933a.538.538 0 00-.539.517 7.592 7.592 0 00-1.149-.093h-.021V5.263h23.569v9.623zm2.09-2.241h-1.01V4.718a.543.543 0 00-.539-.539H9.314V3.016H32.89zm1.939-1.946h-.862V2.477a.543.543 0 00-.539-.539H11.26v-.862h23.569z"
          fill="#223242"
        />
        <g data-name="Grupo 1046">
          <g data-name="Grupo 1045">
            <path
              data-name="Trazado 5295"
              d="M19.009 12.086a1.968 1.968 0 111.968-1.968 1.971 1.971 0 01-1.968 1.968zm0-2.858a.89.89 0 10.89.89.895.895 0 00-.89-.89z"
              fill="#223242"
            />
          </g>
        </g>
        <g>
          <path
            data-name="Trazado 5303"
            d="M8.692 13.881L10 12.576a.461.461 0 000-.653l-.653-.653a.461.461 0 00-.653 0l-1.308 1.306-1.3-1.3a.462.462 0 00-.654 0l-.653.653a.461.461 0 000 .653l1.3 1.3-1.3 1.3a.461.461 0 000 .653l.653.653a.461.461 0 00.653 0l1.3-1.3 1.315 1.31a.462.462 0 00.654 0l.646-.651a.462.462 0 000-.649zM7.385 6.498a7.384 7.384 0 107.385 7.384 7.384 7.384 0 00-7.385-7.384zm0 13.384a6 6 0 116-6 6 6 0 01-6 6.004z"
            fill="#223242"
          />
        </g>
      </g>
    </svg>
  )
}
