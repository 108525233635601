import React, {PureComponent} from 'react';
import { BankAuctionBlack } from '../../lib/Icon/icons/BankAuction';
import { FreeLienBlack } from '../../lib/Icon/icons/FreeLien';
import { IntestatedBlack } from '../../lib/Icon/icons/Intestated';
import { OnAssessmentBlack } from '../../lib/Icon/icons/OnAssessment';
import { OtherBlack } from '../../lib/Icon/icons/Other';
import { TransferBlack } from '../../lib/Icon/icons/Transfer';


export default class LegalStatus extends PureComponent {
  getIcon() {
    const name = this.props.value
    switch(name) {
      case 'Intestada':
        return <IntestatedBlack className="w-10"/>;
      case 'Libre Gravamen':
        return <FreeLienBlack className="w-10"/>;
      case 'Remate Bancario':
        return <BankAuctionBlack className="w-10"/>;
      case 'Gravada':
        return <OnAssessmentBlack className="w-10"/>;
      case 'Traspaso':
        return <TransferBlack className="w-10"/>;
      case 'Otro':
        return <OtherBlack className="w-10"/>;
      default:
        return <OtherBlack className="w-10"/>;
    }
  }
  iconName() {
    const name = this.props.value
    switch(name) {
      case 'Intestada':
        return 'intestated-black';
      case 'Libre Gravamen':
        return 'free_lien-black';
      case 'Remate Bancario':
        return 'bank_auction-black';
      case 'Gravada':
        return 'on_assessment-black';
      case 'Traspaso':
        return 'transfer-black';
      case 'Otro':
        return 'other-black';
      default:
        return 'other-black';
    }
  }

  render() {
    return(
      <div className="flex flex-col my-5">
        <p  className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Estatus legal:</p>
        <div className="flex flex-row mt-2 items-center">
          {
            this.getIcon()
          }
          <p className="ml-2 questrial text-redaria-black" style={{ fontSize:19 }}>{this.props.value}</p>
        </div>
      </div>
    );
  }
}
