import React from 'react';

export function Restaurant({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 23 28' className={className ? className : "w-5"}>
      <g
        id="restaurant_svg__prefix__kitchen_1_"
        data-name="kitchen (1)"
        transform="translate(-47.711)"
      >
        <path
          id="restaurant_svg__prefix__Trazado_23847"
          d="M53.721 0a.4.4 0 00-.4.4v5.66h-1.072V.4a.4.4 0 00-.808 0v5.66H50.4V.4a.4.4 0 00-.808 0v5.66h-1.07V.4a.4.4 0 00-.808 0v8.321a2.411 2.411 0 001.208 2.086l.67.384v2.478a1.759 1.759 0 00-.422 1.139v.414a1.063 1.063 0 00.232.664 1.062 1.062 0 00-.232.664v6.319a.4.4 0 10.808 0v-6.32a.26.26 0 01.26-.26H51.6a.26.26 0 01.26.26v9.265a.943.943 0 01-1.885 0v-1.065a.4.4 0 00-.808 0v1.065a1.751 1.751 0 003.5 0v-9.265a1.063 1.063 0 00-.232-.664 1.062 1.062 0 00.232-.664v-.414a1.76 1.76 0 00-.42-1.137v-2.48l.669-.384a2.411 2.411 0 001.207-2.085V.4a.4.4 0 00-.4-.4zm-.4 8.721a1.6 1.6 0 01-.8 1.385l-.871.5a.4.4 0 00-.2.35v2.874a.4.4 0 00.126.293.934.934 0 01.294.684v.414a.26.26 0 01-.26.26h-1.375a.26.26 0 01-.26-.26v-.414a.934.934 0 01.3-.685.4.4 0 00.126-.294v-2.871a.4.4 0 00-.2-.35l-.873-.5a1.6 1.6 0 01-.8-1.385V6.868h4.8v1.853z"
          className="restaurant_svg__prefix__cls-1"
          data-name="Trazado 23847"
        />
        <path
          id="restaurant_svg__prefix__Trazado_23848"
          d="M230.841 118.645a2.648 2.648 0 01-.508 1.8 1.837 1.837 0 01-2.27 0 2.648 2.648 0 01-.508-1.8.4.4 0 10-.808 0 3.359 3.359 0 00.734 2.362 2.623 2.623 0 003.434 0 3.359 3.359 0 00.734-2.362.4.4 0 10-.808 0z"
          className="restaurant_svg__prefix__cls-1"
          data-name="Trazado 23848"
          transform="translate(-169.393 -111.873)"
        />
        <path
          id="restaurant_svg__prefix__Trazado_23849"
          d="M200.814 0c-1.1 0-2.144.817-2.945 2.3a9.844 9.844 0 00-1.121 4.472c0 2.3.993 3.893 2.738 4.427v2.469a1.759 1.759 0 00-.422 1.139v.414a1.062 1.062 0 00.232.664 1.062 1.062 0 00-.232.664v9.265a1.751 1.751 0 103.5 0v-5.985a.4.4 0 10-.808 0v5.985a.943.943 0 01-1.885 0v-9.265a.26.26 0 01.26-.26h1.369a.26.26 0 01.26.26v1.418a.4.4 0 10.808 0v-1.418a1.062 1.062 0 00-.232-.664 1.062 1.062 0 00.232-.664v-.414a1.759 1.759 0 00-.42-1.137V11.2c1.744-.535 2.736-2.129 2.736-4.427a9.844 9.844 0 00-1.125-4.473c-.8-1.483-1.847-2.3-2.945-2.3zm.839 10.494a.4.4 0 00-.316.394v2.943a.4.4 0 00.126.293.934.934 0 01.294.684v.414a.26.26 0 01-.26.26h-1.366a.26.26 0 01-.26-.26v-.414a.934.934 0 01.3-.685.4.4 0 00.126-.293v-2.94a.4.4 0 00-.316-.394c-1.562-.348-2.422-1.67-2.422-3.722a9 9 0 011.024-4.088c.644-1.193 1.459-1.877 2.234-1.877s1.59.684 2.234 1.877a9 9 0 011.024 4.088c-.003 2.05-.862 3.372-2.422 3.72z"
          className="restaurant_svg__prefix__cls-1"
          data-name="Trazado 23849"
          transform="translate(-141.009)"
        />
        <path
          id="restaurant_svg__prefix__Trazado_23850"
          d="M386.251 16.962a1.759 1.759 0 00-.42-1.137V5.789a.4.4 0 00-.808 0v10.2a.4.4 0 00.126.293.934.934 0 01.294.684v.414a.26.26 0 01-.26.26h-1.366a.26.26 0 01-.26-.26v-.414a.934.934 0 01.3-.685.4.4 0 00.126-.294v-1.9a.4.4 0 00-.4-.4h-.945V4.188a3.423 3.423 0 012.385-3.273v3.029a.4.4 0 00.808 0V.4a.4.4 0 00-.471-.4 4.23 4.23 0 00-3.538 4.183v9.897a.4.4 0 00.4.4h.945v1.338a1.759 1.759 0 00-.422 1.139v.414a1.062 1.062 0 00.232.664 1.062 1.062 0 00-.232.664v7.111a1.751 1.751 0 003.5 0V18.7a1.063 1.063 0 00-.232-.664 1.062 1.062 0 00.232-.664v-.414zm-.808 8.852a.943.943 0 01-1.885 0V18.7a.26.26 0 01.26-.26h1.366a.26.26 0 01.26.26v7.111z"
          className="restaurant_svg__prefix__cls-1"
          data-name="Trazado 23850"
          transform="translate(-316.115)"
        />
      </g>
    </svg>
  )
}
