import React, { PureComponent } from 'react'
import EditCertificateModal from '../Profile/EditCertificateModal'
import EditProfileModal from '../Profile/EditProfileModal'
import MyPropertiesButton from '../Profile/MyPropertiesButton'
import NewCertificateModal from '../Profile/NewCertificateModal'
import OptionsMenu from '../Profile/OptionsMenu'
import ProfileCard from '../Profile/ProfileCard'
import StoreOptions from '../Profile/StoreOptions'

export default class UserCard extends PureComponent {

  state = {
    editProfileModalVisible:false,
    modalCertificateId:null,
    isCertificateModalVisible:false,
    isNewCertificateModalVisible:false
  }

  

  _onCertificatePress = (id) => {
    this.setState({
      modalCertificateId:id,
      isCertificateModalVisible:true
    },()=>window.scrollTo(0, 0))
  }
  _onNewCertificatePress = () => {
    this.setState({isNewCertificateModalVisible:true},()=>{
      window.scrollTo(0, 0)
    })
  }
  render() {
    
    const {profile,refresh} = this.props
    
    const { editProfileModalVisible,modalCertificateId,isCertificateModalVisible,isNewCertificateModalVisible} = this.state

    return (
      <div style={{ marginLeft:54.5 }}>
        <ProfileCard
          refresh={refresh}
          agencyName={profile.attributes.agencyName}
          agencyPhotoUrl={profile.attributes.agencyImageUrl}
          hasAgency={profile.attributes.hasAgency !== 0}
          name={profile.attributes.name}
          photoUrl={profile.attributes.photoUrl}
          rating={profile.attributes.rating}
          type={profile.attributes.type}
          isPremier={profile.attributes.isPremier}
          numberOfR
          eviews={profile.attributes.numberOfReviews}
          me={true}
          numberOfFollowers={profile.attributes.numberOfFollowers}
          numberOfFollowing={profile.attributes.numberOfFollowing}
          reviews={profile.attributes.numberOfReviews}
          certificates={profile.attributes.certificates.data}
          setEditProfileModalVisible={() => {
            this.setState({editProfileModalVisible:true},()=>window.scrollTo(0, 0))
          }}
          onCertificatePress={this._onCertificatePress}
          onNewCertificatePress={this._onNewCertificatePress}
        />

        <MyPropertiesButton propertiesActive={this.props.propertiesActive}/>

        <OptionsMenu />

        <StoreOptions />


        <EditProfileModal
          refresh={this.props.refresh}
          visible={editProfileModalVisible}
          close={ () => this.setState({editProfileModalVisible:false})}/>

        <NewCertificateModal
          close={() => this.setState({isNewCertificateModalVisible:false})}
          visible={isNewCertificateModalVisible}
          refresh={refresh}
        />
        {
          modalCertificateId &&
          <EditCertificateModal
            certificate={profile.attributes.certificates.data.find(certificate => certificate.id === modalCertificateId)}
            close={() => this.setState({isCertificateModalVisible:false})}
            visible={isCertificateModalVisible}
            refresh={refresh}
          />
        }
      </div>
    )
  }
}
