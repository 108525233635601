import React, { useState} from 'react'
import { useHistory } from 'react-router-dom';
import TemporaryLinksApi from '../../api/TemporaryLinksApi';
import { ErrorAlert } from '../../lib/alerts/ErrorAlert';
import { getAmmenityValue } from '../../lib/Ammenities';
import PropertyInformation from '../Property/PropertyInformation';
import TimeAndType from '../Property/PropertyCard/TimeAndType';
import UserTag from '../Property/UserTag';
import ShareModal from '../ShareModal/ShareModal';
import OptionsButton from './OptionsButton';
import OptionsPropertyModal from './OptionsPropertyModal';
import ShareButton from './ShareButton';

export default function Property({
  marginRight,
  isPremier, property, user, openShareModalWhatsApp, openShareModalFacebook, openShareModalSimple, height, onLayout, navigation, setOutstandingProperty, hiddeOptions,refetch
}) {

  const history = useHistory()

  const uri = property.attributes.images[0].path;
  const [showOptions,setShowOptions] = useState(false)
  const _openOptions = () => setShowOptions(!showOptions)
  const _hideOptions = () => setShowOptions(false)


  const [url,setUrl] = useState('')
  
  const messageShare = `${property.attributes.type} en ${property.attributes.dealType} en ${property.attributes.address.neighborhood}, ${property.attributes.address.city}, ${property.attributes.address.state}.${'\n'}Precio: $${property.attributes.price}.${'\n'}Propiedad ID: ${property.id}  ${'\n'}${'\n'}Puedes ver las fotos y más información de la propiedad en la siguiente liga:${'\n'}${'\n'}`
  
  const [shareModalVisible,setShareModalVisible] = useState(false)

  const openShare = () => {
    const propertyArray = [property.id];
    TemporaryLinksApi.create(propertyArray).then(result => {
      setUrl(result.data.attributes.url)
      setShareModalVisible(true)
    }).catch(error => {
      ErrorAlert('Hubo un error generando el link. Intenta de nuevo más tarde');
    });
  }
  const mr = marginRight >= 0 ? marginRight : 29

  return (
    <div className="bg-white relative" style={{ paddingTop: 23, paddingLeft: 35, paddingRight: 35, width: 426, marginRight: mr, marginBottom: 30,paddingBottom:13 }}>
      <div onClick={ () => history.push(`/signed/property/${property.id}`)} className="cursor-pointer">
        <TimeAndType
          newRecommended={false}
          createdAt={property.attributes.createdAt}
          type={property.attributes.type}
          dealType={property.attributes.dealType} />
        <UserTag
          name={user.name}
          userType={user.type}
          premierUpload={user.isPremier}
          image={user.photoUrl}
        />
        <div style={{ borderRadius:13,boxShadow: "0px 3px 6px #00000040" }}>
          <img alt="property" src={uri} style={{ width: "100%", height: 190, borderTopRightRadius: 13, borderTopLeftRadius: 13, marginTop: 15 }} />

          <PropertyInformation
            bathrooms={getAmmenityValue(property.attributes.ammenities, 2)}
            bedrooms={getAmmenityValue(property.attributes.ammenities, 1)}
            buildingArea={property.attributes.buildingArea}
            city={property.attributes.address.city}
            dealType={property.attributes.dealType}
            floors={property.attributes.floors}
            landArea={property.attributes.landArea}
            neighborhood={property.attributes.address.neighborhood}
            price={property.attributes.price}
            restrooms={getAmmenityValue(property.attributes.ammenities, 3)}
            state={property.attributes.address.state}
            type={property.attributes.type}
          />
        </div>

      </div>


      <div className="flex flex-row justify-between" style={{ marginTop:15 }}>
        <div onClick={(e) => e.stopPropagation()}>
          <ShareButton openShare={openShare}/>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <OptionsButton openOptions={_openOptions} />
        </div>
      </div>
      {
        showOptions &&
        <OptionsPropertyModal
          propertyType={property.attributes.type}
          status={property.attributes.status}
          dealType={property.attributes.dealType}
          refetch={refetch}
          id={property.id}
          close={_hideOptions}
        />
      }
      <ShareModal 
        url={url}
        visible={shareModalVisible}
        messageFacebook={messageShare}
        messageWhatsapp={messageShare}
        close={ () => setShareModalVisible(false)}
        />
    </div>
  ) 
}
