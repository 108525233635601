import React, {PureComponent} from 'react';

export class TextBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textLength: this.props.value.length
    };
  }

  onChangeText = (e) => {
    const text = e.target.value
    this.setState({ textLength: text.length });

    if (typeof this.props.onChangeText === "function") {
      this.props.onChangeText(text)
    }
  }

  render() {
    let counter;
    if (this.props.maxLength) {
      counter = <p className="text-right questrial" style={{ fontSize:17 }}>{this.state.textLength} / {this.props.maxLength}</p>;
    }
    return(
      <div className="w-full">
        {
          !this.props.removeTitle
          &&
          <p className="poppins-semi-bold text-redaria-black" style={{ marginTop:75,fontSize:21,marginBottom:14 }}>Detalles adicionales*</p>
        }
        <textarea
          style={{ border: "2px solid #D1D8D9",borderRadius: 5 }}
          onChange={this.onChangeText}
          className="block w-full  focus:outline-none bg-transparent p-2 h-32"
          maxLength={this.props.maxLength}
          value={this.props.value}
          placeholder={this.props.placeholder}
          >
          
        </textarea>
          { counter }
      </div>
    );
  }
}

