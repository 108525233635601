import React, { PureComponent } from 'react'
import QuestionOption from './QuestionOption'

export class QuestionWithOptions extends PureComponent {
  render() {
    return (
      <div className="flex flex-col mt-5 w-full">
        <p className="text-left poppins-semi-bold text-redaria-black" style={{ fontSize:20 }}>{this.props.title}</p>
        <div className="flex flex-row mt-2 items-center justify-between">
          <QuestionOption onPress={this.props.setValueToTrue} text='Sí' isActive={this.props.value === true} />
          <QuestionOption onPress={this.props.setValueToFalse} text='No' isActive={this.props.value === false} />
        </div>
      </div>
    )
  }
}

export default QuestionWithOptions
