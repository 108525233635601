import {SET_SHOW_WELCOME} from '../actions/welcome';

const initialState = {
  show:true
}

const welcome = (state = initialState, action) => {
  
  switch(action.type) {
    case SET_SHOW_WELCOME:
      return {...state, ...action.value};
    default:
      return state;
  }
}

export default welcome