import React from 'react';

export function School({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 31 28' className={className ? className : "w-5"}>
      <g id="school_svg__prefix__school" transform="translate(0 -21.68)">
        <g
          id="school_svg__prefix__Grupo_1671"
          data-name="Grupo 1671"
          transform="translate(0 21.68)"
        >
          <g id="school_svg__prefix__Grupo_1670" data-name="Grupo 1670">
            <path
              id="school_svg__prefix__Trazado_23845"
              d="M30.327 34.678h-6.805v-1.314a.532.532 0 00-.271-.463l-7.289-4.1v-2.082H17.4v.793a.532.532 0 00.532.532h4.347a.532.532 0 00.5-.713l-.436-1.24.442-1.213a.532.532 0 00-.5-.713h-1.976v-.771a.532.532 0 00-.532-.532h-3.815v-.649a.532.532 0 00-1.064 0V28.8l-7.29 4.1a.532.532 0 00-.271.463v1.314H.532A.532.532 0 000 35.2v13.924a.532.532 0 00.532.532h29.8a.532.532 0 00.532-.532V35.21a.532.532 0 00-.537-.532zm-10.018-8.491l-.021-.021v-.916H21.5l-.218.681a.532.532 0 000 .367l.25.681h-3.064v-.261h1.309a.532.532 0 00.532-.531zm-4.347-2.261h3.283v1.729h-3.283zM7.337 48.592H1.064v-12.85h6.273zm7.561 0H12.1v-6.077h2.8zm3.857 0h-2.793v-6.077h2.793zm3.724 0h-2.66v-6.609a.532.532 0 00-.532-.532h-7.715a.532.532 0 00-.532.532v6.608H8.38v-14.9l7.05-3.969 7.028 3.948.021 1.532zm7.316 0h-6.273v-12.85H29.8z"
              className="school_svg__prefix__cls-1"
              data-name="Trazado 23845"
              transform="translate(0 -21.68)"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1673"
          data-name="Grupo 1673"
          transform="translate(13.2 31.353)"
        >
          <g id="school_svg__prefix__Grupo_1672" data-name="Grupo 1672">
            <path
              id="school_svg__prefix__Trazado_23846"
              d="M200.709 167.12a2.229 2.229 0 102.229 2.224 2.235 2.235 0 00-2.229-2.224zm0 3.395a1.165 1.165 0 111.165-1.16h.005a1.165 1.165 0 01-1.17 1.16z"
              className="school_svg__prefix__cls-1"
              data-name="Trazado 23846"
              transform="translate(-198.48 -167.12)"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1675"
          data-name="Grupo 1675"
          transform="translate(2.346 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1674" data-name="Grupo 1674">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_204"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 204"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1677"
          data-name="Grupo 1677"
          transform="translate(4.969 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1676" data-name="Grupo 1676">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_205"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 205"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1679"
          data-name="Grupo 1679"
          transform="translate(9.997 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1678" data-name="Grupo 1678">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_206"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 206"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1681"
          data-name="Grupo 1681"
          transform="translate(14.366 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1680" data-name="Grupo 1680">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_207"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 207"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1683"
          data-name="Grupo 1683"
          transform="translate(18.734 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1682" data-name="Grupo 1682">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_208"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 208"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1685"
          data-name="Grupo 1685"
          transform="translate(9.997 39.392)"
        >
          <g id="school_svg__prefix__Grupo_1684" data-name="Grupo 1684">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_209"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 209"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1687"
          data-name="Grupo 1687"
          transform="translate(14.366 39.392)"
        >
          <g id="school_svg__prefix__Grupo_1686" data-name="Grupo 1686">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_210"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 210"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1689"
          data-name="Grupo 1689"
          transform="translate(18.734 39.392)"
        >
          <g id="school_svg__prefix__Grupo_1688" data-name="Grupo 1688">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_211"
              d="M0 0h2.128v1.064H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 211"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1691"
          data-name="Grupo 1691"
          transform="translate(2.346 41.1)"
        >
          <g id="school_svg__prefix__Grupo_1690" data-name="Grupo 1690">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_212"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 212"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1693"
          data-name="Grupo 1693"
          transform="translate(4.969 41.1)"
        >
          <g id="school_svg__prefix__Grupo_1692" data-name="Grupo 1692">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_213"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 213"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1695"
          data-name="Grupo 1695"
          transform="translate(2.346 45.293)"
        >
          <g id="school_svg__prefix__Grupo_1694" data-name="Grupo 1694">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_214"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 214"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1697"
          data-name="Grupo 1697"
          transform="translate(4.969 45.293)"
        >
          <g id="school_svg__prefix__Grupo_1696" data-name="Grupo 1696">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_215"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 215"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1699"
          data-name="Grupo 1699"
          transform="translate(24.826 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1698" data-name="Grupo 1698">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_216"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 216"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1701"
          data-name="Grupo 1701"
          transform="translate(27.449 36.913)"
        >
          <g id="school_svg__prefix__Grupo_1700" data-name="Grupo 1700">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_217"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 217"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1703"
          data-name="Grupo 1703"
          transform="translate(24.826 41.1)"
        >
          <g id="school_svg__prefix__Grupo_1702" data-name="Grupo 1702">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_218"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 218"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1705"
          data-name="Grupo 1705"
          transform="translate(27.449 41.1)"
        >
          <g id="school_svg__prefix__Grupo_1704" data-name="Grupo 1704">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_219"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 219"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1707"
          data-name="Grupo 1707"
          transform="translate(24.826 45.293)"
        >
          <g id="school_svg__prefix__Grupo_1706" data-name="Grupo 1706">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_220"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 220"
            />
          </g>
        </g>
        <g
          id="school_svg__prefix__Grupo_1709"
          data-name="Grupo 1709"
          transform="translate(27.449 45.293)"
        >
          <g id="school_svg__prefix__Grupo_1708" data-name="Grupo 1708">
            <path
              id="school_svg__prefix__Rect\xE1ngulo_221"
              d="M0 0h1.064v2.128H0z"
              className="school_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 221"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
