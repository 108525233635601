import React, { PureComponent } from 'react'
import numeral from 'numeral'
import PropertiesApi from '../../api/PropertiesApi';
import { ErrorAlert, InformationAlert } from '../../lib/alerts';
import GooglePlacesApi from '../../lib/GooglePlacesApi';
import { NON_DIGITS_REGEXP } from '../../lib/Regexp';
import { buildFormFromRequest} from '../../lib/Ammenities'
import { buildNearbyPlacesFromRequest } from './NearbyPlaces';
import { PropertyEditSubmit } from '../../lib/FormSubmit';
import { DealTypeForm } from '../../components/Forms/DealTypeForm';
import { TextInputWithPlaceholder } from '../../components/Forms/TextInputWithPlaceholder';
import { SectionDivider } from '../../components/Forms/SectionDivider';
import { BasicDetailsForm } from '../../components/Forms/BasicDetailsForm';
import { LegalStatusForm } from '../../components/Forms/LegalStatusForm';
import AddressForm from '../../components/Forms/AddressForm';
import { OptionalDataSection } from '../../components/Forms/OptionalDataSection';
import { PremierHide, PremierShow } from '../../lib/Icon/icons/PremierFeatures';
import { AmmenitiesForm } from '../../components/Forms/AmmenitiesForm';
import { NearbyPlacesForm } from '../../components/Forms/NearbyPlacesForm';
import { AdditionalAmmenities } from '../../components/Forms/AdditionalAmmenities';
import EditImagesForm from '../../components/Forms/EditImagesForm';
import LoadingModal from '../../components/Loading/LoadingModal';
export class EditProperty extends PureComponent {


  state = {
    activeSection: 0,
    age: -1,
    ammenities: [],
    buildingArea: '',
    city: '',
    dealType: 0,
    deleteImages: [],
    deleteImagesUrl: [],
    description: '',
    disabledButton: false,
    floors: '',
    images: [],
    imagesUrl: [],
    landArea: '',
    latitude: 23.181385167349607,
    latitudeDelta: 24.92818706505762,
    legalStatus: 0,
    longitude: -102.41049645468593,
    longitudeDelta: 31.527897454798236,
    nearbyPlaces: [],
    neighborhood: '',
    number: '',
    price: '',
    state: '',
    street: '',
    type: 0,
    zipCode: '',
    isRecommendedPostsModalVisible: false,
    numberOfRecommendedPosts: 0,
    maintenanceFee: '',
    viewOptionalFields: false,
    requiredFields: [],
    isLoadingModalVisible: false,
    loadingProperty: true,
    uploadedImages: 0,
    loadingMessage: '',
    newImages: [],
  }

  map = React.createRef(null)


  componentDidMount() {
    const { id } = this.props.match.params;

    // this.netinfoUnsubscribe = NetInfo.addEventListener(state => {
    //   console.log(`Connection: ${state.isInternetReachable? 'reachable' : 'unreachable'} (${state.type})`)
    //   this.setState({ netInfoState: state });
    // });

    PropertiesApi.get(id).then(result => {
      this.setStateFromRequest(result.data);
    }).catch(error => {
      return ErrorAlert(
        'Hubo un error. Intena de nuevo más tarde.',
        () => this.props.history.push('/signed/me/properties')
      );
    });
  }



  
  componentWillUnmount(){
    this.netinfoUnsubscribe && this.netinfoUnsubscribe();
  }

  getAgeValue(age) {
    switch(age) {
      case 'Pre-venta':
        return 0;
      case 'En construcción':
        return 1;
      case 'Nuevo':
        return 2;
      case 'Hasta 5 años':
        return 3;
      case '5-10 años':
        return 4;
      case '10-20 años':
        return 5;
      case '20-50 años':
        return 6;
      case '50+ años':
        return 7;
      default:
        return 0;
    }
  }

  getLegalStatus(legalStatus) {
    switch(legalStatus) {
      case 'Libre Gravamen':
        return 0;
      case 'Intestada':
        return 1;
      case 'Remate Bancario':
        return 2;
      case 'Gravada':
        return 3;
      case 'Traspaso':
          return 4;
      case 'Otro':
          return 5;
      default:
        return 0;
    }
  }

  getType(type) {
    switch(type) {
      case 'Casa':
        return 0;
      case 'Departamento':
        return 1;
      case 'Terreno':
        return 2;
      case 'Local':
        return 3;
      case 'Oficina':
        return 4;
      case 'Bodega':
        return 5;
      default:
        return 0;
    }
  }

  getDealType(dealType) {
    switch(dealType) {
      case 'Venta':
        return 0;
      case 'Renta':
        return 1;
      default:
        return 0;
    }
  }

  getImages(images) {

    images = images.filter( img => img.id > 0 );

    return Array.from(images, image => ({
      id: image.id,
      uri: image.path
    }));
  }
  getImagesUrl(images) {
    return Array.from(images, image => ({
      id: image.id,
      uri: image.url
    }));
  }

  setStateFromRequest(data) {
    const age = this.getAgeValue(data.attributes.age);
    const ammenities = buildFormFromRequest(data.attributes.ammenities);
    const dealType = this.getDealType(data.attributes.dealType);
    const images = this.getImages(data.attributes.images);
    const imagesUrl = this.getImagesUrl(data.attributes.imagesUrl);
    const legalStatus = this.getLegalStatus(data.attributes.legalStatus);
    const nearbyPlaces = buildNearbyPlacesFromRequest(data.attributes.nearbyPlaces);
    const price = numeral(data.attributes.price.replace(NON_DIGITS_REGEXP, "")).format('$0,0[.]00');
    const type = this.getType(data.attributes.type);
    const buildingArea = data.attributes.buildingArea === '0' ? '' : data.attributes.buildingArea.replace(NON_DIGITS_REGEXP, '');
    const floors = data.attributes.floors === 0 ? '0' : data.attributes.floors;
    const maintenanceFee = numeral(data.attributes.maintenanceFee.replace(NON_DIGITS_REGEXP, "")).format('$0,0[.]00');
    this.setState({
      age: age,
      ammenities: ammenities,
      buildingArea: buildingArea,
      city: data.attributes.address.city,
      dealType: dealType,
      description: data.attributes.description,
      floors: floors,
      images: images,
      imagesUrl: imagesUrl,
      landArea: data.attributes.landArea.replace(NON_DIGITS_REGEXP, ''),
      latitude: Number(data.attributes.latitude),
      latitudeDelta: 0.015,
      legalStatus: legalStatus,
      longitude: Number(data.attributes.longitude),
      longitudeDelta: 0.015,
      nearbyPlaces: nearbyPlaces,
      neighborhood: data.attributes.address.neighborhood,
      number: data.attributes.address.number,
      price: price,
      state: data.attributes.address.state,
      street: data.attributes.address.street,
      type: type,
      zipCode: data.attributes.address.zipCode || '',
      maintenanceFee: maintenanceFee,
      loadingProperty: false,
    },()=>{
      this.map.current.testChange(this.state.latitude,this.state.longitude)
    });
  }
  parsedData() {
    let propertyObject;

    if(this.state.type === 2) {
      propertyObject = {
        price: this.state.price,
        newImages: this.state.newImages,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        buildingArea: this.state.buildingArea,
        legalStatus: this.state.legalStatus,
        nearbyPlaces: this.state.nearbyPlaces,
        state: this.state.state,
        type: this.state.type,
        city: this.state.city,
        zipCode: this.state.zipCode,
        neighborhood: this.state.neighborhood,
        street: this.state.street,
        number: this.state.number,
        description: this.state.description,
        __method: 'PATCH'
      };
    }
    else {
      propertyObject = {
        age: this.state.age,
        ammenities: this.state.ammenities,
        buildingArea: this.state.buildingArea,
        city: this.state.city,
        zipCode: this.state.zipCode,
        dealType: this.state.dealType,
        deleteImages: this.state.deleteImages,
        deleteImagesUrl: this.state.deleteImagesUrl,
        description: this.state.description,
        floors: this.state.floors,
        images: this.state.images,
        imagesUrl: this.state.imagesUrl,
        landArea: this.state.landArea,
        latitude: this.state.latitude,
        legalStatus: this.state.legalStatus,
        longitude: this.state.longitude,
        nearbyPlaces: this.state.nearbyPlaces,
        neighborhood: this.state.neighborhood,
        newImages: this.state.newImages,
        number: this.state.number,
        price: this.state.price,
        state: this.state.state,
        type: this.state.type,
        street: this.state.street,
        maintenanceFee: this.state.maintenanceFee,
        __method: 'PATCH'
      }
    }

    return propertyObject;
  }

  _setRequiredFields = requiredFields => {

    this.setState({ requiredFields,disabledButton: false }, () => {

      // let sectionIndex = 0;
      let activeSection = this.state.activeSection;
      let alreadyRequired = false;
      switch (requiredFields[0]) {

        case 'price':
          // sectionIndex = 1;
          alreadyRequired = true
          break;
        case 'images':
          // sectionIndex = 2;
          alreadyRequired = true
          break;

        default:
          break;
      }

      if(
        (requiredFields.indexOf('landArea') >= 0 ||
        requiredFields.indexOf('buildingArea') >= 0 ||
        requiredFields.indexOf('bedrooms') >= 0 ||
        requiredFields.indexOf('bathrooms') >= 0 ||
        requiredFields.indexOf('floors') >= 0 ||
        requiredFields.indexOf('age') > 0) && !alreadyRequired
        )
      {
        // sectionIndex = 3
        alreadyRequired = true
        activeSection = 1
      }

      if(
        (requiredFields.indexOf('city') >= 0 ||
        requiredFields.indexOf('neighborhood') >= 0 ||
        requiredFields.indexOf('zipCode') >= 0 ||
        requiredFields.indexOf('description') >= 0) && !alreadyRequired
        )
      {
        // sectionIndex = 5
        alreadyRequired = true
        activeSection = 6
      }

      if (this.listView) {
        this.setState({
          activeSection,
        },()=>{

        });

      }
    })
  }

  submitForm() {
    this.setState({
      disabledButton: true,
    }, () => {
      PropertyEditSubmit(
        this.parsedData(),
        (requiredFields) => this._setRequiredFields(requiredFields),
        formData => this.sendData(formData)
      );
    });
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  showErrorMessage() {
    this.setState({ disabledButton: false, isLoadingModalVisible: false });
    let msg = [] // 'Hubo un error. Intenta de nuevo más tarde';
    if (this.state.netInfoState && !this.state.netInfoState.isInternetReachable) {
      msg.push('Hubo un error con la conexión a internet.');
    } else {
      msg.push('Hubo un error.');
    }
    if (this.state.uploadedImages > 0) {
      msg.push(`Sólamente se han subido ${this.state.uploadedImages} de las imagenes.`);
    }
    msg.push('Intenta de nuevo más tarde');
    ErrorAlert(msg.join(' '), () => false);
  }

  async sendData(entries) {
    const images = entries.filter(([key]) => (key === 'new_images[]')).map(([_, image]) => image);
    const totalImagesCount = images.length;
    let currentImageCount = 1;
    let uploadedImages = 0;
    try {
      await this.setStatePromise({ isLoadingModalVisible: true, loadingMessage: '' });

      let { id:propertyId } = this.props.match.params;
      let numberOfRecommendedPosts;

      if (images.length <= 1) {
        const formData = new FormData();
        entries.forEach(([name, value]) => formData.append(name, value));
        const result = await PropertiesApi.update(propertyId, formData);
        numberOfRecommendedPosts = result.data.data.attributes.numberOfRecommendedPosts;
        images.shift();
      } else {
        while (images.length > 0) {
          const image = images[0];
          const formData = new FormData();
          entries.filter(([key]) => (key !== 'new_images[]')).forEach(([name, value]) => formData.append(name, value));
  
          formData.append('new_images[]', image);
  
          this.setState({ loadingMessage: `Subiendo imagen ${currentImageCount} de ${totalImagesCount}` });
          const result = await PropertiesApi.update(propertyId, formData);
  
          numberOfRecommendedPosts = result.data.data.attributes.numberOfRecommendedPosts;
          await this.setStatePromise(({ propertyId, uploadedImages }));
          
          images.shift(); ++currentImageCount; ++uploadedImages
        }  
      }

      if (images.length > 0) {
        this.showErrorMessage();      
      } else {
        if (numberOfRecommendedPosts && (numberOfRecommendedPosts > 0) && this.props.isPremier) {
          this.setState({
            isLoadingModalVisible: false,
            isRecommendedPostsModalVisible: true,
            numberOfRecommendedPosts,
          });
        } else {
          const finish = () => {
            this.setState({
              isLoadingModalVisible: false
            }, () => {
              this.props.history.push('/signed/me/properties')
            });
          }
          InformationAlert(
            'Listo',
            'Tu propiedad se actualizó correctamente',
            () => {finish()},
            () => {finish()}
          );
        }
      }
    } catch(error) {
      console.log(error);
      console.log(error?.response?.data)
      this.showErrorMessage();
    }
    // this.props.dispatch(setEditPropertyImages(images));
  }

  toggleAmmenity(id) {
    let ammenity = {
      id: id
    };

    if(this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      this.setState(prevState => ({
        ammenities: prevState.ammenities.filter(x => x.id !== id)
      }));
    }
  }

  toggleNearbyPlace(id) {
    if(this.state.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === id) === undefined) {
      this.setState(prevState => ({
        nearbyPlaces: [...prevState.nearbyPlaces, id]
      }));
    }
    else {
      this.setState(prevState => ({
        nearbyPlaces: prevState.nearbyPlaces.filter(nearbyPlaceId => nearbyPlaceId !== id)
      }));
    }
  }
  
  changeAmmenityValue(id, value) {
    let ammenity = {
      id: id,
      value: value
    };

    if(this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      let ammenities = [...this.state.ammenities];
      const ammenityValue = this.state.ammenities.find(x => x.id === ammenity.id)?.value;
      const ammenityIndex = this.state.ammenities.findIndex(x => x.id === ammenity.id);
      if(ammenityValue !== ammenity.value) {
        ammenities[ammenityIndex] = ammenity;
        this.setState({ammenities: ammenities});
      }
      else {
        this.setState({
          ammenities: ammenities.filter(x => x.id !== ammenity.id)
        });
      }
    }
  }
  
  onChangeState(state) {
    this.setState(prevState => {
      if(prevState.state !== state) {
        return {state: state, city: ''};
      }
    });
  }
  
  updateMap() {
    let state = this.state.state.split(' ').join('+');
    let city = this.state.city.split(' ').join('+');
    let neighborhood = this.state.neighborhood.split(' ').join('+');
    let street = this.state.street.split(' ').join('+');
    let number = this.state.number.split(' ').join('+');

    let query = '';

    if(state !== '') {
      query = `${query}+${state}`;
    }
    if(city !== '') {
      query = `${query}+${city}`;
    }
    if(neighborhood !== '') {
      query = `${query}+${neighborhood}`;
    }
    if(street !== '') {
      query = `${query}+calle+${street}`;
    }
    if(number !== '') {
      query = `${query}+${number}`;
    }

    GooglePlacesApi.getPlace(query).then(result => {
      if(result.data.result) {
        this.setState({
          latitude: result.data.result.geometry.location.lat,
          longitude: result.data.result.geometry.location.lng,
        }, () => {
          this.map.current.testChange(this.state.latitude,this.state.longitude)
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  changePrice(value, field) {
    let price = value.replace(NON_DIGITS_REGEXP, "");
    price = price === '0' || price === '' ? '' : parseInt(price);
    price = price === '' ? '' : numeral(price).format('$0,0[.]00');
    this.setState({
      [field]: price
    });
  }
  
  toggleSection(sectionId) {
    if(this.state.activeSection === sectionId) {
      this.setState({
        activeSection: 0
      });
    }
    else {
      this.setState({
        activeSection: sectionId
      });
    }
  }
  
  // _openGallery = () => {
  //   Keyboard.dismiss()
  //   const images = this.state.images || [];
  //   pickImage(this.props.navigation, {
  //     multiple: true,
  //     selectedImages: this.state.newImages,
  //     firstImageIndex: images.length + 1,
  //     maximum: 10 - images.length,
  //   })
  //   .then(images => this.props.dispatch(setEditPropertyImages(images)))
  //   .catch(console.log);
  // }
  
  deleteNewImage(index) {
    let newImages = [...this.state.newImages].filter((_, i) => (i !== index));
    this.setState({newImages})
    // this.props.dispatch(setEditPropertyImages(newImages));
  }
  deleteImage(index) {
    let images = [...this.state.images];
    const imageId = {...this.state.images[index]}.id;

    images.splice(index, 1);
    this.setState(prevState => ({
      images: images,
      deleteImages: [...new Set([...prevState.deleteImages, imageId])]
    }));
  }
  deleteImageUrl(index) {
    let imagesUrl = [...this.state.imagesUrl];
    const imageId = {...this.state.imagesUrl[index]}.id;

    imagesUrl.splice(index, 1);
    this.setState(prevState => ({
      imagesUrl,
      deleteImagesUrl:[...new Set([...prevState.deleteImagesUrl, imageId])]
    }));
  }
  changeFloors(value) {
    this.setState(prevState => {
      if(prevState.floors === value) {
        return {floors: ''};
      }
      else {
        return {floors: value};
      }
    });
  }


  handleChangeImages = (e) => {
    const newImages = [...this.state.newImages,...e.target.files]
    if (Array.from(newImages).length > 10) {
      e.preventDefault();
      ErrorAlert(
        'El limite de imágenes es de 10.',
        ()=>{}
      );
      return;
    }
    
    this.setState({
      newImages: newImages
    });
  }

  
  render() {
    
    return (
      <div>
        <LoadingModal visible={this.state.loadingProperty}/>
        <DealTypeForm
          dealType={this.state.dealType}
          changeDealType={value => this.setState({dealType: value})}/>

          <TextInputWithPlaceholder
            label='Precio*'
            maxLength={12}
            onChangeText={price => this.changePrice(price, 'price')}
            placeholder='Ejemplo: $120,000'
            value={this.state.price}
            emptyField={this.state.requiredFields.find(element => element === 'price') && this.state.price.length === 0}
          />
          {
            this.state.type !== 0 && this.state.type !== 1 && this.state.type !== 2 &&
            <TextInputWithPlaceholder
              label='Cuota mantenimiento'
              maxLength={12}
              onChangeText={maintenanceFee => this.changePrice(maintenanceFee, 'maintenanceFee')}
              placeholder='Ejemplo: $5,000 (opcional)'
              value={this.state.maintenanceFee}
            />
          }
          <EditImagesForm
            addImage={this.handleChangeImages}
            deleteImage={index => this.deleteImage(index)}
            deleteNewImage={index => this.deleteNewImage(index)}
            deleteImageUrl={index => this.deleteImageUrl(index)}
            images={this.state.images}
            imagesUrl={this.state.imagesUrl}
            newImages={this.state.newImages}
            emptyField={this.state.requiredFields.find(element => element === 'image') && (this.state.images.length + this.state.newImages.length) < 1}
            />
          <SectionDivider
            active={this.state.activeSection === 1}
            description='Agrega información de tu propiedad'
            onPress={() => this.toggleSection(1)}
            title='Detalles Generales*'
          />
          <BasicDetailsForm
            active={this.state.activeSection === 1}
            age={this.state.age}
            ammenities={this.state.ammenities}
            floors={this.state.floors}
            onChangeAmmenityValue={(id, value) => this.changeAmmenityValue(id, value)}
            onChangeValue={valueObject => {
              if(Object.keys(valueObject)[0] !== 'floors') {
                this.setState(valueObject)
              }
              else {
                this.changeFloors(valueObject.floors);
              }
            }}
            postType='Property'
            propertyType={this.state.type}
            landArea={this.state.landArea}
            buildingArea={this.state.buildingArea}
            requiredFields={this.state.requiredFields}
          />
         <SectionDivider
            active={this.state.activeSection === 2}
            description='Cuál es el estatus legal de tu propiedad'
            onPress={() => this.toggleSection(2)}
            title='Estatus Legal*'
          />
          <LegalStatusForm
            active={this.state.activeSection === 2}
            onChangeValue={value => this.setState({legalStatus: value})}
            value={this.state.legalStatus}
          />
         <SectionDivider
            active={this.state.activeSection === 6}
            description='Dónde está ubicado'
            onPress={() => this.toggleSection(6)}
            title='Dirección*'
          />
          <AddressForm
            onFocusDescription={this._onFocusDescription}
            active={this.state.activeSection === 6}
            city={this.state.city}
            description={this.state.description}
            latitude={this.state.latitude}
            latitudeDelta={Number(this.state.latitudeDelta)}
            longitude={this.state.longitude}
            longitudeDelta={Number(this.state.longitudeDelta)}
            ref={this.map}
            neighborhood={this.state.neighborhood}
            number={this.state.number}
            onChangeCity={city => this.setState({city})}
            onChangeTextInput={inputObject => this.setState(inputObject)}
            onChangeState={state => this.onChangeState(state)}
            onChangeNeighborhood={neighborhood => {
                this.setState({neighborhood});
                this.updateMap();
            }}
            onChangeStreet={street => {
              this.setState({street});
              this.updateMap();
            }}
            onChangeNumber={number => {
              this.setState({number});
              this.updateMap();
            }}
            onRegionChangeComplete={region => this.setState(region)}
            state={this.state.state}
            street={this.state.street}
            zipCode={this.state.zipCode}
            requiredFields={this.state.requiredFields}
          />
        <OptionalDataSection
            icon={ this.state.viewOptionalFields ? <PremierHide/> : <PremierShow/> }
            active={false}
            description='Estos campos son opcionales, si deseas puedes hacer tu publicación'
            onPress={() => this.setState({ viewOptionalFields: !this.state.viewOptionalFields })}
            title={ this.state.viewOptionalFields ? 'Ocultar campos opcionales' : 'Agregar más información'}
          />
        {
          this.state.viewOptionalFields &&
          <div>
            {
              this.state.propertyType !== 2 &&
              <div>
                <SectionDivider
                  active={this.state.activeSection === 3}
                  description='Agrega opciones de valor a tu propiedad'
                  onPress={() => this.toggleSection(3)}
                  title='Características'
                />
                <AmmenitiesForm
                  active={this.state.activeSection === 3}
                  ammenities={this.state.ammenities}
                  toggleAmmenity={id => this.toggleAmmenity(id)}
                />
                <SectionDivider
                  active={this.state.activeSection === 4}
                  description='Cuenta con...'
                  onPress={() => this.toggleSection(4)}
                  title='Amenidades'
                />
                <AdditionalAmmenities
                  active={this.state.activeSection === 4}
                  ammenities={this.state.ammenities}
                  toggleAmmenity={id => this.toggleAmmenity(id)}
                />
              </div>
            }
            <SectionDivider
              active={this.state.activeSection === 5}
              description='Lugares aledaños cool'
              onPress={() => this.toggleSection(5)}
              title='Alrededores'
            />
            <NearbyPlacesForm
              active={this.state.activeSection === 5}
              nearbyPlaces={this.state.nearbyPlaces}
              toggleNearbyPlace={id => this.toggleNearbyPlace(id)}
            />
          </div>
        }

        <div className="flex flex-row items-center justify-center">
          <button  onClick={() => this.submitForm()} className="redaria-button-blue mt-10 self-center mb-10">Publicar</button>
        </div>
      </div>
    )
  }
}

export default EditProperty
