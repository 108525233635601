import React, {PureComponent} from 'react';
import { CameraPlus } from '../../lib/Icon/icons/Camera';
import { TextInputWithPlaceholder } from '../Forms/TextInputWithPlaceholder';

export default class AgencyForm extends PureComponent {

  hiddenFileInput = React.createRef()

  _handleFile = e => {
    this.props.changeImage(e.target.files[0])
  }

  render() {
    return(
      <div className="mt-5 w-full">
        <input 
          ref={this.hiddenFileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={this._handleFile}
          maxLength="1"/>
        <TextInputWithPlaceholder
          removeContainer
          maxLength={25}
          onChangeText={this.props.changeAgencyName}
          placeholder='Nombre de inmobiliaria*'
          value={this.props.agencyName}
          emptyField={this.props.emptyField}
        />
        <p className="poppins-semi-bold text-redaria-black mt-5" style={{ fontSize:20 }}>Logo de inmobiliaria <span className="poppins-regular">(Opcional)</span></p>
        <div className="flex flex-row mt-2 items-center">
          <div className="cursor-pointer ml-4" onClick={ () => this.hiddenFileInput.current.click()} >
            <div className="flex flex-row items-center justify-center">
              {
                (this.props.agencyLogoUri.name || this.props.agencyLogoUri.uri)
                ?
                (
                  this.props.agencyLogoUri.uri
                  ?
                  <img alt="agency" className="mr-4" style={{ width:72,height:72,borderRadius:72 }} src={ this.props.agencyLogoUri.uri}/>
                  :
                  <img alt="agency" className="mr-4" style={{ width:72,height:72,borderRadius:72 }} src={ URL.createObjectURL(this.props.agencyLogoUri)}/>
                )
                :
                <CameraPlus className="mr-4" style={{width:72}}/>
              }
             
              <p className="poppins-medium text-redaria-blue mr-4" style={{ fontSize:14 }}>Subir logo</p>
            </div>
          </div>
          {
            (this.props.agencyLogoUri.name || this.props.agencyLogoUri.uri) &&
            <div className="cursor-pointer" onClick={this.props.deleteImage}>
              <p className="poppins-regular text-redaria-black" style={{ fontSize:14 }}>Eliminar</p>
            </div>
          }
        </div>
      </div>
    );
  }
}
