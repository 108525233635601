import React, {PureComponent} from 'react';

export default class AmmenityValue extends PureComponent {
  render() {
    return(
        <div className="flex flex-row items-center w-1/4	mb-12">
          {this.props.icon}
          <p className="ml-2 questrial text-redaria-black" style={{ fontSize:16 }} >{this.props.label}</p>
        </div>
    );
  }
}

