import React from 'react';

export default function Copy(props) {
  return (
    <svg {...props} viewBox="0 0 19.416 21.181">
      <g>
        <path
          fill="#2b77b7"
          d="M13.459 21.181H2.427A2.429 2.429 0 010 18.754V6.84a2.429 2.429 0 012.427-2.427h.441a.662.662 0 010 1.324h-.441a1.1 1.1 0 00-1.1 1.1v11.914a1.1 1.1 0 001.1 1.1h11.032a1.1 1.1 0 001.1-1.1v-.441a.662.662 0 111.324 0v.441a2.429 2.429 0 01-2.424 2.43zm0 0"
        />
        <path
          fill="#2b77b7"
          d="M16.989 15.886H7.722a2.429 2.429 0 01-2.427-2.427V2.427A2.429 2.429 0 017.722 0h9.267a2.429 2.429 0 012.427 2.427v11.032a2.429 2.429 0 01-2.427 2.427zM7.722 1.324a1.1 1.1 0 00-1.1 1.1v11.035a1.1 1.1 0 001.1 1.1h9.267a1.1 1.1 0 001.1-1.1V2.427a1.1 1.1 0 00-1.1-1.1zm0 0"
        />
      </g>
    </svg>
  )
}
