import React, { PureComponent } from 'react'
import PasswordApi from '../../api/PasswordApi';
import LogoRedaria from '../../assets/images/icon_redaria.png';
import LoadingModal from '../../components/Loading/LoadingModal';
import Input from '../../components/Login/Input';
import { ErrorAlert, InformationAlert } from '../../lib/alerts';


export class RecoverPassword extends PureComponent {
  state = {
    buttonDisabled: false,
    email: '',
    isEmailSend: false,
    isLoadingModalVisible:false
  }

  submit = () => { 
    const email = this.state.email;
    this.setState({isLoadingModalVisible:true}, () =>{
      PasswordApi.sendResetEmail(email).then(result => {
        this.setState({isEmailSend:true,isLoadingModalVisible:false}); 
      }).catch(error => {
        this.setState({isLoadingModalVisible:false}); 
        if(error?.response?.data?.message)
          InformationAlert('Usuario no encontrado','No encontramos ninguna cuenta con la información que ingresaste. Por favor revísalo nuevamente.', () => {});
        else
        ErrorAlert('Hubo un error. Intenta de nuevo más tarde', () => {});
      });
    });
  }

  render() {
    const { isEmailSend, email } = this.state
    return (
      <div className="min-h-full flex flex-1 flex-col">
        <div className="flex flex-row items-center mt-10 ml-10">
          <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
          <p className="questrial font-semibold text-redaria-blue" style={{ fontSize:27 }}>redaria</p>
        </div>
        
        <div className="flex flex-col mt-20 items-center justify-center ">
          <div className="bg-white" style={{ width:493,boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",borderRadius:9,paddingLeft:48,paddingTop:29,paddingRight:64,paddingBottom:43 }}>

            {
              !isEmailSend
              ?
              <React.Fragment>
                <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:28 }}>Recupera tu contraseña</p>
                <p className="poppins-semi-bold text-redaria-blue mt-1" style={{ fontSize:20 }}>¿Olvidaste tu contraseña?</p>
                <p className="questrial text-redaria-black mt-1 mb-5" style={{ fontSize:23,lineHeight:1.04 }}>Ingresa tu correo electrónico para recuperar tu cuenta.</p>
                
                <Input labelID="l-1" name="Correo electrónico" onChange={ email => this.setState({email})} value={email} />
  
                <button onClick={this.submit} className="redaria-button-blue" style={{ marginTop:47 }}>Enviar correo</button>
              </React.Fragment>
              :
              <React.Fragment>
                <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:28 }}>Recupera tu contraseña</p>
                <p className="poppins-semi-bold text-redaria-blue mt-1" style={{ fontSize:24 }}>Revisa tu correo</p>
                <p className="questrial text-redaria-black mt-1 mb-5" style={{ fontSize:20,lineHeight:1.04 }}>Hemos enviado un correo a <span className="poppins-semi-bold">{email}</span>. Da click en la liga para reestablecer tu contraseña.</p>
                <p className="questrial text-redaria-black mt-1 mb-5" style={{ fontSize:20,lineHeight:1.04 }}>Si no vez el correo, checa en la carpeta de spam o en la papelera de tu cuenta electrónica…</p>
                
              </React.Fragment>
            }


          </div>
        </div>
        
      <LoadingModal visible={this.state.isLoadingModalVisible}/>
      </div>
    )
  }
}

export default RecoverPassword
