import React, { PureComponent } from 'react'
import NewPropertyBasicForm from '../../containers/NewPropertyBasicForm/NewPropertyBasicForm'
import { ErrorAlert } from '../../lib/alerts'
import { NON_DIGITS_REGEXP } from '../../lib/Regexp'
import numeral from 'numeral';
import LeftOptions from './LeftOptions'
import GooglePlacesApi from '../../lib/GooglePlacesApi';
import _ from 'lodash'
import GeneralDetails from './GeneralDetails';
import AddressForm from './AddressForm';
import { connect } from 'react-redux';
import { getPremierStauts } from '../../selectors/premier';
import MoreInformation from './MoreInformation';
import { PropertyBasicInformationSubmit, PropertyGeneralInformationSubmit,PropertyAddressSubmit } from '../../lib/FormSubmit';
import LoadingModal from '../Loading/LoadingModal';
import PropertiesApi from '../../api/PropertiesApi';
import { withRouter } from 'react-router-dom';
import ModalNewPublication from '../Property/ModalNewPublication';
import { compressImages } from '../../lib/compressImages'

class ModalNewProperty extends PureComponent {

  state = {
    step: 0,
    dealType: null,
    images: [],
    price: '',
    type: null,
    maintenanceFee: '',
    requiredFields: [],

    activeSection: 1,
    age: -1,
    ammenities: [],
    buildingArea: '',
    city: '',
    description: '',
    disabledButton: false,
    floors: '',
    landArea: '',
    latitude: 23.181385167349607,
    latitudeDelta: 24.92818706505762,
    legalStatus: 0,
    longitude: -102.41049645468593,
    longitudeDelta: 31.527897454798236,
    nearbyPlaces: [],
    neighborhood: '',
    number: '',
    numberOfRecommendedPosts: 0,
    state: this.props.defaultState,
    street: '',
    zipCode: '',
    isLoadingModalVisible: false,
    isRecommendedPostsModalVisible: false,
    viewOptionalFields: false,
    propertyId: undefined,
    uploadedImages: 0,
    loadingMessage: '',

    finishFirstSection:false,
    finishSecondSection:false,
    finishThirdSection:false,
    zoom:4,
    showModalFinish:false,
    newPropertyId:0,
    loadingCompressFiles:false,
    imagesError:0
  }

  map = React.createRef(null)

  updateMap = _.debounce(this._updateMap, 1000);

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  _setStep = (step) => {
    this.setState({ step })
    document.getElementById('modal-new-property').scroll({top:0})
  }

  changePrice = (value, field) => {

    let price = value.replace(NON_DIGITS_REGEXP, "");
    price = price === '0' || price === '' ? '' : parseInt(price);
    price = price === '' ? '' : numeral(price).format('$0,0[.]00');
    this.setState({
      [field]: price
    });
  }

  handleChange = (files,inputRef) => {
    
    this.setState({loadingCompressFiles:true}, async() => {


      const {images,imagesError,showMaxFiles} = await compressImages(files,10,this.state.images)

      if(showMaxFiles)
      {
        ErrorAlert('El limite de imágenes es de 10.', () =>{})        
      }
      
      if(inputRef)
      {
        inputRef.current.value = ""
      }
      this.setState({
        images: images,
        loadingCompressFiles:false,
        imagesError
      });
    })
  }

  _deleteImage = (imageName) => {



    const selectedImages = this.state.images.filter(({ name }) => (name !== imageName));
    this.setState({ images: selectedImages })
  };

  _setRequiredFieldsFirst = requiredFields => {
    this.setState({ requiredFields })
  }

  toggleSection = (sectionId) => {
    if (this.state.activeSection === sectionId) {
      this.setState({
        activeSection: 0
      });
    }
    else {
      this.setState({
        activeSection: sectionId
      });
    }
  }

  changeAmmenityValue = (id, value) => {
    let ammenity = {
      id: id,
      value: value
    };

    if (this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      let ammenities = [...this.state.ammenities];
      
      const ammenityValue = this.state.ammenities.find(x => x.id === ammenity.id) ? this.state.ammenities.find(x => x.id === ammenity.id).value : null;

      const ammenityIndex = this.state.ammenities.findIndex(x => x.id === ammenity.id);
      if (ammenityValue !== ammenity.value) {
        ammenities[ammenityIndex] = ammenity;
        this.setState({ ammenities: ammenities });
      }
      else {
        this.setState({
          ammenities: ammenities.filter(x => x.id !== ammenity.id)
        });
      }
    }
  }

  
  changeFloors = (value) => {
    this.setState(prevState => {
      if (prevState.floors === value) {
        return { floors: '' };
      }
      else {
        return { floors: value };
      }
    });
  }

  toggleAmmenity = (id) => {
    let ammenity = {
      id: id
    };

    if (this.state.ammenities.find(x => x.id === id) === undefined) {
      this.setState(prevState => ({
        ammenities: [...prevState.ammenities, ammenity]
      }));
    }
    else {
      this.setState(prevState => ({
        ammenities: prevState.ammenities.filter(x => x.id !== id)
      }));
    }
  }

  toggleNearbyPlace= (id) => {
    if (this.state.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === id) === undefined) {
      this.setState(prevState => ({
        nearbyPlaces: [...prevState.nearbyPlaces, id]
      }));
    }
    else {
      this.setState(prevState => ({
        nearbyPlaces: prevState.nearbyPlaces.filter(nearbyPlaceId => nearbyPlaceId !== id)
      }));
    }
  }

  onChangeState = (state) => {
    this.setState(prevState => {
      if (prevState.state !== state) {
        return { state: state, city: '' };
      }
    });
  }


  _updateMap() {
    let state = this.state.state.split(' ').join('+');
    let city = this.state.city.split(' ').join('+');
    let neighborhood = this.state.neighborhood.split(' ').join('+');
    let street = this.state.street.split(' ').join('+');
    let number = this.state.number.split(' ').join('+');

    let query = '';

    if (state !== '') {
      query = `${query}+${state}`;
    }
    if (city !== '') {
      query = `${query}+${city}`;
    }
    if (neighborhood !== '') {
      query = `${query}+${neighborhood}`;
    }
    if (street !== '') {
      query = `${query}+calle+${street}`;
    }
    if (number !== '') {
      query = `${query}+${number}`;
    }

    GooglePlacesApi.getPlace(query).then(result => {
      if (result.data.result) {
        this.setState({
          latitude: result.data.result.geometry.location.lat,
          longitude: result.data.result.geometry.location.lng,
        }, () => {
          this.map.current.testChange(this.state.latitude,this.state.longitude)
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  _finishFirstSection = () => {

    const data = {
      dealType: this.state.dealType,
      images: this.state.images,
      price: this.state.price.replace(NON_DIGITS_REGEXP, ""),
      type: this.state.type,
      maintenanceFee: this.state.maintenanceFee.replace(NON_DIGITS_REGEXP, "")
    }

    return new Promise( (resolve,reject) => {
      PropertyBasicInformationSubmit(
        data,
        () => {
          this.setState({finishFirstSection:true,step:1}, () => {
            document.getElementById('modal-new-property').scroll({top:0})
          })
          resolve(true)
        },
        (requiredFields) => {
          this.setState({ step:0, requiredFields })
          reject(false)
        }
      )
    }) 
  }

  _finishSecondSection = () => {

    const data  = {
      type          : this.state.type,
      dealType      : this.state.dealType,
      price         : this.state.price.replace(NON_DIGITS_REGEXP, ""),
      images        : this.state.images,
      landArea      : this.state.landArea,
      legalStatus   : this.state.legalStatus,
      nearbyPlaces  : this.state.nearbyPlaces,
      state         : this.state.state,
      city          : this.state.city,
      zipCode       : this.state.zipCode,
      neighborhood  : this.state.neighborhood,
      street        : this.state.street,
      number        : this.state.number,
      latitude      : this.state.latitude,
      longitude     : this.state.longitude,
      description   : this.state.description,
      maintenanceFee: this.state.maintenanceFee.replace(NON_DIGITS_REGEXP, ""),
    };

    if (this.state.buildingArea !== '') {
      data.buildingArea = this.state.buildingArea;
    }

    if (data.type !== 2) {
      data.floors     = this.state.floors;
      data.ammenities = this.state.ammenities;
      data.age        = this.state.age;
    }


    return new Promise( (resolve,reject) => {
      PropertyGeneralInformationSubmit(
        data,
        () => {
          this.setState({finishSecondSection:true,step:2}, () => {
            document.getElementById('modal-new-property').scroll({top:0})
          })
          resolve(true)
        },
        (requiredFields) => {
          this.setState({ requiredFields,step:1 })
          reject(false)
        }
      )
    }) 
  }

  _finishThirdSection = () => {

    const data = {
      type: this.state.type,
      dealType: this.state.dealType,
      price: this.state.price.replace(NON_DIGITS_REGEXP, ""),
      images: this.state.images,
      landArea: this.state.landArea,
      legalStatus: this.state.legalStatus,
      nearbyPlaces: this.state.nearbyPlaces,
      state: this.state.state,
      city: this.state.city,
      zipCode: this.state.zipCode,
      neighborhood: this.state.neighborhood,
      street: this.state.street,
      number: this.state.number,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      description: this.state.description,
      maintenanceFee: this.state.maintenanceFee.replace(NON_DIGITS_REGEXP, ""),
    };

    if (this.state.buildingArea !== '') {
      data.buildingArea = this.state.buildingArea;
    }

    if (data.type !== 2) {
      data.floors = this.state.floors;
      data.ammenities = this.state.ammenities;
      data.age = this.state.age;
    }
      
    PropertyAddressSubmit(
      data,
      (requiredFields) => this._setRequiredFieldsFirst(requiredFields),
      formData => this.sendData(formData)
    );
  }

  submitFinish = () => {
    this._finishFirstSection()
      .then( () => 
        this._finishSecondSection()
          .then( () => this._finishThirdSection())
      )
  }

  sendData = async(entries) => {
    const images = entries.filter(([key]) => (key === 'images[]')).map(([_, image]) => image);
    const totalImagesCount = images.length;
    let currentImageCount = 1;
    let uploadedImages = 0;
    try {
      await this.setStatePromise({ isLoadingModalVisible: true, loadingMessage: '' });

      let propertyId = this.state.propertyId;

      while (images.length > 0) {
        const image = images[0];
        const formData = new FormData();
        entries.filter(([key]) => (key !== 'images[]')).forEach(([name, value]) => formData.append(name, value));

        formData.append((!propertyId) ? 'images[]' : 'new_images[]', image);

        this.setState({ loadingMessage: `Subiendo imagen ${currentImageCount} de ${totalImagesCount}` });
        const result = await ((!propertyId) ? PropertiesApi.create(formData) : PropertiesApi.update(propertyId, formData));

        if (!propertyId) {
          propertyId = result.data.data.id;
        }
        await this.setStatePromise(({ propertyId, uploadedImages }));
        
        images.shift(); ++currentImageCount; ++uploadedImages
      }

      if (!propertyId || (images.length > 0)) {
        this.showErrorMessage();      
      } else {
        this.setState({isLoadingModalVisible: false,showModalFinish:true,newPropertyId:propertyId})
      }
    } catch(error) {
      console.log(error);
    }
    this.setState({images})
  }

  updateImagesOrder = images => {
    this.setState({images})
  }

  render() {
    const { close } = this.props
    return (
      <div className="bg-white" style={{
        zIndex: 20,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}>
        <div id="modal-new-property" style={{
          zIndex: 30,
          position: "relative",
          overflow: "auto",
          height: "100%"
        }}>

          <div  onClick={e => e.stopPropagation()} className="bg-white h-full z-30 flex flex-col"
            style={{
              borderRadius: 9,
              width: '100%',
              paddingLeft: 33,
            }}>

            <div className="grid grid-cols-12">

              <LeftOptions
                close={close}
                step={this.state.step}
                setStep={this._setStep}
                viewOptionalFields={this.state.viewOptionalFields}
                parentSetState={(v) => this.setState(v)}
                finishFirstSection={this.state.finishFirstSection}
                finishSecondSection={this.state.finishSecondSection}
                finishThirdSection={this.state.finishThirdSection}
              />
              <div className="col-span-8" style={{ backgroundColor: '#F8F8F8',paddingLeft: 79 }}>

                {
                  this.state.step === 0 &&
                  <NewPropertyBasicForm
                    changePrice={this.changePrice}
                    handleChange={this.handleChange}
                    _deleteImage={this._deleteImage}
                    dealType={this.state.dealType}
                    images={this.state.images}
                    updateImagesOrder={this.updateImagesOrder}
                    imagesError={this.state.imagesError}
                    price={this.state.price}
                    type={this.state.type}
                    maintenanceFee={this.state.maintenanceFee}
                    requiredFields={this.state.requiredFields}
                    parentSetState={(v) => this.setState(v)}
                    nextSttep={() => this.setState({ step: 1 })}
                    setRequiredFields={this._setRequiredFieldsFirst}
                    finishFirstSection={this._finishFirstSection}
                  />
                }
                {
                  this.state.step === 1 &&
                  <GeneralDetails
                    activeSection={this.state.activeSection}
                    age={this.state.age}
                    ammenities={this.state.ammenities}
                    floors={this.state.floors}
                    changeAmmenityValue={this.changeAmmenityValue}
                    parentSetState={ (v) => this.setState(v)}
                    changeFloors={this.changeFloors}
                    propertyType={this.state.type}
                    landArea={this.state.landArea}
                    buildingArea={this.state.buildingArea}
                    requiredFields={this.state.requiredFields}
                    legalStatus={this.state.legalStatus}
                    description={this.state.description}
                    finishSecondSection={this._finishSecondSection}
                    goBack={ () => this.setState({step:0})}
                  />
                }
                {
                this.state.step === 2 &&
                <AddressForm
                  zoom={this.state.zoom}
                  city={this.state.city}
                  description={this.state.description}
                  latitude={Number(this.state.latitude)}
                  latitudeDelta={Number(this.state.latitudeDelta)}
                  longitude={Number(this.state.longitude)}
                  longitudeDelta={Number(this.state.longitudeDelta)}
                  ref={this.map}
                  neighborhood={this.state.neighborhood}
                  number={this.state.number}
                  onChangeCity={city => this.setState({ city })}
                  onChangeTextInput={inputObject => this.setState(inputObject)}
                  onChangeState={state => this.onChangeState(state)}
                  onChangeNeighborhood={neighborhood => {
                    this.setState({ neighborhood });
                    this.updateMap();
                  }}
                  onChangeStreet={street => {
                    this.setState({ street });
                    this.updateMap();
                  }}
                  onChangeNumber={number => {
                    this.setState({ number });
                    this.updateMap();
                  }}
                  onRegionChangeComplete={region => this.setState(region)}
                  state={this.state.state}
                  street={this.state.street}
                  zipCode={this.state.zipCode}
                  requiredFields={this.state.requiredFields}
                  submitFinish={this.submitFinish}
                  goBack={ () => this.setState({step:1})}
                />
                }



                {
                  this.state.step === 3 &&
                  <MoreInformation
                    toggleAmmenity={this.toggleAmmenity}
                    nearbyPlaces={this.state.nearbyPlaces}
                    toggleNearbyPlace={this.toggleNearbyPlace}
                    submitForm={this.submitForm}
                    propertyType={this.state.type}
                    toggleSection={this.toggleSection}
                    activeSection={this.state.activeSection}
                    ammenities={this.state.ammenities}
                    submitFinish={this.submitFinish}
                    goBack={ () => this.setState({step:2})}
                  />
                }
              </div>
            </div>

          </div>
        </div>
        <LoadingModal 
          visible={this.state.isLoadingModalVisible || this.state.loadingCompressFiles}
          message={this.state.loadingMessage}
          />
        <ModalNewPublication visible={this.state.showModalFinish} propertyId={this.state.newPropertyId}/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    defaultState: state.authenticatedUser.attributes.state,
    isPremier: getPremierStauts(state)
  };
};

export default connect(mapStateToProps)( withRouter(ModalNewProperty))