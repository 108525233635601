import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { saveUser } from '../../actions/authenticatedUser'
import UserRequest from '../../api/UserRequest'
import AgencyForm from '../EditProfile/AgencyForm'
import ExperienceAreasForm from '../EditProfile/ExperienceAreasForm'
import ProfilePictureForm from '../EditProfile/ProfilePictureForm'
import QuestionWithOptions from '../EditProfile/QuestionWithOptions'
import { AddressDropdown } from '../Forms/AddressDropdown'
import { TextInputWithPlaceholder } from '../Forms/TextInputWithPlaceholder'
import LoadingModal from '../Loading/LoadingModal'
import { getCitiesFromState } from '../../lib/Address'
import { ErrorAlert } from '../../lib/alerts'
import { NewProfileSubmit } from '../../lib/FormSubmit'
import States from '../../lib/States'
import SecureLS from 'secure-ls';
import { EMAIL_REGEXP } from '../../lib/Regexp'
import { LeftArrow } from '../../lib/Icon/icons/Arrows'

import ReCAPTCHA from "react-google-recaptcha";
import { compressImages } from '../../lib/compressImages'


export class SigIn extends PureComponent {
  state = {
    agencyLogo: {},
    agencyName: '',
    certificates: [],
    city: '',
    deletedLogo: false,
    deleteCertificates: [],
    disabledButton: false,
    email: '',
    experienceAreas: [],
    hasAgency: false,
    newCertificates: [],
    phone: '',
    profilePicture: {},
    state: '',
    type: 0,
    name: '',
    isImagePickerOptionsModalVisible: false,
    loadingSubmit: false,
    requiredFields: [],
    loadingProfile: false,
    changeCertificateType: 'new',
    indexCertificate: 0,
    password: '',
    password_confirmation: '',
    isRegister:true,
    captcha:null
  }

  hiddenFileInput = React.createRef()
  recaptchaRef    = React.createRef();

  onAceptCaptcha = (captcha) => {
    this.setState({captcha})
  }


  getProfilePicture(url) {
    if (url === '') {
      return {};
    }
    else {
      return { uri: url };
    }
  }

  setStateFromRequest(data) {
    const profilePicture = this.getProfilePicture(data.attributes.photoUrl);
    const type = this.getType(data.attributes.type);
    const hasAgency = data.attributes.hasAgency === 1 ? true : false;
    const certificates = this.getCertificates(data.attributes.certificates.data);

    let stateObject = {
      email: data.attributes.email,
      name: data.attributes.name ? data.attributes.name.trim() : '',
      profilePicture: profilePicture,
      phone: data.attributes.phone,
      type: type,
      state: data.attributes.state ? data.attributes.state : '',
      city: data.attributes.city ? data.attributes.city : '',
      hasAgency: hasAgency,
      loginType: data.attributes.loginType,
      certificates: certificates,
      loadingProfile: false,
      disabledButton: false
    };

    if (type === 1) {
      const experienceAreas = this.getExperienceAreas(data.attributes.experienceAreas);
      stateObject = { ...stateObject, ...{ experienceAreas: experienceAreas } };
    }
    if (hasAgency) {
      const agencyName = data.attributes.agencyName;
      const agencyLogo = this.getAgencyLogo(data.attributes.agencyImageUrl);
      stateObject = { ...stateObject, ...{ agencyName: agencyName, agencyLogo: agencyLogo } };
    }

    this.setState(stateObject);
  }

  getCities = () => {
    if (this.state.state === '') {
      return [];
    }
    else {
      return getCitiesFromState(this.state.state);
    }
  }

  addCertificate() {
    this.setState(prevState => {
      if (prevState.certificates.length + prevState.certificates.length < 3) {
        return {
          certificates: [...prevState.certificates, { file: {}, name: '', serialNumber: '' }]
        };
      }
    });
  }

  changeCertificateImage(index) {

    this.setState({ changeCertificateType: 'current', indexCertificate: index }, () => {
      this.hiddenFileInput.current.click();
    })

  }


  _handleFile = e => {

    if (this.state.changeCertificateType === 'current') {
      let certificates = [...this.state.certificates];

      certificates[this.state.indexCertificate].file = e.target.files[0];
      this.setState({
        certificates: certificates
      });
    }

  }

  deleteCertificate(index) {
    let certificates = [...this.state.certificates];
    const certificateId = { ...this.state.certificates[index] }.id;

    certificates.splice(index, 1);

    this.setState(prevState => ({
      certificates: certificates,
      deleteCertificates: [...new Set([...prevState.deleteCertificates, certificateId])]
    }));
  }


  changeCertificateName(index, text) {
    let certificates = [...this.state.certificates];
    certificates[index].name = text;

    this.setState({
      certificates: certificates
    });
  }


  changeCertificateSerialNumber(index, text) {
    let certificates = [...this.state.certificates];
    certificates[index].serialNumber = text;

    this.setState({
      certificates: certificates
    });
  }

  changeAgencyLogo = (image) => {
    this.setState({ agencyLogo: image });
  }
  deleteAgencyLogo() {
    this.setState({
      agencyLogo: {},
      deletedLogo: true
    });
  }

  changeExperienceArea(value) {
    let experienceAreas = [...this.state.experienceAreas];

    if (experienceAreas.includes(value)) {
      this.setState({
        experienceAreas: experienceAreas.filter(experienceArea => experienceArea !== value)
      });
    }
    else {
      this.setState({
        experienceAreas: [...experienceAreas, value]
      });
    }
  }


  getType(type) {
    switch (type) {
      case 'Usuario particular':
        return 0;
      case 'Asesor':
        return 1;
      default:
        return 0;
    }
  }
  getExperienceAreas(experienceAreas) {
    return Array.from(experienceAreas, experienceArea => {
      return experienceArea.id;
    });
  }
  getAgencyLogo(url) {
    if (url === '') {
      return {};
    }
    else {
      return { uri: url };
    }
  }
  getCertificates(certificates) {
    return Array.from(certificates, certificate => ({
      id: certificate.id,
      name: certificate.name,
      serialNumber: certificate.serialNumber ? certificate.serialNumber : '',
      file: {
        id: certificate.image[0].id,
        uri: certificate?.image[0]?.path,
      }
    }));
  }

  submitForm() {
    const data = this.parsedData();
    this.setState({
      disabledButton: true
    }, () => {
      NewProfileSubmit(
        data,
        requiredFields => this._setRequiredFields(requiredFields),
        formData => this.sendData(formData)
      );
    });
  }

  _setRequiredFields = requiredFields => {
    this.setState({ disabledButton: false, requiredFields })
  }

  parsedData() {
    return {
      deletedLogo: this.state.deletedLogo,
      profilePicture: this.state.profilePicture,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      state: this.state.state,
      type: this.state.type,
      city: this.state.city,
      hasAgency: this.state.hasAgency,
      experienceAreas: this.state.experienceAreas,
      agencyName: this.state.agencyName,
      agencyLogo: this.state.agencyLogo,
      deleteCertificates: this.state.deleteCertificates,
      newCertificates: this.state.newCertificates,
      certificates: this.state.certificates,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation
    };
  }


  sendDataCreate = () => {

    const data = {
      email: this.state.email,
      password: this.state.password,
      passwordConfirmation: this.state.password_confirmation,
      captcha: this.state.captcha
    };
    this.setState({loadingSubmit:true,disabledButton:true}, () =>{

      if(!this.state.captcha){
        ErrorAlert('Debes completar el captcha', () => this.setState({disabledButton: false,loadingSubmit:false}));
      } else
       if (!EMAIL_REGEXP.test(this.state.email)) {
        ErrorAlert('Captura un correo electrónico válido', () => this.setState({disabledButton: false,loadingSubmit:false}));
      } else if (this.state.password === '') {
        ErrorAlert('Captura una contraseña', () => this.setState({disabledButton: false,loadingSubmit:false}));
      } else if (this.state.password.length < 6) {
        ErrorAlert('La contraseña debe ser de al menos 6 caracteres', () => this.setState({disabledButton: false,loadingSubmit:false}));
      } else if (this.state.password_confirmation === '') {
        ErrorAlert('Captura nuevamente la contraseña', () => this.setState({disabledButton: false,loadingSubmit:false}));
      } else {
        this.setState({
          loadingSubmit: true,disabledButton:true
        }, () => {
          this.submitFormCreate(data);
        });
      }
    });
  }

  submitFormCreate = (data) => {
    
    const ls = new SecureLS();
    this.setState({ loadingSubmit: true,disabledButton:true }, () => {
      UserRequest.create(data).then((result) => result.data).then((data) => {
        ls.set(process.env.REACT_APP_USER_AUTH_KEY,data.access_token)
        ls.set(process.env.REACT_APP_USER_PROFILE_KEY,'true')
        this.setState({isRegister:false,disabledButton:false})
      })
      .catch( (error) => {
        let message = 'Hubo un error. Intenta de nuevo más tarde.';
        this.recaptchaRef.current?.reset()
        this.setState({captcha:null})
        if (error.response) {
          message = this.getErrorMessage(JSON.parse(error.response.data.errors[0].title));
        }
        ErrorAlert(message,()=>this.setState({ loadingSubmit: false,disabledButton:false }))
      })

    })
    

  }

  getErrorMessage(errors) {
    let message = 'Hubo un problema con los datos que ingresaste';

    if(errors.email !== undefined)
      message = errors.email[0];
    if(errors.password !== undefined)
      message = errors.password[0];
    if(errors.password_confirmation !== undefined)
      message = errors.password_confirmation[0];
    if(errors['g-recaptcha-response'] !== undefined)
      message = errors['g-recaptcha-response'][0];
    return message;
  }

  sendData = (data) => {
    const ls = new SecureLS();
    this.setState({ loadingSubmit: true }, () => {
      return UserRequest.createProfile(data)
      .then( (result) => {
          this.props.dispatch(saveUser(result.data.data));        
          ls.set(process.env.REACT_APP_USER_PROFILE_KEY,'false')
          this.setState({ disabledButton: false }, () => {
            this.props.history.push('/signed/me/properties')
          })
      })
      .catch(error => {
        this.setState({ disabledButton: false, loadingSubmit: false });
        ErrorAlert(
          'Hubo un error. Intena de nuevo más tarde',
          () => { console.log(error) }
        );
      });
    })
  }

  _goWelcome = () => {
    this.props.history.push('/')
  }

  changeProfilePicture = (image) => {

    this.setState({loadingSubmit:true}, async() => {

      const {images,imagesError} = await compressImages([image],1)
      
      this.setState({profilePicture:images.length > 0 ? images[0] : this.state.profilePicture,loadingSubmit:false,imagesError})

    })


  }

  render() {
    return (
      <div className="mt-20 flex flex-col items-center">
        <div style={{ width: 429 }}>
          <div className="bg-white flex flex-col items-center mb-10" style={{ boxShadow: "0px 7px 13px rgba(0, 0, 0, 0.16)", borderRadius: 10,paddingTop:36 }}>

            {
              this.state.isRegister &&
              <div className="flex flex-col w-full px-10">
                <div className="cursor-pointer" onClick={this._goWelcome}>
                  <LeftArrow style={{width:34,height:34}}/>
                </div>
                <div className="mt-5 w-full" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>
              </div>
            }
            <p className="mt-5 poppins-medium text-redaria-black text-center" style={{ fontSize:25 }}>Crear cuenta: </p>

            {
              !this.state.isRegister &&
              <div className="px-10">
                <p className="mt-5 mb-5 poppins-medium text-redaria-black text-center" style={{ fontSize:16 }}>Agrega una foto de perfil (Opcional)</p>

                <ProfilePictureForm
                  imagesError={this.state.imagesError}
                  changeProfilePicture={this.changeProfilePicture}
                  profilePictureUri={this.state.profilePicture}
                  emptyField={this.state.requiredFields.find(element => element === 'profilePicture')}
                />
              </div>
            }



            <div className="w-full px-10 flex flex-col items-center">
              {
                !this.state.isRegister &&
                <React.Fragment>
                  <TextInputWithPlaceholder
                    placeholder="Nombre*"
                    removeContainer
                    onChangeText={name => this.setState({ name })}
                    value={this.state.name}
                    emptyField={this.state.requiredFields.find(element => element === 'name') && this.state.name.length===0}
                  />
                </React.Fragment>
              }
              {
                this.state.isRegister &&
                <React.Fragment>
                  <TextInputWithPlaceholder
                    placeholder="Correo eléctronico*"
                    removeContainer
                    onChangeText={email => this.setState({ email })}
                    value={this.state.email}
                    emptyField={this.state.requiredFields.find(element => element === 'email') && this.state.email.length===0}
                  />
                </React.Fragment>
              }
              {
                !this.state.isRegister &&
                <React.Fragment>
                  <TextInputWithPlaceholder
                    placeholder="Teléfono*"
                    removeContainer
                    onChangeText={phone => this.setState({ phone: phone.trim() })}
                    value={this.state.phone}
                    emptyField={this.state.requiredFields.find(element => element === 'phone') && (this.state.phone.length===0 ? true : ( (this.state.phone.length > 10 || this.state.phone.length < 10) ? true : false  ))}
                  />
                </React.Fragment>
              }

              {
                this.state.isRegister &&
                <React.Fragment>
                  <TextInputWithPlaceholder
                    type="password"
                    placeholder="Contraseña*"
                    removeContainer
                    onChangeText={password => this.setState({ password })}
                    value={this.state.password}
                    emptyField={this.state.requiredFields.find(element => element === 'password') && this.state.password_confirmation.length===0}
                  />
                  <TextInputWithPlaceholder
                    type="password"
                    placeholder="Confirmar contraseña*"
                    removeContainer
                    onChangeText={password_confirmation => this.setState({ password_confirmation })}
                    value={this.state.password_confirmation}
                    emptyField={this.state.requiredFields.find(element => element === 'password_confirmation') &&  this.state.password.length===0}
                  />
                </React.Fragment>
              }

            {
              !this.state.isRegister &&
              <React.Fragment>

                <div className="mt-2 w-full flex flex-col items-center">
                  <AddressDropdown
                    data={States}
                    onSelectOption={state => this.setState({ state })}
                    modalTitle='Selecciona un Estado'
                    value={this.state.state}
                  />
                  {
                    (this.state.requiredFields.find(element => element === 'state') && this.state.state.length === 0) &&
                    <div className="flex w-full">
                      <p className="text-red-500">
                        Campo requerido
                      </p>
                    </div>
                  }
                </div>
                <div className="mt-2 w-full flex flex-col items-center">
                  <AddressDropdown
                    data={this.getCities()}
                    onSelectOption={city => this.setState({ city })}
                    placeholder='Ciudad'
                    modalTitle='Selecciona una Ciudad'
                    value={this.state.city}
                  />
                  {
                    (this.state.requiredFields.find(element => element === 'city') && this.state.city.length === 0) &&
                    <div className="flex w-full">
                      <p className="text-red-500">
                        Campo requerido
                      </p>
                    </div>
                  }
                </div>
                <QuestionWithOptions
                  setValueToTrue={() => {
                    this.setState({ type: 1 })
                  }}
                  setValueToFalse={() => this.setState({ type: 0 })}
                  title='¿Eres asesor inmobiliario?'
                  value={this.state.type === 1}
                />
                {
                  this.state.type === 1 &&
                  <QuestionWithOptions
                    setValueToTrue={() => {
                      this.setState({ hasAgency: true });
                    }}
                    setValueToFalse={() => this.setState({ hasAgency: false })}
                    title='¿Perteneces a una inmobiliaria?'
                    value={this.state.hasAgency}
                  />
                }
                {
                  this.state.type === 1 && this.state.hasAgency &&
                  <AgencyForm
                    emptyField={this.state.requiredFields.find(element => element === 'agencyName') && this.state.agencyName.length === 0}
                    agencyLogoUri={this.state.agencyLogo}
                    agencyName={this.state.agencyName}
                    changeAgencyName={agencyName => this.setState({ agencyName })}
                    changeImage={this.changeAgencyLogo}
                    deleteImage={() => this.deleteAgencyLogo()}
                  />
                }
                {
                  this.state.type === 1 &&
                  <ExperienceAreasForm
                    emptyField={this.state.requiredFields.find(element => element === 'experienceAreas') && this.state.experienceAreas.length === 0}
                    changeExperienceArea={value => this.changeExperienceArea(value)}
                    experienceAreas={this.state.experienceAreas}
                  />
                }
              </React.Fragment>
            }
            {
              this.state.isRegister &&
              <div className="mt-5">
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={this.onAceptCaptcha}
                />
              </div>
            }
              <LoadingModal visible={this.state.disabledButton} />
              <div className="flex flex-row items-center justify-center w-full">
                {
                  !this.state.isRegister ?
                  <button onClick={() => this.submitForm()} className="redaria-button-blue mt-10 mb-10">Continuar</button>
                  :
                  <button onClick={() => this.sendDataCreate()} className="redaria-button-blue mt-10 mb-10">Siguiente</button>
                
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(SigIn);
