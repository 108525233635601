import React, { PureComponent } from 'react'
import { CameraPlusNormal } from '../../lib/Icon/icons/Camera';
import { CloseCircleBlue } from '../../lib/Icon/icons/Close';
import { InfoBlue, InfoRed } from '../../lib/Icon/icons/Info';
export default class EditImagesForm extends PureComponent {

  state = {
    dragEnter: false,
  }

  hiddenFileInput = React.createRef()

  handleClick = event => {
    this.hiddenFileInput.current.click();
  };

  dragOver = (e) => {
    e.preventDefault();
  }

  dragEnter = (e) => {
    this.setState({dragEnter: true})
    e.preventDefault();
  }

  dragLeave = (e) => {
    this.setState({dragEnter: false})
    e.preventDefault();
  }

  fileSelect = (e) => {
    e.preventDefault();
    const files = e.target.files
    this.props.addImage(files,this.hiddenFileInput)
  }
  fileDrop = (e) => {
    e.preventDefault();
    this.setState({dragEnter: false})
    const files = e.dataTransfer.files;
    this.props.addImage(files)
  }

  render() {
    const {imagesError} = this.props

    const files = Array.from(this.props.images)
    const imagesUrl = Array.from(this.props.imagesUrl)
    const newFiles = Array.from(this.props.newImages)
    
    return (
      <div className="rounded-lg padding-5 mt-10 mx-2">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 21 }}>
          Imágenes
        </p>
        {
          this.props.emptyField &&
          <span className="text-red-500">
            {`\nCampo requerido`}
          </span>
        }

        {
          files.length > 0 &&
          <div className="flex relative flex-row bg-gray-200 w-11/12 h-24" style={{ borderRadius:9,marginTop:10,marginBottom:15 }}>
            <div className="bg-redaria-blue absolute left-0 top-0 bottom-0" style={{width:8,borderTopLeftRadius:9,borderBottomLeftRadius:9}}/>
            <div className="flex flex-row py-5 px-10 items-center">
              <div >
                  <InfoBlue/>
              </div>
              <div className="">
                <p className="questrial text-redaria-black pl-5" style={{ fontSize:18 }}>
                  {`La primera foto será la portada de tu propiedad. Asegúrate de no agregar información de contacto, logos ni marcas de agua en las imágenes.`}
                </p>  
              </div>

            </div>
          </div>
        }
        {
          imagesError > 0 &&
          <div className="flex relative flex-row bg-gray-200 w-11/12 h-24" style={{ borderRadius:9,marginTop:10,marginBottom:15 }}>
            <div className="bg-red-500 absolute left-0 top-0 bottom-0" style={{width:8,borderTopLeftRadius:9,borderBottomLeftRadius:9}}/>
            <div className="flex flex-row py-5 px-10 items-center">
              <div >
                <InfoRed/>
              </div>
              <div className="">
                <p className="questrial text-redaria-black ml-5" style={{ fontSize:18 }}>
                  {`${imagesError} ${imagesError === 1 ? 'foto' : 'fotos'} no ${imagesError === 1 ? 'cumplió' : 'cumplieron'} con los requisitos. Deben ser en formato JPG, JPEG o PNG.`}
                </p>  
              </div>

            </div>
          </div>
        }

        {
          (files.length + newFiles.length + imagesUrl.length) < 10 &&
          <div
            onClick={this.handleClick}
            className="flex w-11/12 flex-col items-center justify-center relative cursor-pointer"
            style={{ backgroundColor: '#DEDEDE', border: "1px dashed #223242", borderRadius: 10, height: 181 }}
            onDragOver={this.dragOver}
            onDragEnter={this.dragEnter}
          >
            {
              this.state.dragEnter &&
            <div
            onDragOver={this.dragOver}
            onDragEnter={this.dragEnter}
            onDragLeave={this.dragLeave}
            onDrop={this.fileDrop}
            className="absolute left-0 right-0 bottom-0 top-0" style={{ borderRadius:10, backgroundColor:"rgba(0, 0, 0, 0.2)" }}>

            </div>
            }
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row my-2 cursor-pointer">

                  <div className="flex items-center justify-center">
                    <div className="flex flex-row">
                      <CameraPlusNormal style={{ width:24,height:25, marginRight:10 }}/>
                      <p className="questrial text-redaria-black" style={{ fontSize: 21 }}>
                        Arrastra tus imágenes aquí o presiona el siguiente botón
                      </p>
                    </div>
                  </div>
                </div>
                <button className="questrial" style={{ fontSize: 20, backgroundColor: '#BFBEBE', borderRadius: 7, boxShadow: "0px 0px 5px #00000084", paddingTop: 12, paddingBottom: 12, paddingRight: 16, paddingLeft: 16 }}>Seleccionar Archivo</button>
              </div>
            
          </div>
        }

        <input 
        ref={this.hiddenFileInput}
        hidden
        multiple
        type="file"
        accept="image/*"
        onChange={this.fileSelect}
        maxLength="10"/>

        <div className="flex flex-wrap mt-5">
          {
            files.map((img, i) =>
              <div className="flex relative flex-col items-center my-2 mr-5" key={`ImagesForm-${i}`} >
                <div className="bg-white shadow-lg" style={{ borderRadius:10,height:101,width:107 }}>
                  <img alt="upload shadow-lg" style={{ height:101,width:107,borderRadius:10, }} src={img.uri} />
                </div>
                <div onClick={() => this.props.deleteImage(i)} className="absolute -top-6 -right-6 cursor-pointer">
                  <CloseCircleBlue />
                </div>
                {
                  i === 0 &&
                  <p className="questrial text-redaria-black" style={{ fontSize:21 }} >Portada*</p>
                }
              </div>
            )
          }
          {
             newFiles.map( (img,i) => 
             <div className="flex relative flex-col items-center my-2 mr-5" key={`ImagesForm-${i}`} >
                <div className="bg-white shadow-lg" style={{ borderRadius:10,height:101,width:107 }}>
                  <img alt="upload shadow-lg" style={{ height:101,width:107,borderRadius:10, }} src={URL.createObjectURL(img)} />
                </div>
                <div onClick={() => this.props.deleteNewImage(i)} className="absolute -top-6 -right-6 cursor-pointer">
                  <CloseCircleBlue />
                </div>
                {
                  (i === 0 && files.length === 0) &&
                  <p className="questrial text-redaria-black" style={{ fontSize:21 }} >Portada*</p>
                }
              </div>)
          }
        </div>
        {
          imagesUrl.length > 0 &&
          <>
            <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 21 }}>
              Imágenes externas
            </p>
            <div className="flex flex-wrap mt-5">
            {
              imagesUrl.map( (img,i) => 
              <div className="flex relative flex-col items-center my-2 mr-5" key={`ImagesForm-${i}`} >
                <div className="bg-white shadow-lg" style={{ borderRadius:10,height:101,width:107 }}>
                  <img alt="upload shadow-lg" style={{ height:101,width:107,borderRadius:10, }} src={img.uri} />
                </div>
                <div onClick={() => this.props.deleteImageUrl(i)} className="absolute -top-6 -right-6 cursor-pointer">
                  <CloseCircleBlue />
                </div>
                {
                  (i === 0 && files.length === 0) &&
                  <p className="questrial text-redaria-black" style={{ fontSize:21 }} >Portada*</p>
                }
              </div>)
            }
            </div>
          </>
        }

      </div>
    )
  }
}
