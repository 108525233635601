import React, {PureComponent} from 'react';

export default class ExperienceArea extends PureComponent {
  render() {
    let containerStyle = "flex items-center justify-center w-24"
    containerStyle += this.props.isActive 
      ? " redaria-button-blue border-redaria-white" 
      : " redaria-button-white border-redaria-blue";
    
    return(
      <div className={containerStyle} onClick={this.props.onPress} style={{ width:103 }} >
        <span style={{ fontSize:12 }}>{this.props.text}</span>
      </div>
    );
  }
}

