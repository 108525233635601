import React, { PureComponent } from 'react';
import { Chimney } from '../../lib/Icon/icons/Chimney';
import { Grill } from '../../lib/Icon/icons/Grill';
import { Pool } from '../../lib/Icon/icons/Pool';
import { Yard } from '../../lib/Icon/icons/Yard';
import { Palapa } from '../../lib/Icon/icons/Palapa';
import IconButton from './IconButton';
import { Gym } from '../../lib/Icon/icons/Gym';

export class AdditionalAmmenities extends PureComponent {
  render() {
    if (!this.props.active) {
      return null
    }
    return (
      <div className="mt-5" style={{ marginBottom:59 }}>

        <div className="flex flex-row flex-wrap">
          <IconButton
            id={16}
            label='Alberca'
            active={this.props.ammenities.find(ammenity => ammenity.id === 16) !== undefined}
            icon={<Pool className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 16) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />
          <IconButton
            id={17}
            label='Asador'
            active={this.props.ammenities.find(ammenity => ammenity.id === 17) !== undefined}
            icon={<Grill className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 17) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />
          <IconButton
            id={18}
            label='Jardín'
            active={this.props.ammenities.find(ammenity => ammenity.id === 18) !== undefined}
            icon={<Yard className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 18) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />
          <IconButton
            id={19}
            label='Chimenea'
            active={this.props.ammenities.find(ammenity => ammenity.id === 19) !== undefined}
            icon={<Chimney className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 19) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />
          <IconButton
            id={20}
            label='Palapa'
            active={this.props.ammenities.find(ammenity => ammenity.id === 20) !== undefined}
            icon={<Palapa className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 20) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />
          <IconButton
            id={21}
            label='Gimnasio privado'
            active={this.props.ammenities.find(ammenity => ammenity.id === 21) !== undefined}
            icon={<Gym className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 21) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleAmmenity}
          />

        </div>
      </div>
    );

  }
}
