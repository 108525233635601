import React, {PureComponent} from 'react';
import _ from 'lodash';
import { TextInputWithPlaceholder } from '../Forms/TextInputWithPlaceholder';
import Add from '../../lib/Icon/icons/Add';
import TimesCircle from '../../lib/Icon/icons/TimesCircle';
import { CameraPlus } from '../../lib/Icon/icons/Camera';

export default class EditCertificatesForm extends PureComponent {
  
  render() {
    return(
      <div className="mt-5">
        
        <p className="poppins-regular subtitle text-redaria-black">Certificados <span className="subtitle">(Opcional)</span></p>
        <div className="flex flex-col">
          {
            this.props.certificates.map((certificate, index) => (
              <div key={index} className="flex flex-row items-center mt-2">
                <div className="cursor-pointer"
                onClick={() => this.props.deleteCertificate(index)}
                >
                  <TimesCircle />
                  
                </div>
                <div className="cursor-pointer" 
                  onClick={() => this.props.changeCertificateImage(index)}
                  >
                  {
                    (certificate.file.uri || certificate.file.name)
                    ?
                    (
                      certificate.file.uri
                      ?
                      <img alt={`current-certificate-${index}`} className="mr-4 ml-2 " style={{ width:50,height:50,borderRadius:25 }} src={ certificate.file.uri}/>
                      :
                      <img alt={`current-certificate-${index}`} className="mr-4 ml-2 " style={{ width:50,height:50,borderRadius:25 }} src={ URL.createObjectURL(certificate.file)}/>
                    )
                    :
                    <CameraPlus className="w-14 mx-2"/>
                     
                  }
                  
                </div>
                <div style={{width: '65%'}}>
                  <TextInputWithPlaceholder
                    removeContainer
                    maxLength={50}
                    onChangeText={value => this.props.changeCertificateName(index, value)}
                    placeholder='Nombre de certificado*'
                    value={certificate.name}
                  />
                  <TextInputWithPlaceholder
                    removeContainer
                    maxLength={50}
                    onChangeText={value => this.props.changeCertificateSerialNumber(index, value)}
                    placeholder='Núm. de certificado (Opcional)'
                    value={certificate.serialNumber}
                  />
                </div>
              </div>
            ))
          }
          {
            this.props.newCertificates.map((certificate, index) => (
              <div key={index} className="flex flex-row items-center mt-2">
                <div className="cursor-pointer" 
                onClick={() => this.props.deleteNewCertificate(index)}
                >
                  <TimesCircle />
                </div>
                <div className="cursor-pointer" 
                onClick={() => this.props.changeNewCertificateImage(index)}
                >
                  
                  {
                    !certificate.file.name
                    ?
                    <CameraPlus className="w-14 mx-2"/>
                    : 
                    <img alt="agency" className="mr-4 ml-2 " style={{ width:50,height:50,borderRadius:25 }} src={ URL.createObjectURL(certificate.file)}/>
                  }
                  {
                    (this.props.emptyFieldImage && _.isEmpty(certificate.file)) &&
                    <span className="text-red-500 text-xs text-center">
                      Campo requerido
                    </span>
                  }
                  
                </div>

                <div className="">
                  <TextInputWithPlaceholder
                    removeContainer
                    maxLength={50}
                    onChangeText={value => this.props.changeNewCertificateName(index, value)}
                    placeholder='Nombre de certificado*'
                    value={certificate.name}
                  />
                  {
                      (this.props.emptyFieldName && certificate.name.length ===0) &&
                      <div className="text-red-500">
                        Campo requerido
                      </div>
                    }
                  <TextInputWithPlaceholder
                    removeContainer
                    maxLength={50}
                    onChangeText={value => this.props.changeNewCertificateSerialNumber(index, value)}
                    placeholder='Núm. de certificado'
                    value={certificate.serialNumber}
                  />
                </div>
              </div>
            ))
          }
          {
            this.props.newCertificates.length + this.props.certificates.length < 3 &&
            <div className="max-w-xs flex items-center justify-center">
              <div className="redaria-button-blue mt-5" onClick={this.props.addCertificate}>
                <p className="questrial text-white text-sm mr-2" style={{ fontSize:24 }}>Agregar</p>
                <Add fill="#fff" className="w-5"/>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

