import React from 'react';

export function Elevator({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 21 25" className={className ? className : "w-5"}>
      <g id="elevator_svg__prefix__elevator" transform="translate(-10.5)">
        <path
          id="elevator_svg__prefix__Trazado_23796"
          d="M30.665 0h-19.83a.335.335 0 00-.335.335v23.853a.335.335 0 00.335.335h19.83a.335.335 0 00.335-.335V.335A.335.335 0 0030.665 0zM12.7 23.853V5.773h5.244v18.08zm5.915 0V5.773h5.244v18.08zm11.711 0h-5.8V5.437A.335.335 0 0024.2 5.1H12.368a.335.335 0 00-.335.335v18.418h-.862V.671h19.158z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23796"
        />
        <path
          id="elevator_svg__prefix__Trazado_23797"
          d="M18.835 10.806H35.6a.335.335 0 00.335-.335V8.459a.335.335 0 00-.335-.335H18.835a.335.335 0 00-.335.335v2.012a.335.335 0 00.335.335zm.335-2.012h16.094v1.341H19.171z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23797"
          transform="translate(-6.467 -6.568)"
        />
        <path
          id="elevator_svg__prefix__Trazado_23798"
          d="M91.986 26.631h-3.4a.335.335 0 00-.335.335v3.4a.335.335 0 00.335.335h3.4a.335.335 0 00.335-.335v-3.4a.335.335 0 00-.335-.335zm-.335 3.4h-2.73V27.3h2.73z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23798"
          transform="translate(-62.854 -21.529)"
        />
        <path
          id="elevator_svg__prefix__Trazado_23799"
          d="M91.986 52.388h-3.4a.335.335 0 00-.335.335v3.4a.335.335 0 00.335.335h3.4a.335.335 0 00.335-.335v-3.4a.335.335 0 00-.335-.335zm-.335 3.4h-2.73v-2.73h2.73z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23799"
          transform="translate(-62.854 -42.351)"
        />
        <path
          id="elevator_svg__prefix__Trazado_23800"
          d="M95.151 34.943h.885a.335.335 0 00.29-.5l-.442-.766a.335.335 0 00-.581 0l-.442.766a.335.335 0 00.29.5z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23800"
          transform="translate(-68.162 -27.087)"
        />
        <path
          id="elevator_svg__prefix__Trazado_23801"
          d="M95.3 60.532a.335.335 0 00.581 0l.442-.766a.335.335 0 00-.29-.5h-.885a.335.335 0 00-.29.5z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23801"
          transform="translate(-68.162 -47.909)"
        />
        <path
          id="elevator_svg__prefix__Trazado_23802"
          d="M91.986 78.144h-3.4a.335.335 0 00-.335.335v1.53a.335.335 0 00.335.335h3.4a.335.335 0 00.335-.335v-1.53a.335.335 0 00-.335-.335zm-.335 1.53h-2.73v-.86h2.73z"
          className="elevator_svg__prefix__cls-1"
          data-name="Trazado 23802"
          transform="translate(-62.854 -63.172)"
        />
      </g>
    </svg>
  )
}
