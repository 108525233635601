import AuthenticatedApi from './AuthenticatedApi';

export default class CertificatesApi {
  static create(params) {
    let headerParams = {'Content-Type': 'multipart/form-data'};
    return AuthenticatedApi.post('/api/me/certificates', headerParams, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static update(id, params) {
    let headerParams = {'Content-Type': 'multipart/form-data'};
    return AuthenticatedApi.post(`/api/me/certificates/${id}`, headerParams, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static delete(id) {
    return AuthenticatedApi.delete(`/api/me/certificates/${id}`, {}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }
}
