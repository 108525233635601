import React from 'react';

export function DownArrow({ className }) {
  return (
    <svg viewBox='0 0 11 6' stroke="#243443" className={className ? className : "w-5"}>
      <path
        d="M1.061 1.061l4.493 4.493 4.494-4.493"
        data-name="Trazado 5591"
        fill="none"

        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}
export function LeftArrow(props) {
  return (
    <svg viewBox='0 0 24 24' stroke="#243443" fill="#243443" {...props}>
      <path id="a" d="M20.889 10.786H6.11c-.614 0-1.111.506-1.111 1.13 0 .625.497 1.132 1.111 1.132H20.89c.614 0 1.111-.507 1.111-1.131 0-.625-.497-1.131-1.111-1.131zM10.563 5.93a1.145 1.145 0 0 0 0-1.6 1.097 1.097 0 0 0-1.57 0l-6.668 6.786a1.145 1.145 0 0 0 0 1.6l6.667 6.785a1.097 1.097 0 0 0 1.571 0 1.145 1.145 0 0 0 0-1.6l-5.88-5.985 5.88-5.986z" />
    </svg>
  )
}

export function ArrowWithCircleLeft(props) {
  return (
    <svg
      viewBox="0 0 52.996 210.512"
      {...props}>
      <path
        d="M52.998 210.512a131.568 131.568 0 01-38.555-45.406 130.268 130.268 0 01-10.681-28.564 132.059 132.059 0 010-62.566 130.638 130.638 0 0127.39-53.52A131.865 131.865 0 0153 .003z"
        fill="#152232"
        opacity={0.406}
      />
      <path
        d="M26.498 96.057l9.29 9.291-9.29 9.293"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </svg>
  )
}
export function ArrowWithCircleRight(props) {
  return (
    <svg
      viewBox="0 0 52.996 210.512"
      {...props}
    >
      <path
        d="M-.002 0a131.568 131.568 0 0138.555 45.406A130.268 130.268 0 0149.234 73.97a132.059 132.059 0 010 62.566 130.638 130.638 0 01-27.39 53.52A131.865 131.865 0 01-.004 210.509z"
        fill="#152232"
        opacity={0.407}
      />
      <path
        d="M26.498 114.455l-9.29-9.291 9.29-9.293"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </svg>
  )
}