import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { saveUser } from '../../actions/authenticatedUser'
import UserRequest from '../../api/UserRequest'
import AgencyForm from '../../components/EditProfile/AgencyForm'
import EditCertificatesForm from '../../components/EditProfile/EditCertificatesForm'
import ExperienceAreasForm from '../../components/EditProfile/ExperienceAreasForm'
import ProfilePictureForm from '../../components/EditProfile/ProfilePictureForm'
import QuestionWithOptions from '../../components/EditProfile/QuestionWithOptions'
import { AddressDropdown } from '../../components/Forms/AddressDropdown'
import { TextInputWithPlaceholder } from '../../components/Forms/TextInputWithPlaceholder'
import LoadingModal from '../../components/Loading/LoadingModal'
import { getCitiesFromState } from '../../lib/Address'
import { ErrorAlert, InformationAlert } from '../../lib/alerts'
import { EditProfileSubmit } from '../../lib/FormSubmit'
import States from '../../lib/States'

class EditProfile extends PureComponent {
  state = {
    agencyLogo: {},
    agencyName: '',
    certificates: [],
    city: '',
    deletedLogo: false,
    deleteCertificates: [],
    disabledButton: true,
    email: '',
    experienceAreas: [],
    hasAgency: false,
    newCertificates: [],
    phone: '',
    profilePicture: {},
    state: '',
    type: 0,
    name: '',
    isImagePickerOptionsModalVisible: false,
    loadingSubmit:false,
    requiredFields:[],
    loadingProfile:true,
    changeCertificateType:'new',
    indexCertificate:0
  }

  hiddenFileInput = React.createRef()

  componentDidMount() {
    this.getProfile();
  }
  getProfile() {
    UserRequest.profile().then(result => {
      this.setStateFromRequest(result.data);
    }).catch(error => {
      console.log(error);
    });
  }

  getProfilePicture(url) {
    if(url === '') {
      return {};
    }
    else {
      return {uri: url};
    }
  }

  setStateFromRequest(data) {
    const profilePicture = this.getProfilePicture(data.attributes.photoUrl);
    const type = this.getType(data.attributes.type);
    const hasAgency = data.attributes.hasAgency === 1 ? true : false;
    const certificates = this.getCertificates(data.attributes.certificates.data);

    let stateObject = {
      email: data.attributes.email,
      name: data.attributes.name? data.attributes.name.trim() : '',
      profilePicture: profilePicture,
      phone: data.attributes.phone,
      type: type,
      state: data.attributes.state ? data.attributes.state : '',
      city: data.attributes.city ? data.attributes.city : '',
      hasAgency: hasAgency,
      loginType: data.attributes.loginType,
      certificates: certificates,
      loadingProfile:false,
      disabledButton:false
    };

    if(type === 1) {
      const experienceAreas = this.getExperienceAreas(data.attributes.experienceAreas);
      stateObject = {...stateObject, ...{experienceAreas: experienceAreas}};
    }
    if(hasAgency) {
      const agencyName = data.attributes.agencyName;
      const agencyLogo = this.getAgencyLogo(data.attributes.agencyImageUrl);
      stateObject = {...stateObject, ...{agencyName: agencyName, agencyLogo: agencyLogo}};
    }

    this.setState(stateObject);
  }

  getCities = () => {
    if (this.state.state === '') {
      return [];
    }
    else {
      return getCitiesFromState(this.state.state);
    }
  }

  addCertificate() {
    this.setState(prevState => {
      if(prevState.newCertificates.length + prevState.certificates.length < 3) {
        return {
          newCertificates: [...prevState.newCertificates, {file: {}, name: '', serialNumber: ''}]
        };
      }
    });
  }

  changeCertificateImage(index) {
    
    this.setState({changeCertificateType:'current',indexCertificate:index}, () =>{
      this.hiddenFileInput.current.click();
    })
    // pickImage(this.props.navigation).then(image => {
    //   certificates[index].file = image;

    //   this.setState({
    //     certificates: certificates
    //   });
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  changeNewCertificateImage(index,e) {
    this.setState({changeCertificateType:'new',indexCertificate:index}, () =>{
      this.hiddenFileInput.current.click();
    })
    // let certificates = [...this.state.newCertificates];
    
    // certificates[index].file = e.target.files[0];

    // this.setState({
    //   newCertificates: certificates
    // });

  }

  _handleFile = e => {
    if(this.state.changeCertificateType === 'new')
    {
      let certificates = [...this.state.newCertificates];
  
      certificates[this.state.indexCertificate].file = e.target.files[0];

      this.setState({
        newCertificates: certificates
      });
    }
    if(this.state.changeCertificateType === 'current')
    {
      let certificates = [...this.state.certificates];

      certificates[this.state.indexCertificate].file = e.target.files[0];
      this.setState({
        certificates: certificates
      });
    }
    
  }

  deleteCertificate(index) {
    let certificates = [...this.state.certificates];
    const certificateId = {...this.state.certificates[index]}.id;

    certificates.splice(index, 1);

    this.setState(prevState => ({
      certificates: certificates,
      deleteCertificates: [...new Set([...prevState.deleteCertificates, certificateId])]
    }));
  }

  deleteNewCertificate(index) {
    let certificates = [...this.state.newCertificates];
    certificates.splice(index, 1);

    this.setState({
      newCertificates: certificates
    });
  }
  changeCertificateName(index, text) {
    let certificates = [...this.state.certificates];
    certificates[index].name = text;

    this.setState({
      certificates: certificates
    });
  }
  changeNewCertificateName(index, text) {
    let certificates = [...this.state.newCertificates];
    certificates[index].name = text;

    this.setState({
      newCertificates: certificates
    });
  }
  changeNewCertificateSerialNumber(index, text) {
    let certificates = [...this.state.newCertificates];
    certificates[index].serialNumber = text;

    this.setState({
      newCertificates: certificates
    });
  }
  changeCertificateSerialNumber(index, text) {
    let certificates = [...this.state.certificates];
    certificates[index].serialNumber = text;

    this.setState({
      certificates: certificates
    });
  }

  changeAgencyLogo = (image) => {
    this.setState({agencyLogo: image});
  }
  deleteAgencyLogo() {
    this.setState({
      agencyLogo: {},
      deletedLogo: true
    });
  }

  changeExperienceArea(value) {
    let experienceAreas = [...this.state.experienceAreas];

    if(experienceAreas.includes(value)) {
      this.setState({
        experienceAreas: experienceAreas.filter(experienceArea => experienceArea !== value)
      });
    }
    else {
      this.setState({
        experienceAreas: [...experienceAreas, value]
      });
    }
  }


  getType(type) {
    switch(type) {
      case 'Usuario particular':
        return 0;
      case 'Asesor':
        return 1;
      default:
        return 0;
    }
  }
  getExperienceAreas(experienceAreas) {
    return Array.from(experienceAreas, experienceArea => {
      return experienceArea.id;
    });
  }
  getAgencyLogo(url) {
    if(url === '') {
      return {};
    }
    else {
      return {uri: url};
    }
  }
  getCertificates(certificates) {
    return Array.from(certificates, certificate => ({
      id: certificate.id,
      name: certificate.name,
      serialNumber: certificate.serialNumber ? certificate.serialNumber : '',
      file: {
        id: certificate.image[0].id,
        uri: certificate?.image[0]?.path,
      }
    }));
  }

  submitForm() {
    const data = this.parsedData();

    this.setState({
      disabledButton: true
    }, () => {
      EditProfileSubmit(
        data,
        requiredFields => this._setRequiredFields(requiredFields),
        formData => this.sendData(formData)
      );
    });
  }

  _setRequiredFields = requiredFields => {
    this.setState({ disabledButton: false,requiredFields})
  }

  parsedData() {
    return {
      deletedLogo: this.state.deletedLogo,
      profilePicture: this.state.profilePicture,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      state: this.state.state,
      type: this.state.type,
      city: this.state.city,
      hasAgency: this.state.hasAgency,
      experienceAreas: this.state.experienceAreas,
      agencyName: this.state.agencyName,
      agencyLogo: this.state.agencyLogo,
      deleteCertificates: this.state.deleteCertificates,
      newCertificates: this.state.newCertificates,
      certificates: this.state.certificates
    };
  }

  sendData(data) {
    this.setState({loadingSubmit:true}, () =>{
      return UserRequest.updateProfile(data).then(result => {
        this.setState({loadingSubmit:false});
        this.props.dispatch(saveUser(result.data.data));
        const finish = () => {
          this.setState({disabledButton:false},()=>{
            this.props.history.push('/signed/me/properties')
          })
        }
        InformationAlert('Datos actualizados','',
        ()=>{finish()},
        ()=>{finish()}
        )
      }).catch(error => {
        this.setState({disabledButton: false,loadingSubmit:false});
        ErrorAlert(
          'Hubo un error. Intena de nuevo más tarde',
          () => {console.log(error)}
        );
      });
    })
  }

  render() {
    return (
      <div className="mt-5 flex flex-col">
        <ProfilePictureForm
          changeProfilePicture={(image) => this.setState({profilePicture:image})}
          profilePictureUri={this.state.profilePicture}
          emptyField={this.state.requiredFields.find(element => element === 'profilePicture')}
        />
        <TextInputWithPlaceholder
          placeholder="Nombre*"
          removeContainer
          onChangeText={name => this.setState({name})}
          value={this.state.name}
        />
        <TextInputWithPlaceholder
          placeholder="Correo eléctronico*"
          removeContainer
          onChangeText={email => this.setState({email})}
          value={this.state.email}
        />
        <TextInputWithPlaceholder
          placeholder="Teléfono*"
          removeContainer
          onChangeText={phone => this.setState({phone:phone.trim()})}
          value={this.state.phone}
        />
        <div className="mt-2">
          <AddressDropdown
            data={States}
            onSelectOption={ state => this.setState({state})}
            modalTitle='Selecciona un Estado'
            value={this.state.state}
          />
          {
            (this.state.requiredFields.find(element => element === 'state') && this.state.state.length === 0) &&
            <p className="text-red-500">
              Campo requerido
              </p>
          }
        </div>
        <div className="mt-2">
          <AddressDropdown
            data={this.getCities()}
            onSelectOption={city => this.setState({city})}
            placeholder='Ciudad'
            modalTitle='Selecciona una Ciudad'
            value={this.state.city}
          />
          {
            (this.state.requiredFields.find(element => element === 'city') && this.state.city.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }
        </div>
        <QuestionWithOptions
          setValueToTrue={() => {
            this.setState({ type: 1 })
          }}
          setValueToFalse={() => this.setState({ type: 0 })}
          title='¿Eres asesor inmobiliario?'
          value={this.state.type === 1}
        />
        {
          this.state.type === 1 &&
          <div>
            <EditCertificatesForm
              emptyFieldName={this.state.requiredFields.find(element => element === 'certificateName')}
              emptyFieldImage={this.state.requiredFields.find(element => element === 'certificateImage')}
              addCertificate={() => this.addCertificate()}
              certificates={this.state.certificates}
              changeCertificateImage={index => this.changeCertificateImage(index)}
              changeNewCertificateImage={index => this.changeNewCertificateImage(index)}
              changeCertificateName={(index, text) => this.changeCertificateName(index, text)}
              changeNewCertificateName={(index, text) => this.changeNewCertificateName(index, text)}
              changeCertificateSerialNumber={(index, text) => this.changeCertificateSerialNumber(index, text)}
              changeNewCertificateSerialNumber={(index, text) => this.changeNewCertificateSerialNumber(index, text)}
              deleteCertificate={index => this.deleteCertificate(index)}
              deleteNewCertificate={index => this.deleteNewCertificate(index)}
              newCertificates={this.state.newCertificates}
            />
            <input 
              ref={this.hiddenFileInput}
              hidden
              type="file"
              accept="image/*"
              onChange={this._handleFile}
              maxLength="1"/>
          </div>
        }
        {
          this.state.type === 1 &&
          <QuestionWithOptions
            setValueToTrue={() => {
              this.setState({hasAgency: true});
            }}
            setValueToFalse={() => this.setState({hasAgency: false})}
            title='¿Perteneces a una inmobiliaria?'
            value={this.state.hasAgency}
          />
        }
        {
          this.state.type === 1 && this.state.hasAgency &&
          <AgencyForm
            emptyField={this.state.requiredFields.find(element => element === 'agencyName') && this.state.agencyName.length===0}
            agencyLogoUri={this.state.agencyLogo}
            agencyName={this.state.agencyName}
            changeAgencyName={agencyName => this.setState({agencyName})}
            changeImage={this.changeAgencyLogo}
            deleteImage={() => this.deleteAgencyLogo()}
          />
        }
        {
          this.state.type === 1 &&
          <ExperienceAreasForm
            emptyField={this.state.requiredFields.find(element => element === 'experienceAreas') && this.state.experienceAreas.length === 0}
            changeExperienceArea={value => this.changeExperienceArea(value)}
            experienceAreas={this.state.experienceAreas}
          />
        }
        <LoadingModal visible={this.state.disabledButton}/>
        <div className="flex flex-row items-center justify-center">
          <button  onClick={() => this.submitForm()} className="redaria-button-blue mt-10 self-center mb-10">Continuar</button>
        </div>
      </div>
    )
  }
}

export default connect()(EditProfile);
