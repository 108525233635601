import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import UserRequest from '../../api/UserRequest';
import Input from '../../components/Login/Input';
import SecureLS from 'secure-ls';
import PremierApi from '../../api/PremierApi';
import { useDispatch, useSelector } from 'react-redux';
import { setPremier } from '../../actions/premier';
import { saveUser } from '../../actions/authenticatedUser';
import { ErrorAlert } from '../../lib/alerts/ErrorAlert';
import LoadingModal from '../Loading/LoadingModal';
import ReCAPTCHA from "react-google-recaptcha";


export default function Login() {

  
  const history = useHistory();
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)

  
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [captcha,setCaptcha] = useState(null)

  const [validate,setValidate] = useState(false)

  const recaptchaRef = useRef()

  const _login = () => {

    let errors = []

    if(email.length ===0)
      errors.push('Debes ingresar un email')
    if(password.length ===0)
      errors.push('Debes ingresar una contraseña')

    if(!captcha)
      errors.push('Debes completar el captcha')
    if(errors.length > 0)
    {
      ErrorAlert(errors, () => { setValidate(true) });
      return
    }
    setModalVisible(true)
    UserRequest.login(email, password,captcha)
      .then((result) => result.data)
      .then((data) => {
        const ls = new SecureLS();
        ls.set(process.env.REACT_APP_USER_AUTH_KEY,data.access_token)
      })
      .then(() => UserRequest.profile())
      .then((result) => PremierApi.currentLicense()
        .then(responsePremier => dispatch(setPremier(responsePremier)))
        .then(() => {
          dispatch(saveUser(result.data))
        })
        .then(() => result.data.attributes)
      ).then(user => {
        const ls = new SecureLS();
        ls.set(process.env.REACT_APP_USER_PROFILE_KEY,String(user.needsProfileInformation))
      })
      .then( result => {
        setModalVisible(false)
        history.push('/signed/me/properties')
      })
      .catch((error) => {
        recaptchaRef.current?.reset()
        setCaptcha(null)
        setModalVisible(false)
        ErrorAlert('Los datos que ingresaste no son válidos')
        console.log(error)
      });
  }

  const user = useSelector(state => state.authenticatedUser)


  const _handleLogin = () => {
    if(user.attributes){
      history.push('/signed/me/properties');
    }
  }

  useEffect(_handleLogin, [user,history])

  const goToResetPassword = () => {
    history.push('/recoverPassword')
  }

  const onAceptCaptcha = (captcha) => {
    setCaptcha(captcha)
  }


  return (
    <div className="flex flex-1 flex-col w-10/12">
      <div className="flex flex-1 justify-center items-center flex-col">
      
          <Input labelID="l-1" name="Correo electrónico" onChange={setEmail} value={email} />
          {
            (validate && email.length === 0) && <span className="text-red-500">Campo requerido</span>
          }
          <Input labelID="l-2" name="Contraseña" onChange={setPassword} value={password} type="password"/>
          {
            (validate && password.length === 0) && <span className="text-red-500">Campo requerido</span>
          }

          <div className="mt-5">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onChange={onAceptCaptcha}
            />
            {
            (validate && !captcha) && <span className="text-red-500">Campo requerido</span>
            }
          </div>

        <button onClick={_login} className="redaria-button-blue mt-10">Continuar</button>
        <p onClick={goToResetPassword} className="link text-redaria-black questrial text-center" style={{ fontSize:19,marginTop:26 }}>
          ¿Olvidaste tu contraseña?
          <br/>
          <span className="poppins-medium" style={{ fontSize:17 }}>Cambiar contraseña</span>
        </p>

        <div className="mt-5 w-10/12" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>

      </div>
      
      <LoadingModal visible={modalVisible}/>





    </div>
  )
}
