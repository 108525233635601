// Action types
export const SET_SHOW_WELCOME = 'SET_SHOW_WELCOME';

// Actions
export const setStatusWelcome = (show) => {
  return {
    type: SET_SHOW_WELCOME,
    value:{
      show
    }
  };
};

