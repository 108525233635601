import React, { PureComponent } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { SignedContainer } from '../App'
import Property from '../containers/Property/Property'
import EditProfile from '../containers/EditProfile/EditProfile'
import EditProperty from '../containers/EditProperty/EditProperty'
import MeContainer from '../containers/MeContainer/MeContainer'
import NewPropertyContainer from '../containers/NewPropertyContainer/NewPropertyContainer'
import Profile from '../containers/Profile/Profile'
import LogoRedaria from '../assets/images/icon_redaria.png'
import Archive from '../lib/Icon/icons/Archive'
import SecureLS from 'secure-ls';
import { deleteUser } from '../actions/authenticatedUser'
import { clearPremier } from '../actions/premier'
import { connect } from 'react-redux'
import LoadingScreen from '../components/Loading/LoadingScreen'
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions'
import PrivacyPolicies from '../components/PrivacyPolicies/PrivacyPolicies'
import { Exit } from '../lib/Icon/icons/Exit'

class LoginStack extends PureComponent {

  state = {
    loading:false,
  }  

  goHome = () => {
    this.props.history.push('/signed/me/properties')
  }

  signOut = () => {
    this.setState({loading:true}, () =>{
      const ls = new SecureLS();
      ls.remove(process.env.REACT_APP_USER_AUTH_KEY)
      ls.remove(process.env.REACT_APP_USER_PROFILE_KEY)
      this.props.dispatch(deleteUser());
      this.props.dispatch(clearPremier());
      this.props.history.replace('/')
    })
  }

  render() {
    const { match } = this.props
    if(this.state.loading)
      return(<LoadingScreen/>)
    else
    return (
      <SignedContainer>
        <div>
          <div className="w-full">

            <div className="flex bg-white flex-1 flex-row pt-6 mx-auto items-centerpt-2 pb-4" style={{ paddingLeft: 54.5 }}>
              <div onClick={this.goHome} className="cursor-pointer flex flex-row items-center">
                <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
                <p className="questrial font-semibold text-redaria-blue" style={{ fontSize:27 }}>redaria</p>
              </div>
              <div className="relative flex flex-1 items-center flex-row justify-end" style={{ marginRight:130 }}>
                <p onClick={this.goHome} className="poppins-semi-bold text-redaria-black cursor-pointer" style={{ fontSize: 17 }}>Inicio</p>
                <div className="mx-4" style={{ width:1,height:25, backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>
                <p onClick={this.signOut} className="poppins-semi-bold text-redaria-black cursor-pointer" style={{ fontSize: 17, marginRight:5 }}>Cerrar sesión</p>
                <Exit style={{ width:16, height:16}}/>
              </div>
            </div>
          </div>
          <div className="">
            <Route exact path={`${match.path}/profile`} component={Profile} />
            <Route exact path={`${match.path}/me/:type`} component={MeContainer} />
            <Route exact path={`${match.path}/new_property`} component={NewPropertyContainer} />
            <Route exact path={`${match.path}/edit/profile`} component={EditProfile} />
            <Route exact path={`${match.path}/property/:id`} component={Property} />
            <Route exact path={`${match.path}/edit/property/:id`} component={EditProperty} />

            <Route exact path={`${match.path}/archive`} component={Archive  } />
            <Route exact path={`${match.path}/terms`} component={TermsAndConditions} />
            <Route exact path={`${match.path}/privacy`} component={PrivacyPolicies} />

          </div>
        </div>
      </SignedContainer>
    )
  }
}

export default connect()(withRouter(LoginStack))