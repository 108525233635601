import React from 'react';

export default function Intestated({ className, fill }) {
  return (
    <svg viewBox="0 0 17 23" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g>
        <g id="intestated_svg__prefix__paper" transform="translate(-.109)">
          <path
            id="intestated_svg__prefix__Trazado_23714"
            d="M.109 57.272v19.217a.37.37 0 00.37.37h14.043a.37.37 0 00.369-.37V60.945a.348.348 0 00-.019-.095c0-.012-.007-.023-.012-.035a.366.366 0 00-.074-.108l-3.7-3.7a.358.358 0 00-.109-.074l-.034-.012a.366.366 0 00-.1-.019H.479a.369.369 0 00-.37.369zM13.63 60.6H11.2v-2.436zM.848 57.641h9.609v3.326a.37.37 0 00.369.369h3.326V76.12H.848zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23714"
            transform="translate(0 -54.685)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23715"
            d="M70.312 19.217v.739h1.109a.369.369 0 00.37-.369V.37a.37.37 0 00-.37-.37H57.377a.37.37 0 00-.37.37v1.108h.739V.739h13.3v18.478zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23715"
            transform="translate(-54.681)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23716"
            d="M75.977 189.668h8.869v.739h-8.869zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23716"
            transform="translate(-72.911 -182.277)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23717"
            d="M75.977 132.766h2.957v.739h-2.957zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23717"
            transform="translate(-72.911 -127.592)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23718"
            d="M75.977 246.566h5.913v.739h-5.913zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23718"
            transform="translate(-72.911 -236.957)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23719"
            d="M246.676 246.566h2.217v.739h-2.217zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23719"
            transform="translate(-236.958 -236.957)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23720"
            d="M75.977 303.465h8.869v.739h-8.869zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23720"
            transform="translate(-72.911 -291.639)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23721"
            d="M75.977 417.266h8.869v.739h-8.869zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23721"
            transform="translate(-72.911 -401.005)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23722"
            d="M161.324 360.367h5.543v.739h-5.543zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23722"
            transform="translate(-154.932 -346.324)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23723"
            d="M75.977 360.367h2.587v.739h-2.587zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23723"
            transform="translate(-72.911 -346.324)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23724"
            d="M75.977 474.168h1.478v.739h-1.478zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23724"
            transform="translate(-72.911 -455.69)"
          />
          <path
            id="intestated_svg__prefix__Trazado_23725"
            d="M132.875 474.168h6.652v.739h-6.652zm0 0"
            className="intestated_svg__prefix__cls-1"
            data-name="Trazado 23725"
            transform="translate(-127.592 -455.69)"
          />
        </g>
      </g>
    </svg>
  )
}

export function IntestatedBlack({ className }) {
  return (
    <svg viewBox="0 0 17 22.174" className={className ? className : "w-4"}>
      <g fill="#223242">
        <path
          data-name="Trazado 23714"
          d="M0 2.587v19.217a.37.37 0 00.37.37h14.043a.37.37 0 00.369-.37V6.283 6.26a.348.348 0 00-.019-.095c0-.012-.007-.023-.012-.035a.366.366 0 00-.074-.108l-3.7-3.7a.358.358 0 00-.109-.074l-.034-.012a.366.366 0 00-.1-.019H.37a.369.369 0 00-.37.369zm13.521 3.328h-2.43V3.479zM.739 2.956h9.609v3.326a.37.37 0 00.369.369h3.326v14.784H.739zm0 0"
        />
        <path
          data-name="Trazado 23715"
          d="M15.522 19.217v.739h1.109a.369.369 0 00.37-.369V.37a.37.37 0 00-.37-.37H2.587a.37.37 0 00-.37.37v1.108h.739V.739h13.3v18.478zm0 0"
        />
        <path
          data-name="Trazado 23716"
          d="M2.957 7.391h8.869v.739H2.957zm0 0"
        />
        <path
          data-name="Trazado 23717"
          d="M2.957 5.174h2.957v.739H2.957zm0 0"
        />
        <path data-name="Trazado 23718" d="M2.957 9.609H8.87v.739H2.957zm0 0" />
        <path
          data-name="Trazado 23719"
          d="M9.609 9.609h2.217v.739H9.609zm0 0"
        />
        <path
          data-name="Trazado 23720"
          d="M2.957 11.826h8.869v.739H2.957zm0 0"
        />
        <path data-name="Trazado 23721" d="M2.957 16.261h8.869V17H2.957zm0 0" />
        <path
          data-name="Trazado 23722"
          d="M6.283 14.043h5.543v.739H6.283zm0 0"
        />
        <path
          data-name="Trazado 23723"
          d="M2.957 14.043h2.587v.739H2.957zm0 0"
        />
        <path
          data-name="Trazado 23724"
          d="M2.957 18.478h1.478v.739H2.957zm0 0"
        />
        <path
          data-name="Trazado 23725"
          d="M5.174 18.478h6.652v.739H5.174zm0 0"
        />
      </g>
    </svg>
  )
}
