import React from 'react';

export default function BankAuction({ className,fill }) {
  return (
    <svg viewBox="0 0 25 26" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g>
        <path
          d="M.464 6.968a1.021 1.021 0 00-.016.151v1.037a1.029 1.029 0 00.668.96v1.527a.96.96 0 00.914.955v8.821a.96.96 0 00-.914.956v1.524a1.04 1.04 0 00-.668.969v1.015a.343.343 0 00.343.343h23.12a.343.343 0 00.343-.343v-1.016a1.04 1.04 0 00-.667-.969v-1.532a.96.96 0 00-.914-.956v-8.811a.96.96 0 00.914-.955V9.117a1.029 1.029 0 00.667-.96V7.125a1 1 0 00-.016-.151.341.341 0 00.277-.624L12.511.04a.343.343 0 00-.32 0L.183 6.344a.343.343 0 00.281.624zm1.337 2.743v-.528h3.954v.524zm8.8 1.89v8.818a.96.96 0 00-.914.956v1.458h-3.25v-1.458a.96.96 0 00-.914-.956v-8.82a.96.96 0 00.914-.955V9.183h3.25v1.458a.96.96 0 00.914.958zm-.229-1.89v-.528h3.955v.524zm8.8 1.89v8.818a.96.96 0 00-.914.956v1.458h-3.247v-1.458a.96.96 0 00-.914-.956v-8.82a.96.96 0 00.914-.955V9.183h3.25v1.458a.96.96 0 00.914.958zm-.229-1.89v-.528h3.968v.524zm3.968 12.599v.523h-3.962v-.523zm-8.576 0v.523h-3.962v-.523zm-3.388-11.39h-.3a.274.274 0 01-.274-.274v-.247h3.954v.249a.274.274 0 01-.274.274zm2.471.686v8.809h-2.131v-8.809zm-2.471 9.5h3.109a.274.274 0 01.274.274v.249h-3.957v-.249a.274.274 0 01.274-.274zM5.751 22.31v.523H1.796v-.523zM1.796 10.653v-.254h3.955v.249a.274.274 0 01-.274.274H2.064a.274.274 0 01-.267-.277zm3.04.96v8.8H2.711v-8.807zm-3.04 9.77a.274.274 0 01.274-.274h3.407a.274.274 0 01.274.274v.249H1.796zm21.774 2.5v.672H1.129v-.672a.354.354 0 01.354-.354h21.734a.354.354 0 01.354.347zm-.667-2.5v.249h-3.954v-.249a.274.274 0 01.274-.274h3.413a.274.274 0 01.267.267zm-3.04-.96v-8.817h2.128v8.809zm3.04-9.769a.274.274 0 01-.274.274h-3.4a.274.274 0 01-.274-.274v-.255h3.954zM12.35.725l10.211 5.367H2.139zm-11.221 6.4a.343.343 0 01.343-.343h21.756a.343.343 0 01.343.343v1.038a.343.343 0 01-.343.343H1.472a.343.343 0 01-.343-.343zm0 0"
          data-name="Trazado 23713"
        />
      </g>
    </svg>
  )
}

export function BankAuctionBlack({ className,fill }) {
  return (
    <svg viewBox="0 0 24.721 25.224" className={className ? className : "w-5"}>
      <g>
        <path
          data-name="Trazado 23713"
          d="M.464 6.968a1.021 1.021 0 00-.016.151v1.037a1.029 1.029 0 00.668.96v1.527a.96.96 0 00.914.955v8.821a.96.96 0 00-.914.956v1.524a1.04 1.04 0 00-.668.969v1.015a.343.343 0 00.343.343h23.12a.343.343 0 00.343-.343v-1.016a1.04 1.04 0 00-.667-.969v-1.532a.96.96 0 00-.914-.956v-8.811a.96.96 0 00.914-.955V9.117a1.029 1.029 0 00.667-.96V7.125a1 1 0 00-.016-.151.341.341 0 00.277-.624L12.511.04a.343.343 0 00-.32 0L.183 6.344a.343.343 0 00.281.624zm1.337 2.743v-.528h3.954v.524zm8.8 1.89v8.818a.96.96 0 00-.914.956v1.458h-3.25v-1.458a.96.96 0 00-.914-.956v-8.82a.96.96 0 00.914-.955V9.183h3.25v1.458a.96.96 0 00.914.958zm-.229-1.89v-.528h3.955v.524zm8.8 1.89v8.818a.96.96 0 00-.914.956v1.458h-3.247v-1.458a.96.96 0 00-.914-.956v-8.82a.96.96 0 00.914-.955V9.183h3.25v1.458a.96.96 0 00.914.958zm-.229-1.89v-.528h3.968v.524zm3.968 12.599v.523h-3.962v-.523zm-8.576 0v.523h-3.962v-.523zm-3.388-11.39h-.3a.274.274 0 01-.274-.274v-.247h3.954v.249a.274.274 0 01-.274.274zm2.471.686v8.809h-2.131v-8.809zm-2.471 9.5h3.109a.274.274 0 01.274.274v.249h-3.957v-.249a.274.274 0 01.274-.274zM5.751 22.31v.523H1.796v-.523zM1.796 10.653v-.254h3.955v.249a.274.274 0 01-.274.274H2.064a.274.274 0 01-.267-.277zm3.04.96v8.8H2.711v-8.807zm-3.04 9.77a.274.274 0 01.274-.274h3.407a.274.274 0 01.274.274v.249H1.796zm21.774 2.5v.672H1.129v-.672a.354.354 0 01.354-.354h21.734a.354.354 0 01.354.347zm-.667-2.5v.249h-3.954v-.249a.274.274 0 01.274-.274h3.413a.274.274 0 01.267.267zm-3.04-.96v-8.817h2.128v8.809zm3.04-9.769a.274.274 0 01-.274.274h-3.4a.274.274 0 01-.274-.274v-.255h3.954zM12.35.725l10.211 5.367H2.139zm-11.221 6.4a.343.343 0 01.343-.343h21.756a.343.343 0 01.343.343v1.038a.343.343 0 01-.343.343H1.472a.343.343 0 01-.343-.343zm0 0"
          fill="#223242"
        />
      </g>
    </svg>
  )
}
