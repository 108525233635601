/* eslint-disable */
// @flow
import { EMAIL_REGEXP, NON_DIGITS_REGEXP } from './Regexp';
import { ErrorAlert, InformationAlert } from './alerts';
import _ from 'lodash'

export const PropertyBasicInformationSubmit = (data, success = () => { }, error = () => { }) => {

  let messsage = '', requiredFields = [], errors = false;
  if (!data.images.length) {
    messsage = 'Debes seleccionar por lo menos una imagen para tu propiedad.';
    requiredFields.push('image');
    errors = true;
  }
  if (data.price === '') {
    messsage = 'El precio no debe estar en blanco.';
    requiredFields.push('price');
    errors = true;
  }
  if (data.dealType === null) {
    messsage = 'Debes seleccionar un tipo de operación.';
    requiredFields.push('dealType');
    errors = true;
  }
  if (data.type === null) {
    messsage = 'Debes seleccionar un tipo de propiedad.';
    requiredFields.push('type');
    errors = true;
  }
  if (errors)
    InformationAlert('Campos requeridos',messsage, 
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else 
    success();
  
};
export const PropertyGeneralInformationSubmit = (data, success = () => { }, error = () => { }) => {

  let messsage = '', requiredFields = [], errors = false;
  
  if (data.description === '') {
    messsage = 'Debes escribir una breve descripción de tu propiedad.';
    requiredFields.push('description');
    errors = true;
  }

  if (data.type !== 2 && data.age === '') {
    messsage = 'Debes seleccionar la antigüedad de la propiedad.';
    requiredFields.push('age');
    errors = true;
  }
  if (data.age) {
    if(data.age === -1){
      messsage = 'Debes seleccionar la antigüedad de la propiedad.';
      requiredFields.push('age');
      errors = true;
    }
  }

  if (data.type !== 2 && data.floors === '') {
    messsage = 'Debes seleccionar la cantidad de niveles para la propiedad.';
    requiredFields.push('floors');
    errors = true;
  }
  if (data.type !== 2 && data.ammenities.find(ammenity => ammenity.id === 2) === undefined) {
    messsage = 'Debes seleccionar los baños de la propiedad.';
    requiredFields.push('bathrooms');
    errors = true;
  }
  if (data.type !== 2 && data.type !== 3 && data.type !== 4 && data.type !== 5 && data.ammenities.find(ammenity => ammenity.id === 1) === undefined) {
    messsage = 'Debes seleccionar las recámaras de la propiedad.';
    requiredFields.push('bedrooms');
    errors = true;
  }

  if (data.type !== 2 && (data.buildingArea === '' || data.buildingArea === undefined)) {
    messsage = 'Debes llenar el área de construcción de la propiedad.';
    requiredFields.push('buildingArea');
    errors = true;
  }
  if (data.type !== 3 && data.type !== 4 && data.landArea === '') {
    messsage = 'Debes llenar el área de la propiedad.';
    requiredFields.push('landArea');
    errors = true;
  }

  if (errors)
    InformationAlert('Campos requeridos',messsage,
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else 
    success();
  
};

export const PropertyAddressSubmit = (data, error = () => { }, success = (_) => {}) => {
  let messsage = '', requiredFields = [], errors = false;
  if (data.zipCode === '') {
    messsage = 'Debes llenar el código postal de la propiedad.';
    requiredFields.push('zipCode');
    errors = true;
  }
  if (data.neighborhood === '') {
    messsage = 'Debes seleccionar una colonia para la dirección.';
    requiredFields.push('neighborhood');
    errors = true;
  }
  if (data.city === '') {
    messsage = 'Debes seleccionar una ciudad para la dirección.';
    requiredFields.push('city');
    errors = true;
  }
  if (data.state === '') {
    messsage = 'Debes seleccionar un estado para la dirección.';
    requiredFields.push('state');
    errors = true;
  }

  
  if (errors)
    InformationAlert('Campos requeridos',messsage,
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    delete data.disabledButton;
    let formData = [];

    Object.keys(data).forEach(key => {
      if (typeof (data[key]) !== 'object') {
        if (key === 'buildingArea' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key === 'floors' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key !== 'floors' && key !== 'buildingArea') {
          formData.push([key, data[key]]);
        }
      }
      else if (typeof (data[key]) === 'object' && key === 'images') {
        data[key].forEach(image => {
          formData.push(['images[]', image]);
        });
      }
      else {
        formData.push([key, JSON.stringify(data[key])]);
      }
    });

    success(formData);
  }
}
export const PropertyFormSubmit = (data, error = () => { }, success = (_) => {}) => {
  let messsage = '', requiredFields = [], errors = false;
  if (data.description === '') {
    messsage = 'Debes escribir una breve descripción de tu propiedad.';
    requiredFields.push('description');
    errors = true;
  }
  if (data.zipCode === '') {
    messsage = 'Debes llenar el código postal de la propiedad.';
    requiredFields.push('zipCode');
    errors = true;
  }
  if (data.neighborhood === '') {
    messsage = 'Debes seleccionar una colonia para la dirección.';
    requiredFields.push('neighborhood');
    errors = true;
  }
  if (data.city === '') {
    messsage = 'Debes seleccionar una ciudad para la dirección.';
    requiredFields.push('city');
    errors = true;
  }
  if (data.state === '') {
    messsage = 'Debes seleccionar un estado para la dirección.';
    requiredFields.push('state');
    errors = true;
  }

  if (data.type !== 2 && data.age === '') {
    messsage = 'Debes seleccionar la antigüedad de la propiedad.';
    requiredFields.push('age');
    errors = true;
  }
  if (data.age) {
    if(data.age === -1){
      messsage = 'Debes seleccionar la antigüedad de la propiedad.';
      requiredFields.push('age');
      errors = true;
    }
  }

  if (data.type !== 2 && data.floors === '') {
    messsage = 'Debes seleccionar la cantidad de niveles para la propiedad.';
    requiredFields.push('floors');
    errors = true;
  }
  if (data.type !== 2 && data.ammenities.find(ammenity => ammenity.id === 2) === undefined) {
    messsage = 'Debes seleccionar los baños de la propiedad.';
    requiredFields.push('bathrooms');
    errors = true;
  }
  if (data.type !== 2 && data.type !== 3 && data.type !== 4 && data.type !== 5 && data.ammenities.find(ammenity => ammenity.id === 1) === undefined) {
    messsage = 'Debes seleccionar las recámaras de la propiedad.';
    requiredFields.push('bedrooms');
    errors = true;
  }

  if (data.type !== 2 && (data.buildingArea === '' || data.buildingArea === undefined)) {
    messsage = 'Debes llenar el área de construcción de la propiedad.';
    requiredFields.push('buildingArea');
    errors = true;
  }
  if (data.type !== 3 && data.type !== 4 && data.landArea === '') {
    messsage = 'Debes llenar el área de la propiedad.';
    requiredFields.push('landArea');
    errors = true;
  }
  if (errors)
    InformationAlert('Campos requeridos',messsage,
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    delete data.disabledButton;
    let formData = [];

    Object.keys(data).forEach(key => {
      if (typeof (data[key]) !== 'object') {
        if (key === 'buildingArea' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key === 'floors' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key !== 'floors' && key !== 'buildingArea') {
          formData.push([key, data[key]]);
        }
      }
      else if (typeof (data[key]) === 'object' && key === 'images') {
        data[key].forEach(image => {
          formData.push(['images[]', image]);
        });
      }
      else {
        formData.push([key, JSON.stringify(data[key])]);
      }
    });

    success(formData);
  }
}

export const EditProfileSubmit = (data, error = () => {}, success = () => {}) => {
  let requiredFields = []
  let errors = false
  let messsage = ''

  if (data.type === 1 && data.experienceAreas.length < 1) {
    messsage = 'Debes seleccionar por lo menos una área de experiencia';
    requiredFields.push('experienceAreas');
    errors = true;
  }


  if(data.type === 1 && data.certificates.length + data.newCertificates.length > 0 && (validateCertificatesNames(data.certificates) || validateCertificatesNames(data.newCertificates))) {
    messsage = 'Todos tus certificados deben tener nombre';
    requiredFields.push('certificateName');
    errors = true;
  }
  
  if(data.type === 1 && data.certificates.length + data.newCertificates.length > 0 && (validateCertificatesImages(data.certificates) || validateCertificatesImages(data.newCertificates))) {
    messsage = 'Todos tus certificados deben tener imagen';
    requiredFields.push('certificateImage');
    errors = true;
  }

  if (data.city === '') {
    messsage = 'Debes seleccionar una ciudad para tu perfil';
    requiredFields.push('city');
    errors = true;
  }
  if (data.state === '') {
    messsage = 'Debes seleccionar un estado para tu perfil';
    requiredFields.push('state');
    errors = true;
  }

  if (data.phone.length === 0 ) {
    messsage = 'Debes escribir un número de teléfono válido';
    requiredFields.push('phone');
    errors = true;
  }else if ( data.phone.length > 10 || data.phone.length < 10) {
    messsage = 'El número de teléfono debe ser de 10 dígitos';
    requiredFields.push('phone');
    errors = true;
  }

  if (data.email === '' || !EMAIL_REGEXP.test(data.email)) {
    messsage = 'Debes escribir un correo electrónico válido';
    requiredFields.push('email');
    errors = true;
  }

  if (/\d/.test(data.name)) {
    messsage = 'El nombre no puede tener números';
    requiredFields.push('name');
    errors = true;
  }
  if (data.name === '') {
    messsage = 'Debes escribir tu nombre para tu perfil';
    requiredFields.push('name');
    errors = true;
  }

  if (!data.profilePicture) {
    messsage = 'Debes seleccionar una foto para tu perfil';
    requiredFields.push('profilePicture');
    errors = true;
  }
  
  if(errors)
    InformationAlert('Campos requeridos',messsage,
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    let formData = new FormData();

    if (data.profilePicture.name !== undefined) {
      formData.append('profile_picture', data.profilePicture);
    }
    formData.append('deleted_logo', data.deletedLogo);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('state', data.state);
    formData.append('city', data.city);
    formData.append('type', data.type);
    formData.append('has_agency', data.hasAgency ? 1 : 0);
    data.deleteCertificates.forEach(deleteCertificate => {
      formData.append('deleteCertificates[]', deleteCertificate);
    });
    if (data.type === 1) {
      if (data.certificates.length > 0) {
        data.certificates.forEach((certificate, index) => {
          formData.append(`certificates[${index}][id]`, certificate.id);
          formData.append(`certificates[${index}][name]`, certificate.name);
          if(certificate.file.type) {
            formData.append(`certificates[${index}][file]`, certificate.file);
          }
          if(certificate.serialNumber) {
            formData.append(`certificates[${index}][serialNumber]`, certificate.serialNumber);
          }
        });
      }
      if (data.newCertificates.length > 0) {
        data.newCertificates.forEach((certificate, index) => {
          formData.append(`new_certificates[${index}][name]`, certificate.name);
          formData.append(`new_certificates[${index}][file]`, certificate.file);
          if(certificate.serialNumber) {
            formData.append(`new_certificates[${index}][serialNumber]`, certificate.serialNumber);
          }
        });
      }

      formData.append('experience_areas', JSON.stringify(data.experienceAreas));
    }
    if (data.hasAgency) {
      formData.append('agency_name', data.agencyName);
      if (data.agencyLogo.name !== undefined) {
        formData.append('agency_logo', data.agencyLogo);
      }
    }
    success(formData);
  }
}

const validateCertificatesImages = (certificates) => {
  let certificatesHaveMissingImages = certificates.map(certificate => {
    const file = certificate.file
    
    if(file.name || file.uri) {
      return false;
    }
    else
    {
      return true
    }
  });

  return certificatesHaveMissingImages.includes(true);
};

const validateCertificatesNames = (certificates) => {
  let certificatesHaveMissingNames = certificates.map(certificate => {
    if(!certificate.name) {
      return true;
    }
  });

  return certificatesHaveMissingNames.includes(true);
};



export const PropertyEditSubmit = (data, error = () => { }, success = (formData) => { }) => {
  let messsage = '', requiredFields = [], errors = false;
  if (data.description === '') {
    messsage = 'Debes escribir una breve descripción de tu propiedad.';
    requiredFields.push('description');
    errors = true;
  }
  if (data.neighborhood === '') {
    messsage = 'Debes seleccionar una colonia para la dirección.';
    requiredFields.push('neighborhood');
    errors = true;
  }
  if (data.zipCode === '') {
    messsage = 'Debes ingresar un código postal para la dirección.';
    requiredFields.push('zipCode');
    errors = true;
  }
  if (data.age) {
    if(data.age === -1){
      messsage = 'Debes seleccionar la antigüedad de la propiedad.';
      requiredFields.push('age');
      errors = true;
    }
  }
  if (data.city === '') {
    messsage = 'Debes seleccionar una ciudad para la dirección.';
    requiredFields.push('city');
    errors = true;
  }
  if (data.state === '') {
    messsage = 'Debes seleccionar un estado para la dirección.';
    requiredFields.push('state');
    errors = true;
  }
  if (data.type !== 2 && data.age === '') {
    messsage = 'Debes seleccionar la antigüedad de la propiedad.';
    requiredFields.push('age');
    errors = true;
  }
  if (data.type !== 2 && data.floors === '') {
    messsage = 'Debes seleccionar la cantidad de niveles para la propiedad.';
    requiredFields.push('floors');
    errors = true;
  }
  if (data.type !== 2 && data.ammenities.find(ammenity => ammenity.id === 2) === undefined) {
    messsage = 'Debes seleccionar los baños de la propiedad.';
    requiredFields.push('bathrooms');
    errors = true;
  }
  if (data.type !== 2 && data.type !== 3 && data.type !== 4 && data.type !== 5 && data.ammenities.find(ammenity => ammenity.id === 1) === undefined) {
    messsage = 'Debes seleccionar las recámaras de la propiedad.';
    requiredFields.push('bedrooms');
    errors = true;
  }
  if (data.type !== 2 && (data.buildingArea === '' || data.buildingArea === undefined)) {
    messsage = 'Debes llenar el área de construcción de la propiedad.';
    requiredFields.push('buildingArea');
    errors = true;
  }
  if (data.landArea === '') {
    messsage = 'Debes llenar el área de la propiedad.';
    requiredFields.push('landArea');
    errors = true;
  }
  if ((data.images.length + data.newImages.length) < 1) {
    messsage = 'Debes seleccionar por lo menos una imagen para tu propiedad.';
    requiredFields.push('image');
    errors = true;
  }
  if (data.price === '') {
    messsage = 'El precio no debe estar en blanco.';
    requiredFields.push('price');
    errors = true;
  }  
  if(errors)
    InformationAlert('Campos requeridos',messsage,
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    let formData: [string, any][] = [];

    Object.keys(data).forEach(key => {
      if (typeof (data[key]) !== 'object' && key !== 'price' && key !== 'maintenanceFee') {
        if (key === 'buildingArea' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key === 'floors' && data[key]) {
          formData.push([key, data[key]]);
        }
        if (key !== 'floors' && key !== 'buildingArea') {
          formData.push([key, data[key]]);
        }
      }
      else if (typeof (data[key]) !== 'object' && (key === 'price' || key === 'maintenanceFee')) {
        formData.push([key, data[key].replace(NON_DIGITS_REGEXP, "")]);
      }
      else if (typeof (data[key]) === 'object' && key === 'newImages') {
        data[key].forEach(image => {
          formData.push(['new_images[]', image]);
        });
      }
      else {
        formData.push([key, JSON.stringify(data[key])]);
      }
    })

    success(formData);
  }
}

export const NewProfileSubmit = (data, error = () => {}, success = () => {}) => {

  let requiredFields = []
  let errors = false
  let messsage = ''

  if (data.type === 1 && data.experienceAreas.length < 1) {
    messsage = 'Debes seleccionar por lo menos una área de experiencia';
    requiredFields.push('experienceAreas');
    errors = true;
  }

  if (data.type === 1 && data.hasAgency && data.agencyName === '') {
    messsage = 'Debes escribir el nombre de la inmobiliaria';
    requiredFields.push('agencyName');
    errors = true;
  }

  if(data.type === 1 && data.certificates.length > 0 && validateCertificatesNames(data.certificates)) {
    messsage = 'Todos tus certificados deben tener nombre';
    requiredFields.push('certificateName');
    errors = true;
  }
  if(data.type === 1 && data.certificates.length > 0 && validateCertificatesImages(data.certificates)) {
    messsage = 'Todos tus certificados deben tener imagen';
    requiredFields.push('certificateImage');
    errors = true;
  }

  if (data.city === '') {
    messsage = 'Debes seleccionar una ciudad para tu perfil';
    requiredFields.push('city');
    errors = true;
  }
  if (data.state === '') {
    messsage = 'Debes seleccionar un estado para tu perfil';
    requiredFields.push('state');
    errors = true;
  }

  if(data.password_confirmation.length === 0)
  {
    messsage = 'Debes escribir la confirmación de la contraseña';
    requiredFields.push('password')
    errors = true;
  }
  if(data.password.length === 0)
  {
    messsage = 'Debes escribir la contraseña';
    requiredFields.push('password_confirmation')
    errors = true;
  }

  if (data.phone.length === 0 ) {
    messsage = 'Debes escribir un número de teléfono válido';
    requiredFields.push('phone');
    errors = true;
  }else if ( data.phone.length > 10 || data.phone.length < 10) {
    messsage = 'El número de teléfono debe ser de 10 dígitos';
    requiredFields.push('phone');
    errors = true;
  }


  if (data.email === '' || !EMAIL_REGEXP.test(data.email)) {
    messsage = 'Debes escribir un correo electrónico válido';
    requiredFields.push('email');
    errors = true;
  }

  if (/\d/.test(data.name)) {
    messsage = 'El nombre no puede tener números';
    requiredFields.push('name');
    errors = true;
  }
  if (data.name === '') {
    messsage = 'Debes escribir tu nombre para tu perfil';
    requiredFields.push('name');
    errors = true;
  }

  if(errors)
    InformationAlert('Campos requeridos',messsage, 
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    let formData = new FormData();

    if (data.profilePicture.name !== undefined) {
      formData.append('profile_picture', data.profilePicture);
    }
    formData.append('deleted_logo', data.deletedLogo);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('password_confirmation', data.password_confirmation);
    formData.append('password', data.password);
    formData.append('state', data.state);
    formData.append('city', data.city);
    formData.append('type', data.type);
    formData.append('has_agency', data.hasAgency ? 1 : 0);
    if (data.type === 1) {
      if (data.certificates.length > 0) {
        data.certificates.forEach((certificate, index) => {
          formData.append(`certificates[${index}][name]`, certificate.name);
          formData.append(`certificates[${index}][file]`, certificate.file);
          if(certificate.serialNumber) {
            formData.append(`certificates[${index}][serialNumber]`, certificate.serialNumber);
          }
        });
      }
      formData.append('experience_areas', JSON.stringify(data.experienceAreas));
    }
    if (data.hasAgency) {
      formData.append('agency_name', data.agencyName);
      if (!_.isEmpty(data.agencyLogo)) {
        formData.append('agency_logo', data.agencyLogo);
      }
    }
    
    success(formData);
  }
}


export const ResetPasswordSubmit = (data, error = () => { }, success = () => { }) => {


  if (data.currentPassword === '') {
    ErrorAlert('Debes llenar el campo de tu contraseña actual', () => error());
  }
  else if (data.password === '') {
    ErrorAlert('Debes llenar el campo de tu nueva contraseña', () => error());
  }
  else if (data.password.length < 6) {
    ErrorAlert('La contraseña debe ser de al menos 6 caracteres', () => error());
  }
  else if (data.passwordConfirmation === '') {
    ErrorAlert('Debes llenar el campo de tu confirmar tu nueva contraseña', () => error());
  }
  else if (data.password !== data.passwordConfirmation) {
    ErrorAlert('Tu nueva contraseña y la confirmación deben coincidir', () => error());
  }
  else {
    success(data);
  }
}


export const editCertificateSubmit = (data, error = () => {}, success = () => {}) => {
  if(!data.file) {
    ErrorAlert('Debes incluir una foto para tu certificado.', () => error());
  }
  else if(!data.name) {
    ErrorAlert('Debes incluir el nombre de tu certificado.', () => error());
  }
  else {
    let formData = new FormData();

    formData.append(`name`, data.name);
    if(data.serialNumber) {
      formData.append(`serial_number`, data.serialNumber);
    }
    if(data.file) {
      formData.append(`file`, data.file);
    }

    success(formData, data.id);
  }
};



export const EditPropertyFirst = (data, error = () => { }, success = (_) => { }) => {
  let messsage = '', requiredFields = [], errors = false;

  if ((data.images.length + data.newImages.length) < 1) {
    messsage = 'Debes seleccionar por lo menos una imagen para tu propiedad.';
    requiredFields.push('image');
    errors = true;
  }
  if (data.price === '') {
    messsage = 'El precio no debe estar en blanco.';
    requiredFields.push('price');
    errors = true;
  }  
  if(errors)
    InformationAlert('Campos requeridos',messsage, 
    () => error(requiredFields.reverse()),
    () => error(requiredFields.reverse()),
    )
  else {
    success();
  }
}