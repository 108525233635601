import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';



export const ConfirmationAlert = (message, callback = () => {}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <p className="poppins-semi-bold text-redaria-black text-center px-5" style={{ fontSize:27 }}>{message}</p>
          <div className="flex flex-row mt-10">
            <button
              className="redaria-button-white mx-5"
              onClick={() => {
                callback();
                onClose();
              }}
            >
            Continuar
            </button>
            <button className="redaria-button-blue mx-5" onClick={onClose}>No</button>
          </div>
        </div>
      );
    }
  });
};
