import React, { PureComponent } from 'react'
import { LeftArrow } from '../../lib/Icon/icons/Arrows';
import { BasicDetailsForm } from '../Forms/BasicDetailsForm';

export default class GeneralDetailsForm extends PureComponent {
  render() {
    return (
      <div>
        <BasicDetailsForm
          active={true}
          age={Number(this.props.age)}
          ammenities={this.props.ammenities}
          floors={this.props.floors}
          onChangeAmmenityValue={(id, value) => this.props.changeAmmenityValue(id, value)}
          onChangeValue={valueObject => {
            if (Object.keys(valueObject)[0] !== 'floors') {
              this.props.parentSetState(valueObject)
            }
            else {
              this.props.changeFloors(valueObject.floors);
            }
          }}
          postType='Property'
          propertyType={this.props.type}
          landArea={this.props.landArea}
          buildingArea={this.props.buildingArea}
          requiredFields={this.props.requiredFields}
          description={this.props.description}
          legalStatus={this.props.legalStatus}
          changeLegalStatus={(s) => this.props.parentSetState(s)}
          onChangeTextInput={this.props.parentSetState}
        />
        <div className="bg-gray-300 mt-10 w-full" style={{ height: 1, }} />
        <div className="flex flex-row items-center justify-end ">
          <div className="w-72 mr-10">
            <button onClick={() => {
              this.props.goBackSecondSection()
            }} className="redaria-button flex flex-row items-center justify-center  remove-background mt-10 self-center mb-10">
              <LeftArrow stroke="#2b77b7" fill="#2b77b7" style={{ width: 20 }} className="mr-3" />
              <span>Atras</span>
            </button>
          </div>
          <div className="w-72">
            <button onClick={() => {
              this.props.finishSecondSection()
            }} className="redaria-button-white  remove-background mt-10 self-center mb-10">Siguiente</button>
          </div>
        </div>
      </div>
    )
  }
}
