import React from 'react'
import Add from '../../lib/Icon/icons/Add'

export default function NewCertificate({openNewCertificateModal}) {
  return (
    <div onClick={openNewCertificateModal} className="rounded cursor-pointer bg-redaria-blue flex items-center justify-center shadow-lg" style={{ width:79,height:79 }}>
      <Add fill="#fff"/>
    </div>
  )
}
