import React, {PureComponent} from 'react';

import {getAmmenityValue} from '../../lib/Ammenities';
import { BathroomsGrey } from '../../lib/Icon/icons/Bathrooms';
import { BedroomsGrey } from '../../lib/Icon/icons/Bedrooms';
import { DimensionsGrey } from '../../lib/Icon/icons/Dimensions';
import { FloorGrey } from '../../lib/Icon/icons/Floor';

export default class AmmenitiesList extends PureComponent {


  formatValue = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  parseValue = (value) => {
    const val = parseInt(value.replace(/,/g, ''))
    return (value === '0' || value === undefined) ? '0m²' : (
    val > 9999 ? this.formatValue(parseInt(val / 10000)) + 'ha' :
    this.formatValue(val) + 'm²')
  }

  render() {
    const landArea = this.parseValue(this.props.landArea)
    const buildingArea = this.parseValue(this.props.buildingArea)

    return(
      <div className="flex flex-row flex-wrap" style={{ }}>
        
        {
          this.props.propertyType !== 'Terreno' && this.props.propertyType !== 'Local' && this.props.propertyType !== 'Oficina' && this.props.propertyType !== 'Bodega' &&
          <div className=" flex justify-center flex-col w-1/4" >
            <div className="flex flex-row  items-center">
              <BedroomsGrey className="w-10" />
              <p className="ml-2 questrial text-redaria-black" style={{ fontSize:19 }}>{`${getAmmenityValue(this.props.ammenities, 1)}`}</p>
            </div>
          </div>
        }
        {
          this.props.propertyType !== 'Terreno' &&
          <div className=" flex justify-center flex-col w-1/4" >
            <div className="flex flex-row  items-center">
              <BathroomsGrey className="w-10"/>
              <p className="ml-2 questrial text-redaria-black" style={{ fontSize:19 }}>{`${getAmmenityValue(this.props.ammenities, 2)}`}</p>
            </div>
          </div>
        }
        {
          this.props.propertyType !== 'Local' && this.props.propertyType !== 'Oficina' &&
          <div className=" flex justify-center flex-col w-1/4" >
            <div className="flex flex-row  items-center">
              <DimensionsGrey className="w-10"/>
              <p className="ml-2 questrial text-redaria-black" style={{ fontSize:19 }}>{landArea}</p>
            </div>
          </div>
        }
        <div className="flex justify-center flex-col w-1/4" >
          <div className="flex flex-row  items-center">
            <FloorGrey className="w-8"/>
            <p className="ml-2 questrial text-redaria-black" style={{ fontSize:19 }}>{buildingArea}</p>
          </div>
        </div>
      </div>
    );
  }
}

