import React, { PureComponent } from 'react'
import Location from '../../lib/Icon/icons/Location'
import Ammenities from './Ammenities'

export class PropertyInformation extends PureComponent {
  render() {
    return (
      <div style={{ marginTop:34,paddingBottom:31,marginRight:25,marginLeft:25 }}>
        <div className="flex flex-row justify-between">
          
            <div className="flex flex-row">
              <Location className="w-5 -mt-2"/>
              <div className="flex flex-col ml-1 limit-text-two-lines" style={{ width:141 }}>
                <p className="poppins-medium" style={{ color:"#4A4A4ACC",fontSize:12 }}>{this.props.neighborhood}</p>
                <p className="poppins-medium" style={{ color:"#4A4A4ACC",fontSize:12 }}>{`${this.props.city}, ${this.props.state}`}</p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="poppins-semi-bold" style={{ color:"#4A4A4ACC",fontSize:8 }}>PRECIO</p>
              <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:17 }}>{`$${this.props.price}`}</p>
            </div>

          
        </div>
        <div>
          <Ammenities
            bathrooms={this.props.bathrooms}
            bedrooms={this.props.bedrooms}
            buildingArea={this.props.buildingArea}
            floors={this.props.floors}
            landArea={this.props.landArea}
            restrooms={this.props.restrooms}
            propertyType={this.props.type}
          />
        </div>
      </div>
    )
  }
}

export default PropertyInformation
