import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropertiesApi from '../../api/PropertiesApi'
import UserRequest from '../../api/UserRequest'
import LoadingScreen from '../../components/Loading/LoadingScreen'
import NeedProfileScreen from '../../components/NeedProfileScreen/NeedProfileScreen'
import MyProperties from '../../components/Profile/MyProperties'
import Search from '../../components/Profile/Search'
import { ErrorAlert } from '../../lib/alerts/ErrorAlert'
import { InformationAlert } from '../../lib/alerts/InformationAlert'
import { useRef } from 'react'
import BottomModal from '../../components/Archive/BottomModal'
import { ConfirmationAlert } from '../../lib/alerts/ConfirmationAlert'
import { useHistory } from 'react-router-dom'
import UserCard from '../../components/UserCard/UserCard'

export default function Profile() {

  const history = useHistory()

  const needsProfileInformation = useSelector(state => state.authenticatedUser.attributes.needsProfileInformation)
  const [profile, setProfile] = useState(null)
  const [loadingData, setLoadingData] = useState(true)
  const [isAlreadySearch, setIsAlreadySearch] = useState(false)
  const [search, setSearch] = useState('')
  
  const [isPropertiesActive, setPropertiesActive] = useState(false)
  const [properties, setProperties] = useState([])

  const [PRPSData, setPRPSData] = useState([])
  const [PRPSEmpty, setPRPSEmpty] = useState(false)
  const [HiddePRPS, setHiddePRPS] = useState(false)

  

  const _toggleProperties = () => setPropertiesActive(!isPropertiesActive)
  
  const [propertyOptionsId,setPropertyOptionsID] = useState(0)
  const [propetyDealType,setPropertyDealType] = useState('')
  
  

  useEffect(() => {fetchData()}, [])

  const modalPropertyRef = useRef(null)

  const fetchData = () => {

    Promise.all([
      UserRequest.profile(),
      PropertiesApi.myIndex(),
    ]).then(([
      profile,properties
      ]) => {
        setProfile(profile.data)
        setProperties(properties.data)
        setLoadingData(false)
    })
    .catch( err => console.log(err))

  }

  const _clearSearch = () => {
    
    setSearch('')
    setIsAlreadySearch(false)
    setPRPSData([])
    setPRPSEmpty(true)
    setIsAlreadySearch(false)
    setHiddePRPS(false)
  }

  const _onSubmitEditingSearch = () => {


    if(search.length === 0){
      InformationAlert(
        'El campo se encuentra vacio',
        'Debes ingresar un ID de Publicación.',
      );
      return
    }
    setHiddePRPS(true)

      UserRequest.searchPRPS(search).then( result =>{
        setPRPSData(result)
        setPRPSEmpty(false)
        setIsAlreadySearch(true)
      })
      .catch( error =>{
        ErrorAlert(
          'Hubo un error al buscar. Intenta de nuevo más tarde',
          ()=>{
            setHiddePRPS(false)
            setIsAlreadySearch(false)
          }
        );
      })

  }


  const _openPropertyOptions = (id,dealType) => {
    
    setPropertyDealType(dealType)
    setPropertyOptionsID(id)
    setTimeout(() => {
      modalPropertyRef.current?.open();
    }, 200);

  }

  const _changePropertyToRent = () => {
    let formData = new FormData();
    formData.append('status', '4');

    ConfirmationAlert('¿Estás seguro de que deseas marcar esta propiedad como rentada?', () => {
      PropertiesApi.update(propertyOptionsId, formData).then(result => {
        InformationAlert('Listo', 'Tu propiedad se ha marcado como rentada', () => {
            let p = [...properties];
            let ps = p.filter(property => parseInt(property.id) !== parseInt(propertyOptionsId))
            setProperties(ps)
            setPropertyOptionsID(0)
            _clearSearch()
        });
      }).catch(error => {
      });
    });
  }

  const _changeAvailableStatus = () => {
    const propertyStatus = properties.find(property => property.id === propertyOptionsId).attributes.status;
    const newStatus = propertyStatus === 'Disponible' ? 1 : 0;
    let formData = new FormData();
    formData.append('status', String(newStatus));

    ConfirmationAlert('¿Estás seguro que quieres cambiar la disponibilidad de tu propiedad?', () => {
      PropertiesApi.update(propertyOptionsId, formData).then(result => {
        InformationAlert('Listo', 'Se ha cambiado la disponibilidad de tu propiedad', () => {
          PropertiesApi.myIndex().then(result => {
            setProperties(result.data)
          });
        });
      }).catch(error => {
        console.log(error);
      });
    });
  }

  const _archiveProperty = () => {
    let formData = new FormData();
    formData.append('status', '2');

    ConfirmationAlert('¿Estás seguro de que deseas archivar esta propiedad?', () => {
      PropertiesApi.update(propertyOptionsId, formData).then(result => {
        InformationAlert('Listo', 'Se ha archivado esta propiedad', () => {
          let p = [...properties];
          let ps = p.filter(property => parseInt(property.id) !== parseInt(propertyOptionsId))
          setProperties(ps)
          setPropertyOptionsID(0)
          _clearSearch()
        });
      }).catch(error => {
        console.log(error);
      });
    });
  }

  const _editProperty = () => {
    history.push(`/signed/property/${propertyOptionsId}`)
  }

  const _changePropertyToSold = () => {
    let formData = new FormData();
    formData.append('status', '5');

    ConfirmationAlert('¿Estás seguro de que deseas marcar esta propiedad como vendida?', () => {
      PropertiesApi.update(propertyOptionsId, formData).then(result => {
        return InformationAlert('Listo', 'Tu propiedad se ha marcado como vendida', () => {

          let p = [...properties];
          let ps = p.filter(property => parseInt(property.id) !== parseInt(propertyOptionsId))
          setProperties(ps)
          setPropertyOptionsID(0)
          _clearSearch()

        });
      }).catch(error => {
        console.log(error);
      });
    });
  }

  


  const _refetch = () => {
    setLoadingData(true)
    fetchData()
  }
  

  if(needsProfileInformation)
  {
    return <NeedProfileScreen description={'Termina tu perfil para administrar tu cuenta.'}/>
  }

  if(loadingData)
    return <LoadingScreen/>

  return (
    <div className="grid grid-cols-12 pt-10 mb-10">

      <UserCard
        refresh={fetchData} 
        profile={profile}/>
      
      <div className="col-span-8  h-20 mr-5 -ml-5">


      <Search
        isAlreadySearch={isAlreadySearch}
        isPremier={profile.attributes.isPremier}
        onSubmitEditing={_onSubmitEditingSearch}
        clearSearch={_clearSearch}
        value={search}
        setSearch={setSearch}
        PRPSData={PRPSData}
        PRPSEmpty={PRPSEmpty}
        />
      

      <MyProperties
        hidde={HiddePRPS}
        isPremier={profile.attributes.isPremier}
        isActive={isPropertiesActive}
        onPress={_toggleProperties}
        openOptions={_openPropertyOptions}
        properties={properties}
        refetch={_refetch}
      />

      {
        propertyOptionsId !== 0 &&
          <BottomModal
            ref={modalPropertyRef}
              options={
                propetyDealType === 'Renta'
                ?
                [
                {key:"1",title:`Marcar como ${properties.find(property => parseInt(property.id) === parseInt(propertyOptionsId)).attributes.status === 'Apartada' ? 'disponible' : 'apartada'}`,onPress:_changeAvailableStatus},
                {key:"2",title:'Marcar como Rentada',onPress:_changePropertyToRent},
                {key:"3",title:'Archivar propiedad',onPress:_archiveProperty},
                {key:"4",title:'Editar',onPress:_editProperty},
                ]
                :
                [
                {key:"1",title:`Marcar como ${properties.find(property => parseInt(property.id) === parseInt(propertyOptionsId)).attributes.status === 'Apartada' ? 'disponible' : 'apartada'}`,onPress:_changeAvailableStatus},
                {key:"2",title:'Marcar como Vendida',onPress:_changePropertyToSold},
                {key:"3",title:'Archivar propiedad',onPress:_archiveProperty},
                {key:"4",title:'Editar',onPress:_editProperty},
                ]
            }
            title='Opciones'
          />
      }

      </div>
      
      
    </div>
  )
}
