import {SET_TOKEN} from '../actions/localFirebaseToken';

const initialState = {
  token:''
}

const localFirebaseToken = (state = initialState, action) => {
  
  switch(action.type) {
    case SET_TOKEN:
      return {...state, ...action.value};
    default:
      return state;
  }
}

export default localFirebaseToken