import React from 'react'
import { useState } from 'react'
import EditProfileModal from '../Profile/EditProfileModal'
import ProfileBlue from './../../lib/Icon/icons/ProfileBlue'
export default function NeedProfileScreen({description,refresh}) {
  
  const [showModal,setShowModal] = useState(false)
  return (
    <div className="flex flex-col items-center mt-10">

      <div className="">
        <div className="rounded-full bg-blue-500 h-24 w-24 flex items-center justify-center">
          <ProfileBlue />
        </div>
      </div>

      <div className="flex flex-1 items-center flex-col mt-10">

        <p className="title poppins-regular text-redaria-black">{description}</p>

        <button onClick={ () => setShowModal(true)} className="redaria-button-blue mt-10">
          <p className="sub-title poppins-regular text-white">Terminar perfil</p>
        </button>

      </div>

      <EditProfileModal
        refresh={refresh}
        visible={showModal}
        close={ () => setShowModal(false)}/>

    </div>
  )
}
