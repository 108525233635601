/* eslint-disable */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { saveUser } from '../../actions/authenticatedUser';
import UserRequest from '../../api/UserRequest';
import LoadingScreen from '../../components/Loading/LoadingScreen';
import { ErrorAlert } from '../../lib/alerts';

export default function AppleLogin({match}) {

  const ls       = new SecureLS();
  const history  = useHistory();
  const dispatch = useDispatch();

  const { token } = match.params;

  const login = () => {
    ls.set(process.env.REACT_APP_USER_AUTH_KEY,token)
    
    UserRequest.profile().then(result =>{
      dispatch(saveUser(result.data));
      ls.set(process.env.REACT_APP_USER_PROFILE_KEY,String(result.data.attributes.needsProfileInformation))
      history.replace('/signed/me/properties')
    })
    .catch( e =>{
      ErrorAlert('Ocurrio un error al acceder, intende nuevo más tarde',()=>{
        history.replace('/')
      })
    })

  }

  useEffect(() => {
    
    login()
  }, [])

  return (
    <div>
      <LoadingScreen/>
    </div>
  )
}
