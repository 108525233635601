import React, { PureComponent } from 'react'
import GoogleMap from 'google-map-react';
import { LocationBig } from '../../lib/Icon/icons/Location';
import { CloseDark } from '../../lib/Icon/icons/Close';

export default class ModalMap extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      defaultLocation: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      center: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      zoom:this.props.zoom,
      lat: this.props.latitude,
      lng: this.props.longitude,
      draggable: true,
    }
  }

  mapRef = React.createRef(null)

  
  onMouseUp = (e) => {
    this.setState({
      center: e.center,
    })
    
  }

  onDrag = (e) => {
    this.setState({
      center: {
        lat:e.center.lat(),
        lng:e.center.lng(),
      },
    })
  }

  close = () => {
    const r = this.state
    this.props.closeModalMap(r)
  }

  onCircleInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
      center: {
        lat:mouse.lat,
        lng:mouse.lng,
      },
    });
  }

  onCircleInteraction3 = (childKey, childProps, mouse) => {
    this.setState({draggable: true});
  }

  mapClick = (e) => {
    this.setState({
      lat: e.lat,
      lng: e.lng,
      center: {
        lat:e.lat,
        lng:e.lng,
      },
    });
  }

  onRegionChangeComplete = e => {
    this.setState({zoom:e.zoom})
  }

  render() {
    return (
      <div onClick={this.props.closeModalMapWithoutSave} className="fixed flex items-center justify-center z-40 top-0 left-0 right-0 bottom-0" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>
        <div onClick={ e => e.stopPropagation()} className="bg-white z-20 flex flex-col w-full h-full" 
          style={{ 
            borderRadius:9,
            paddingLeft:10,
            paddingRight:10,
            paddingBottom:10,
          }}>
            <div className="flex flex-row justify-between items-center" style={{ marginBottom: 10, paddingTop: 31, }}>
              <div onClick={this.props.closeModalMapWithoutSave} className="cursor-pointer">
                <CloseDark style={{ widht: 20, height: 20 }} />
              </div>
              <button onClick={this.close} className="redaria-button-white" style={{ width:200 }}>Guardar</button>
            </div>
          <div style={{ height: '100%', width: '100%',zIndex:41,borderRadius:4,overflow: 'hidden',boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)" }} className="mt-5">
            <GoogleMap
              id="mapa"
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
              zoom={this.state.zoom}
              center={this.state.defaultLocation}
              ref={(ref) => this.mapRef = ref}
              draggable={this.state.draggable}
              onChildMouseDown={this.onCircleInteraction}
              onChildMouseUp={this.onCircleInteraction3}
              onChildMouseMove={this.onCircleInteraction}
              onClick={this.mapClick}
              onChange={this.onRegionChangeComplete}
            >
              <div 
                className="place" 
                lat={this.state.lat} 
                lng={this.state.lng}>
                <LocationBig
                  style={{ width: 35,height:45 }}
                />
              </div>
            </GoogleMap>
          </div>
          </div>
      </div>
    )
  }
}
