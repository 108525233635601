// import { USER_AUTH_KEY } from '@env';
// import RNSecureKeyStore from 'react-native-secure-key-store';
import SecureLS from 'secure-ls';
import Api from './Api';


// This will reduce the number of concurrent requests to the server for the exact same resources
// Some parts of the app need a proper rewrite but this should help in the mean time
const promiseCache = {};

export default class AuthenticatedApi {

  static resetPromise(){

    return new Promise( (resolve,reject) =>{
      const iterator = Object.keys(promiseCache)
  
      for (const key of iterator) {
        delete promiseCache[key]
      }
      resolve()
    })
    
  }

  static delete(route, hParams = {}) {
    const authToken = this._getToken()
    const headers = {'Authorization': `Bearer ${authToken}`};
    const headerParams = Object.assign(headers, hParams);

    return Api.delete(route, headerParams);
  }

  static async get(route, headerParams = {}) {
    const key = `GET:${route}`;

    const asyncGET = async () => {
      const token = await this._getToken();
      const result = await Api.get(route, {
        'Authorization': `Bearer ${token}`,
        ...headerParams,
      });
      setTimeout(() => { delete promiseCache[key]; }, 2000);
      return result;
    };

    return promiseCache[key] = promiseCache[key] || asyncGET();
  }

  static async patch(route, headerParams = {}, params = {}) {
    const authToken = await this._getToken()

    let headers = Object.assign({'Authorization': `Bearer ${authToken}`}, headerParams);
    
    let result =  Api.patch(route, headers, params);
    return result
    
  }

  static post(route, headerParams = {}, params = {}) {
    const authToken = this._getToken()
    let headers = Object.assign({'Authorization': `Bearer ${authToken}`}, headerParams);
    return Api.post(route, headers, params);
    
  }

  static _getToken() {

    const ls = new SecureLS();
    let token = ls.get(process.env.REACT_APP_USER_AUTH_KEY)

    return token;
  }
}
