import { SET_NEW_PUBLICATION_PROPERTY } from '../actions/newProperty';

const initialState = {
  newPublicationProperty: false,
  newPropertyId:0
}

const property = (state = initialState, action) => {
  switch(action.type) {
    case SET_NEW_PUBLICATION_PROPERTY:
      return {...state, ...action.value};
    default:
      return state;
  }
}

export default property
