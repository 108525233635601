import React from 'react';

export default function Restrooms({className}) {
  return (
    <svg viewBox='0 0 20 21' className={className ? className : "w-7"}>
      <g>
        <path
          d="M6.808 5.734H5.375A1.075 1.075 0 004.3 6.809v1.075H1.075a1.072 1.072 0 00-.343 2.089 6.094 6.094 0 006.077 5.795h1.074v1.075a1.075 1.075 0 00.717 1.008v2.216a1.433 1.433 0 002.867 0v-2.216a1.075 1.075 0 00.717-1.009v-1.075h1.075a6.094 6.094 0 006.077-5.795 1.072 1.072 0 00-.343-2.089h-3.226V6.809a1.075 1.075 0 00-1.075-1.075h-1.434a1.075 1.075 0 00-1.075 1.075v1.075h-1.075V2.867A2.87 2.87 0 007.866.024a2.918 2.918 0 00-2.491 2.93v.271a.717.717 0 00.717.717h.717a.717.717 0 00.717-.717v-.358a.717.717 0 01.862-.7.749.749 0 01.571.745v4.972H7.883V6.809a1.075 1.075 0 00-1.075-1.075zM5.017 6.809a.358.358 0 01.358-.358h1.433a.358.358 0 01.358.358v1.075H5.017zm5.733 13.258a.717.717 0 01-1.433 0v-2.15h1.433zm.717-3.225a.358.358 0 01-.358.358H8.958a.358.358 0 01-.358-.358v-1.075h2.867zm1.792-1.792H6.808a5.382 5.382 0 01-5.363-5.017h17.176a5.382 5.382 0 01-5.363 5.017zm6.091-6.091a.358.358 0 01-.358.358H1.075a.358.358 0 110-.717h17.917a.358.358 0 01.358.359zm-6.45-2.15a.358.358 0 01.358-.358h1.433a.358.358 0 01.358.358v1.075H12.9zM8.524 1.461a1.433 1.433 0 00-1.716 1.406v.358h-.716v-.27A2.2 2.2 0 017.957.735a2.153 2.153 0 012.434 2.132v5.017h-.716V2.909a1.457 1.457 0 00-1.151-1.448z"
          fill="#223242"
          data-name="024---Sink"
        />
      </g>
    </svg>
  )
}