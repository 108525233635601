import React from 'react'
import logo from '../../assets/loading.gif'

export default function LoadingScreen() {
  return (
    <div className="absolute top-0 right-0 left-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center">
      <img src={logo} alt="loading..." className="w-28" />
    </div>
  )
}
