import React from 'react';

export default function Location({ className }) {
  return (
    <svg viewBox='2 0 24 24' className={className ? className : "w-5"}>
      <path fill='#2b77b7' id="a" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z" />
    </svg>
  )
}

export function LocationBig(props) {
  return (
    <svg
      viewBox="0 0 56.464 77.954"
      {...props}
    >
      <path
        d="M28.227 0A28.264 28.264 0 000 28.232c0 19.319 25.265 47.681 26.34 48.879a2.543 2.543 0 003.783 0c1.076-1.2 26.34-29.56 26.34-48.879A28.264 28.264 0 0028.227 0zm0 42.436a14.2 14.2 0 1114.2-14.2 14.22 14.22 0 01-14.2 14.2z"
        fill={props.fill ? props.fill : "#2b77b7"}
      />
    </svg>
  )
}