import React from 'react';

export function OtherWhite({ className, fill }) {
  return (
    <svg viewBox="0 0 27 8" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g data-name="Grupo 1722">
        <g data-name="Elipse 67" fill="none" transform="translate(0)" stroke="#fff">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
        <g data-name="Elipse 68" fill="none" transform="translate(10)" stroke="#fff">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
        <g data-name="Elipse 69" fill="none" transform="translate(19)" stroke="#fff">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
      </g>
    </svg>
  )
}

export function OtherBlue({ className, fill }) {
  return (
    <svg viewBox="0 0 27 8" fill={fill ? fill : '#2b77b7'} className={className ? className : "w-5"}>
      <g data-name="Grupo 1722">
        <g data-name="Elipse 67" fill="none" transform="translate(0)" stroke="#286faa">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
        <g data-name="Elipse 68" fill="none" transform="translate(10)" stroke="#286faa">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
        <g data-name="Elipse 69" fill="none" transform="translate(19)" stroke="#286faa">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} fill="none" />
        </g>
      </g>
    </svg>
  )
}

export function OtherBlack({ className, fill }) {
  return (
    <svg viewBox="0 0 27 8" className={className ? className : "w-5"}>
       <g data-name="Grupo 1744" fill="none" stroke="#223242">
        <g data-name="Elipse 67">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} />
        </g>
        <g data-name="Elipse 68" transform="translate(10)">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} />
        </g>
        <g data-name="Elipse 69" transform="translate(19)">
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.5} />
        </g>
      </g>
    </svg>
  )
}
