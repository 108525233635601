import React, {PureComponent} from 'react';
import { LocationBig } from '../../lib/Icon/icons/Location';

export default class BasicInformation extends PureComponent {
  render() {
    return(
      <div className="mt-5">
        <p className="questrial text-redaria-black" style={{ fontSize:19 }}>ID: #{this.props.id}</p>
        <div className="flex flex-row justify-between">
          <p className="text-redaria-black poppins-semi-bold" style={{ fontSize:25 }}>{this.props.propertyType}</p>
          <p className="questrial text-redaria-black" style={{ fontSize:21 }}>Precio: <span className="poppins-semi-bold" style={{ fontSize:32 }}>{`$${this.props.price}`}</span></p>
        </div>


        {
          (
            (this.props.propertyType === 'Local' ||
            this.props.propertyType === 'Bodega' ||
            this.props.propertyType === 'Oficina')
            &&
            this.props.maintenanceFee > 0
            )
          &&
          <div className="flex flex-row justify-end">
            <p className="questrial text-redaria-black" style={{ fontSize:21 }}>Mantenimiento: <span className="poppins-semi-bold" style={{ fontSize:32 }}>{`$${this.props.maintenanceFee}`}</span></p>
          </div>
        }

        
        <div className="flex flex-row items-center">
          <LocationBig style={{width: 12,height: 17}}/>
          <p className="poppins-regular text-redaria-blue ml-2" style={{ fontSize:15 }}>
            {`${this.props.neighborhood}, ${this.props.city}, ${this.props.state}`}
          </p>
        </div>
        
      </div>
    );
  }
}

