import { SET_PREMIER, CLEAR_PREMIER } from '../actions/premier';

const initialState = {
  showInvite: true,
  isPremier: false,
  startDate: null,
  endDate: null,
}

const premier = (state = initialState, action) => {
  switch(action.type) {
    case SET_PREMIER:
      return {...state, ...action.premier};
    case CLEAR_PREMIER:
      return initialState;
    default:
      return state;
  }
}

export default premier
