import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import ModalPropertyMasive from './ModalPropertyMasive'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NewPropertyButton({openNewPropertyModal,refetch}) {
  
  const _goNewProperty = () => {
    openNewPropertyModal()
  }

  const newPropertyMasive = () => {
    setVisible(true)
  }

  const [visible,setVisible] = useState(false)

  const closeModal = () => { setVisible(false) }


  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div style={{ width: 270 }}>
          <Menu.Button className="inline-flex redaria-button-blue">
            Publicar propiedad
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={_goNewProperty}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left'
                    )}
                  >
                    Nueva
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={newPropertyMasive}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left'
                    )}
                  >
                    Carga Masiva
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <ModalPropertyMasive
        refetch={refetch}
        visible={visible}
        close={closeModal}/>
    </>
  )
}
