import React, { PureComponent } from 'react'
import { BathroomsGrey } from '../../lib/Icon/icons/Bathrooms'
import { BedroomsGrey } from '../../lib/Icon/icons/Bedrooms'
import { DimensionsGrey } from '../../lib/Icon/icons/Dimensions'
import { FloorGrey } from '../../lib/Icon/icons/Floor'

export class Ammenities extends PureComponent {

  formatValue(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  parseValue(value) {
    const val = parseInt(value.replace(/,/g, ''));
    return (value === '0' || value === undefined) ? '0m²' : (
    val > 9999 ? this.formatValue(parseInt(val / 10000)) + 'ha' :
    this.formatValue(val) + 'm²');
  }
  getArea() {

    if(this.props.propertyType === 'Local' || this.props.propertyType === 'Oficina' || this.props.propertyType === 'Departamento' ) {
      return this.parseValue(this.props.buildingArea);
    }

    return this.parseValue(this.props.landArea);
  }

  render() {



    const {id} = this.props
    const amts = []
    if(this.props.propertyType !== 'Terreno' && this.props.propertyType !== 'Local' && this.props.propertyType !== 'Oficina' && this.props.propertyType !== 'Bodega')
    amts.push(
      <div key={`${id}-0`} className="flex flex-row items-center justify-center">
        <BedroomsGrey/>
        <p className="questrial text-redaria-black" style={{ fontSize:9, marginLeft:5 }}>{this.props.bedrooms}</p>
      </div>
    )
    
  if(this.props.propertyType !== 'Terreno' )
      amts.push(
        <div key={`${id}-1`} className="flex flex-row items-center justify-center">
          <BathroomsGrey/>
          <p className="questrial text-redaria-black" style={{ fontSize:9, marginLeft:5 }}>{this.props.bathrooms}</p>
        </div>
      )

    amts.push(
      <div key={`${id}-2`} className="flex flex-row items-center justify-center">
        <DimensionsGrey/>
        <p className="questrial text-redaria-black" style={{ fontSize:9, marginLeft:5 }}>{this.getArea()}</p>
      </div>
    )
    if(this.props.propertyType !== 'Terreno')
      amts.push(
        <div key={`${id}-3`} className="flex flex-row items-center justify-center">
            <FloorGrey className="w-5"/>
            <p className="questrial text-redaria-black" style={{ fontSize:9, marginLeft:5 }}>{this.props.floors || '∞'}</p>
        </div>
      )
    if(amts.length <= 3){
      for (let index = 0; index <= 4 - amts.length; index++) {
        amts.push(<div key={`${id}-2-${index}`} style={{width:50}}></div>)
      }
    }

    return (
      <div className="flex flex-row justify-between" style={{ marginTop:17 }}>
      {
        amts.map( amt => amt)
      }

      </div>
    )
  }
}

export default Ammenities
