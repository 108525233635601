import React, { PureComponent } from 'react'
import LogoRedaria from '../../assets/images/icon_redaria.png'
import Disponible_AppStore from '../../assets/images/Disponible_AppStore.png'
import Disponible_GooglePlay from '../../assets/images/Disponible_GooglePlay.png'
import { connect } from 'react-redux'
import { setStatusWelcome } from '../../actions/welcome'

class WelcomeModal extends PureComponent {

  state = {
    checkedRadio:false,
    hiddeLocal:false
  }

  finish = () => {
    if(this.state.checkedRadio)
    {
      this.props.dispatch(setStatusWelcome(false))
    }
    else
    {
      this.setState({hiddeLocal:true})
    }
  }

  handleCheck = () => this.setState({checkedRadio:!this.state.checkedRadio})

  render() {
    const { visible } = this.props
    if (!visible || (visible && this.state.hiddeLocal))
      return null
    return (
      <div className="absolute z-30 flex mx-auto left-0 right-0 items-center justify-center top-20">
        <div className="fixed z-20  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>

        </div>
        <div className="bg-white z-20 flex flex-col items-center justify-center" 
          style={{ 
            borderRadius:9,
            width:493,
            paddingLeft:40,
            paddingRight:40,
            paddingTop:19,
            }}>
          <div className="flex flex-row justify-center items-center ml-5">
            <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
            <p className="questrial font-semibold text-redaria-blue" style={{ fontSize:27 }}>redaria</p>
          </div>
          <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:28,marginTop:18 }}>¡Bienvenido a <span className="text-redaria-blue">Redaria</span> web!</p>
          <p className="poppins-semi-bold text-redaria-blue" style={{ fontSize:20,marginTop:50 }}>Publica tus propiedades…</p>
          
          <div className="px-2">
            <p className="questrial text-center text-redaria-black" style={{ fontSize:19,marginTop:11 }}>Por el momento nuestro portal web funciona solo para <span className="poppins-semi-bold">publicar tus propiedades.</span></p>
            <p className="questrial text-center text-redaria-black" style={{ fontSize:19,marginTop:15 }}>El uso completo de Redaria es a través de la <span className="poppins-semi-bold">App móvil</span>, descárgala en el App Store o Google Play.</p>
          </div>

          <div className="flex flex-row">
            <a href="https://bit.ly/RedariaPlayStore" target="blank">
              <img src={Disponible_GooglePlay} style={{ width: 144 }} alt="logo google" />
            </a>
            <a href="https://bit.ly/RedariaAppStore" target="blank">
              <img src={Disponible_AppStore} style={{ width: 144 }}  alt="logo appstore" />
            </a>
          </div>

          <p className="questrial text-center text-redaria-black" style={{ fontSize:19,marginTop:15 }}>Seguimos trabajando para traer toda la experiencia de <span className="text-redaria-blue">Redaria</span> a tu ordenador.</p>

          <div className="flex items-center mr-4 mb-4">
            <input id="radio1" type="checkbox" name="radio" className="hidden" checked={this.state.checkedRadio} onChange={this.handleCheck} />
            <label htmlFor="radio1" className="flex items-center cursor-pointer questrial text-redaria-black" style={{ fontSize:16,marginTop:25 }}>
            
            <div className="w-6 h-6 inline-block mr-2 relative rounded-full flex items-center justify-center" style={{ border: "2px solid #152232" }}>
              {
                this.state.checkedRadio &&
                <div className="w-4 h-4 bg-blue-500 rounded-full absolute"></div>
              }
            </div>
            No volver a mostrar este mensaje</label>
          </div>

          <button onClick={this.finish} className="redaria-button-blue" style={{ marginTop:30,marginBottom:50 }}>Aceptar</button>

        </div>

      </div>
    )
  }
}


export default connect()(WelcomeModal)