import React, { PureComponent } from 'react';
import { AirConditioner } from '../../lib/Icon/icons/AirConditioner';
import { Elevator } from '../../lib/Icon/icons/Elevator';
import { EquippedBlue } from '../../lib/Icon/icons/Equipped';
import { FurnitureBlue, FurnitureWhite } from '../../lib/Icon/icons/Furniture';
import { Heater } from '../../lib/Icon/icons/Heater';
import { PetFriendly } from '../../lib/Icon/icons/PetFriendly';
import { PrivateSecurity } from '../../lib/Icon/icons/PrivateSecurity';
import { ServiceRoom } from '../../lib/Icon/icons/ServiceRoom';
import { SolarEnergy } from '../../lib/Icon/icons/SolarEnergy';
import { TvRoom } from '../../lib/Icon/icons/TvRoom';
import { WalkInCloset } from '../../lib/Icon/icons/WalkInCloset';
import IconButton from './IconButton';

export class AmmenitiesForm extends PureComponent {
  render() {
    if (!this.props.active) {
      return null
    }
    return (
      <div className="mt-5" style={{ marginBottom:59 }}>

        <div className="flex flex-row flex-wrap">
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 5) !== undefined}
            icon={this.props.ammenities.find(ammenity => ammenity.id === 5) !== undefined ? <FurnitureWhite className="w-9" /> : <FurnitureBlue className="w-9" />}
            label='Amueblado'
            onPress={this.props.toggleAmmenity}
            id={5}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 6) !== undefined}
            icon={<EquippedBlue className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 6) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Equipado'
            onPress={this.props.toggleAmmenity}
            id={6}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 7) !== undefined}
            icon={<PetFriendly className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 7) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Pet friendly'
            onPress={this.props.toggleAmmenity}
            id={7}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 8) !== undefined}
            icon={<ServiceRoom className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 8) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Cuarto de servicio'
            onPress={this.props.toggleAmmenity}
            id={8}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 9) !== undefined}
            icon={<AirConditioner className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 9) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Aire acondicionado'
            onPress={this.props.toggleAmmenity}
            id={9}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 10) !== undefined}
            icon={<Heater className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 10) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Calefacción'
            onPress={this.props.toggleAmmenity}
            id={10}
          />
          <IconButton
            viewBox="0 0 24 24"
            active={this.props.ammenities.find(ammenity => ammenity.id === 11) !== undefined}
            icon={<WalkInCloset className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 11) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Walk-in closet'
            onPress={this.props.toggleAmmenity}
            id={11}
          />
          <IconButton
            viewBox="0 0 27 20"
            active={this.props.ammenities.find(ammenity => ammenity.id === 12) !== undefined}
            icon={<TvRoom className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 12) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Cuarto TV'
            onPress={this.props.toggleAmmenity}
            id={12}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 13) !== undefined}
            icon={<SolarEnergy className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 13) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Energía solar'
            onPress={this.props.toggleAmmenity}
            id={13}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 14) !== undefined}
            icon={<Elevator className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 14) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Elevador'
            onPress={this.props.toggleAmmenity}
            id={14}
          />
          <IconButton
            active={this.props.ammenities.find(ammenity => ammenity.id === 15) !== undefined}
            icon={<PrivateSecurity className="w-9" fill={this.props.ammenities.find(ammenity => ammenity.id === 15) !== undefined ? '#fff' : '#2b77b7'} />}
            label='Seguridad privada'
            onPress={this.props.toggleAmmenity}
            id={15}
          />
        </div>
      </div>
    );

  }
}
