import React from 'react';

export function PrivateSecurity({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 25 28" className={className ? className : "w-5"}>
      <path
        d="M12.149 0L0 3.455v7.168a17.827 17.827 0 0011.88 16.805l.269.095.269-.095A17.827 17.827 0 0024.3 10.622V3.455zm0 22.306a13.031 13.031 0 01-4.877-4.113 12.859 12.859 0 01-2.35-6.078l7.226-2.055 7.226 2.055a12.858 12.858 0 01-2.35 6.078 13.03 13.03 0 01-4.875 4.113zm7.311-11.844l-7.311-2.079-7.311 2.079V7.109l7.311-2.079 7.311 2.079zm-17.847.16V4.673l9.73-2.767v1.677L3.225 5.891v4.732a14.488 14.488 0 002.738 8.512 14.648 14.648 0 005.379 4.572v1.777a16.24 16.24 0 01-9.73-14.861zm21.073 0a16.24 16.24 0 01-9.73 14.861v-1.777a14.648 14.648 0 005.379-4.572 14.488 14.488 0 002.738-8.512V5.891l-8.118-2.308V1.906l9.73 2.767z"
        data-name="Trazado 23805"
      />
    </svg>
  )
}
