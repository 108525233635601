import React from 'react';

export default function Edit(props) {
  return (
    <svg viewBox="0 0 13.045 12.979" {...props}>

      <path
        fill="#223242"
        d="M12.04 8.145a.325.325 0 00-.325.325v2.885a.976.976 0 01-.975.975H1.624a.976.976 0 01-.975-.975V2.892a.976.976 0 01.975-.975h2.885a.325.325 0 000-.65H1.624A1.626 1.626 0 000 2.892v8.466a1.626 1.626 0 001.624 1.624h9.116a1.626 1.626 0 001.624-1.624V8.47a.325.325 0 00-.324-.325zm0 0"
      />
      <path
        fill="#223242"
        d="M12.236.428a1.462 1.462 0 00-2.068 0l-5.8 5.8a.325.325 0 00-.083.143l-.76 2.748a.325.325 0 00.4.4l2.752-.762a.325.325 0 00.143-.083l5.8-5.8a1.464 1.464 0 000-2.068zM5.08 6.439l4.745-4.748 1.53 1.53-4.744 4.744zm-.306.613l1.222 1.222-1.69.465zm7.383-4.631l-.345.345-1.53-1.53.345-.345a.812.812 0 011.149 0l.381.381a.813.813 0 01.001 1.145zm0 0"
      />
    </svg>
  )
}
