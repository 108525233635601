import React from 'react';

export default function ProfileBlue() {
  return (
    <svg viewBox='0 0 22.5 22.457' className="w-12">

      <g data-name="Grupo 1547">
        <g data-name="Grupo 1131">
          <g data-name="Grupo 1130">
            <g data-name="Grupo 1129">
              <g
                data-name="Elipse 12"
                transform="translate(4.918)"
                fill="#ffffff"
                stroke="#ffffff"
              >
                <circle cx={6.5} cy={6.5} r={6.5} stroke="none" />
                <circle cx={6.5} cy={6.5} r={6} fill="none" />
              </g>
            </g>
          </g>
          <path
            data-name="Trazado 5368"
            d="M.502 21.713s.943-9.267 10.909-9.267 10.59 9.267 10.59 9.267"
            fill="#ffffff"
            stroke="#ffffff"
          />
        </g>
        <path
          data-name="Trazado 23648"
          d="M0 21.958h22.5"
          fill="#102131"
          stroke="#ffffff"
        />
      </g>

    </svg>
  )
}