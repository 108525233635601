import moment from "moment";

export const getPremierStauts = (state) => {
  
  const { startDate,endDate } = state.premier;
  
  if(startDate === null || endDate === null)
    return false

  const start = moment(startDate);
  const end   = moment(endDate);
  const now   = moment(Date.now());

  if(now.isBetween(start, end,'days', '[]'))
    return true
  else
    return false

}

export const getPremierDays = (state) => {
  const { endDate } = state.premier
  
  if(endDate === null)
    return 0
  
  const end     = moment(endDate)
  const now     = moment(Date.now())
  
  if(now.isSameOrBefore(end,'days'))
    return end.diff(now, 'days')+1
  else
    return 0

}

export const isFirstTimer = (state) => {
  const {showInvite, isPremier} = state.premier;
  return (showInvite && !isPremier);
}