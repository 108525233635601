import React, { PureComponent } from 'react'
import Certificate from './Certificate'
import EmptyCertificate from './EmptyCertificate'
import NewCertificate from './NewCertificate'
export default class CertificatesList extends PureComponent {


  emptyCertificates = () => {
    const { certificates } = this.props

    if(certificates.length === 3)
      return <div></div>
    
    const emptyCertificates = []

    for (let index = certificates.length +1 ; index < 3; index++) {
      emptyCertificates.push( <EmptyCertificate/>)
    }
    return emptyCertificates
  }

  render() {

    const { certificates,onCertificatePress,onNewCertificatePress } = this.props



    return (
      <div className="mt-5 flex flex-col w-full px-5">
      <p className="poppins-medium text-redaria-black mb-2" style={{ fontSize:13 }}>Certificados</p>
      <div className="flex flex-row justify-between">
        {
          certificates.length < 3
          &&
          <NewCertificate 
            openNewCertificateModal={onNewCertificatePress}
          />
        }

        {
          certificates.map( (certificate,index) => 
          <Certificate
            key={'certificate-'+index}
            certificate={certificate}
            onCertificatePress={onCertificatePress}
            />
          )
        }

        {
          this.emptyCertificates()
        }

       
      </div>
    </div>
    )
  }
}

