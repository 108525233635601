import React from 'react';

export function Park({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 27 27' className={className ? className : "w-5"}>
      <path
        d="M26.421 10.233v-.257a6.251 6.251 0 00-1.506-4.083 4.918 4.918 0 10-9.634 0 6.278 6.278 0 00-.955 1.5 3.258 3.258 0 00-.325.172 4.271 4.271 0 00-5.243 1.128 3.335 3.335 0 00-1.6.014 4.27 4.27 0 00-7.065.6.774.774 0 101.353.753 2.722 2.722 0 014.743-.031.774.774 0 001.016.308 1.757 1.757 0 011.521-.018.774.774 0 001-.31 2.734 2.734 0 012.351-1.35 2.706 2.706 0 011.529.47.773.773 0 00.214.1 6.368 6.368 0 00-.044.749 7.935 7.935 0 00.432 2.795 4.112 4.112 0 00.576 1.08c.1.134.536.536.5.722a4.9 4.9 0 004.043 5.833v4.466h-5.083v-1.6h.206a.774.774 0 000-1.548h-.155v-3.408a.774.774 0 00-.774-.774H1.701a.774.774 0 00-.774.774v3.406H.774a.774.774 0 100 1.548h.207v1.6H.774a.774.774 0 100 1.548h24.875a.774.774 0 100-1.548h-4.775v-4.466a4.9 4.9 0 004.043-5.833 6.28 6.28 0 001.268-2.363 7.521 7.521 0 00.236-1.977zM2.477 19.092h10.27v2.632H2.477zm.052 4.18h10.167v1.6H2.529zm22.356-12.781a5.064 5.064 0 01-1.372 3.325.774.774 0 00-.188.769 3.353 3.353 0 01-2.45 4.243v-7.24a.77.77 0 00.221-.154l1.85-1.85a.774.774 0 00-1.095-1.095l-.976.976V7.979a.774.774 0 10-1.548 0v4.568l-.8-.8a.774.774 0 10-1.096 1.092l1.85 1.85.045.041v4.1a3.353 3.353 0 01-2.45-4.243.774.774 0 00-.188-.769 4.718 4.718 0 01-1.363-3.326v-.513a4.718 4.718 0 011.363-3.326.774.774 0 00.188-.769 3.37 3.37 0 116.448 0 .774.774 0 00.188.769 5.157 5.157 0 011.373 3.84z"
      />
    </svg>
  )
}
