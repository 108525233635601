import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { CloseCircleBlue } from '../../lib/Icon/icons/Close';


export const ItemTypes = {
  CARD: 'card',
}


const style = {
    cursor: 'move',
};
export const Image = ({ id, img, moveCard, findCard,onFinishDrop,deleteImage }) => {
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem();
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
            onFinishDrop()
        },
    });
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    });
    const opacity = isDragging ? 0 : 1;
    return (
    <div ref={(node) => drag(drop(node))} style={{ ...style, opacity }}>
	   <div className="flex relative flex-col items-center my-2 mr-5 mb-10" >
        <div className="bg-white shadow-lg" style={{ borderRadius:10,height:80,width:85 }}>
          <img alt="upload shadow-lg" style={{ height:80,width:85,borderRadius:10, }} src={URL.createObjectURL(img)} />
        </div>
        <div 
        onClick={() => deleteImage(img.name)}
         className="absolute -top-6 -right-6 cursor-pointer">
          <CloseCircleBlue fill="#2b77b7" />
        </div>
      </div>
	</div>);
};
