import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

class MyPropertiesButton extends PureComponent {
  goProperties = () => this.props.history.push('/signed/me/properties')
  render() {
    const { propertiesActive } = this.props

    return (
      <div onClick={this.goProperties} className="cursor-pointer shadow-lg bg-white flex flex-col" style={{ borderRadius: 11, marginTop: 15 }}>
        <div className="flex flex-row w-full items-center justify-between my-3">
          <p className={`poppins-medium ${propertiesActive ? 'text-redaria-blue' : 'text-redaria-black'} ml-5`} style={{ fontSize: 21 }}>Propiedades</p>
          <div className={`${propertiesActive && 'bg-redaria-blue'} w-1.5 self-end h-12`} />
        </div>
      </div>
    )
  }
}

export default withRouter(MyPropertiesButton)