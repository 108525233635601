import React from 'react';

export default function Cellar({fill,className}) {
  return (
    <svg fill={ fill ? fill : '#2b77b7'} viewBox='0 0 27 27' className={className ? className :"w-5"}>
      <g id="bodega_svg__prefix__warehouse">
        <g id="bodega_svg__prefix__Grupo_1230" data-name="Grupo 1230">
          <g id="bodega_svg__prefix__Grupo_1229" data-name="Grupo 1229">
            <path
              id="bodega_svg__prefix__Trazado_5440"
              d="M26.628 7.671L13.651.063a.447.447 0 00-.443-.006L.231 7.217A.447.447 0 000 7.609V26.4a.447.447 0 00.447.447h2.238a.447.447 0 00.447-.447V10.741h20.585V26.4a.447.447 0 00.447.447H26.4a.447.447 0 00.447-.447V8.056a.446.446 0 00-.219-.385zm-.674 18.285h-1.342V10.294a.447.447 0 00-.447-.447H2.685a.448.448 0 00-.447.447v15.662H.895V7.873L13.418.964l12.536 7.349v17.643z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5440"
              transform="translate(0 -.002)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1232"
          data-name="Grupo 1232"
          transform="translate(16.557 20.584)"
        >
          <g id="bodega_svg__prefix__Grupo_1231" data-name="Grupo 1231">
            <path
              id="bodega_svg__prefix__Trazado_5441"
              d="M321.551 392.531h-5.37a.447.447 0 00-.447.447v5.37a.447.447 0 00.447.447h5.37a.447.447 0 00.447-.447v-5.37a.447.447 0 00-.447-.447zm-.448 5.37h-4.475v-4.475h4.472z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5441"
              transform="translate(-315.734 -392.531)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1234"
          data-name="Grupo 1234"
          transform="translate(18.347 21.032)"
        >
          <g id="bodega_svg__prefix__Grupo_1233" data-name="Grupo 1233">
            <path
              id="bodega_svg__prefix__Trazado_5442"
              d="M351.656 401.064v1.342h-.9v-1.342h-.895v1.79a.447.447 0 00.447.447h1.79a.447.447 0 00.447-.447v-1.79z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5442"
              transform="translate(-349.866 -401.064)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1236"
          data-name="Grupo 1236"
          transform="translate(11.187 20.584)"
        >
          <g id="bodega_svg__prefix__Grupo_1235" data-name="Grupo 1235">
            <path
              id="bodega_svg__prefix__Trazado_5443"
              d="M219.151 392.531h-5.37a.447.447 0 00-.447.447v5.37a.447.447 0 00.447.447h5.37a.447.447 0 00.447-.447v-5.37a.447.447 0 00-.447-.447zm-.448 5.37h-4.475v-4.475h4.472z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5443"
              transform="translate(-213.334 -392.531)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1238"
          data-name="Grupo 1238"
          transform="translate(12.977 21.032)"
        >
          <g id="bodega_svg__prefix__Grupo_1237" data-name="Grupo 1237">
            <path
              id="bodega_svg__prefix__Trazado_5444"
              d="M249.256 401.064v1.342h-.895v-1.342h-.895v1.79a.447.447 0 00.447.447h1.79a.447.447 0 00.447-.447v-1.79z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5444"
              transform="translate(-247.466 -401.064)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1240"
          data-name="Grupo 1240"
          transform="translate(16.557 15.214)"
        >
          <g id="bodega_svg__prefix__Grupo_1239" data-name="Grupo 1239">
            <path
              id="bodega_svg__prefix__Trazado_5445"
              d="M321.551 290.131h-5.37a.447.447 0 00-.447.447v5.37a.447.447 0 00.447.447h5.37a.447.447 0 00.447-.447v-5.37a.447.447 0 00-.447-.447zm-.448 5.37h-4.475v-4.475h4.472z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5445"
              transform="translate(-315.734 -290.131)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1242"
          data-name="Grupo 1242"
          transform="translate(18.347 15.662)"
        >
          <g id="bodega_svg__prefix__Grupo_1241" data-name="Grupo 1241">
            <path
              id="bodega_svg__prefix__Trazado_5446"
              d="M351.656 298.664v1.342h-.9v-1.342h-.895v1.79a.447.447 0 00.447.447h1.79a.447.447 0 00.447-.447v-1.79z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5446"
              transform="translate(-349.866 -298.664)"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1244"
          data-name="Grupo 1244"
          transform="translate(2.685 13.424)"
        >
          <g id="bodega_svg__prefix__Grupo_1243" data-name="Grupo 1243">
            <path
              id="bodega_svg__prefix__Rect\xE1ngulo_48"
              d="M0 0h21.479v.895H0z"
              className="bodega_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 48"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1246"
          data-name="Grupo 1246"
          transform="translate(2.685 11.634)"
        >
          <g id="bodega_svg__prefix__Grupo_1245" data-name="Grupo 1245">
            <path
              id="bodega_svg__prefix__Rect\xE1ngulo_49"
              d="M0 0h21.479v.895H0z"
              className="bodega_svg__prefix__cls-1"
              data-name="Rect\xE1ngulo 49"
            />
          </g>
        </g>
        <g
          id="bodega_svg__prefix__Grupo_1248"
          data-name="Grupo 1248"
          transform="translate(10.74 6.265)"
        >
          <g id="bodega_svg__prefix__Grupo_1247" data-name="Grupo 1247">
            <path
              id="bodega_svg__prefix__Trazado_5447"
              d="M209.723 119.464h-4.475a.448.448 0 00-.447.447v1.79a.448.448 0 00.447.447h4.475a.447.447 0 00.447-.447v-1.79a.447.447 0 00-.447-.447zm-.448 1.79H205.7v-.895h3.58z"
              className="bodega_svg__prefix__cls-1"
              data-name="Trazado 5447"
              transform="translate(-204.801 -119.464)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}