import React from 'react';

export default function Camera({ className }) {
  return (
    <svg viewBox='0 0 114 113' className={className ? className : "w-24"}>
      <g
        id="camera-circle-blue_svg__prefix__Grupo_1567"
        data-name="Grupo 1567"
        transform="translate(-115 -110.5)">
        <g transform="translate(115 110.5)">
          <ellipse
            id="camera-circle-blue_svg__prefix__Elipse_49-2"
            cx={48}
            cy={47.5}
            data-name="Elipse 49"
            rx={48}
            ry={47.5}
            transform="translate(9 6)"
            fill="#2b77b7"
          />
        </g>
        <g
          id="camera-circle-blue_svg__prefix__photo-camera"
          transform="translate(155.336 150.195)"
        >
          <g
            id="camera-circle-blue_svg__prefix__Grupo_1558"
            data-name="Grupo 1558"
          >
            <g
              id="camera-circle-blue_svg__prefix__Grupo_1557"
              data-name="Grupo 1557"
            >
              <circle
                id="camera-circle-blue_svg__prefix__Elipse_48"
                cx={6.684}
                cy={6.684}
                r={6.684}
                className="camera-circle-blue_svg__prefix__cls-2"
                data-name="Elipse 48"
                transform="translate(9.357 8.02)"
                fill="#fff"
              />
              <path
                id="camera-circle-blue_svg__prefix__Trazado_23692"
                d="M29.408 46.677h-4.239a1.994 1.994 0 01-1.418-.587l-2.444-2.444a3.321 3.321 0 00-2.363-.979h-5.806a3.321 3.321 0 00-2.363.979L8.331 46.09a1.994 1.994 0 01-1.418.587H2.674A2.676 2.676 0 000 49.351v17.377A2.676 2.676 0 002.674 69.4h26.735a2.676 2.676 0 002.674-2.674V49.351a2.676 2.676 0 00-2.675-2.674zM16.041 65.392a8.02 8.02 0 118.02-8.02 8.029 8.029 0 01-8.02 8.02zm10.694-12.031a1.337 1.337 0 111.337-1.337 1.338 1.338 0 01-1.337 1.337z"
                className="camera-circle-blue_svg__prefix__cls-2"
                data-name="Trazado 23692"
                transform="translate(0 -42.667)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export function CameraPlus(props) {
  return (
    <svg viewBox='0 0 60 60' {...props}>
      <g>
        <defs>
          <clipPath id="camara-icon_svg__a">
            <path
              d="M21 21H5a2 2 0 0 1-2-2V9h3V6h3V3h7l1.83 2H21a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zM13 8a5 5 0 1 0 5 5 5.005 5.005 0 0 0-5-5zm0 8.2a3.2 3.2 0 1 1 3.2-3.2 3.2 3.2 0 0 1-3.2 3.2zM5 8H3V5H0V3h3V0h2v3h3v2H5v3z"
              transform="translate(0 1)"
            />
          </clipPath>
          <clipPath id="camara-icon_svg__b">
            <path fill="none" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
        <circle id="prefix__prefix__Oval" cx={30} cy={30} r={30} fill="#2b77b7" />
        <g
          id="prefix__prefix__Image_ic_add_a_photo"
          data-name="Image/ic_add_a_photo"
          transform="translate(17 17)"
        >
          <path d="M24 24H0V0h24v24z" fill="#2b77b7" />
          <g transform="translate(0 1)" clipPath="url(#camara-icon_svg__a)">
            <g
              data-name="\u21B3 \uD83C\uDFA8Color"
              clipPath="url(#camara-icon_svg__b)"
            >
              <path data-name="Rectangle 2 Copy 2" fill="#fff" d="M0 0h24v24H0z" />
            </g>
          </g>

        </g>
      </g>
    </svg>
  )
}
export function CameraPlusGray(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 177.268 50.5"
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M29.217 30H6.957a2.824 2.824 0 01-2.784-2.857V12.857h4.175V8.572h4.173V4.285h9.739l2.546 2.858h4.41A2.824 2.824 0 0132 10v17.143A2.824 2.824 0 0129.217 30zM18.086 11.429a7.145 7.145 0 000 14.286 7.145 7.145 0 000-14.286zm0 11.714a4.573 4.573 0 114.453-4.572 4.518 4.518 0 01-4.453 4.572zM6.957 11.429H4.173V7.143H0V4.285h4.173V0h2.784v4.285h4.173v2.858H6.957v4.284z" />
        </clipPath>
        <clipPath id="prefix__b">
          <path className="prefix__a" d="M0 0h33.391v34.286H0z" />
        </clipPath>
        <style>{".prefix__a{fill:none}"}</style>
      </defs>
      <path
        d="M88.633 50.5a103.265 103.265 0 01-27.234-3.639 102.472 102.472 0 01-24.494-10.273A103.636 103.636 0 01-.001.001h177.268a103.641 103.641 0 01-36.906 36.587 102.472 102.472 0 01-24.494 10.273A103.265 103.265 0 0188.633 50.5z"
        fill="#a8a8a8"
        opacity={0.8}
      />
      <path className="prefix__a" d="M105.633 40h-33V7h33v33z" />
      <g transform="translate(72.633 9)" clipPath="url(#prefix__a)">
        <g transform="translate(0 -1.429)" clipPath="url(#prefix__b)">
          <path fill="#fff" d="M0 0h33.391v34.286H0z" />
        </g>
      </g>
    </svg>
  )
}

export function CameraPlusNormal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.467 31.467"
      {...props}
    >
      <defs>
        <clipPath id="prefix_CPN">
          <path
            className="prefix_CPN"
            d="M27.859 28.606H6.634a2.693 2.693 0 01-2.654-2.724V12.26h3.98V8.174h3.98V4.086h9.287l2.427 2.725h4.205a2.693 2.693 0 012.654 2.724v16.347a2.693 2.693 0 01-2.654 2.724zM17.246 10.9a6.813 6.813 0 000 13.622 6.813 6.813 0 000-13.622zm0 11.17a4.36 4.36 0 114.246-4.36 4.308 4.308 0 01-4.246 4.358zM6.634 10.9H3.98V6.812H0V4.086h3.98V0h2.654v4.086h3.98v2.726h-3.98V10.9z"
          />
        </clipPath>
        <clipPath id="prefix_CPNB">
          <path className="prefix_CPNB" d="M0 0h31.84v32.693H0z" />
        </clipPath>
        <style>{".prefix_CPN{fill:#123}.prefix_CPNB{fill:none}"}</style>
      </defs>
      <path className="prefix_CPNB" d="M31.467 31.467H0V0h31.467v31.467z" />
      <g transform="translate(0 1.907)" clipPath="url(#prefix_CPN)">
        <g transform="translate(0 -1.362)" clipPath="url(#prefix_CPNB)">
          <path className="prefix_CPN" d="M0 0h31.84v32.693H0z" />
        </g>
      </g>
    </svg>
  )
}