import React, {PureComponent} from 'react';
import AmmenityValue from './AmmenityValue';
import {getAdditionalAmmenities, getAmmenityValue} from '../../lib/Ammenities';
import { FurnitureBlack } from '../../lib/Icon/icons/Furniture';
import { PetFriendly } from '../../lib/Icon/icons/PetFriendly';
import { AirConditioner } from '../../lib/Icon/icons/AirConditioner';
import { Heater } from '../../lib/Icon/icons/Heater';
import { WalkInCloset } from '../../lib/Icon/icons/WalkInCloset';
import { TvRoom } from '../../lib/Icon/icons/TvRoom';
import { SolarEnergy } from '../../lib/Icon/icons/SolarEnergy';
import { Elevator } from '../../lib/Icon/icons/Elevator';
import { PrivateSecurity } from '../../lib/Icon/icons/PrivateSecurity';
import { ServiceRoom } from '../../lib/Icon/icons/ServiceRoom';
import Separator from './Separator';


export default class AdditionalAmmenities extends PureComponent {
  

  getIcon = (name) => {
    switch (name) {
      case 'furniture':
        return <FurnitureBlack fill="#112233" className="w-10"/>;
      case 'pet_friendly':
        return <PetFriendly fill="#112233" className="w-10"/>;
      case 'service_room':
        return <ServiceRoom fill="#112233" className="w-10"/>;
      case 'air_conditioner':
        return <AirConditioner fill="#112233" className="w-10"/>;
      case 'heater':
        return <Heater fill="#112233" className="w-10"/>;
      case 'walk_in_closet':
        return <WalkInCloset fill="#112233" className="w-10"/>;
      case 'tv_room':
        return <TvRoom fill="#112233" className="w-10"/>;
      case 'solar_energy':
        return <SolarEnergy fill="#112233" className="w-10"/>;
      case 'elevator':
        return <Elevator fill="#112233" className="w-10"/>;
      case 'private_security':
        return <PrivateSecurity fill="#112233" className="w-10"/>;
        
      default:
        return <PrivateSecurity fill="#112233" className="w-10"/>;
    }
  }

  render() {
    const additionalAmmenities = getAdditionalAmmenities(this.props.ammenities)
    if(additionalAmmenities.length === 0)
      return null
    return(
      <React.Fragment>
        <div className="flex flex-col my-5">
          <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Características adicionales:</p>
          <div className="flex flex-row flex-wrap mt-5">
          {
              additionalAmmenities.map((ammenity, index) =>
                <AmmenityValue
                  icon={this.getIcon(ammenity.slug)}
                  key={index}
                  label={ammenity.name}
                  value={getAmmenityValue(this.props.ammenities, ammenity.id)}
                />
              )
            }
          </div>
        </div>
        <Separator />
      </React.Fragment>
    );
  }
}
