import React, { PureComponent } from 'react'

export default class TermsAndConditions extends PureComponent {
  render() {
    return (
      <div className="max-w-screen-lg mx-auto">
         <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>Términos de Uso</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Al accesar, dar clic en aceptar a estos términos y condiciones y/o usar el sitio web
          www.redaria.com (el “Sitio Web”), o cualquier aplicación móvil (la “Aplicación Móvil” y conjuntamente
          con el Sitio Web, las “Aplicaciones”) que se ponga a disposición AC Tecnologías Inmobiliarias, S.A. de
          C.V. (el “Proveedor”), incluyendo sin limitar, cualquier tipo de Dispositivos, según dicho término se
          define más adelante, (los “Servicios”); el usuario de las Aplicaciones (el “Usuario”) manifiesta
          expresamente su conformidad con los presentes términos de uso y acepta sujetarse a los mismos (los
          “Términos de Uso”). El consentimiento del Usuario de éstos Términos de Uso es otorgado en términos
          de los artículos 1803, 1807 y 1811 del Código Civil Federal.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          El Usuario reconoce que los Servicios y las Aplicaciones son propiedad del Proveedor. Los
          Términos de Uso limitan los derechos y obligaciones del Usuario al hacer uso de las Aplicaciones. Si no
          está de acuerdo con los Términos de Uso, absténgase de acceder y/o ingresar a los Servicios y/o utilizar
          las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Puede darse el caso en que ofrezcamos servicios y aplicaciones especiales que tengan sus propios
          términos y condiciones adicionales a los presentes Términos de Uso. En dichos casos, los términos y
          condiciones específicos para los servicios especiales prevalecerán en caso de algún conflicto con los
          presentes Términos de Uso, y serán indicados previo al inicio del uso de dichos servicios.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          El Proveedor, se reserva el derecho de cambiar, modificar, añadir o eliminar parte o la totalidad
          de los Términos de Uso en cualquier momento, los cambios serán efectivos en el momento en que se
          publiquen en las Aplicaciones y/o por cualquier otro medio electrónico, por lo que el Usuario deberá
          revisar periódicamente las actualizaciones de los presentes Términos de Uso y se entenderá como
          aceptada cualquier modificación mediante el simple uso e interacción con las Aplicaciones por parte del
          Usuario posterior a dicha modificación.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>TÉRMINOS Y CONDICIONES</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">1.</span> El Usuario, con la aceptación de los presentes Términos de Uso, reconoce y manifiesta que deberá
          tener más de 18 años de edad cumplidos al momento de (i) aceptar los Términos de Uso y (ii)
          utilizar los Servicios.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">2.</span> El Usuario otorga su consentimiento, para que, las Aplicaciones utilicen de su teléfono móvil
          inteligente, computadora, tableta electrónica, y de cualquier otro dispositivo y/o aparato
          electrónico que sea utilizado para acceder a las Aplicaciones (los “Dispositivos”), de manera
          enunciativa más no limitativa, la cámara fotográfica, el auricular y micrófono para grabar audio,
          localización GPS (sistema de posicionamiento global, por sus siglas en inglés), datos de
          contactos, datos del perfil del Usuario, acceso a las cuentas programadas en los Dispositivos,
          acceso a internet sin límites,
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">3.</span> El Usuario manifiesta bajo protesta de decir verdad que toda información que proporcione durante
          el registro para la creación de la cuenta de Usuario de las Aplicaciones es verdadera, precisa,
          actual y completa, y se obliga a actualizar su información según sea necesario para mantener su veracidad y precisión. En caso de que el Usuario proporcione información errónea, incorrecta o
          falsa, el Proveedor podrá suspender al Usuario de utilizar los servicios o las Aplicaciones hasta
          que corrija dicha información; estando facultado el Proveedor para rescindir el presente Contrato
          de forma inmediata en caso de que se proporcione información incorrecta, errónea o falsa.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">4.</span> El Usuario reconoce y manifiesta su conformidad con que cualquier infracción, ya sea, a estos
          Términos de Uso y/o a las leyes aplicables que regulan las Aplicaciones, facultarán al Proveedor
          para cerrar la cuenta del Usuario y restringirlo de utilizar las Aplicaciones por tiempo indefinido,
          a la sola discreción del Proveedor, sin que esto signifique una renuncia a cualquier otra acción
          legal que pudiera ejercer el Proveedor en contra del Usuario en virtud de dicha infracción. En
          caso de que la infracción, a consideración del Proveedor sea un delito, remitirá la información
          correspondiente a las autoridades competentes, sin incurrir en responsabilidad alguna.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">5.</span> El Usuario manifiesta que es de su conocimiento que parte de los Servicios se pueden financiar
          mediante ingresos publicitarios, y en consecuencia las Aplicaciones pueden mostrar anuncios y/o
          promociones, por lo que el Usuario acepta que el Proveedor podrá insertar estos anuncios y
          promociones en las Aplicaciones, sobre, acerca de o junto con el contenido del Usuario. La
          manera, el modo y la extensión de estos anuncios y estas promociones están sujetos a cambios
          sin que sea necesario que el Proveedor notifique al Usuario, manifestando, el Usuario, su entera
          conformidad con lo anterior.
          El Usuario admite que el Proveedor no siempre podrá identificar en el contenido patrocinado o
          las comunicaciones comerciales de los servicios que tendrán un costo adicional.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">6.</span> El Usuario no enviará y/o publicará a través las Aplicaciones fotografías u otro tipo de contenido
          que muestre imágenes violentas, discriminatorias, ilegales, transgresoras, pornográficas o con
          contenido sexual y/o que violen derechos de terceros.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">7.</span> El Usuario será responsable de todo tipo de actividades(s) que se realicen a través de la cuenta
          creada para utilizar las Aplicaciones y se obliga a abstenerse de vender, transferir, otorgar
          licencias o ceder su cuenta, nombre de Usuario ni los derechos de la cuenta del usuario, así como
          cualquier marca, logotipo, denominación, imagen, promoción, información, derechos de autor,
          entre otros, publicados en las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">8.</span> El Usuario será responsable de mantener su contraseña e información de acceso en secreto y a
          salvo.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">9.</span> El Usuario se obliga a no solicitar, recopilar o utilizar otras credenciales de otros usuarios para
          tener acceso a las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">10.</span> El Usuario se obliga a no utilizar las Aplicaciones para fines no autorizados, ilegales y/o distinto
          a los previstos en los presentes Términos de Uso. Asimismo, el Usuario se obliga a cumplir con
          todas las leyes (federales, estatales y/o municipales) que le sean aplicables a las Aplicaciones y/o
          a los Servicios.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">11.</span> El Usuario es responsable por los datos, textos, archivos, información, nombres de usuario,
          imágenes, gráficos, fotografías, sonidos y cualquier otro tipo de contenido o material que envíe
          al Proveedor por medio de las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">12.</span> El Usuario no debe difamar, acosar, intimidar, abusar, hostigar, amenazar, intimidar o hacerse
          pasar por personas o entidades, y tampoco debe publicar información privada o confidencial a
          través en las Aplicaciones, incluyendo sin limitar, la información de tarjetas de crédito, números
          del seguro social o documentos de identidad, números de teléfono o direcciones de correo
          electrónico privados del Usuario o propiedad de cualquier otra persona.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">13.</span> El Usuario no debe cambiar, modificar, adaptar o alterar las Aplicaciones, ni cambiar, modificar
          o alterar otro sitio web, para que, de ello se deduzca erróneamente que está asociado a los
          Servicios o al Proveedor
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">14.</span> El Usuario no debe acceder a la interfaz de programación de aplicaciones (“API”, por sus siglas
          en inglés) privada del Proveedor, o en su caso a los APK, según sus siglas en inglés, mediante
          métodos distintos a los permitidos por el Proveedor.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">15.</span> El Usuario por este medio otorga su consentimiento respeto del aviso de privacidad del Proveedor
          para el uso de las Aplicaciones, el cual puede ser consultado en el Sitio Web.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">16.</span> El Usuario no debe crear ni enviar correos electrónicos, comentarios u otros métodos de
          comunicación comerciales o intimidatorios no deseados (es decir, spam) a ningún usuario de las
          Aplicaciones
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">17.</span> El Usuario no debe utilizar dominios o direcciones URL de la web como nombre de usuario de
          las Aplicaciones
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">18.</span> El Usuario no debe perjudicar ni interferir en las Aplicaciones o los servidores o las redes
          conectados a las Aplicaciones, incluyendo la transmisión de virus, programas espía, software
          malintencionado o cualquier otro código de naturaleza destructiva o perjudicial. El Usuario no
          puede insertar contenido o código, ni alterar o interferir el modo en el que se presenta o se muestra
          cualquier página del Proveedor en un explorador o dispositivo del Usuario.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">19.</span> El Usuario no debe crear cuentas en las Aplicaciones mediante medios no autorizados, incluyendo
          sin limitar, el uso de dispositivos automatizados, secuencias de comandos, robots, rastreadores,
          arañas o extracción de información web.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">20.</span> El Usuario no debe intentar restringir a otro usuario, ni a cualquier persona, el uso o disfrute de
          las Aplicaciones y no debe fomentar o facilitar las infracciones de estos Términos de Uso o de
          cualquier otra condición de uso del Proveedor.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">21.</span> El Usuario es responsable por cualquier cargo de datos en los que incurra por el uso de las
          Aplicaciones
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">22.</span> El Usuario tiene estrictamente prohibido el rastreo, scraping (extracción de información web),
          almacenamiento en caché o acceso a cualquier contenido en las Aplicaciones a través de medio
          automatizados, incluyendo de manera enunciativa más no limitativa, los perfiles de los usuarios,
          fotografías y/o información de cualquier tipo.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">23.</span> El Usuario por este medio cede gratuitamente al Proveedor todos los derechos de las fotografías
          e información que el Proveedor a través de las Aplicaciones, a excepción de toda aquella
          información que contravenga disposiciones legales que le apliquen a las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">24.</span> El Usuario reconoce y manifiesta su consentimiento en que la información que le proporcione al
          Proveedor por medio de las Aplicaciones, incluyendo sin limitar, a las fotografías, información
          personal del Usuario y/o cualquier otro información proporcionada por el Usuario (la
          “Información”), será utilizada por el Proveedor con fines estadísticos, para la elaboración de bases
          de datos, así mismo, el Usuario reconoce que el Proveedor utilizará dichas bases de datos con
          fines comerciales, otorgando en este acto él Usuario su consentimiento al Proveedor de transferir
          la Información a cualquier tercero, sin la necesidad de ningún consentimiento posterior por parte
          del Usuario ni notificación por parte del Proveedor. Manifestando el Usuario su entera
          conformidad: (i) con el uso que el Proveedor hará a la Información; y (ii) que en caso de que el
          Proveedor obtenga algún beneficio económico de las bases de datos creadas en base a la
          Información, dichas cantidades corresponderán exclusivamente al Proveedor sin corresponderle
          cantidad alguna al Usuario.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">25.</span> El Usuario reconoce que puede haber enlaces en las Aplicaciones o en las comunicaciones que
          reciba el Usuario de estas que remitan a sitios web o funciones de terceros. De igual manera
          pueden existir enlaces a sitios web o funciones de terceros en imágenes o comentarios dentro de
          las Aplicaciones. Las Aplicaciones también incluyen contenido de terceros que no se encuentra
          bajo el control ni mantenimiento del Proveedor, por lo que el Proveedor no avala dicho contenido.
          La funcionalidad en las Aplicaciones también puede permitir interacciones entre las Aplicaciones
          y un sitio web o una función de terceros, incluidas las aplicaciones que conectan a las
          Aplicaciones o al perfil del Usuario en las Aplicaciones con un sitio web o una función de
          terceros. Por ejemplo, las Aplicaciones pueden incluir una función que te permita compartir
          contenido desde éstas o su contenido con un tercero, el cual puede hacerse público en ese servicio
          o esa aplicación de terceros. El uso de esta funcionalidad normalmente requiere que el Usuario
          inicie una sesión en la cuenta de servicio de terceros y que se haga bajo la propia responsabilidad
          de Usuario. El Proveedor no controla ninguno de estos servicios web de terceros, ni sus
          contenidos. El Usuario reconoce y acepta expresamente que el Proveedor no es de ningún modo
          responsable de los servicios o funciones de esos terceros. LA CORRESPONDENCIA Y LOS
          ACUERDOS COMERCIALES CON TERCERAS PARTES QUE SE PRODUZCAN A
          TRAVÉS DE LAS APLICACIONES, YA SEA DIRECTA O INDIRECTAMENTE, SON
          EXCLUSIVOS DEL USUARIO Y ESA TERCERA PARTE. El Usuario puede, a su entera y
          absoluta discreción y bajo su responsabilidad, optar por utilizar aplicaciones que conecten las
          Aplicaciones o el perfil de las Aplicaciones con un servicio de terceros (en adelante, el “Servicio
          de Terceros”) y dicho Servicio de Terceros puede interactuar con el perfil de las Aplicaciones,
          conectarse a este o recopilar y/o extraer e introducir información en dicho perfil. Al utilizar los
          Servicios de Terceros, el Usuario reconoce y acepta que: (i) si utiliza un Servicio de Terceros
          para compartir información, manifiesta su conformidad con el uso que haga dicho tercero de su
          información; (ii) el uso que realice de Servicios de Terceros puede causar que la información de
          identificación personal proporcionada al Proveedor sea divulgada; y (iii) el uso que haga el
          Usuario de un Servicios de Terceros debe ejercerse bajo el criterio y responsabilidad del Usuario
          y evitar que el Proveedor sufra daños causados por la actividad relacionada con el Servicio de
          Terceros.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">26.</span> El Usuario libera por este medio al Proveedor de cualquier pérdida o dañó, sea directo y/o
          indirecto, económico y/o patrimonial, que resulte por el uso de las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">27.</span> El Usuario será el único responsable de la interacción que establezca con otros usuarios de las
          Aplicaciones, ya sea, con o sin conexión, liberando al Proveedor de cualquier responsabilidad
          que se pueda generar por dicha interacción.
          El Proveedor se reserva el derecho a supervisar o involucrarse en los conflictos que tenga el
          Usuario con otros usuarios de las Aplicaciones.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">28.</span> El Usuario en ningún momento podrá hacer uso de los Servicios a nombre de terceros.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">29.</span> El Proveedor es titular de todos los derechos sobre las Aplicaciones y/o los Servicios, así como
          de los derechos de propiedad intelectual referidos a los contenidos que en ella se incluyan, a
          excepción de los derechos sobre marcas y logotipos de los productos, promociones y/o servicios
          que se anuncien y sean mostrados en las Aplicaciones, incluyendo las marcas, productos,
          promociones, logotipos y/o servicios de los establecimientos participantes, cuyas marcas están
          registradas a favor de sus respectivos titulares, y como tal son reconocidas por el Proveedor. Las
          marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son
          utilizadas únicamente con fines enunciativos. Las páginas que vinculen a las Aplicaciones o sean
          vinculadas desde las mismas NO se encuentran bajo ningún tipo de licencia a menos que se
          indique lo contrario por sus respectivos propietarios. Los productos, promociones y/o servicios
          ofrecidos en las Aplicaciones no están oficialmente avalados, certificados o recomendados de
          manera alguna por ninguna empresa, organización o individuo mencionado a menos que se
          indique lo contrario. El Usuario reconoce que el Proveedor podrá modificar, cambiar y/o
          adicionar las promociones, productos, marcas y/p logotipos que se publiquen en las Aplicaciones,
          en cualquier momento y sin la necesidad de previo aviso por parte del Proveedor ni
          consentimiento por parte del Usuario, El Usuario no podrá reproducir, modificar, copiar,
          distribuir, licenciar, publicar, cargar, enviar o divulgar, cualquier texto, vídeo, programa de
          software, producto, servicio o cualquier otro servicio o producto contenido en las Aplicaciones,
          sin la autorización por escrito por parte del Proveedor.
          Los nombres, las imágenes, y las fotografías que identifican los productos, promociones y/o
          servicios que anuncien el Proveedor son propiedad en exclusiva de sus respectivos titulares, y
          como tal son reconocidas por el Proveedor. Cualquier referencia que pueda hacerse al emplear
          las Aplicaciones a algún tercero en materia de información, contenido, artículos, productos,
          procesos o servicios por marca de fábrica, marca comercial, fabricante o de cualquier otro modo,
          incluyendo las marcas, productos, promociones, logotipos y/o servicios de los establecimientos
          participantes, no constituye ni implica necesariamente su aceptación, aprobación o
          recomendación por parte de sus respectivos titulares, ni ninguna conexión del Proveedor con
          ellos. Queda, totalmente, prohibido su uso sin el consentimiento previo y expreso de sus
          respectivos titulares. Todas las demás referencias a otras marcas comerciales o registradas
          pertenecen a sus respectivos propietarios.
          El Proveedor no concede ninguna licencia o autorización de uso de ninguna clase sobre sus
          derechos de propiedad industrial, intelectual, secretos empresariales o sobre cualquier otra
          propiedad o derecho relacionado con las Aplicaciones y sus contenidos.
          En caso de que algún Usuario o tercero consideren que cualquiera de los contenidos en las
          Aplicaciones, violen los derechos de propiedad intelectual del Usuario o de terceros, estos
          deberán enviar una notificación a la siguiente dirección electrónica hola@redaria.com, en la que
          indiquen: i) datos personales verídicos (nombre, dirección, número de teléfono y dirección de
          correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los
          derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido (s)
          protegido (s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como
          la localización de dichas violaciones en las Aplicaciones; iv) declaración expresa y clara de que
          la introducción del (los) contenido (s) indicado (s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; v) declaración expresa, clara
          y bajo protesta de decir verdad del reclamante de que la información proporcionada en la
          notificación es exacta y de que la introducción del(los) contenido(s) constituye una violación de
          dichos derechos; y, vi) constancia de titularidad de los derechos de la propiedad intelectual
          supuestamente infringida.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">30.</span> El Proveedor se reserva el derecho de no brindar los Servicios a cualquier persona a su entera
          discreción.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">31.</span> El Proveedor tiene el propósito de que los Servicios estén disponibles el mayor tiempo posible,
          habrá ocasiones en las que los Servicios sean interrumpidos, incluyendo sin limitar, para el
          mantenimiento o las actualizaciones planificadas, las reparaciones de emergencia o debido a un
          fallo de los enlaces de telecomunicaciones y/o el Dispositivo. Asimismo, el Proveedor se reserva
          el derecho de eliminar cualquier contenido de las Aplicaciones por cualquier motivo, sin
          necesidad de previo aviso. El Proveedor puede seguir almacenando el contenido eliminado de las
          Aplicaciones, incluyendo sin limitar, para cumplir determinadas obligaciones derivadas de la
          legislación aplicable. Como consecuencia, el Proveedor recomienda encarecidamente que se
          mantenga una copia de seguridad propia del contenido del Usuario. En otras palabras, las
          Aplicaciones no son un servicio de copia de seguridad por lo que el Usuario acepta que no las
          utilizará con fines de almacenamiento o copia de seguridad del contenido. El Proveedor no se
          responsabilizará de la modificación, suspensión o interrupción de las Aplicaciones o de la pérdida
          de cualquier contenido que el Usuario envíe. También reconoce el Usuario que el internet puede
          estar sujeto a infracciones de seguridad y que el envío de contenido o de otra información puede
          no ser seguro.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">32.</span> El Proveedor no considerará, a menos que se describa lo contrario en el aviso de privacidad de
          los Servicios, mismo que se encuentra disponible el Sitio Web, ningún contenido como
          confidencial, por lo que no será responsable del uso o divulgación de este. El Usuario reconoce
          y acepta que la relación con el Proveedor no es confidencial, ni exclusiva en ningún modo y que
          la decisión de enviar cualquier tipo de contenido no hace que el Proveedor desempeñe un papel
          especial distinto del que desempeñan otros usuarios a nivel general, incluso en lo que respecta al
          contenido del Usuario. Ninguna parte del contenido del Usuario estará sujeta a ninguna
          obligación de confidencialidad por parte del Proveedor, y este no será responsable de ningún uso
          o divulgación del contenido proporcionado por el Usuario.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">33.</span> El Usuario reconoce y acepta que el Proveedor podrá utilizar un sistema de seguimiento y
          reproducción mediante la utilización de cookies (las "Cookies") y web beacon (“web beacon”).
          Las cookies son archivos temporales que se instalan en el equipo del Usuario y el web beacon es
          un código o imagen electrónica transparente, ambos permiten a el Proveedor medir patrones de
          tráfico en las Aplicaciones, así como conocer el comportamiento y la demografía del Usuario,
          con el fin de analizar su actividad y las búsquedas realizadas, para mejorar la oferta comercial del
          Proveedor, mostrar publicidad, noticias de interés, personalizar contenidos, presentación y
          servicios, así como hacer cumplir el Aviso Legal del Sitio.

          Los datos personales que obtenemos mediante las Cookies y el web beacon, son:<br/>
          a) Identificadores, nombres de usuario y contraseñas de una sesión.<br/>
          b) Tipo de navegador del usuario.<br/>
          c) Tipo de sistema operativo del usuario.<br/>
          d) Fecha y hora del inicio y final de una sesión de un usuario.<br/>

          EL Usuario reconoce que la instalación, permanencia y existencia de las cookies puede ser
          cancelada y/o eliminada de su equipo cuando el Usuario así lo desee, consultando la sección de
          Ayuda del navegador que utilice para acceder a Internet en los Dispositivos.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">34.</span> El Proveedor tiene como norma no aceptar o tomar en consideración contenido, información,
          ideas, sugerencias u otros materiales que no sean los específicamente solicitados y a los que se
          pueden aplicar determinados términos, condiciones y requisitos específicos. Lo anterior a fin de
          evitar que se produzcan malentendidos si las ideas del Usuario se asemejan a las que el Proveedor
          ha desarrollado o está desarrollando por su cuenta. En consecuencia, el Proveedor no aceptará
          materiales o ideas no solicitados, y no se responsabiliza de los materiales o ideas que sean
          transmitidos. Si, a pesar de lo anterior, el Usuario decide enviar contenido, información, ideas,
          sugerencias u otros materiales, este acepta que el Proveedor tiene libertad para usar cualquier
          dicho contenido, información, ideas, sugerencias u otros materiales mencionados con cualquier
          propósito, incluidos sin limitación, los productos y servicios de desarrollo y marketing sin que se
          espere ninguna responsabilidad o pago de ningún tipo en favor del Usuario.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">35.</span> Estos Términos de Uso son y serán regidos por las Leyes de la República Mexicana y cualquier
          disputa será sometida a la jurisdicción y competencia de las leyes y los tribunales de la Ciudad
          de Monterrey, Nuevo León, renunciando expresamente el Usuario, a cualquier otra jurisdicción
          que pudiere corresponderle por motivo de su domicilio o nacionalidad, presente o futuro, o por
          cualquier otra causa.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">36.</span> El Proveedor y el Usuario son partes contratantes totalmente independientes, por lo que no existe
          ningún nexo o relación obrero-patronal entre ellas ni con los empleados de cada una de ellas.
          Ambas Partes asumen cualquier responsabilidad derivada de sus respectivas relaciones
          personales y subordinadas, de carácter individual o colectivo, con sus trabajadores, factores,
          dependientes o personal administrativo, liberando de cualquier obligación de esa naturaleza a la
          otra Parte.
          Cada Parte será responsable por los actos de sus dependientes o factores en relación con el
          cumplimiento de estos Términos de Uso.
          El Usuario se obliga a rembolsar al Proveedor los gastos que haya incurrido y a ejercer las
          acciones que correspondan a fin de dar solución a los conflictos laborales que se susciten, de los
          que puedan resultar perjudicados intereses del Proveedor.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">37.</span> El Proveedor podrá rescindir de pleno derecho los presentes Términos de Uso, sin necesidad de
          declaración judicial, en caso de que el Usuario incumpla cualquiera de las obligaciones que
          contrae por virtud de los presentes Términos de Uso. En cuyo caso, el Proveedor se reserva el
          derecho de dar por terminado, limitar o negar por completo el acceso del Usuario a las
          Aplicaciones de manera inmediata, en cualquier momento y a su entera discreción, sin la
          necesidad de cualquier notificación al respecto.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">38.</span> En cumplimiento a los establecido en el Título Segundo, Capito I del Código de Comercio, tanto
          el Usuario como el Proveedor manifiestan expresamente su conformidad en que lo pactado en
          estos Términos de Uso consiste en un acuerdo de voluntades y que deberá de considerarse como
          tal en cumplimiento a lo establecido en los artículos 89, 90, 90 Bis, 91 y 93 y así como cualquier
          otro aplicable de dicho ordenamiento legal, a fin de manifestar que la aceptación por parte del Usuario de estos Términos de Uso consiste en una aceptación expresa mediante un Mensaje de
          datos, por lo que el Usuario acepta sujetarse a todo lo establecido en los presentes Términos de
          Uso.
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">39.</span> Toda comisión de venta que sea pactada entre los Usuarios de la aplicación, serán bajo su propia responsabilidad y costa.
          Por lo anterior, AC Tecnologías Inmobiliarias, S.A. de C.V. y/o REDARIA ni Legal Global Consulting, S.C. serán responsables
          en ninguna forma para el pago de comisiones de ventas pactadas entre usuarios.
          </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>Liberación de responsabilidad</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          LOS SERVICIOS, INCLUYENDO SIN LIMITAR, EL CONTENIDO DEL PROVEEDOR SE
          PROPORCIONAN "TAL CUAL", "COMO ESTÉ DISPONIBLE" Y "CON TODOS LOS FALLOS".
          DENTRO DEL ÁMBITO PERMITIDO POR LA LEY, NI EL PROVEEDOR, NI SUS PERSONAS
          CONTROLADORAS, NI NINGUNO DE SUS EMPLEADOS, GERENTES, OFICIALES O
          AGENTES (COLECTIVAMENTE, LAS "PARTES DEL PROVEEDOR") OFRECEN GARANTÍA O
          APROBACIÓN DE NINGÚN TIPO, EXPRESA O IMPLÍCITA, CON RESPECTO A: (A) LOS
          SERVICIOS; (B) CONTENIDOS DEL PROVEEDOR; (C) CONTENIDO DEL USUARIO; (D)
          INFORMACIÓN PROPORCIONA POR EL USUARIO; (E) LA SEGURIDAD ASOCIADA CON LA
          TRANSMISIÓN DE INFORMACIÓN A O A TRAVÉS DE LOS SERVICIOS. ASIMISMO, POR
          MEDIO DE LA PRESENTE, LAS PARTES DEL PROVEEDOR SE LIBERAN DE TODA
          RESPONSABILIDAD, EXPRESA O IMPLÍCITA, INCLUIDAS SIN LIMITACIÓN, LAS
          GARANTÍAS DE COMERCIABILIDAD, ADECUACIÓN A UN FIN PARTICULAR Y NO
          CONTRAVENCIÓN, TÍTULO, COSTUMBRE, TRANSACCIÓN, POSESIÓN PACÍFICA,
          INTEGRACIÓN DEL SISTEMA Y AUSENCIA DE VIRUS INFORMÁTICOS.
          LAS PARTES DEL PROVEEDOR NO MANIFIESTAN NI GARANTIZAN, QUE: (I) LOS
          SERVICIOS NO CONTIENEN ERRORES O NO SE INTERRUMPIRÁN; (II) LOS DEFECTOS SE
          CORREGIRÁN; (III) LOS SERVICIOS O EL SERVIDOR QUE PERMITE QUE LOS SERVICIOS
          ESTÉN DISPONIBLES NO CONTENGAN COMPONENTES PERJUDICIALES, INCLUIDOS SIN
          LIMITACIÓN, VIRUS; (IV) LA INFORMACIÓN (INCLUIDA CUALQUIER INSTRUCTIVO)
          SOBRE LOS SERVICIOS SEA PRECISA, COMPLETA Y/O ÚTIL, Y; (V) EL USO LOS SERVICIOS
          SEA LEGAL EN UNA JURISDICCIÓN EN CONCRETO.
          AL UTILIZAR O ACCEDER A LOS SERVICIOS, EL USUARIO MANIFIESTA Y GARANTIZA
          QUE LAS ACTIVIDADES QUE REALIZARÁ SERÁN LEGALES EN TODAS LAS
          JURISDICCIONES EN LAS QUE ACCEDA O UTILICE LOS SERVICIOS.
          LAS PARTES DEL PROVEEDOR NO AVALAN EL CONTENIDO DE LOS SERVICIOS, POR LO
          QUE EL USUARIO EN ESTE ACTO LIBERA AL PROVEEDOR DE TODA RESPONSABILIDAD
          CON RESPECTO A CUALQUIER PERSONA O ENTIDAD, SEA CUAL SEA EL TIPO DE
          PÉRDIDA, DAÑO, (YA SEA REAL, DERIVADO, CONSECUENCIAL, PUNITIVO O DE OTRO
          TIPO), LESIÓN, DEMANDA, U OTRA CAUSA DE CUALQUIER TIPO O NATURALEZA QUE SE
          BASE O RESULTE DE CUALQUIER CONTENIDO DE LOS SERVICIOS.
          EL PROVEEDOR HACE DEL CONOCIMIENTO DEL USUARIO QUE NO ASUME NINGUNA
          RESPONSABILIDAD RESPECTO A LAS APLICACIONES, INCLUYENDO SIN LIMITAR A: (I)
          EL FUNCIONAMIENTO DE LAS APLICACIONES; (II) DAÑOS, DESPERFECTOS Y/O FALLAS
          EN LOS APLICACIONES, MISMOS QUE OCASIONEN O PUDIERAN LLEGAR A OCASIONAR
          DAÑOS, DESPERFECTOS Y/O FALLOS EN LOS DISPOSITIVOS; (III) EL USO QUE SERÁ DADO
          A LAS BASES DE DATOS QUE SE ELABOREN CON LA INFORMACIÓN; ENTRE OTROS. EL
          USUARIO EN ESTE ACTO MANIFIESTA SU ENTERA CONFORMIDAD CON LO ANTERIOR
          LIBERANDO AL PROVEEDOR, DE TODA RESPONSABILIDAD EN LA QUE PUDIERA
          INCURRIR FRENTE AL USUARIO, POR EL USO DE LA APLICACIÓN QUE HAGA EL USUARIO
          O CUALQUIER TERCERO QUE TENGA ACCESO A LA MISMA, Y OBLIGANDOSE A SACAR
          EN PAZ Y A SALVO AL PROVEEDOR, Y A LAS PARTES DEL PROVEEDOR, DE CUALQUIER
          RECLAMACIÓN EN SU CONTRA POR EL USO DE LA APLICACIÓN QUE HAGA EL USUARIO.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>Limitación de responsabilidad; exención</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          BAJO NINGUNA CIRCUNSTANCIA, LAS PARTES DEL PROVEEDOR SERÁN RESPONSABLES
          ANTE EL USUARIO DE CUALQUIER PÉRDIDA O DAÑO DEL TIPO QUE SEA (INCLUIDOS,
          SIN LIMITACIÓN, LOS DAÑOS O PÉRDIDAS DIRECTOS, INDIRECTOS, ECONÓMICOS,
          EJEMPLARES, ESPECIALES, PUNITIVOS, CONSECUENCIALES, INCIDENTALES O
          DERIVADOS) QUE GUARDEN RELACIÓN DIRECTA O INDIRECTA CON: (A) LOS SERVICIOS;
          (B) EL CONTENIDO PROPORCIONADO POR EL PROVEEDOR EN LAS APLICACIONES; (C)
          EL CONTENIDO DEL USUARIO; (D) EL USO, LA INCAPACIDAD DE USO O EL DESEMPEÑO
          DE LOS SERVICIOS Y/O LAS APLICACIONES; (E) CUALQUIER ACCIÓN QUE SE LLEVE A
          CABO CON RESPECTO A LOS TITULARES DEL DERECHO DE PROPIEDAD INTELECTUAL O
          DE AUTOR; (F) CUALQUIER ERROR U OMISIÓN EN EL FUNCIONAMIENTO DE LOS
          SERVICIOS Y/O LAS APLICACIONES; O (H) CUALQUIER DAÑO A LOS DISPOSITIVOS,
          INCLUYENDO SIN LIMITAR, LOS DAÑOS CAUSADOS POR EL INCUMPLIMIENTO DE UNA
          MEDIDA DE SEGURIDAD O POR CUALQUIER VIRUS, ERROR, MANIPULACIÓN, FRAUDE,
          OMISIÓN, INTERRUPCIÓN, DEFECTO, DEMORA EN EL FUNCIONAMIENTO O
          TRANSMISIÓN, FALLO DE LA RED O LÍNEA INFORMÁTICA, O CUALQUIER OTRA AVERÍA,
          INCLUSO SI SON CASOS PREVISIBLES O SI SE HA ADVERTIDO O INFORMADO A LAS
          PARTES DEL PROVEEDORDE LA POSIBILIDAD DE DICHOS DAÑOS, YA SEA MEDIANTE
          UNA ACCIÓN DERIVADA DE UN CONTRATO, NEGLIGENCIA, RESPONSABILIDAD
          ESTRICTA O PERJUICIO, FALLO EN LAS TELECOMUNICACIONES O ROBO O
          DESTRUCCIÓN DE LOS SERVICIOS. EN NINGÚN CASO LAS PARTES DEL PROVEEDORSE
          RESPONSABILIZARÁN ANTE EL USUARIO POR LOS DAÑOS, PÉRDIDAS O CAUSAS, O
          ACCIÓN QUE SUPEREN LOS CIEN DÓLARES ESTADOUNIDENSES ($100.00 USD).
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>Indemnización</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          El Usuario, acepta defender (a petición del Proveedor), indemnizar y evitar que las Partes del Proveedor
          sufran daños causados o derivados de las demandas, acciones de responsabilidad, pérdidas y gastos,
          incluidos sin limitación, los honorarios y costas razonables de abogados, derivados de o, en algún modo,
          relacionados con cualquiera de los siguientes supuestos (incluso como consecuencia de las actividades
          directas en las Aplicaciones o las que has realizado por su cuenta): (i) la Información o el acceso o uso
          que haga a las Aplicaciones; (ii) el incumplimiento o supuesto incumplimiento por parte del Usuario de
          estos Términos de Uso; (iii) la infracción por parte del Usuario del derecho de un tercero, incluidos sin
          limitación los derechos de propiedad intelectual, publicidad, confidencialidad, propiedad o privacidad;
          (iv) la infracción por parte del Usuario de cualquier ley, reglamento, normativa, código, o requerimiento
          de cualquier autoridad gubernamental, incluidas sin limitación, todas las autoridades judiciales,
          administrativas; y/o (v) cualquier declaración falsa realizada por parte del Usuario. El Usuario cooperará
          en la medida en la que el Proveedor lo requiera, a fin de defender cualquier demanda o acción en contra
          del Proveedor, y ésta se reserva el derecho de asumir la defensa y el control exclusivos de cualquier
          asunto que esté sujeto a indemnización por parte de Usuario y, en ningún caso, éste interpondrá ninguna
          demanda sin el consentimiento previo por escrito del Proveedor.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>Modalidad de Pago de las Aplicaciones</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
        1.- El Usuario y Proveedor acuerdan que adicional a lo antes establecido, el Usuario podrá de manera opcional pagarle al Proveedor una modalidad premier por la cantidad de $395.69 m.n. (trescientos noventa y cinco pesos 69/100 moneda nacional) más IVA o su equivalente a cualquier otra moneda extranjera. <br/>
        2.- (Clientes Potenciales o Leads) El Proveedor a través de las Aplicaciones utilizará las propiedades publicadas en sus plataformas para generar clientes potenciales (también conocidos como leads). <br/>
        3.- (Derecho de Preferencia en Leads) Estos leads se entregarán a los Usuarios que estén en la modalidad premier de las Aplicaciones. En el caso de que un lead esté interesado en la propiedad de un Usuario premier el lead se entregará directo. Por otro lado, si un lead contacta al Proveedor en una propiedad de un Usuario que esté en modalidad gratuita de las Aplicaciones, el lead se entregará a un usuario Premier a quien se le entregarán los datos de contacto del asesor que publicó la propiedad para que puedan ofrecer la propiedad en conjunto. La generación de leads se realizará a través de la publicación de las propiedades en portales inmobiliarios, tales como, propiedades.com, Lamudi, entre otros. El Proveedor se limita a hacer cambios en la cantidad de portales inmobiliarios con quienes participa para la generación de leads y no está limitado en utilizar otros medios para generarlos.<br/>
        4.- La función de conexiones inteligentes es un sistema que permite hacer un emparejamiento entre solicitudes y propiedades. Al ser un Usuario premier, se activa esta función para que automáticamente sepan cuando alguien está interesado en una de sus propiedades, así como también, enterarse cuando alguien publique una propiedad que necesitan para sus clientes o terceros.<br/>
        5.- (Destacados) El Usuario premier tiene la posibilidad de destacar sus publicaciones hasta un máximo de 3 publicaciones destacadas. Es posible destacar propiedades, así como las solicitudes para que tengan mayor exposición en las Aplicaciones.<br/>
        6.- (Título de asesor premier) El Usuario premier tendrá un signo distintivo dentro de las Aplicaciones.<br/>
        7.- (Soporte preferencial adicional) El Usuario premier tendrá un derecho de preferencia para soporte técnico para que sus sean solventados en cualquier problema técnico que esté relacionado con las Aplicaciones.<br/>
        8.- El Usuario podrá solicitar su factura vía el portal de www.redaria.com/facturacion utilizando el folio ubicado en su comprobante de pago, siempre y cuando sea dentro de los siguientes 15 días hábiles en que fue realizado el pago.<br/>
        9.- El Usuario podrá solicitar la cancelación y reembolso de su suscripción en la modalidad premier de las Aplicaciones dentro de las 48 horas naturales siguientes al pago de la modalidad premier.<br/>
        10.- El Usuario acepta que la suscripción en la modalidad premier se podrá cobrar de manera automática por lo que asume la responsabilidad total de realizar la cancelación de su suscripción de manera manual.<br/><br/>
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
        El Usuario al dar clic en aceptar manifiesta que: (i) ha leído en su totalidad los presentes Términos de Uso; (ii) entiende el contenido de estos Términos de Uso, así como su alcance y sus consecuencias legales; (iii) no existe vicio de voluntad en el otorgamiento de su consentimiento; y (iv) acepta de conformidad todo y cada uno de los Términos de Uso aquí escritos.
        </div>
      </div>
    )
  }
}
