import React from 'react';

export function SolarEnergy({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 25 25" className={className ? className : "w-5"}>
      <g
        id="solar-panel_svg__prefix__solar-panel"
        transform="translate(-4.75 -4.75)"
      >
        <path
          id="solar-panel_svg__prefix__Trazado_23803"
          d="M29.336 17.676L25.663 5.012a.363.363 0 00-.349-.262H8.786a.363.363 0 00-.349.262L4.764 17.676a.362.362 0 00-.014.1v1.868a.363.363 0 00.363.363H14.1v.882a.363.363 0 00.363.363h1.291v4.879h-1.292a.363.363 0 00-.363.363v.882H8.786a.364.364 0 00-.363.363v1.246a.364.364 0 00.363.363h16.528a.364.364 0 00.363-.363v-1.243a.364.364 0 00-.363-.363H20V26.5a.363.363 0 00-.363-.363h-1.289v-4.883h1.291a.363.363 0 00.361-.363v-.882h8.985a.363.363 0 00.363-.363v-1.869a.362.362 0 00-.014-.1zM5.6 17.414l1.6-5.5h3.92l-.867 5.5zm6.258-5.5h4.832v5.5h-5.7zm5.558 0h4.832l.867 5.5h-5.7zm6.436 5.5l-.867-5.5h3.927l1.6 5.5zm2.848-6.228h-3.833l-.9-5.709h3.075zm-4.566 0h-4.72V5.477h3.818zm-5.447-5.709v5.709h-4.718l.9-5.709zm-7.628 0h3.075l-.9 5.709H7.4zM5.477 18.14H10.2v1.142H5.477zm5.449 0h5.761v1.142h-5.761zm14.025 10.484H9.149V28.1h15.8zm-5.675-1.246h-4.451v-.519h4.451zm-1.655-1.246h-1.142v-4.878h1.142zm1.655-5.605h-4.451v-.519h4.451zm.363-1.246h-2.225V18.14h5.761v1.142zm4.262 0V18.14h4.723v1.142z"
          className="solar-panel_svg__prefix__cls-1"
          data-name="Trazado 23803"
        />
        <path
          id="solar-panel_svg__prefix__Trazado_23804"
          d="M98.964 16.037l.349 1.2a.363.363 0 10.7-.2l-.349-1.2a.363.363 0 10-.7.2z"
          className="solar-panel_svg__prefix__cls-1"
          data-name="Trazado 23804"
          transform="translate(-74.643 -8.575)"
        />
      </g>
    </svg>
  )
}
