import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom';
import Archive from '../../lib/Icon/icons/Archive'
import LoadingModal from '../Loading/LoadingModal';

class OptionsPropertyModal extends PureComponent {



  constructor(props) {
    super(props);

    this.state = {
      loading:false
    }
  }


  

  render() {

    
  
    return (
      <div 
       className="flex flex-col absolute bg-white shadow-lg z-10" 
       style={{ borderRadius:7,width:319,bottom:54,right:17,paddingLeft:14,paddingTop:23,paddingRight:16,paddingBottom:22 }}>
        
        <LoadingModal visible={this.state.loading}/>
        
        <div
          onClick={ e => {
            e.stopPropagation()
            this.props.removeArchive()
          }}
          className="flex cursor-pointer flex-row items-center">
          <Archive style={{width: 15,height: 14,marginRight:9}}/>
          <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Eliminar de archivo</p>
        </div>



      </div>
    )
  }
}

export default withRouter(OptionsPropertyModal)