import React from 'react';

export function FurnitureWhite({className}) {
  return (
    <svg viewBox="0 0 29 17" className={className ? className : "w-5"}>
      <g>
      <g data-name="Grupo 1612">
        <path
          d="M27.563 4.437h-.576V2.296a2.3 2.3 0 00-2.3-2.3h-8.32a2.294 2.294 0 00-1.871.968 2.294 2.294 0 00-1.871-.968H7.4a.425.425 0 000 .85h5.227a1.448 1.448 0 011.446 1.446v4.249l-.027-.025a2.778 2.778 0 00-1.875-.74H5.1a1.457 1.457 0 00-.963-1.262 1.412 1.412 0 00-.471-.08h-.8V2.296A1.448 1.448 0 014.312.85H5.7a.425.425 0 000-.85H4.309a2.3 2.3 0 00-2.3 2.3v2.141h-.572A1.439 1.439 0 000 5.874v4.432a3 3 0 002.993 3h.213l-.465 1.252a1.241 1.241 0 001.151 1.656h.683a1.235 1.235 0 00.98-.487l1.832-2.421h14.227l1.831 2.421a1.235 1.235 0 00.979.487h.683a1.24 1.24 0 001.151-1.656l-.466-1.252h.214a3 3 0 002.994-3V5.87a1.439 1.439 0 00-1.437-1.433zM14.925 2.296A1.448 1.448 0 0116.371.85h8.32a1.448 1.448 0 011.446 1.446v2.141h-.8a1.471 1.471 0 00-1.326.883 1.428 1.428 0 00-.108.46h-7.076a2.786 2.786 0 00-1.9.765V2.296zM5.1 6.629h7.073a1.9 1.9 0 011.9 1.9v.6H5.1zm-.222 8.583a.381.381 0 01-.3.15h-.686a.382.382 0 01-.355-.51l.576-1.548h2.208zm20.23.15h-.683a.381.381 0 01-.3-.15l-1.446-1.906h2.208l.576 1.548a.382.382 0 01-.355.508zm.9-2.908H2.993A2.146 2.146 0 01.85 10.306V5.87a.588.588 0 01.588-.588h2.225a.588.588 0 01.588.588v3.686a.425.425 0 00.425.425h15.6a.425.425 0 000-.85h-5.351v-.6a1.9 1.9 0 011.9-1.9H23.9v2.506h-1.922a.425.425 0 000 .85h2.347a.425.425 0 00.425-.425V5.876a.588.588 0 01.588-.588h2.226a.588.588 0 01.588.588v4.43a2.146 2.146 0 01-2.145 2.147z"
          data-name="Trazado 23742"
          fill="#fff"
        />
      </g>
    </g>
    </svg>
  )
}
export function FurnitureBlue({className}) {
  return (
    <svg viewBox="0 0 29 17" className={className ? className : "w-5"}>
      <g data-name="Grupo 1612">
        <path
          data-name="Trazado 23742"
          d="M27.563 4.437h-.576V2.296a2.3 2.3 0 00-2.3-2.3h-8.32a2.294 2.294 0 00-1.871.968 2.294 2.294 0 00-1.871-.968H7.4a.425.425 0 000 .85h5.227a1.448 1.448 0 011.446 1.446v4.249l-.027-.025a2.778 2.778 0 00-1.875-.74H5.1a1.457 1.457 0 00-.963-1.262 1.412 1.412 0 00-.471-.08h-.8V2.296A1.448 1.448 0 014.312.85H5.7a.425.425 0 000-.85H4.309a2.3 2.3 0 00-2.3 2.3v2.141h-.572A1.439 1.439 0 000 5.874v4.432a3 3 0 002.993 3h.213l-.465 1.252a1.241 1.241 0 001.151 1.656h.683a1.235 1.235 0 00.98-.487l1.832-2.421h14.227l1.831 2.421a1.235 1.235 0 00.979.487h.683a1.24 1.24 0 001.151-1.656l-.466-1.252h.214a3 3 0 002.994-3V5.87a1.439 1.439 0 00-1.437-1.433zM14.925 2.296A1.448 1.448 0 0116.371.85h8.32a1.448 1.448 0 011.446 1.446v2.141h-.8a1.471 1.471 0 00-1.326.883 1.428 1.428 0 00-.108.46h-7.076a2.786 2.786 0 00-1.9.765V2.296zM5.1 6.629h7.073a1.9 1.9 0 011.9 1.9v.6H5.1zm-.222 8.583a.381.381 0 01-.3.15h-.686a.382.382 0 01-.355-.51l.576-1.548h2.208zm20.23.15h-.683a.381.381 0 01-.3-.15l-1.446-1.906h2.208l.576 1.548a.382.382 0 01-.355.508zm.9-2.908H2.993A2.146 2.146 0 01.85 10.306V5.87a.588.588 0 01.588-.588h2.225a.588.588 0 01.588.588v3.686a.425.425 0 00.425.425h15.6a.425.425 0 000-.85h-5.351v-.6a1.9 1.9 0 011.9-1.9H23.9v2.506h-1.922a.425.425 0 000 .85h2.347a.425.425 0 00.425-.425V5.876a.588.588 0 01.588-.588h2.226a.588.588 0 01.588.588v4.43a2.146 2.146 0 01-2.145 2.147z"
          fill="#2b77b7"
        />
      </g>
    </svg>
  )
}
export function FurnitureBlack({className}) {
  return (
    <svg viewBox="0 0 29 17" className={className ? className : "w-5"}>
      <g data-name="Grupo 1612">
        <path
          data-name="Trazado 23742"
          d="M27.563 4.437h-.576V2.296a2.3 2.3 0 00-2.3-2.3h-8.32a2.294 2.294 0 00-1.871.968 2.294 2.294 0 00-1.871-.968H7.4a.425.425 0 000 .85h5.227a1.448 1.448 0 011.446 1.446v4.249l-.027-.025a2.778 2.778 0 00-1.875-.74H5.1a1.457 1.457 0 00-.963-1.262 1.412 1.412 0 00-.471-.08h-.8V2.296A1.448 1.448 0 014.312.85H5.7a.425.425 0 000-.85H4.309a2.3 2.3 0 00-2.3 2.3v2.141h-.572A1.439 1.439 0 000 5.874v4.432a3 3 0 002.993 3h.213l-.465 1.252a1.241 1.241 0 001.151 1.656h.683a1.235 1.235 0 00.98-.487l1.832-2.421h14.227l1.831 2.421a1.235 1.235 0 00.979.487h.683a1.24 1.24 0 001.151-1.656l-.466-1.252h.214a3 3 0 002.994-3V5.87a1.439 1.439 0 00-1.437-1.433zM14.925 2.296A1.448 1.448 0 0116.371.85h8.32a1.448 1.448 0 011.446 1.446v2.141h-.8a1.471 1.471 0 00-1.326.883 1.428 1.428 0 00-.108.46h-7.076a2.786 2.786 0 00-1.9.765V2.296zM5.1 6.629h7.073a1.9 1.9 0 011.9 1.9v.6H5.1zm-.222 8.583a.381.381 0 01-.3.15h-.686a.382.382 0 01-.355-.51l.576-1.548h2.208zm20.23.15h-.683a.381.381 0 01-.3-.15l-1.446-1.906h2.208l.576 1.548a.382.382 0 01-.355.508zm.9-2.908H2.993A2.146 2.146 0 01.85 10.306V5.87a.588.588 0 01.588-.588h2.225a.588.588 0 01.588.588v3.686a.425.425 0 00.425.425h15.6a.425.425 0 000-.85h-5.351v-.6a1.9 1.9 0 011.9-1.9H23.9v2.506h-1.922a.425.425 0 000 .85h2.347a.425.425 0 00.425-.425V5.876a.588.588 0 01.588-.588h2.226a.588.588 0 01.588.588v4.43a2.146 2.146 0 01-2.145 2.147z"
          fill="#112233"
        />
      </g>
    </svg>
  )
}