import React from 'react';

export function Grill({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 25 28' className={className ? className : "w-5"}>
      <g id="grill_svg__prefix__grill" transform="translate(-29.999)">
        <g
          id="grill_svg__prefix__Grupo_1654"
          data-name="Grupo 1654"
          transform="translate(29.999)"
        >
          <g id="grill_svg__prefix__Grupo_1653" data-name="Grupo 1653">
            <path
              id="grill_svg__prefix__Trazado_23808"
              d="M185.849 6.029a.41.41 0 00.692.441c.054-.084 1.3-2.072-.055-3.43a1.458 1.458 0 01-.414-1.54 2.467 2.467 0 01.428-.82.41.41 0 00-.618-.539 3.28 3.28 0 00-.592 1.1 2.274 2.274 0 00.611 2.379c.888.887-.037 2.38-.052 2.409z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23808"
              transform="translate(-176.695)"
            />
            <path
              id="grill_svg__prefix__Trazado_23809"
              d="M81.166 227.162A3.76 3.76 0 0178 225.438a.41.41 0 00-.688.446 4.577 4.577 0 003.853 2.1.41.41 0 000-.82z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23809"
              transform="translate(-74.664 -212.933)"
            />
            <path
              id="grill_svg__prefix__Trazado_23810"
              d="M292.2 6.029a.41.41 0 10.691.441c.054-.084 1.3-2.072-.055-3.43a1.458 1.458 0 01-.414-1.54 2.465 2.465 0 01.433-.822.41.41 0 00-.619-.538 3.278 3.278 0 00-.592 1.1 2.274 2.274 0 00.611 2.379c.887.891-.046 2.395-.055 2.41z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23810"
              transform="translate(-277.229)"
            />
            <path
              id="grill_svg__prefix__Trazado_23811"
              d="M239.024 6.029a.41.41 0 10.691.441c.054-.084 1.3-2.072-.055-3.43a1.458 1.458 0 01-.414-1.54 2.467 2.467 0 01.433-.822.41.41 0 00-.619-.538 3.278 3.278 0 00-.592 1.1 2.274 2.274 0 00.611 2.379c.888.891-.045 2.396-.055 2.41z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23811"
              transform="translate(-226.962)"
            />
            <path
              id="grill_svg__prefix__Trazado_23812"
              d="M354.849 469.988v.053a.41.41 0 10.82 0v-.053a.41.41 0 00-.82 0z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23812"
              transform="translate(-337.084 -443.899)"
            />
            <path
              id="grill_svg__prefix__Trazado_23813"
              d="M142.149 469.988v.053a.41.41 0 10.82 0v-.053a.41.41 0 00-.82 0z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23813"
              transform="translate(-136.016 -443.899)"
            />
            <path
              id="grill_svg__prefix__Trazado_23814"
              d="M54.717 148.139a.941.941 0 00-.939-.941H50.91v-.121a1.471 1.471 0 00-1.468-1.472h-1.585a1.471 1.471 0 00-1.468 1.472v.121h-1.77v-.121a1.471 1.471 0 00-1.468-1.472h-1.586a1.471 1.471 0 00-1.468 1.472v.121h-1.876v-.121a1.471 1.471 0 00-1.468-1.472h-1.585a1.471 1.471 0 00-1.468 1.472v.121h-2.762a.941.941 0 00-.939.941 6.245 6.245 0 006.133 6.249v7.533a1.877 1.877 0 10.82 0v-3.286h10.812v3.286a1.877 1.877 0 10.82 0v-7.533a6.245 6.245 0 006.133-6.249zm-7.508-1.062a.65.65 0 01.647-.652h1.586a.65.65 0 01.647.652v.121h-2.88zm-6.292 0a.65.65 0 01.647-.652h1.586a.65.65 0 01.647.652v.121h-2.879zM37.6 163.759a1.057 1.057 0 11-1.057-1.063 1.061 1.061 0 011.057 1.063zm11.632 0a1.057 1.057 0 11-1.057-1.063 1.061 1.061 0 011.056 1.063zm-.74-10.191h-1.957a.41.41 0 000 .82h1.229v3.426H36.952v-3.426h7.785a.41.41 0 000-.82h-8.512a5.424 5.424 0 01-5.406-5.429.12.12 0 01.119-.121h4.309a.41.41 0 000-.82h-.727v-.121a.65.65 0 01.647-.652h1.586a.65.65 0 01.647.652v.121h-.647a.41.41 0 000 .82h17.025a.12.12 0 01.119.121 5.424 5.424 0 01-5.406 5.43z"
              className="grill_svg__prefix__cls-1"
              data-name="Trazado 23814"
              transform="translate(-29.999 -137.643)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
