import Api from './Api';
import AuthenticatedApi from './AuthenticatedApi';

export default class PasswordApi {
  static sendResetEmail(email) {
    return Api.post('/api/password/create', {}, {email: email}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static resetPassword(data) {
    return AuthenticatedApi.patch('/api/me/password', {}, data).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }
}
