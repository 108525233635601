import React from 'react';

export function PremierHide({className}) {
  return (
    <svg fill='#ffffff' viewBox='0 0 170 170' className={className ? className : "w-5"}>
      <g>
        <path id="Sustracción_9" data-name="Sustracción 9" d="M82.5,165a82.115,82.115,0,0,1-46.126-14.09,82.743,82.743,0,0,1-29.891-36.3A82.391,82.391,0,0,1,14.09,36.372a82.743,82.743,0,0,1,36.3-29.891,82.391,82.391,0,0,1,78.239,7.607,82.743,82.743,0,0,1,29.89,36.3,82.39,82.39,0,0,1-7.607,78.239,82.742,82.742,0,0,1-36.3,29.89A81.983,81.983,0,0,1,82.5,165ZM41,78a5,5,0,1,0,0,10h83a5,5,0,0,0,0-10Z" transform="translate(0 0.002)" fill="#223242" />
      </g>
    </svg>
  )
}
export function PremierShow({className}) {
  return (
    <svg fill='#ffffff' viewBox='0 0 170 170' className={className ? className : "w-5"}>
      <g>
        <path id="plus" d="M82.5,0A82.5,82.5,0,1,0,165,82.5,82.594,82.594,0,0,0,82.5,0Zm39.664,88.847H88.847v34.9a6.346,6.346,0,1,1-12.692,0v-34.9H42.837a6.346,6.346,0,1,1,0-12.692H76.154V44.423a6.346,6.346,0,1,1,12.692,0V76.154h33.318a6.346,6.346,0,1,1,0,12.692Z" fill="#223242" />
      </g>
    </svg>
  )
}