import React, { PureComponent } from 'react'
import ShareProperty from '../../lib/Icon/icons/ShareProperty'

export class ShareButton extends PureComponent {
  render() {
    return (
      <div onClick={ () => {
        this.props.openShare()
      }} className="relative flex items-center justify-center cursor-pointer" style={{ width:280,height:33,backgroundColor:'#68d058',borderRadius:9,borderWidth:1,borderColor:'#fff',boxShadow: "0px 3px 6px #00000040" }}>
        <ShareProperty className="absolute left-0 bottom-0 -ml-2" style={{height:42,width:35,top:-1}}/>
        <p className="poppins-medium text-white" style={{ fontSize:16 }}>Compartir</p>
      </div>
    )
  }
}

export default ShareButton
