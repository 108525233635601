import Api from './Api';
import AuthenticatedApi from './AuthenticatedApi';


export default class UserRequest {

  static saveFirebaseToken(token) {
    return AuthenticatedApi.post('/api/web/firebase/save', {}, {token}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }
  static deleteFirebaseToken(token) {
    return AuthenticatedApi.post('/api/web/firebase/delete', {}, {token}).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static create(params) {
    return Api.post('/api/register', {}, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static update(params) {
    return AuthenticatedApi.patch('/me', {}, params).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  }

  static createProfile(params) {
    return AuthenticatedApi.post('/api/profile', {}, params).then(result => {
      return result
    }).catch(error => {
      throw error;
    })
  }

  static updateProfile(params) {
    return AuthenticatedApi.post('/api/profile/update', {}, params).then(result => {
      return result
    }).catch(error => {
      throw error;
    })
  }

  static profile() {
    return AuthenticatedApi.get('/api/me', {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static facebookLogin(fbToken) {
      const PARAMS = {
        fbToken: fbToken,
        fcmToken: ''
      };
      return Api.post('/api/auth/facebook', {}, PARAMS).then(result => {
        return result;
      })
  }

  static googleLogin(googleToken) {
      const PARAMS = {
        googleToken: googleToken,
        fcmToken: ''
      };
      return Api.post('/api/auth/google', {}, PARAMS).then(result => {
        return result;
    }).catch(error => {
      throw error;
    });
  }

  static login(email, password,captcha) {

    const PARAMS = {
      captcha      : captcha,
      username     : email,
      password     : password,
      fcm_token    : '',
      grant_type   : 'password',
      client_id    : process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '*'
    };
    return Api.post('/api/sessions', {}, PARAMS).then(result => {
      return result;
    });
  }


  static searchPRPS(id) {

    const PARAMS = { id };

    return AuthenticatedApi.post('/api/me/search', {}, PARAMS).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static searchMyProperties(search){

    const PARAMS = {search}

    return AuthenticatedApi.post('/api/me/search/properties', {}, PARAMS).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });

  }

}
