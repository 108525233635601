import React from 'react'

const OptionsButton = ({openOptions}) => {
  return (
    
      <div className="cursor-pointer border-redaria-blue flex row items-center justify-center w-full" onClick={openOptions} style={{ height:37,boxShadow: "0px 3px 6px #00000040",borderRadius:8 }}>
        <div className="flex flex-col items-center justify-center" style={{ marginTop:5 }}>
          <div className="bg-redaria-blue" style={{borderRadius:5,height:5,width:5,marginBottom:5}}/>
          <div className="bg-redaria-blue" style={{borderRadius:5,height:5,width:5,marginBottom:5}}/>
          <div className="bg-redaria-blue" style={{borderRadius:5,height:5,width:5,marginBottom:5}}/>
        </div>
        <p className="poppins-medium text-redaria-blue ml-3" style={{ fontSize:21 }}>Opciones</p>
      </div>

  )
}

export default OptionsButton
