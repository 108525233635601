import React, { PureComponent } from 'react'
import EmptyFeed from '../../lib/Icon/icons/EmptyFeed'

export default class EmptyList extends PureComponent {
  render() {
    const { title, description } = this.props
    return (
      <div className="flex flex-col justify-center items-center mt-5 w-full mt-10">
          <EmptyFeed className="w-14"/>
        <p className="title text-redaria-black poppins-semi-bold mt-5">{title}</p>
        <p className="sub-title text-redaria-black poppins-regular">{description}</p>
      </div>
    )
  }
}
