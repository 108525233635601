import React, { PureComponent } from 'react'
import { DealTypeForm } from '../Forms/DealTypeForm'
import EditImagesForm from '../Forms/EditImagesForm'
import { TextInputWithPlaceholder } from '../Forms/TextInputWithPlaceholder'

export default class BasicForm extends PureComponent {
  render() {
    return (
      <div>
        <DealTypeForm
          dealType={this.props.dealType}
          changeDealType={value => this.props.parentSetState({ dealType: value })} />
        <EditImagesForm
          imagesError={this.props.imagesError}
          addImage={this.props.handleChangeImages}
          deleteImage={index => this.props.deleteImage(index)}
          deleteImageUrl={index => this.props.deleteImageUrl(index)}
          deleteNewImage={index => this.props.deleteNewImage(index)}
          images={this.props.images}
          imagesUrl={this.props.imagesUrl}
          newImages={this.props.newImages}
          emptyField={this.props.requiredFields.find(element => element === 'image') && (this.props.images.length + this.props.newImages.length) < 1}
        />
        <TextInputWithPlaceholder
          removeContainer
          label='Precio*'
          maxLength={12}
          onChangeText={price => this.props.changePrice(price, 'price')}
          placeholder='Ejemplo: $120,000'
          value={this.props.price}
          emptyField={this.props.requiredFields.find(element => element === 'price') && this.props.price.length === 0}
        />
        {
          this.props.type !== 0 && this.props.type !== 1 && this.props.type !== 2 &&
          <TextInputWithPlaceholder
            removeContainer
            label='Cuota mantenimiento'
            maxLength={12}
            onChangeText={maintenanceFee => this.props.changePrice(maintenanceFee, 'maintenanceFee')}
            placeholder='Ejemplo: $5,000 (opcional)'
            value={this.props.maintenanceFee}
          />
        }
        <div className="bg-gray-300 mt-10 w-full" style={{ height: 1, }} />
        <div className="flex flex-row items-center justify-end ">
          <div className="w-72">
            <button onClick={() => {
              this.props.finishFirstSection()
            }} className="redaria-button-white  remove-background mt-10 self-center mb-10">Siguiente</button>
          </div>
        </div>
      </div>
    )
  }
}
