import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCw87TxlsDyHEHiutbLcJkUrG07Cok9Khg",
  authDomain: "redaria.firebaseapp.com",
  databaseURL: "https://redaria.firebaseio.com",
  projectId: "redaria",
  storageBucket: "redaria.appspot.com",
  messagingSenderId: "407477404294",
  appId: "1:407477404294:web:bf62048ec81d6be4ee4707",
  measurementId: "G-J3MGY2262P"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = () => {
  console.log('search token',messaging)
  return new Promise((resolve, reject) => {
    return getToken(messaging, {vapidKey: 'BN6i65fbgV_iMSIxDlQ_h3LpQYH8r0TxdH-aZ3zQnoAdwAn6j6qQFLzvLoikOLOZ-vnPi10EvCmIfOdA8HXfets'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        resolve(currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        resolve('')
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      reject('')
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});