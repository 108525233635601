import React, { PureComponent } from 'react';
import { Gym } from '../../lib/Icon/icons/Gym';
import { Hospital } from '../../lib/Icon/icons/Hospital';
import { Mall } from '../../lib/Icon/icons/Mall';
import { Park } from '../../lib/Icon/icons/Park';
import { Restaurant } from '../../lib/Icon/icons/Restaurant';
import { School } from '../../lib/Icon/icons/School';
import IconButton from './IconButton';

export class NearbyPlacesForm extends PureComponent {
  render() {
    if (!this.props.active) {
      return null
    }
    return (
      <div className="mt-5">

        <div className="flex flex-row flex-wrap">
          <IconButton
            id={1}
            label='Parque'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 1) !== undefined}
            icon={<Park className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 1) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />
          <IconButton
            id={2}
            label='Escuelas'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 2) !== undefined}
            icon={<School className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 2) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />
          <IconButton
            id={3}
            label='Restaurantes'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 3) !== undefined}
            icon={<Restaurant className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 3) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />
          <IconButton
            id={4}
            label='Gym'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 4) !== undefined}
            icon={<Gym className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 4) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />
          <IconButton
            id={5}
            label='Centro Comercial'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 5) !== undefined}
            icon={<Mall className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 5) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />
          <IconButton
            id={6}
            label='Hospital'
            active={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId === 6) !== undefined}
            icon={<Hospital className="w-9" fill={this.props.nearbyPlaces.find(nearbyPlaceId => nearbyPlaceId  === 6) !== undefined ? '#fff' : '#2b77b7'} />}
            onPress={this.props.toggleNearbyPlace}
          />

        </div>
      </div>
    );

  }
}
