import React from 'react';

export function Hospital({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 21 27' className={className ? className : "w-5"}>
      <g id="hospital_svg__prefix__hospital" transform="translate(-55.441)">
        <g
          id="hospital_svg__prefix__Grupo_1714"
          data-name="Grupo 1714"
          transform="translate(55.441)"
        >
          <path
            id="hospital_svg__prefix__Trazado_23853"
            d="M119.337 56.888a7.427 7.427 0 107.427 7.427 7.435 7.435 0 00-7.427-7.427zm0 13.823a6.4 6.4 0 116.4-6.4 6.4 6.4 0 01-6.4 6.4z"
            className="hospital_svg__prefix__cls-1"
            data-name="Trazado 23853"
            transform="translate(-108.999 -53.956)"
          />
          <path
            id="hospital_svg__prefix__Trazado_23854"
            d="M185.1 124.584h-.864v-.884a1.227 1.227 0 00-1.221-1.23h-1.4a1.227 1.227 0 00-1.221 1.23v.881h-.864a1.227 1.227 0 00-1.221 1.23v1.412a1.227 1.227 0 001.221 1.23h.864v.881a1.227 1.227 0 001.221 1.23h1.4a1.227 1.227 0 001.221-1.23v-.881h.864a1.227 1.227 0 001.221-1.23v-1.412a1.227 1.227 0 00-1.221-1.23zm.191 2.642a.2.2 0 01-.191.2h-1.38a.515.515 0 00-.515.515v1.4a.2.2 0 01-.191.2h-1.4a.2.2 0 01-.191-.2v-1.4a.515.515 0 00-.515-.515h-1.38a.2.2 0 01-.19-.2v-1.412a.2.2 0 01.19-.2h1.38a.515.515 0 00.515-.515v-1.4a.2.2 0 01.191-.2h1.4a.2.2 0 01.191.2v1.4a.515.515 0 00.515.515h1.38a.2.2 0 01.191.2z"
            className="hospital_svg__prefix__cls-1"
            data-name="Trazado 23854"
            transform="translate(-171.975 -116.16)"
          />
          <path
            id="hospital_svg__prefix__Trazado_23855"
            d="M71.539 20.859h-2.123c1.879-1.672 3.838-3.421 3.963-3.556a10.294 10.294 0 00-.288-14.279l-.007-.007a10.325 10.325 0 00-14.9 14.29c.125.135 2.085 1.885 3.964 3.557h-2.13a2.766 2.766 0 100 5.532h3.43a.515.515 0 100-1.031h-3.43a1.735 1.735 0 010-3.47H63.3l2.137 1.9a.515.515 0 00.684 0l2.136-1.9h3.282a1.735 1.735 0 110 3.47h-3.43a.515.515 0 100 1.031h3.43a2.766 2.766 0 100-5.532zm-5.76 1.853c-2.75-2.441-6.612-5.878-6.848-6.114A9.3 9.3 0 0172.349 3.739l.007.007a9.235 9.235 0 01.27 12.854c-.236.235-4.097 3.671-6.848 6.112z"
            className="hospital_svg__prefix__cls-1"
            data-name="Trazado 23855"
            transform="translate(-55.441)"
          />
          <path
            id="hospital_svg__prefix__Trazado_23856"
            d="M247.191 492.324a.516.516 0 10-.19.626.521.521 0 00.19-.626z"
            className="hospital_svg__prefix__cls-1"
            data-name="Trazado 23856"
            transform="translate(-236.368 -466.646)"
          />
        </g>
      </g>
    </svg>
  )
}
