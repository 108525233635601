import React, { useRef } from 'react'
import { default as SearchIconBlack }  from './../../lib/Icon/icons/Search'
import { default as CloseIcon }  from './../../lib/Icon/icons/Close'
import Property from './Property'
import EmptySearch from './EmptySearch'
import FlatList from 'flatlist-react'
import NewPropertyButton from './NewPropertyButton'

export default function Search({refetch,openNewPropertyModal,value,setSearch,clearSearch,isAlreadySearch,onSubmitEditing,PRPSData,isPremier}) {

  const inputRef = useRef()

  const _onInputClick = () => inputRef.current.focus()
  const _onChange = (e) => setSearch(e.target.value)
  const _clearSearch = () => clearSearch()

  const _onSubmit = e => {
    e.preventDefault()
    onSubmitEditing()
  }

  const _renderProperty = ( item ) => {


    return (
        <Property
          user={item.attributes.uploadedBy.data}
          key={'profile-property' + String(item.id)}
          isPremier={isPremier}
          property={item}
          refetch={refetch}
        />
    );
  }
  return (
    <div>

      <div className="flex  flex-col" style={{ marginRight:130 }}>

      <div className="flex flex-row items-center mt-5 justify-between">
      <div className="mr-2 rounded-full border border-color-gray-500 flex flex-row items-center"
        style={{ height:46,width:270,backgroundColor:'#D7D9DE' }}
        onClick={_onInputClick}>
        <div className="ml-4">
          <SearchIconBlack/>
        </div>
        <form className="ml-4 w-full"
          onSubmit={_onSubmit}>
          <input
            style={{ height:40,fontSize:16,backgroundColor:'#D7D9DE' }}
            ref={inputRef}
            value={value}
            onChange={_onChange}
            className={ value.length > 0 ? " w-11/12 focus:outline-none mr-4" : " w-11/12 focus:outline-none mr-4"}
            placeholder="Buscar"/>
        </form>
        {
          (value || isAlreadySearch )
          &&
          <div className="mr-4" onClick={_clearSearch}>
            <CloseIcon/>
          </div>
        }
      </div>
      
      <NewPropertyButton refetch={refetch} openNewPropertyModal={openNewPropertyModal}/>

      </div>
      <div className="w-full" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)",marginTop:14,marginBottom:20 }}/>


      </div>
        {
          isAlreadySearch &&
          <div className="flex justify-between flex-row items-center" style={{ marginBottom:20 }}>
              <div className="flex flex-row items-center">
                <p className="poppins-medium text-redaria-black" style={{ fontSize:27 }}>Mis Propiedades</p>
                <div className="border-redaria-black px-4 ml-5" style={{ borderRadius:18 }}>
                  <p className="questrial text-redaria-black" style={{ fontSize:20 }}>{PRPSData.length}</p>
                </div>
              </div>
            </div>
        }
        <div className="flex flex-wrap">
            {
              isAlreadySearch && 
              <FlatList
                list={PRPSData}
                renderItem={_renderProperty}
                renderWhenEmpty={() => <EmptySearch/>}
              />
            }
        </div>
    </div>
  )
}
