import React from 'react'
import logo from '../../assets/loading.gif'

export default function LoadingModal({message,visible}) {
  if(!visible)
    return null
  return (
    <div onClick={e => e.stopPropagation()} className="fixed z-50  top-0 right-0 left-0 bottom-0 bg-white bg-opacity-75 flex flex-col justify-center items-center">
      <img src={logo} alt="loading..." className="w-28" />
      {
        (message && message.length > 0) && <p className="poppins-regular text-redaria-black text-center" style={{ fontSize:21 }}>{message}</p>
      }
    </div>
  )
}
