import React from 'react';

export default function Clock({className}) {
  return (
    <svg viewBox="0 0 21 21" className={ className ? className :"w-7"}>
      <g id="clock_svg__prefix__clock">
        <path
          id="clock_svg__prefix__Trazado_23707"
          d="M18.276 5.374a.851.851 0 00-.4 1.136 8.423 8.423 0 01.842 3.7 8.51 8.51 0 11-8.51-8.51A8.408 8.408 0 0115.5 3.538a.851.851 0 101.059-1.332 10.214 10.214 0 103.87 8.006 10.108 10.108 0 00-1.013-4.443.85.85 0 00-1.136-.4zm0 0"
          className="clock_svg__prefix__cls-1"
          data-name="Trazado 23707"
        />
        <path
          id="clock_svg__prefix__Trazado_23708"
          d="M176.851 64a.851.851 0 00-.851.851v5.957a.851.851 0 00.851.851h4.255a.851.851 0 100-1.7h-3.4v-5.108a.851.851 0 00-.855-.851zm0 0"
          className="clock_svg__prefix__cls-1"
          data-name="Trazado 23708"
          transform="translate(-166.638 -60.596)"
        />
      </g>
    </svg>
  )
}