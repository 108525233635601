import React, {PureComponent} from 'react';

export class AmmenitySelector extends PureComponent {
  render() {
    return(
      <div className="flex flex-row flex-wrap mt-2">
        <div onClick={() => this.props.onChangeValue('1')}>
          <div className={`flex items-center justify-center rounded-full mr-5 mt-2 ${this.props.value === '1' ? 'bg-redaria-blue hover:bg-blue-400 cursor-pointer' : 'bg-gray-300 hover:bg-gray-200 cursor-pointer' } `}  style={{ width:69,height:69 }}>
            <p className={`poppins-medium  ${this.props.value === '1' ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>
              1
            </p>
          </div>
        </div>
        <div onClick={() => this.props.onChangeValue('2')}>
          <div className={`flex items-center justify-center rounded-full mr-5 mt-2 ${this.props.value === '2' ? 'bg-redaria-blue hover:bg-blue-400 cursor-pointer' : 'bg-gray-300 hover:bg-gray-200 cursor-pointer' } `}  style={{ width:69,height:69 }}>
            <p className={`poppins-medium  ${this.props.value === '2' ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>
              2
            </p>
          </div>
        </div>
        <div onClick={() => this.props.onChangeValue('3')}>
          <div className={`flex items-center justify-center rounded-full mr-5 mt-2 ${this.props.value === '3' ? 'bg-redaria-blue hover:bg-blue-400 cursor-pointer' : 'bg-gray-300 hover:bg-gray-200 cursor-pointer' } `}  style={{ width:69,height:69 }}>
            <p className={`poppins-medium  ${this.props.value === '3' ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>
              3
            </p>
          </div>
        </div>
        <div onClick={() => this.props.onChangeValue('4')}>
          <div className={`flex items-center justify-center rounded-full mr-5 mt-2 ${this.props.value === '4' ? 'bg-redaria-blue hover:bg-blue-400 cursor-pointer' : 'bg-gray-300 hover:bg-gray-200 cursor-pointer' } `}  style={{ width:69,height:69 }}>
            <p className={`poppins-medium  ${this.props.value === '4' ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>
              4
            </p>
          </div>
        </div>
        <div onClick={() => this.props.onChangeValue('5+')}>
          <div className={`flex items-center justify-center rounded-full mr-5 mt-2 ${this.props.value === '5+' ? 'bg-redaria-blue hover:bg-blue-400 cursor-pointer' : 'bg-gray-300 hover:bg-gray-200 cursor-pointer' } `}  style={{ width:69,height:69 }}>
            <p className={`poppins-medium  ${this.props.value === '5+' ? 'text-white' : 'text-redaria-black'}`} style={{ fontSize:20 }}>
              5+
            </p>
          </div>
        </div>
      </div>
    );
  }
}

