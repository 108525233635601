import React, { PureComponent } from 'react'

export class UserTag extends PureComponent {
  render() {
    let userType = this.props.userType;
    if(this.props.premierUpload)
      userType = 'Asesor Premier'
    return (
      <div className="flex flex-row" style={{ marginTop:20 }}>
        <img alt="user" src={this.props.image} className="avatar" style={{ width:45.45,height:45.45, borderRadius:45.45,marginRight:10 }}/>
        <div className="flex flex-col">
          <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:17 }}>{this.props.name}</p>
          <p className="questrial" style={{ fontSize:12,color:"#4A4A4ACC" }}>{userType}</p>
        </div>
      </div>
    )
  }
}

export default UserTag
