import React from 'react';

export function PetFriendly({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 27 24" className={className ? className : "w-5"}>
      <g id="dog_svg__prefix__dog">
        <path
          id="dog_svg__prefix__Trazado_23737"
          d="M78.773 184.906a6.421 6.421 0 00-10.812 0l-2.861 4.461a4.064 4.064 0 005.057 5.914l.053-.023a7.99 7.99 0 016.363.023 4.042 4.042 0 001.631.345 4.093 4.093 0 00.91-.1 4.065 4.065 0 002.517-6.156zm1.352 8.291a2.5 2.5 0 01-2.928.669 9.538 9.538 0 00-7.659 0l-.035.015a2.518 2.518 0 01-3.1-3.679l2.86-4.461a4.875 4.875 0 018.209 0l2.859 4.46a2.5 2.5 0 01-.206 2.999zm0 0"
          className="dog_svg__prefix__cls-1"
          data-name="Trazado 23737"
          transform="translate(-61.14 -172.578)"
        />
        <path
          id="dog_svg__prefix__Trazado_23738"
          d="M4.733 106.123a3.118 3.118 0 001.82-1.93 4.028 4.028 0 00-.071-2.777 4.03 4.03 0 00-1.809-2.108 3.118 3.118 0 00-2.643-.213A3.569 3.569 0 00.281 103.8a3.765 3.765 0 003.386 2.518 2.952 2.952 0 001.065-.2zm-3.009-2.876a2.038 2.038 0 01.86-2.71 1.434 1.434 0 01.517-.1 1.743 1.743 0 01.832.224 2.483 2.483 0 011.105 1.3 2.481 2.481 0 01.055 1.709 1.6 1.6 0 01-.916 1 2.038 2.038 0 01-2.454-1.433zm0 0"
          className="dog_svg__prefix__cls-1"
          data-name="Trazado 23738"
          transform="translate(0 -93.801)"
        />
        <path
          id="dog_svg__prefix__Trazado_23739"
          d="M128.287 8.828a4.175 4.175 0 003.874-4.413 3.906 3.906 0 10-7.747 0 4.174 4.174 0 003.873 4.413zm0-7.281a2.644 2.644 0 012.328 2.868 2.378 2.378 0 11-4.655 0 2.644 2.644 0 012.327-2.868zm0 0"
          className="dog_svg__prefix__cls-1"
          data-name="Trazado 23739"
          transform="translate(-118.006)"
        />
        <path
          id="dog_svg__prefix__Trazado_23740"
          d="M286.528 48.093a3.157 3.157 0 001 .161 4.015 4.015 0 003.655-2.876 4.328 4.328 0 00-.062-2.986 3.274 3.274 0 00-4.854-1.613 4.328 4.328 0 00-1.836 2.355 3.829 3.829 0 002.1 4.959zm-.633-4.472a2.783 2.783 0 011.171-1.52 1.732 1.732 0 012.621.871 2.783 2.783 0 01.028 1.918 2.059 2.059 0 11-3.819-1.269zm0 0"
          className="dog_svg__prefix__cls-1"
          data-name="Trazado 23740"
          transform="translate(-269.557 -38.181)"
        />
        <path
          id="dog_svg__prefix__Trazado_23741"
          d="M382.459 171.641a3.8 3.8 0 00-4.481 6.054 3.028 3.028 0 001.819.587 3.916 3.916 0 003.093-1.638 3.57 3.57 0 00-.431-5.003zm-.813 4.083a1.82 1.82 0 11-2.856-2.112 2.373 2.373 0 011.84-1.016 1.51 1.51 0 01.909.289 2.039 2.039 0 01.107 2.84zm0 0"
          className="dog_svg__prefix__cls-1"
          data-name="Trazado 23741"
          transform="translate(-357.332 -162.244)"
        />
      </g>
    </svg>
  )
}
