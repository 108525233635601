import React, { PureComponent } from 'react'

export default class PrivacyPolicies extends PureComponent {
  render() {
    return (
      <div className="max-w-screen-lg mx-auto">
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>AVISO DE PRIVACIDAD (EL “AVISO”)</div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          En cumplimiento a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo "la Ley"), nos
          permitimos informarle lo siguiente:
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          <span className="font-bold">AC TECNOLOGIAS INMOBILIARIAS S.A. de C.V</span> (en lo sucesivo, la “Sociedad”) es la sociedad responsable del tratamiento de sus datos
          personales, con domicilio en Av. Fundidora 501, Planta Alta, Local 35, Obrera, 64610 Monterrey, Nuevo León. Por favor, revise cuidadosamente
          este Aviso antes de ingresar o proporcionar sus datos personales o entregar cualquier dato o información a la Sociedad. Mediante el presente
          Aviso, le informamos sobre las medidas de seguridad de la Sociedad para proteger sus datos personales.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          RECOPILACIÓN DE DATOS PERSONALES
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          La Sociedad recaba la información contenida en la forma adjunta (nombre completo, domicilio, fecha de nacimiento, números de contacto, entre
          otros), para las finalidades señaladas en el presente Aviso, podemos recabar sus datos personales de distintas formas: cuando usted nos los
          proporciona directamente mediante la forma adjunta, cuando visita nuestro sitio de Internet, mediante correos electrónicos, cuando obtenemos
          información a través de otras fuentes que están permitidas por la ley y cuando usted mediante su firma autógrafa nos autoriza para ello. La
          Sociedad no recopila datos personales sensibles.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          DATOS PERSONALES VINCULADOS CON EL PRESENTE AVISO DE PRIVACIDAD
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Los datos personales que se recaban son:<br/>
          I. Nombre completo<br/>
          II. Edad<br/>
          III. Sexo<br/>
          IV. Correo electrónico<br/>
          V. Número telefónico celular<br/>
          VI. Domicilio<br/>
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          PROPÓSITOS DE USO
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          La recopilación de dichos datos personales, tienen la finalidad y son para efectos única y exclusivamente de evaluar una relación de colaboración
          entre usted y la Sociedad. En caso de no contar con esta información no estaríamos en posibilidad de evaluar la futura relación de colaboración
          entre Usted y la Sociedad. Sus datos personales se almacenan en nuestros servidores utilizando medidas pertinentes de seguridad. La base de datos
          y su contenido permanece en la Sociedad y se queda en nuestros procesadores de datos o servidores. La Sociedad no facilitará sus datos personales
          a terceras partes, a menos que hayamos obtenido su consentimiento o un mandato oficial para hacerlo. Mantendremos el control de y la
          responsabilidad del uso de cualquier información personal que nos proporcione. La Sociedad no realiza transferencia de sus datos personales a
          terceros.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
            DERECHO DE ACCESO Y CORRECCIÓN
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Usted tiene el derecho de revisar y corregir cualquier información personal almacenada en nuestro sistema si usted considera que puede estar
          desactualizada o incorrecta. Le pedimos que en dicho caso, envíe un correo electrónico a hola@redaria.com con su solicitud de acceso y
          corrección a sus datos personales.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          DERECHO DE REVOCACIÓN Y CANCELACIÓN
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          En todo momento usted podrá revocar el consentimiento que nos ha otorgado para el uso especificado de sus datos personales, a fin de que
          dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petición al correo electrónico hola@redaria.com Su petición deberá ir
          acompañada de su nombre completo y las razones por las cuales usted revoca a la Sociedad el consentimiento para el tratamiento de sus datos
          personales. Para ello, tendremos un plazo de 10 días hábiles para atender su petición y le informaremos sobre la procedencia de la misma a través
          del correo electrónico registrado en la forma adjunta.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          RETENCIÓN DE INFORMACIÓN
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Únicamente retenemos datos personales durante el tiempo que sea necesario y esté vigente la relación de colaboración entre la Sociedad y Usted,
          para la cual dio su consentimiento, excepto en donde de otra forma se provee por ley.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          SEGURIDAD
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          La Sociedad utiliza precauciones de seguridad, técnicas y organizacionales, con el fin de proteger su información de manipulación, pérdida,
          destrucción o acceso de personas no autorizadas. Cualquier información personal que se le facilite a la Sociedad será codificada en tránsito para
          prevenir el posible mal uso por terceras personas. Nuestros procedimientos de seguridad se revisan continuamente, tomando como base los nuevos
          desarrollos tecnológicos.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          MODIFICACIONES AL AVISO
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          El desarrollo constante del Internet requiere ajustes ocasionales en nuestra declaración de privacidad. Nos reservamos el derecho de efectuar en
          cualquier momento modificaciones y actualizaciones al presente Aviso, para la atención de novedades legislativas o jurisprudenciales, políticas
          internas de la Sociedad, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas del mercado. Estas
          modificaciones estarán disponibles a través de nuestro sitio de Internet.
        </div>
        <div className="poppins-semi-bold mt-10 text-redaria-black text-center" style={{ fontSize:27}}>
          CÓMO CONTACTARNOS
        </div>
        <div className="questrial text-redaria-black" style={{ fontSize:20 }}>
          Departamento: Jurídico<br/>
          Domicilio: Av. Fundidora 501, Planta Alta, Local 35, Obrera, 64610 Monterrey, Nuevo León<br/>
          Correo electrónico: hola@redaria.com
        </div>
      </div>
    )
  }
}
