import React, { PureComponent } from 'react'
import LeftContainer from '../../components/Welcome/LeftContainer'
import LogoRedaria from '../../assets/images/icon_redaria.png';
import SigIn from '../../components/CreateAccount/SigIn';

export class CreateAccount extends PureComponent {
  static propTypes = {

  }

  render() {
    return (
      <div className="min-h-full flex flex-1 flex-col">
        <div className="flex flex-row items-center mt-10 ml-10">
          <img src={LogoRedaria} style={{ width:56,height:56,marginRight:10 }} alt="logo" />
          <p className="questrial font-semibold text-redaria-blue" style={{ fontSize:27 }}>redaria</p>
        </div>
        <div className="grid grid-cols-2 ml-10">
          <LeftContainer />
          <SigIn history={this.props.history}/>
        </div>


      </div>
    )
  }
}

export default CreateAccount
