import React, {PureComponent} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { ArrowWithCircleLeft,ArrowWithCircleRight } from '../../lib/Icon/icons/Arrows';
import { CloseDark } from '../../lib/Icon/icons/Close';

export default class PropertyHeader extends PureComponent {

  state = {
    index:0,
    modalSliderVisible:false
  }

  handleSelect = (index, e) => {
    this.setState({index});
  };

  _handleLeft= () => {
    let index = this.state.index;
    if(index -1 < 0 )
    {
      index = this.props.images.length - 1;
    }
    else
    {
      index--;
    }
    this.setState({index})
  }
  _handleRight= () => {
    let index = this.state.index;
    if(index >= this.props.images.length -1)
    {
      index = 0;
    }
    else
    {
      index ++;
    }
    this.setState({index})
  }

  render() {

    const images = [];
    this.props.images.forEach(img => {
      images.push({ url: img.path })
    });
    

    return(
      <div>

        <div className="relative">
          <div onClick={this._handleLeft} className="absolute cursor-pointer left-0 top-0 bottom-0 z-10 flex flex-col justify-center">
            <ArrowWithCircleRight style={{widht:53,height:211}}/>
          </div>
          <div onClick={this._handleRight} className="absolute cursor-pointer right-0 top-0 bottom-0 z-10 flex flex-col justify-center">
            <ArrowWithCircleLeft style={{widht:53,height:211}}/>
          </div>
          <Carousel interval={null}  style={{ borderRadius:8, }} controls={false} slide={true} indicators={false} activeIndex={this.state.index} onSelect={this.handleSelect}>
          {
            this.props.images.map((image, index) => {
              return(
                  <Carousel.Item key={index}
                  onClick={ () => this.setState({modalSliderVisible:true})}
                  style={{ backgroundImage:'url('+image.path+')',borderRadius:8,height:"550px" }}>
                  
                  </Carousel.Item>
              )
            })
          }
          </Carousel>
        </div>
        <div className="w-full flex flex-row" style={{ marginTop:22 }}>
          {
            this.props.images.length >= 1 &&
            <img onClick={ () => this.setState({index:0})} className={`w-1/4 cursor-pointer ${this.state.index === 0 ? 'border border-black' : ''}`} alt="property" src={this.props.images[0].path} style={{height:120,borderRadius:8,}}/>
          }
          {
            this.props.images.length >= 2 &&
            <img onClick={ () => this.setState({index:1})} className={`w-1/4 ml-4 cursor-pointer ${this.state.index === 1 ? 'border border-black' : ''}`} alt="property" src={this.props.images[1].path} style={{height:120,borderRadius:8,}}/>
          }
          {
            this.props.images.length >= 3 &&
            <img onClick={ () => this.setState({index:2})} className={`w-1/4 ml-4 cursor-pointer ${this.state.index === 2 ? 'border border-black' : ''}`} alt="property" src={this.props.images[2].path} style={{height:120,borderRadius:8,}}/>
          }
          {
            this.props.images.length > 4 &&
            <div
              onClick={ () => this.setState({modalSliderVisible:true})}
              className="relative cursor-pointer w-1/4 ml-4 " style={{height:120,borderRadius:8,}}>
              <div className="absolute flex items-center justify-center z-10 w-full" style={{height:120,borderRadius:8,backgroundColor:"rgba(17, 34, 51, 0.72)"}}>
                <p className="questrial text-white" style={{ fontSize:35 }}>{`+${this.props.images.length}`}</p>
              </div>
              <img alt="property" src={this.props.images[3].path} style={{height:120,borderRadius:8,width:'100%'}}/>
            </div>
          }
        </div>


        {
          this.state.modalSliderVisible &&
            <div onClick={ () => this.setState({modalSliderVisible:false})} className="fixed z-20  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>
              <div className="abosolute flex items-center justify-center">


            
            <div onClick={ e => e.stopPropagation()} className="z-30 flex flex-col justify-center items-center bg-white" 
              style={{ 
                height:'90vh',
                width:'90vw',
                borderRadius:8,
                }}
                >
                <div className="flex flex-row justify-end" style={{ marginBottom:34,width:'85vw' }}>
                  <div onClick={ () => this.setState({modalSliderVisible:false})} className="cursor-pointer">
                    <CloseDark style={{widht:20,height:20}}/>
                  </div>
                </div>
              <div className="relative" style={{ width:'85vw' }}>

                <div onClick={this._handleLeft} className="absolute cursor-pointer left-0 top-0 bottom-0 z-10 flex flex-col justify-center">
                  <ArrowWithCircleRight style={{widht:53,height:211}}/>
                </div>
                <div onClick={this._handleRight} className="absolute cursor-pointer right-0 top-0 bottom-0 z-10 flex flex-col justify-center">
                  <ArrowWithCircleLeft style={{widht:53,height:211}}/>
                </div>
                <Carousel interval={null} controls={false} slide={true}   activeIndex={this.state.index} onSelect={this.handleSelect}>
                {
                  this.props.images.map((image, index) => {
                    return(
                      <Carousel.Item key={index}
                      onClick={ () => this.setState({modalSliderVisible:true})}
                      style={{ backgroundImage:'url('+image.path+')',borderRadius:8,height:"80vh" }}>
                      </Carousel.Item>
                    )
                  })
                }
                </Carousel>
              </div>
            </div>
          </div>
          </div>
        }


      </div>
    );
  }
}

