import React from 'react'

export default function Certificate({certificate,onCertificatePress}) {
  const _onPress = () => onCertificatePress(certificate.id);

  let uri = '';

  if(certificate)
    if(certificate.image[0].path)
      uri = certificate.image[0].path;
  if(uri) {
    return(
        <div onClick={_onPress} className="rounded cursor-pointer bg-redaria-blue flex items-center justify-center shadow-lg" style={{ width:79,height:79 }}>
          <img alt="certificate" className="rounded cursor-pointer bg-redaria-blue flex items-center justify-center" style={{ width:79,height:79 }} src={uri} />
        </div>
    );
  } else {
    return(
      null
    );
  }

}
