import {DELETE_USER, SAVE_USER} from '../actions/authenticatedUser';

const initialState = {
}

const authenticatedUser = (state = initialState, action) => {
  switch(action.type) {
    case SAVE_USER:
      return {...state, ...action.user};
    case DELETE_USER:
      return {...state.authenticatedUser, ...{}};
    default:
      return state;
  }
}

export default authenticatedUser