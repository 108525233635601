import React from 'react';

export default function Land({active,className}) {
  return (
    <svg fill={ active ? '#fff' : '#2b77b7'} viewBox='0 0 29 29' className={className ? className :"w-5"}>
      <g>
      <g data-name="Group 31">
        <path
          data-name="Fill 1"
          d="M7.026 11.764H2.95a1.031 1.031 0 01-.858-1.334l2.016-9.066a.864.864 0 011.721 0l.1.45-1.917 8.616a1.038 1.038 0 00.857 1.333z"
          fill="#fff"
        />
        <path
          data-name="Fill 3"
          d="M7.032 11.764H4.876a1.031 1.031 0 01-.858-1.333l1.907-8.625 1.913 8.625a1.043 1.043 0 01-.806 1.333"
          fill="#fff"
        />
        <path
          data-name="Fill 5"
          d="M14.09 14.095h-2.56a.648.648 0 01-.537-.834l1.267-5.7a.543.543 0 011.081 0l.416 1.89-.845 3.81a.651.651 0 00.537.838z"
          fill="#fff"
        />
        <path
          data-name="Fill 7"
          d="M14.087 14.096h-.643a.648.648 0 01-.537-.838l.844-3.81.845 3.81a.651.651 0 01-.505.838"
          fill="#fff"
        />
        <path
          data-name="Fill 9"
          d="M27.88 12.297h-3.091a.787.787 0 01-.653-1.005l1.53-6.865a.655.655 0 011.305 0l.307 1.387-1.216 5.478a.78.78 0 00.653 1.005z"
          fill="#fff"
        />
        <path
          data-name="Fill 11"
          d="M27.886 12.297h-1.171a.787.787 0 01-.653-1.006l1.216-5.478 1.216 5.478a.783.783 0 01-.608 1.006"
          fill="#fff"
        />
        <g data-name="Group 15">
          <path data-name="Clip 14" d="M0 0h25.443v32H0z" fill="none" />
          <g data-name="Group 15" clipPath="url(#terreno-azul_svg__a)" >
            <path
              data-name="Fill 13"
              d="M24.955 32a.513.513 0 01-.468-.443 20 20 0 00-4.217-8.075 21.272 21.272 0 00-7.255-5.548A29.345 29.345 0 00.48 15.406a.533.533 0 01-.48-.572.538.538 0 01.48-.571 36.481 36.481 0 014.018.212v-2.147H2.95a1.3 1.3 0 01-1.089-.634 1.866 1.866 0 01-.237-1.409l2.017-9.074A1.414 1.414 0 014.966 0 1.414 1.414 0 016.29 1.211l2.017 9.083a1.838 1.838 0 01-.238 1.406 1.3 1.3 0 01-1.089.632H5.458V14.6a28.155 28.155 0 016.821 1.8v-1.73h-.755a.99.99 0 01-.826-.479 1.446 1.446 0 01-.179-1.075l1.268-5.7a1.008 1.008 0 012.009 0l1.266 5.7a1.423 1.423 0 01-.179 1.075.985.985 0 01-.825.479h-.812v2.149c.029.016.059.03.089.044s.056.026.083.041A22.086 22.086 0 0121 22.736a22.58 22.58 0 013.544 5.85 16.25 16.25 0 01.884 2.713.64.64 0 01-.055.422.507.507 0 01-.311.264.4.4 0 01-.107.015zM12.766 11.741a.542.542 0 01.486.571v1.219h.812a.073.073 0 00.064-.038.116.116 0 00.012-.083l-1.266-5.7c-.007-.029-.017-.068-.076-.068a.069.069 0 00-.076.063l-1.268 5.7a.116.116 0 00.012.083.083.083 0 00.064.038h.755v-1.214a.539.539 0 01.481-.571zM4.985 8.527a.444.444 0 01.34.171.6.6 0 01.132.4v2.1h1.524A.382.382 0 007.307 11a.555.555 0 00.071-.419L5.362 1.508a.4.4 0 00-.4-.365.407.407 0 00-.4.365L2.56 10.582a.557.557 0 00.07.418.382.382 0 00.326.191h1.549V9.1a.532.532 0 01.48-.573z"

            />
          </g>
        </g>
        <path
          data-name="Fill 16"
          d="M20.956 31.116a.635.635 0 01-.211.77.45.45 0 01-.217.061.472.472 0 01-.429-.312 21.589 21.589 0 00-5.529-7.894c-3.692-3.28-8.434-4.937-14.09-4.937a.536.536 0 01-.48-.572.536.536 0 01.48-.571c5.868 0 10.8 1.737 14.659 5.166a22.485 22.485 0 015.816 8.29"

        />
        <path
          data-name="Fill 18"
          d="M15.056 31.086a.625.625 0 01-.179.777.415.415 0 01-.237.076.476.476 0 01-.416-.29 18.03 18.03 0 00-4.633-6.13 13.9 13.9 0 00-9.105-3.157.536.536 0 01-.48-.572.536.536 0 01.48-.571c8.1.008 12.394 5.371 14.57 9.867"

        />
        <g data-name="Group 22">
          <path data-name="Clip 21" d="M0 24.549h8.6v7.4H0z" fill="none" />
          <g
            data-name="Group 22"
            clipPath="url(#terreno-azul_svg__b)"
            transform="translate(0 24.549)"
          >
            <path
              data-name="Fill 20"
              d="M8.542 6.56a.625.625 0 01-.2.77.41.41 0 01-.224.068.49.49 0 01-.429-.3C5.957 3.147 3.532 1.143.48 1.143A.536.536 0 010 .571.536.536 0 01.48 0 7.2 7.2 0 015.5 2.034 13.315 13.315 0 018.542 6.56"

            />
          </g>
        </g>
        <path
          data-name="Fill 23"
          d="M32 25.112a.536.536 0 01-.48.571 6.4 6.4 0 00-4.678 2.035.429.429 0 01-.307.137.447.447 0 01-.365-.206.647.647 0 01.058-.808 7.255 7.255 0 015.298-2.3.535.535 0 01.474.571"

        />
        <path
          data-name="Fill 25"
          d="M31.998 21.798a.536.536 0 01-.48.571 14.32 14.32 0 00-6.84 1.63.457.457 0 01-.2.053.484.484 0 01-.442-.335.616.616 0 01.243-.754 15.136 15.136 0 017.241-1.736.531.531 0 01.478.571"

        />
        <path
          data-name="Fill 27"
          d="M32 18.217a.536.536 0 01-.48.571 22.721 22.721 0 00-9.156 1.783.44.44 0 01-.166.038.487.487 0 01-.448-.373.606.606 0 01.288-.731 23.532 23.532 0 019.483-1.852.523.523 0 01.48.564"

        />
        <path
          data-name="Fill 29"
          d="M19.67 17.6a.494.494 0 01-.454-.382.606.606 0 01.287-.731 27.2 27.2 0 016.309-1.782v-1.836h-1.017a1.079 1.079 0 01-.914-.535 1.591 1.591 0 01-.2-1.188l1.53-6.864a1.192 1.192 0 011.113-1.021 1.026 1.026 0 01.7.283 1.388 1.388 0 01.41.738l1.526 6.865a1.56 1.56 0 01-.2 1.188 1.112 1.112 0 01-.916.535h-1.072v1.7a36.861 36.861 0 014.748-.3.578.578 0 01.017 1.136h-.01a29.87 29.87 0 00-11.7 2.164.377.377 0 01-.157.03zm6.617-7.863a.439.439 0 01.339.171.609.609 0 01.134.4v1.418h1.081a.189.189 0 00.153-.092.275.275 0 00.032-.2l-1.523-6.863a.186.186 0 00-.371 0l-1.523 6.866a.247.247 0 00.032.2.172.172 0 00.153.092h1.018v-1.421a.538.538 0 01.475-.571z"

        />
      </g>
    </g>
    </svg>
  )
}