import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { CheckBlack } from '../../lib/Icon/icons/Check'

class ModalNewPublication extends PureComponent {

  close = () => {
    this.props.history.push(`/signed/property/${this.props.propertyId}`) 
  }

  render() {
    if (!this.props.visible)
      return null
    return (
      <div onClick={ e => e.stopPropagation()} className="absolute z-30 flex mx-auto left-0 right-0 items-center justify-center top-20">
        
        <div onClick={ e => e.stopPropagation()} className="fixed z-30  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor: "rgba(100, 107, 115, 0.6)" }}>
        </div>

        <div onClick={ e => e.stopPropagation()}  className="bg-white z-40 flex flex-col items-center justify-center" 
          style={{ 
            width:454,
            height:367,
            borderRadius:9
          }}>

          <CheckBlack style={{width:86,height:86}}/>

          <p className="poppins-semi-bold text-redaria-black text-center" style={{ fontSize:29,marginTop:21 }}>¡Publicación realizada con éxito!</p>
          
          <button onClick={this.close} className="redaria-button-blue" style={{ width:358,marginTop:31 }}>Aceptar</button>

        </div>

      </div>
    )
  }
}

export default withRouter(ModalNewPublication)