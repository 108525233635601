import React from 'react'

const OptionsButton = ({openOptions}) => {
  return (
    
      <div className="cursor-pointer border-redaria-blue rounded flex flex-col items-center justify-center" onClick={openOptions} style={{ width:43,height:33,boxShadow: "0px 3px 6px #00000040" }}>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
        <div className="bg-redaria-blue" style={{borderRadius:4,height:4,width:4,marginTop:1,marginBottom:1}}/>
      </div>

  )
}

export default OptionsButton
