import React, {PureComponent} from 'react';
import AmmenityValue from './AmmenityValue';
import { Park } from '../../lib/Icon/icons/Park';
import { School } from '../../lib/Icon/icons/School';
import { Restaurant } from '../../lib/Icon/icons/Restaurant';
import { Gym } from '../../lib/Icon/icons/Gym';
import { Mall } from '../../lib/Icon/icons/Mall';
import { Hospital } from '../../lib/Icon/icons/Hospital';


export default class NearbyPlaces extends PureComponent {
  

  getIcon = (name) => {
    switch (name) {
      case 'park':
        return <Park fill="#112233" className="w-10"/>;
      case 'school':
        return <School fill="#112233" className="w-10"/>;
      case 'restaurant':
        return <Restaurant fill="#112233" className="w-10"/>;
      case 'gym':
        return <Gym fill="#112233" className="w-10"/>;
      case 'mall':
        return <Mall fill="#112233" className="w-10"/>;
      case 'hospital':
        return <Hospital fill="#112233" className="w-10"/>;
        
      default:
        return <Hospital className="w-10"/>;
    }
  }

  render() {
    
    return(
      <div className="flex flex-col my-5">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>Alrededores:</p>
        <div className="flex flex-row flex-wrap mt-5">
        {
            this.props.nearbyPlaces.map((nearbyPlace) =>
              <AmmenityValue
                key={nearbyPlace.id}
                icon={this.getIcon(nearbyPlace.slug)}
                label={nearbyPlace.name}
              />
            )
          }
        </div>
      </div>
    );
  }
}
