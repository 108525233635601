import React, { useState} from 'react'
import { useHistory } from 'react-router-dom';
import { getAmmenityValue } from '../../lib/Ammenities';
import PropertyInformation from '../Property/PropertyInformation';
import TimeAndType from '../Property/PropertyCard/TimeAndType';
import UserTag from '../Property/UserTag';
import OptionsButton from './OptionsButton';
import OptionsPropertyModal from './OptionsPropertyModal';

export default function Property({
  removeArchive, property, user, setAsAvailable, openShareModalFacebook, openShareModalSimple, height, onLayout, navigation, setOutstandingProperty, hiddeOptions,refetch
}) {

  const history = useHistory()

  const uri = property.attributes.images[0].path;
  const [showOptions,setShowOptions] = useState(false)
  const _openOptions = () => setShowOptions(!showOptions)
  const _hideOptions = () => setShowOptions(false)


  return (
    <div className="bg-white relative" style={{ paddingTop: 23, paddingLeft: 35, paddingRight: 35, width: 426, marginRight: 29, marginBottom: 30,paddingBottom:13 }}>
      <TimeAndType
        newRecommended={false}
        createdAt={property.attributes.createdAt}
        type={property.attributes.type}
        dealType={property.attributes.dealType} />
      <UserTag
        name={user.name}
        userType={user.type}
        premierUpload={user.isPremier}
        image={user.photoUrl}
      />

      <div className="shadow-lg cursor-pointer" style={{ borderRadius:13 }} onClick={ () => history.push(`/signed/property/${property.id}`)}>
        <img alt="property" src={uri} style={{ width: "100%", height: 190, borderTopRightRadius: 13, borderTopLeftRadius: 13, marginTop: 15 }} />

        <PropertyInformation
          bathrooms={getAmmenityValue(property.attributes.ammenities, 2)}
          bedrooms={getAmmenityValue(property.attributes.ammenities, 1)}
          buildingArea={property.attributes.buildingArea}
          city={property.attributes.address.city}
          dealType={property.attributes.dealType}
          floors={property.attributes.floors}
          landArea={property.attributes.landArea}
          neighborhood={property.attributes.address.neighborhood}
          price={property.attributes.price}
          restrooms={getAmmenityValue(property.attributes.ammenities, 3)}
          state={property.attributes.address.state}
          type={property.attributes.type}
        />
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <div className="flex flex-row justify-between" style={{ marginTop:15 }}>
          <OptionsButton 
            openOptions={_openOptions} 
            setAsAvailable={setAsAvailable}
          />
        </div>
      </div>
      {
        showOptions &&
        <OptionsPropertyModal
          propertyType={property.attributes.type}
          status={property.attributes.status}
          dealType={property.attributes.dealType}
          refetch={refetch}
          id={property.id}
          close={_hideOptions}
          removeArchive={removeArchive}
        />
      }
    </div>
  ) 
}
