import React, { PureComponent } from 'react'
import ModalEditAgency from './ModalEditAgency'

export default class Agency extends PureComponent {

  state = {
    modalVisible:false
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({modalVisible:true})
  }
  
  closeModal = () => this.setState({modalVisible:false})

  render() {
    const {photoUrl,name,refresh} = this.props
    let buttonName = 'Editar Inmobiliaria'

    if(name.length === 0)
      buttonName = 'Agregar Inmobiliaria'

    return (
      <div className="flex flex-col items-center justify-center mt-5 w-full px-5">
        <div className="flex flex-row items-center justify-center max-w-full">
            {
              (photoUrl === '' && name.length > 0) &&
              <div className="bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center">
                <p className="text-white" style={{ fontSize:14 }}>{name.charAt(0).toUpperCase()}</p>
              </div>
            }
            {
              photoUrl !== '' &&
              <img
                alt="agency"
                className="avatar bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center"
                src={photoUrl}
              />
            }
            
            <p className="ml-1 poppins-medium truncate " style={{ fontSize:13,color:'#4A4A4ACC' }}>{name}</p>
          </div>
          <p onClick={this.openModal} className="ml-1 cursor-pointer poppins-medium text-redaria-blue mt-5" style={{ fontSize:13 }}>{buttonName}</p>
          <ModalEditAgency name={name} photoUrl={photoUrl} refresh={refresh} visible={this.state.modalVisible} close={this.closeModal}/>
      </div>
    )
  }
}
