import React, { PureComponent } from 'react'
import AddressForm from '../../components/Forms/AddressForm';
import { AmmenitiesForm } from '../../components/Forms/AmmenitiesForm';
import { AdditionalAmmenities } from '../../components/Forms/AdditionalAmmenities';
import { BasicDetailsForm } from '../../components/Forms/BasicDetailsForm';
import { LegalStatusForm } from '../../components/Forms/LegalStatusForm';
import { OptionalDataSection } from '../../components/Forms/OptionalDataSection';
import { SectionDivider } from '../../components/Forms/SectionDivider'
import { PremierHide, PremierShow } from '../../lib/Icon/icons/PremierFeatures';
import { NearbyPlacesForm } from '../../components/Forms/NearbyPlacesForm';

export default class NewPropertyAdditionalInformationForm extends PureComponent {
  render() {
    return (
      <div>
        <SectionDivider
          active={this.props.activeSection === 1}
          description='Agrega información de tu propiedad'
          onPress={() => this.props.toggleSection(1)}
          title='Detalles Generales*'
        />
        <BasicDetailsForm
          active={this.props.activeSection === 1}
          age={this.props.age}
          ammenities={this.props.ammenities}
          floors={this.props.floors}
          onChangeAmmenityValue={(id, value) => this.props.changeAmmenityValue(id, value)}
          onChangeValue={valueObject => {
            if (Object.keys(valueObject)[0] !== 'floors') {
              this.props.parentSetState(valueObject)
            }
            else {
              this.props.changeFloors(valueObject.floors);
            }
          }}
          postType='Property'
          propertyType={this.props.propertyType}
          landArea={this.props.landArea}
          buildingArea={this.props.buildingArea}
          requiredFields={this.props.requiredFields}
        />

        <SectionDivider
          active={this.props.activeSection === 2}
          description='Cuál es el estatus legal de tu propiedad'
          onPress={() => this.props.toggleSection(2)}
          title='Estatus Legal*'
        />
        <LegalStatusForm
          active={this.props.activeSection === 2}
          onChangeValue={value => this.props.parentSetState({ legalStatus: value })}
          value={this.props.legalStatus}
        />

        <SectionDivider
            active={this.props.activeSection === 6}
            description='Dónde está ubicado'
            onPress={() => this.props.toggleSection(6)}
            title='Dirección*'
          />
        <AddressForm
          onFocusDescription={this.props._onFocusDescription}
          active={this.props.activeSection === 6}
          city={this.props.city}
          description={this.props.description}
          latitude={Number(this.props.latitude)}
          latitudeDelta={Number(this.props.latitudeDelta)}
          longitude={Number(this.props.longitude)}
          longitudeDelta={Number(this.props.longitudeDelta)}
          ref={this.props.map}
          neighborhood={this.props.neighborhood}
          number={this.props.number}
          onChangeCity={city => this.props.parentSetState({ city })}
          onChangeTextInput={inputObject => this.props.parentSetState(inputObject)}
          onChangeState={state => this.props.onChangeState(state)}
          onChangeNeighborhood={neighborhood => {
            this.props.parentSetState({ neighborhood });
            this.props.updateMap();
          }}
          onChangeStreet={street => {
            this.props.parentSetState({ street });
            this.props.updateMap();
          }}
          onChangeNumber={number => {
            this.props.parentSetState({ number });
            this.props.updateMap();
          }}
          onRegionChangeComplete={region => this.props.parentSetState(region)}
          state={this.props.state}
          street={this.props.street}
          zipCode={this.props.zipCode}
          requiredFields={this.props.requiredFields}
        />
        
        <OptionalDataSection
          icon={ this.props.viewOptionalFields ? <PremierHide/> : <PremierShow/> }
          active={false}
          description='Estos campos son opcionales, si deseas puedes hacer tu publicación'
          onPress={() => this.props.parentSetState({ viewOptionalFields: !this.props.viewOptionalFields })}
          title={ this.props.viewOptionalFields ? 'Ocultar campos opcionales' : 'Agregar más información'}
        />

        {
          this.props.viewOptionalFields &&
          <div>
            {
              this.props.propertyType !== 2 &&
              <div>
                <SectionDivider
                  active={this.props.activeSection === 3}
                  description='Agrega opciones de valor a tu propiedad'
                  onPress={() => this.props.toggleSection(3)}
                  title='Características'
                />
                <AmmenitiesForm
                  active={this.props.activeSection === 3}
                  ammenities={this.props.ammenities}
                  toggleAmmenity={id => this.props.toggleAmmenity(id)}
                />
                <SectionDivider
                  active={this.props.activeSection === 4}
                  description='Cuenta con...'
                  onPress={() => this.props.toggleSection(4)}
                  title='Amenidades'
                />
                <AdditionalAmmenities
                  active={this.props.activeSection === 4}
                  ammenities={this.props.ammenities}
                  toggleAmmenity={id => this.props.toggleAmmenity(id)}
                />
              </div>
            }
            <SectionDivider
              active={this.props.activeSection === 5}
              description='Lugares aledaños cool'
              onPress={() => this.props.toggleSection(5)}
              title='Alrededores'
            />
            <NearbyPlacesForm
              active={this.props.activeSection === 5}
              nearbyPlaces={this.props.nearbyPlaces}
              toggleNearbyPlace={id => this.props.toggleNearbyPlace(id)}
            />
          </div>
        }

        <div className="flex flex-row items-center justify-center">
          <button  onClick={() => this.props.submitForm()} className="redaria-button-blue mt-10 self-center mb-10">Publicar</button>
        </div>
      </div>
    )
  }
}
