import React, { PureComponent } from 'react'
import { AddressDropdown } from '../Forms/AddressDropdown'
import { TextInputWithPlaceholder } from '../Forms/TextInputWithPlaceholder'
import GoogleMap from 'google-map-react';
import States from '../../lib/States'
import { getCitiesFromState } from '../../lib/Address';
import { LocationBig } from '../../lib/Icon/icons/Location';
import { LeftArrow } from '../../lib/Icon/icons/Arrows';
import ModalMap from './ModalMap';

export default class AddressForm extends PureComponent {

  
  constructor(props) {
    super(props);

    this.state = {
      defaultLocation: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      center: { 
        lat: this.props.latitude,
        lng: this.props.longitude
      },
      zoom:this.props.zoom,
      modalMapVisible:false,
      lat: this.props.latitude,
      lng: this.props.longitude,
      draggable: true,
    }
  }

  mapRef = React.createRef(null)

  getCities = () => {
    if (this.props.state === '') {
      return [];
    }
    else {
      return getCitiesFromState(this.props.state);
    }
  }


  onMouseUp = (e) => {
    this.setState({
      center: e.center,
    })
    
    this.props.onRegionChangeComplete({
      latitude:Number(e.center.lat),
      longitude:Number(e.center.lng),
      zoom:e.zoom
    })
  }


  testChange = (lat,lng,zoom = 16) => {
    this.setState({
      defaultLocation: {
        lat:lat,
        lng:lng,
      },
      center: {
        lat:lat,
        lng:lng,
      },
      lat: lat,
      lng: lng,
      zoom
    })
  }

  openModalMap = () => this.setState({modalMapVisible:true})
  closeModalMap = (e) => {
    this.setState({modalMapVisible:false}, () => {
      this.testChange(e.center.lat,e.center.lng,e.zoom)
      this.onMouseUp(e)
    })
  }

  closeModalMapWithoutSave = () => {
    this.setState({modalMapVisible:false})
  }


  onCircleInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng
    });
    this.props.onRegionChangeComplete({
      latitude:Number(mouse.lat),
      longitude:Number(mouse.lng),
    })
  }

  onCircleInteraction3 = (childKey, childProps, mouse) => {
    this.setState({draggable: true});
  }

  mapClick = (e) => {
    this.setState({
      lat: e.lat,
      lng: e.lng
    });
    this.props.onRegionChangeComplete({
      latitude:Number(e.lat),
      longitude:Number(e.lng),
    })
  }

  onRegionChangeComplete = e => {
    
    this.props.onRegionChangeComplete({
      zoom:e.zoom
    })
    this.setState({zoom:e.zoom})
  }

  render() {
    return (
      <div className="w-11/12">
        <p className="poppins-semi-bold text-redaria-black" style={{ fontSize: 21 }}>Dirección</p>
        
        <div className="mt-10">
          <AddressDropdown
            showLocationIcon={true}
            data={States}
            onSelectOption={this.props.onChangeState}
            modalTitle='Selecciona un Estado'
            value={this.props.state}
          />
          {
            (this.props.requiredFields.find(element => element === 'state') && this.props.state.length === 0) &&
            <p className="text-red-500">
              Campo requerido
              </p>
          }
        </div>

        <div className="mt-10">
          <AddressDropdown
            showLocationIcon={true}
            data={this.getCities()}
            onSelectOption={this.props.onChangeCity}
            placeholder='Ciudad'
            modalTitle='Selecciona una Ciudad'
            value={this.props.city}
          />
          {
            (this.props.requiredFields.find(element => element === 'city') && this.props.city.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }
        </div>


        <div className="flex flex-row mt-5">
          <div className="flex flex-1 mr-10">
            <TextInputWithPlaceholder
              paddingBottom={3}
              leftIcon={<LocationBig fill="#112233" className="w-4  mr-4" style={{marginLeft: 8}}/>}
              removeContainer
              maxLength={100}
              onChangeText={this.props.onChangeNeighborhood}
              placeholder='Colonia*'
              value={this.props.neighborhood}
              emptyField={this.props.requiredFields.find(element => element === 'neighborhood') && this.props.neighborhood.length === 0}
            />
          </div>
          <div className="flex flex-1">
            <TextInputWithPlaceholder
              paddingBottom={3}
              leftIcon={<LocationBig fill="#112233" className="w-4  mr-4" style={{marginLeft: 8}}/>}
              removeContainer
              type="number"
              maxLength={100}
              onChangeText={zipCode => this.props.onChangeTextInput({ zipCode })}
              placeholder='Código Postal*'
              value={this.props.zipCode}
              emptyField={this.props.requiredFields.find(element => element === 'zipCode') && this.props.zipCode.length === 0}
            />
          </div>
        </div>
        <div className="flex flex-row mt-5">
          <div className="flex flex-1 mr-10">
            <TextInputWithPlaceholder
              paddingBottom={3}
              leftIcon={<LocationBig fill="#112233" className="w-4  mr-4" style={{marginLeft: 8}}/>}
              removeContainer
              maxLength={100}
              onChangeText={this.props.onChangeStreet}
              placeholder='Calle (opcional)'
              value={this.props.street}
            />
          </div>
          <div className="flex flex-1">
            <TextInputWithPlaceholder
              paddingBottom={3}
              leftIcon={<LocationBig fill="#112233" className="w-4  mr-4" style={{marginLeft: 8}}/>}
              removeContainer
              maxLength={10}
              onChangeText={this.props.onChangeNumber}
              placeholder='Número (opcional)'
              value={this.props.number}
            />
          </div>
        </div>

        <div style={{ height: '500px', width: '100%',borderRadius:4,overflow: 'hidden',boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)" }} className="mt-10">
          <GoogleMap
            options={{ scrollwheel: false,}}
            id="mapa"
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
            zoom={this.state.zoom}
            center={this.state.defaultLocation}
            draggable={this.state.draggable}
            onChildMouseDown={this.onCircleInteraction}
            onChildMouseUp={this.onCircleInteraction3}
            onChildMouseMove={this.onCircleInteraction}
            ref={(ref) => this.mapRef = ref}
            onClick={this.mapClick}
            onChange={this.onRegionChangeComplete}
          >
            <div 
              className="place" 
              lat={this.state.lat} 
              lng={this.state.lng}>
              <LocationBig
                style={{ width: 35,height:45 }}
              />
            </div>
          </GoogleMap>
        </div>

        {
          this.state.modalMapVisible &&
          <ModalMap
            latitude={this.state.center.lat}
            longitude={this.state.center.lng}
            zoom={this.props.zoom}
            closeModalMap={this.closeModalMap}
            closeModalMapWithoutSave={this.closeModalMapWithoutSave}
          />
        }

        <button onClick={this.openModalMap} className="redaria-button-white" style={{ marginTop:25 }}>Fijar posición en el mapa</button>


        <div className="bg-gray-300 mt-10 w-full" style={{ height: 1, }} />
        <div className="flex flex-row items-center justify-end ">
          <div className="w-72 mr-10">
            <button onClick={() => {
             this.props.goBack()
            }} className="redaria-button flex flex-row items-center justify-center  remove-background mt-10 self-center mb-10">
              <LeftArrow stroke="#2b77b7" fill="#2b77b7" style={{ width:20 }} className="mr-3"/>
              <span>Atras</span>
            </button>
          </div>
          <div className="w-72">
            <button onClick={() => {
             this.props.submitFinish()
            }} className="redaria-button-blue  mt-10 self-center mb-10">Publicar</button>
          </div>
        </div>

      </div>
    )
  }
}
