import React, { PureComponent } from 'react'
import { ShareBig } from '../../lib/Icon/icons/Whatsapp'

export class ShareButton extends PureComponent {
  render() {
    return (
      <div onClick={ () => {
        this.props.openShare()
      }} className="relative flex items-center justify-center cursor-pointer" style={{height:37,backgroundColor:'#68d058',borderRadius:9,borderWidth:1,borderColor:'#fff',boxShadow: "0px 3px 6px #00000040",marginLeft:18,}} >
        <div className="absolute" style={{ top:-5 ,left:-15 }}>
          <ShareBig style={{width: 194,height: 48}}/>
        </div>
        <p className="poppins-medium text-white" style={{ fontSize:21 }}>Compartir</p>
      </div>
    )
  }
}

export default ShareButton
