import React from 'react';

export function Heater({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 30 30" className={className ? className : "w-5"}>
      <g>
        <path
          id="sun_svg__prefix__Trazado_23757"
          d="M113.162 104.57a8.591 8.591 0 108.591 8.591 8.6 8.6 0 00-8.591-8.591zm0 15.48a6.889 6.889 0 116.889-6.889 6.9 6.9 0 01-6.889 6.889z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23757"
          transform="translate(-98.639 -98.638)"
        />
        <path
          id="sun_svg__prefix__Trazado_23758"
          d="M319.312 23.068a.85.85 0 001.116-.45l.939-2.209a.851.851 0 00-1.566-.666l-.939 2.209a.851.851 0 00.45 1.116z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23758"
          transform="translate(-300.711 -18.134)"
        />
        <path
          id="sun_svg__prefix__Trazado_23759"
          d="M148.806 423.941a.851.851 0 00-1.116.45l-.939 2.209a.851.851 0 101.566.666l.939-2.209a.851.851 0 00-.45-1.116z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23759"
          transform="translate(-138.362 -399.83)"
        />
        <path
          id="sun_svg__prefix__Trazado_23760"
          d="M19.743 148.316l2.209.939a.851.851 0 10.666-1.566l-2.209-.94a.851.851 0 00-.666 1.566z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23760"
          transform="translate(-18.135 -138.361)"
        />
        <path
          id="sun_svg__prefix__Trazado_23761"
          d="M427.268 319.8l-2.209-.939a.851.851 0 10-.666 1.566l2.209.939a.851.851 0 10.666-1.566z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23761"
          transform="translate(-399.831 -300.711)"
        />
        <path
          id="sun_svg__prefix__Trazado_23762"
          d="M151.853 20.874a.851.851 0 001.578-.636l-.9-2.226a.851.851 0 10-1.578.636z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23762"
          transform="translate(-142.334 -16.487)"
        />
        <path
          id="sun_svg__prefix__Trazado_23763"
          d="M316.961 425.845a.851.851 0 00-1.578.636l.9 2.226a.851.851 0 101.578-.636z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23763"
          transform="translate(-297.435 -401.187)"
        />
        <path
          id="sun_svg__prefix__Trazado_23764"
          d="M21.346 315.854a.851.851 0 00-1.107-.471l-2.226.9a.851.851 0 10.636 1.578l2.226-.9a.851.851 0 00.471-1.107z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23764"
          transform="translate(-16.488 -297.435)"
        />
        <path
          id="sun_svg__prefix__Trazado_23765"
          d="M425.373 152.959a.85.85 0 001.107.471l2.226-.9a.851.851 0 10-.636-1.578l-2.226.9a.851.851 0 00-.471 1.107z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23765"
          transform="translate(-401.186 -142.333)"
        />
        <path
          id="sun_svg__prefix__Trazado_23766"
          d="M241.874 4.1a.851.851 0 00.851-.851V.851a.851.851 0 10-1.7 0v2.4a.851.851 0 00.849.849z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23766"
          transform="translate(-227.351)"
        />
        <path
          id="sun_svg__prefix__Trazado_23767"
          d="M241.874 439.73a.851.851 0 00-.851.851v2.4a.851.851 0 101.7 0v-2.4a.851.851 0 00-.849-.851z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23767"
          transform="translate(-227.351 -414.787)"
        />
        <path
          id="sun_svg__prefix__Trazado_23768"
          d="M4.1 241.873a.851.851 0 00-.851-.851H.851a.851.851 0 100 1.7h2.4a.851.851 0 00.849-.849z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23768"
          transform="translate(0 -227.35)"
        />
        <path
          id="sun_svg__prefix__Trazado_23769"
          d="M442.982 241.023h-2.4a.851.851 0 000 1.7h2.4a.851.851 0 100-1.7z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23769"
          transform="translate(-414.788 -227.351)"
        />
        <path
          id="sun_svg__prefix__Trazado_23770"
          d="M72.54 73.743a.851.851 0 001.2-1.2l-1.7-1.7a.851.851 0 00-1.2 1.2z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23770"
          transform="translate(-66.589 -66.589)"
        />
        <path
          id="sun_svg__prefix__Trazado_23771"
          d="M382.983 381.78a.851.851 0 10-1.2 1.2l1.7 1.7a.851.851 0 001.2-1.2z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23771"
          transform="translate(-359.889 -359.889)"
        />
        <path
          id="sun_svg__prefix__Trazado_23772"
          d="M72.54 381.779l-1.7 1.7a.851.851 0 001.2 1.2l1.7-1.7a.851.851 0 00-1.2-1.2z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23772"
          transform="translate(-66.589 -359.888)"
        />
        <path
          id="sun_svg__prefix__Trazado_23773"
          d="M382.381 73.992a.848.848 0 00.6-.249l1.7-1.7a.851.851 0 00-1.2-1.2l-1.7 1.7a.851.851 0 00.6 1.453z"
          className="sun_svg__prefix__cls-1"
          data-name="Trazado 23773"
          transform="translate(-359.889 -66.589)"
        />
      </g>
    </svg>
  )
}
