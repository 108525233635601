import React, { PureComponent } from 'react'
import { CameraPlusGray } from '../../lib/Icon/icons/Camera'
import { InfoRed } from '../../lib/Icon/icons/Info'

export default class AgencyPictureForm extends PureComponent {

  hiddenFileInput = React.createRef()

  _handleFile = e => {
    this.props.changeAgencyPicture(e.target.files[0])
  }

  render() {
    const {imagesError} = this.props
    return (
      <div onClick={ () => this.hiddenFileInput.current.click()} className="flex flex-col justify-center items-center mx-auto cursor-pointer" style={{ marginTop: 47 }}>
        <input 
          ref={this.hiddenFileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={this._handleFile}
          maxLength="1"/>
        {
          this.props.agencyPicture !== null ?
          <div className="flex relative flex-col items-end justify-center">
            <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
              <CameraPlusGray style={{width:109}}/>
            </div>
            <img alt="new-profile" className="rounded-full" style={{ width:127,height:127 }} src={ URL.createObjectURL(this.props.agencyPicture)}/>
          </div>
          :
          (this.props.photoUrl !== ''
            ?
            <div className="flex relative flex-col items-end justify-center">
              <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
                <CameraPlusGray style={{ width: 109 }} />
              </div>
              <img alt="current-profile" className="rounded-full" style={{ width: 127, height: 127 }} src={this.props.photoUrl} />
            </div>
            :
            <div className="flex relative flex-col items-end justify-center">
              <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center">
                <CameraPlusGray style={{ width: 109 }} />
              </div>
              <div style={{ width: 127, height: 127 }} className="bg-redaria-blue rounded-full" />
            </div>)
        }
        <p className="questrial text-redaria-black" style={{ fontSize:12,marginTop:10 }}>Agregar logotipo</p>

        {
          imagesError > 0 &&
          <div className="flex relative flex-row bg-gray-200 w-full h-24" style={{ borderRadius:9,marginTop:10,marginBottom:15 }}>
            <div className="bg-red-500 absolute left-0 top-0 bottom-0" style={{width:8,borderTopLeftRadius:9,borderBottomLeftRadius:9}}/>
            <div className="flex flex-row py-5 px-10 items-center">
              <div >
                <InfoRed/>
              </div>
              <div className="">
                <p className="questrial text-redaria-black ml-5" style={{ fontSize:18 }}>
                  La foto no cumplió con los requisitos. Debe ser en formato JPG, JPEG o PNG.
                </p>  
              </div>

            </div>
          </div>
        }

      </div>
    )
  }
}
