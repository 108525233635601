import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { saveUser } from '../../actions/authenticatedUser'
import UserRequest from '../../api/UserRequest'
import Facebook from '../../lib/Icon/icons/Facebook'
import Google from '../../lib/Icon/icons/Google'
import Login from './Login'
import MyAppleSigninButton from './MyAppleSigninButton'
import SocialButton from './SocialButton'
import SecureLS from 'secure-ls';
import { ErrorAlert, InformationAlert } from '../../lib/alerts'

export default function RightContainer(props) {

  const history = useHistory();
  const dispatch = useDispatch()

  const ls = new SecureLS();


  const user = useSelector(state => state.authenticatedUser)


  const _handleLogin = () => {
    if(user.attributes){
      history.push('/signed/me/properties');
    }
  }

  useEffect(_handleLogin, [user,history])

  const loginFacebook = result => {
    
    ls.remove(process.env.REACT_APP_USER_AUTH_KEY)
    ls.remove(process.env.REACT_APP_USER_PROFILE_KEY)
      
    
    const facebookToken = result._token.accessToken
    UserRequest.facebookLogin(facebookToken).then(result => {
      const data = result.data;
      ls.set(process.env.REACT_APP_USER_AUTH_KEY,data.accessToken)
    })
    .then(() => {
      return UserRequest.profile().then(result => {
        dispatch(saveUser(result.data));
        ls.set(process.env.REACT_APP_USER_PROFILE_KEY,String(result.data.needsProfileInformation))
      });
    })
    .then(() => {
      history.push('/signed/me/properties')
    }).catch(error => {
      ErrorAlert('Error al iniciar sesión',()=>{console.log('error')})
    })
  }
  const loginGoogle = (user) => {
    ls.remove(process.env.REACT_APP_USER_AUTH_KEY)
    ls.remove(process.env.REACT_APP_USER_PROFILE_KEY)
    
    const googleToken = user._token.accessToken
    
    UserRequest.googleLogin(googleToken).then(result => {
      return result.data;
    }).then( data =>{
      ls.set(process.env.REACT_APP_USER_AUTH_KEY,data.accessToken)
    }).then( () =>{
      UserRequest.profile().then( result => {
        dispatch(saveUser(result.data));
        ls.set(process.env.REACT_APP_USER_PROFILE_KEY,String(result.data.needsProfileInformation))
      });
    }).then( () =>{
      history.push('/signed/me/properties')
    });
  }
  const handleSocialLoginFailure = () => {}

  const goToCreateAccount = () => {
    history.push('/sigin')
  }

  const location = useLocation()
  
  if(location.state?.needClear)
  {
    InformationAlert('La sesión ha caducado','Por favor inicia sesión nuevamente.',()=>{})
    history.replace({})
  }

  return (
    <div className="mt-20 flex flex-col items-center justify-center">
      <div style={{ width:429 }}>
        <div className="bg-white flex flex-col items-center rounded shadow-lg roundex mb-10">
          <p className="mt-5 poppins-medium text-redaria-black text-center" style={{ fontSize:25 }}>Bienvenido a redaria web</p>
          <div className="mt-5 w-10/12" style={{ height:1,backgroundColor:"rgba(112, 112, 112, 0.21)" }}/>
          <p className="mt-5 poppins-medium text-redaria-black text-center" style={{ fontSize:25 }}>Iniciar sesión:</p>
          <Login/>
          <div className="w-10/12">
            <SocialButton
              className="redaria-button-social bg-facebook mt-5"
              provider='facebook'
              appId={process.env.REACT_APP_FACEBOOK_AP_ID}
              onLoginSuccess={loginFacebook}
              onLoginFailure={handleSocialLoginFailure}
              >
                <div className="ml-10 w-7 mr-2 pl-1">
                  <Facebook/>
                </div>
                <div className="">
                  <span>Entrar con Facebook</span>
                </div>

            </SocialButton>
            
            <SocialButton 
              className="redaria-button-social bg-google mt-5"
              provider='google'
              appId={process.env.REACT_APP_GOOGLE_AP_ID}
              onLoginSuccess={loginGoogle}
              onLoginFailure={handleSocialLoginFailure}
              >
                <div className="ml-10 w-7 mr-2">
                  <Google/>
                </div>
                <div className="">
                  <span className="text-redaria-black">Entrar con Google</span>
                </div>

            </SocialButton>

          <MyAppleSigninButton/>
          </div>
          <div className="px-5 my-5 flex flex-row w-full items-center justify-center">
            <p className="line-text poppins-medium"><span>ó</span></p>
          </div>

          <div className="w-10/12 flex flex-col justify-center">
            <button onClick={goToCreateAccount} type="button" className="redaria-button-white">
              Crear tu cuenta
            </button>

            <span className="poppins-regular text-gray-500 px-5 text-center my-5" style={{ fontSize:11 }}>Al presionar en continuar o crear cuenta acepto los Términos y Condiciones y las Políticas de privacidad de Redaria.</span>
          </div>
        </div>
      </div>
    </div>
  )
}
