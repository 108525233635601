import React, {PureComponent} from 'react';

export class SectionDivider extends PureComponent {
  render() {
    const {title,description} = this.props
    return(
      <div className="">
        <div className="flex justify-between flex-row items-center">
          
          <div className="flex flex-row items-center">
            <div className="flex flex-col ml-3">
              <p className="poppins-semi-bold text-redaria-black" style={{ fontSize:21 }}>{title}</p>
              <p className="questrial text-redaria-black" style={{ fontSize:14 }}>{description}</p>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

