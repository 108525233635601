import React from 'react'

export default function ProfileActionLinks({me,following,numberOfFollowers,numberOfFollowing,reviews}) {


  return (

      <div className="w-full px-5 flex flex-row mt-5 justify-between">
        <div className="flex flex-col items-center">
          <p className="text-redaria-black poppins-semi-bold" style={{ fontSize:20 }}>{numberOfFollowers} </p>
          <p className="poppins-medium" style={{ color:'#4A4A4ACC',fontSize:13 }}>Seguidores</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-redaria-black poppins-semi-bold" style={{ fontSize:20 }}>{numberOfFollowing} </p>
          <p className="poppins-medium" style={{ color:'#4A4A4ACC',fontSize:13 }}>Siguiendo</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-redaria-black poppins-semi-bold" style={{ fontSize:20 }}>{reviews} </p>
          <p className="poppins-medium" style={{ color:'#4A4A4ACC',fontSize:13 }}>Reseñas</p>
        </div>
      </div>
  )
}
