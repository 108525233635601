
import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST

export default class Api {
  static delete(route, headerParams = {}) {
    return this._xhr('delete', route, headerParams);
  }

  static get(route, headerParams = {}) {
    return this._xhr('get', route, headerParams);
  }

  static patch(route, headerParams = {}, params = {}) {
    return this._xhr('patch', route, headerParams, params);
  }

  static post(route, headerParams = {}, params = {}) {
    return this._xhr('post', route, headerParams, params);
  }

  static async _xhr(verb, route, headers = {}, params = {}) {
    const url = `${API_HOST}${route}`;
    const requestData = this._requestData(headers, url, verb, params);

    requestData.timeout = 300000;
    requestData.onUploadProgress = ({ loaded, total }: any) => {
      if (loaded && total) {
        (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(`${100*loaded/total}% (${loaded}/${total})`);
      }
    };
    const startTime = Date.now();
    return axios(requestData).then(response => {
      if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
        console.log(JSON.stringify({
          ...requestData, headers: undefined, v: 1,
          duration: Date.now() - startTime,
        }, null, 2));

        if ((response.status/100 | 0) !== 2) {
          console.log(JSON.stringify(response));
        }
      }
      return response;
    }).catch(error => {
      if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
        console.log(JSON.stringify({
          ...requestData, headers: undefined, v: 1,
          duration: Date.now() - startTime,
        }, null, 2));
      }
      console.log(error);
      // Sentry.captureException(error);
      throw error;
    });
  }

  static _requestData(headers, url, verb, params) {
    const headerParams = Object.assign(this._headers(), headers);
    let dataObject = {
      method: verb,
      url: url,
      headers: headerParams
    }
    if(verb === 'post' || verb === 'patch') {
      dataObject = Object.assign(dataObject, {data: params});
    }
    return dataObject;
  }

  static _headers() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',



    };
  }
}
