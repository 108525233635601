import React from 'react';

export default function House({active,className}) {
  return (
    <svg fill={ active ? '#fff' : '#2b77b7'} viewBox='0 0 25 25' className={className ? className :"w-5"}>
      <g data-name="Group 5">
        <path
          data-name="Fill 3"
          d="M21.9 25H3.1V13.8l-.145.144-.711.7-.707-.7L0 12.421 11.794.707 12.5 0l.706.707L25 12.421l-1.538 1.528-.708.7-.854-.849V25zM9.549 16.672h5.9v7.333H20.9v-11.2l-8.4-8.34-8.4 8.344v11.2h5.449v-7.336zm1 .995v6.338h3.9v-6.338zM12.5 3.057l10.25 10.189.833-.826L12.5 1.408 1.418 12.421l.832.826 9.544-9.488.705-.7z"
        />
      </g>
    </svg>
  )
}