import React from 'react';

export function EquippedBlue({className,fill}) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox="0 0 27 30" className={className ? className : "w-5"}>
      <g id="kitchen_svg__prefix__kitchen">
        <path
          id="kitchen_svg__prefix__Trazado_23743"
          d="M26.55 96h-8.1a.45.45 0 00-.45.45v10.35H8.1v-1.35a.45.45 0 00-.45-.45H6.3v.9h.9v.9H5.4V105h-.9v1.8H2.7v-.9h.9v-.9H2.25a.45.45 0 00-.45.45v1.35H.45a.45.45 0 00-.45.45v9.9a.45.45 0 00.45.45h26.1a.45.45 0 00.45-.45v-20.7a.45.45 0 00-.45-.45zm-7.65.9h7.2v5.4h-7.2zm-.9 12.6H9.9v-1.8H18zM.9 107.7H9v1.8H.9zm0 2.7H9v6.3H.9zm9 0h3.6v6.3H9.9zm4.5 0H18v6.3h-3.6zm4.5 6.3v-13.5h7.2v13.5zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23743"
          transform="translate(0 -90.6)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23744"
          d="M352 144h.9v1.8h-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23744"
          transform="translate(-332.2 -135.9)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23745"
          d="M352 256h.9v1.8h-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23745"
          transform="translate(-332.2 -241.6)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23746"
          d="M32.9 312.45a.45.45 0 11-.45-.45.45.45 0 01.45.45zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23746"
          transform="translate(-30.2 -294.45)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23747"
          d="M64.9 312.45a.45.45 0 11-.45-.45.45.45 0 01.45.45zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23747"
          transform="translate(-60.4 -294.45)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23748"
          d="M96.9 312.45a.45.45 0 11-.45-.45.45.45 0 01.45.45zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23748"
          transform="translate(-90.6 -294.45)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23749"
          d="M128.9 312.45a.45.45 0 11-.45-.45.45.45 0 01.45.45zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23749"
          transform="translate(-120.8 -294.45)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23750"
          d="M.45 8.55h9a.45.45 0 00.45-.45V6.75a.45.45 0 00-.132-.318l-1.8-1.8A.45.45 0 007.65 4.5H7.2V.45A.45.45 0 006.75 0h-3.6a.45.45 0 00-.45.45V4.5h-.45a.45.45 0 00-.318.132l-1.8 1.8A.45.45 0 000 6.75V8.1a.45.45 0 00.45.45zM3.6.9h2.7v3.6H3.6zM.9 6.936L2.436 5.4h5.028L9 6.936v.714H.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23750"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23751"
          d="M37.85 368h-5.4a.45.45 0 00-.45.45v3.6a.45.45 0 00.45.45h5.4a.45.45 0 00.45-.45v-3.6a.45.45 0 00-.45-.45zm-.45 3.6h-4.5v-2.7h4.5zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23751"
          transform="translate(-30.2 -347.3)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23752"
          d="M232 312h1.8v.9H232zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23752"
          transform="translate(-218.95 -294.45)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23753"
          d="M208 392h.9v1.8h-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23753"
          transform="translate(-196.3 -369.95)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23754"
          d="M272 392h.9v1.8h-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23754"
          transform="translate(-256.7 -369.95)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23755"
          d="M184.45 11.25h2.7a.45.45 0 00.45-.45v-.45a1.8 1.8 0 00-1.35-1.736V0h-.9v8.614A1.8 1.8 0 00184 10.35v.45a.45.45 0 00.45.45zm1.35-1.8a.9.9 0 01.9.9h-1.8a.9.9 0 01.9-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23755"
          transform="translate(-173.65)"
        />
        <path
          id="kitchen_svg__prefix__Trazado_23756"
          d="M240.45 7.65h2.7a.45.45 0 00.45-.45v-.45a1.8 1.8 0 00-1.35-1.736V0h-.9v5.014A1.8 1.8 0 00240 6.75v.45a.45.45 0 00.45.45zm1.35-1.8a.9.9 0 01.9.9h-1.8a.9.9 0 01.9-.9zm0 0"
          className="kitchen_svg__prefix__cls-1"
          data-name="Trazado 23756"
          transform="translate(-226.5)"
        />
      </g>
    </svg>
  )
}
