import React, {PureComponent} from 'react';
// import {AmmenityDropdown, AmmenitySelector, LabeledIcon, TextInputWithPlaceholder} from './';
import {getAmmenityValueWithoutPivot} from '../../lib/Ammenities';
import Bathrooms from '../../lib/Icon/icons/Bathrooms';
import Bedrooms from '../../lib/Icon/icons/Bedrooms';
import Cellar from '../../lib/Icon/icons/Cellar';
import Clock from '../../lib/Icon/icons/Clock';
import Dimensions from '../../lib/Icon/icons/Dimensions';
import Floor from '../../lib/Icon/icons/Floor';
import Restrooms from '../../lib/Icon/icons/Restrooms';
import { NON_DIGITS_REGEXP } from '../../lib/Regexp';
import { AmmenityDropdown } from './AmmenityDropdown';
import { AmmenitySelector } from './AmmenitySelector';
import LabeledIcon from './LabeledIcon';
import { LegalStatusForm } from './LegalStatusForm';
import { TextBox } from './TextBox';
import { TextInputWithPlaceholder } from './TextInputWithPlaceholder';

export class BasicDetailsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAgeModalVisible: false
    };
  }

  landAreaLabel() {
    if(this.props.postType === 'Request') {
      return 'Terreno';
    }
    else {
      return 'Terreno*';
    }
  }
  buildingAreaLabel() {
    if(this.props.postType === 'Request') {
      return 'Construído';
    }
    else if(this.props.propertyType !== 2) {
      return 'Construído*';
    }
    else {
      return 'Construído';
    }
  }
  getAgeValueLabel() {
    
    let v = [
      {value: "-1", label: 'Selecciona una opción'},
      {value: "0", label: 'Pre-Venta'},
      {value: "1", label: 'En construcción'},
      {value: "2", label: 'Nuevo'},
      {value: "3", label: 'Hasta 5 años'},
      {value: "4", label: '5-10 años'},
      {value: "5", label: '10-20 años'},
      {value: "6", label: '20-50 años'},
      {value: "7", label: '50+ años'},
    ].find(item => Number(this.props.age) === Number(item.value));
    if(v)
    {
      return {
        value: v.value,
        label: v.label
      }
    }
    else
    return {}
  }

  changeArea = (text) => {

    let landArea = text.replace(NON_DIGITS_REGEXP, "")
    console.log(landArea)

    this.props.onChangeValue({landArea})
  }

  changeBuildingArea = (text) => {

    let buildingArea = text.replace(NON_DIGITS_REGEXP, "")

    this.props.onChangeValue({buildingArea})
  }

  render() {
    if(this.props.active) {
      return(
        <div className="">
          <LabeledIcon
          icon={<Dimensions style={{height:39,width:43}}/>}
          label="Dimensiones"
          />
          <div className="flex flex-row" style={{ marginTop:25 }}>
              {
                this.props.propertyType !== 3 && this.props.propertyType !== 4 &&
              <div className="flex flex-1 mr-10">
                <TextInputWithPlaceholder
                  removeContainer
                  label={this.landAreaLabel()}
                  maxLength={7}
                  onChangeText={text => this.changeArea(text)}
                  placeholder='Ingresa el número de m²'
                  value={this.props.landArea}
                  emptyField={this.props.requiredFields.find(element => element === 'landArea') && this.props.landArea.length===0}
                />
              </div>
              }
              <div className="flex flex-1">
              <TextInputWithPlaceholder
                removeContainer
                label={this.buildingAreaLabel()}
                maxLength={7}
                onChangeText={text => this.changeBuildingArea(text)}
                placeholder='Ingresa el número de m²'
                value={this.props.buildingArea}
                emptyField={this.props.requiredFields.find(element => element === 'buildingArea') && this.props.buildingArea.length ===0}
              />
            </div>
          </div>

          
          {
            this.props.propertyType !== 2 &&
            <div className="w-full">
              { 
                this.props.propertyType !== 3 && this.props.propertyType !== 4 && this.props.propertyType !== 5 &&
                <div className="mt-14">
                  <LabeledIcon
                  icon={<Bedrooms/>}
                  label={`Recámaras${this.props.postType === 'Property' ? '*' : ''}`}
                  />
                  <AmmenitySelector
                    onChangeValue={value => this.props.onChangeAmmenityValue(1, value)}
                    value={getAmmenityValueWithoutPivot(this.props.ammenities, 1)}
                  />
                </div>
              }
              {
                (this.props.requiredFields.find(element => element === 'bedrooms') && getAmmenityValueWithoutPivot(this.props.ammenities, 1) === '') &&
                <p className="text-red-500">
                  Campo requerido
                </p>
              }
              <div className="mt-10">
                <LabeledIcon
                icon={<Bathrooms/>}
                label={`Baños${this.props.postType === 'Property' ? '*' : ''}`}
                />
                  <AmmenitySelector
                    onChangeValue={value => this.props.onChangeAmmenityValue(2, value)}
                    value={getAmmenityValueWithoutPivot(this.props.ammenities, 2)}
                  />
              </div>
              
              {
                (this.props.requiredFields.find(element => element === 'bathrooms') && getAmmenityValueWithoutPivot(this.props.ammenities, 2) === '')  &&
                <p className="text-red-500">
                  Campo requerido
                </p>
              }
              <div className="mt-10">
                <LabeledIcon
                  icon={<Restrooms/>}
                  label="Medios baños"
                  span="(opcional)"
                />
                  <AmmenitySelector
                    onChangeValue={value => this.props.onChangeAmmenityValue(3, value)}
                    value={getAmmenityValueWithoutPivot(this.props.ammenities, 3)}
                  />
              </div>
              <div className="mt-10">
                <LabeledIcon
                icon={<Floor fill="#112233" className="w-7"/>}
                label={`Niveles${this.props.postType === 'Property' ? '*' : ''}`}
                />
                <AmmenitySelector
                  onChangeValue={value => this.props.onChangeValue({floors: value})}
                  value={this.props.floors}
                />
              </div>
              {
                (this.props.requiredFields.find(element => element === 'floors') && this.props.floors === '')  &&
                <p className="text-red-500">
                  Campo requerido
                </p>
              }
              <div className="mt-10">
                <LabeledIcon
                  icon={<Cellar fill="#112233" className="w-7"/>}
                  label={`Estacionamiento `}
                  span="(opcional)"
                />
                <AmmenitySelector
                  onChangeValue={value => this.props.onChangeAmmenityValue(4, value)}
                  value={getAmmenityValueWithoutPivot(this.props.ammenities, 4)}
                />
              </div>
              <div className="w-full">
                <AmmenityDropdown
                  icon={<Clock/>}
                  label={`Antigüedad${this.props.postType === 'Property' ? '*' : ''}`}
                  placeholder='Seleccionar'
                  value={this.getAgeValueLabel()}
                  options={[
                    {value:"0",label: 'Pre-venta' },
                    {value:"1",label: 'En construcción' },
                    {value:"2",label: 'Nuevo' },
                    {value:"3",label: 'Hasta 5 años' },
                    {value:"4",label: '5-10 años' },
                    {value:"5",label: '10-20 años' },
                    {value:"6",label: '20-50 años' },
                    {value:"7",label: '50+ años'},
                  ]}
                  onChange={(age)=>this.props.onChangeValue({age})}
                />
                
                {
                  (this.props.requiredFields.find(element => element === 'age') && this.props.age === -1) &&
                  <p className="text-red-500">
                    Campo requerido
                  </p>
                }

              </div>
          </div>
          }

          <LegalStatusForm
            onChangeValue={value => this.props.changeLegalStatus({ legalStatus: value })}
            value={this.props.legalStatus}
          />

          <TextBox
            maxLength={280}
            onChangeText={description => this.props.onChangeTextInput({ description })}
            placeholder={`¿Cuál es la mejor cualidad de tu propiedad?\nMáx. 280 caracteres.`}
            value={this.props.description}
          />
          {
            (this.props.requiredFields.find(element => element === 'description') && this.props.description.length === 0) &&
            <p className="text-red-500">
              Campo requerido
            </p>
          }


        </div>
      );
    }
    else {
      return null;
    }
  }
}

