import AuthenticatedApi from './AuthenticatedApi';

export default class PremierApi {
  static activate(data) {
    return AuthenticatedApi.post(`/api/premier/activate`, {}, data).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static currentLicense() {
    return AuthenticatedApi.get(`/api/premier/current_license`, {}, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }


  static storePaymentMethod(data) {
    return AuthenticatedApi.post(`/api/premier/store_payment_method`, {}, data).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static fetchCancelationReasons() {
    return AuthenticatedApi.get(`/api/premier/cancelation_reasons`, {}, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static cancelSuscription(data) {
    return AuthenticatedApi.post(`/api/premier/cancel`, {}, data).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static deleteCancelationRequest(id) {
    return AuthenticatedApi.delete(`/api/premier/delete_cancelation_request/${id}`, {}, {}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }

  static support(message) {
    return AuthenticatedApi.post(`/api/premier/support`, {}, {message}).then(result => {
      return result.data;
    }).catch(error => {
      throw error;
    });
  }



}
