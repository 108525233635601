import React from 'react';

export function Palapa({ className, fill }) {
  return (
    <svg fill={fill ? fill : '#2b77b7'} viewBox='0 0 25 27' className={className ? className : "w-5"}>
      <g
        id="palapa_svg__prefix__residential"
        transform="translate(-86.562 -15.889)"
      >
        <path
          id="palapa_svg__prefix__Trazado_23836"
          d="M119.9 30.09h-17.975a.376.376 0 01-.376-.376c0-7.623 4.2-13.825 9.366-13.825s9.365 6.2 9.365 13.825a.376.376 0 01-.38.376zm-17.6-.752h17.221c-.132-7.036-3.943-12.7-8.61-12.7s-8.475 5.662-8.611 12.7z"
          className="palapa_svg__prefix__cls-1"
          data-name="Trazado 23836"
          transform="translate(-12.171)"
        />
        <path
          id="palapa_svg__prefix__Trazado_23837"
          d="M132.2 30.09h-10.575a.376.376 0 01-.376-.376c0-7.752 2.488-13.825 5.664-13.825s5.664 6.073 5.664 13.825a.376.376 0 01-.377.376zm-10.2-.752h9.82c-.078-6.921-2.294-12.7-4.91-12.7s-4.83 5.779-4.91 12.7z"
          className="palapa_svg__prefix__cls-1"
          data-name="Trazado 23837"
          transform="translate(-28.169)"
        />
        <path
          id="palapa_svg__prefix__Trazado_23838"
          d="M145.375 30.09h-2.644a.376.376 0 01-.376-.376c0-3.6.138-6.995.389-9.547.388-3.948.859-4.278 1.309-4.278s.921.33 1.309 4.278c.251 2.552.389 5.943.389 9.547a.376.376 0 01-.376.376zm-2.268-.752H145c-.018-6.873-.52-11.346-.946-12.49-.427 1.144-.929 5.618-.947 12.49z"
          className="palapa_svg__prefix__cls-1"
          data-name="Trazado 23838"
          transform="translate(-45.309)"
        />
        <path
          id="palapa_svg__prefix__Trazado_23839"
          d="M110.019 30.09H87.468a.907.907 0 01-.905-.938c.263-7.437 5.613-13.263 12.181-13.263s11.918 5.825 12.181 13.263a.906.906 0 01-.905.938zM98.744 16.641c-6.161 0-11.182 5.507-11.43 12.537a.156.156 0 00.044.113.153.153 0 00.11.047h22.551a.153.153 0 00.111-.047.155.155 0 00.043-.113c-.248-7.03-5.273-12.537-11.429-12.537z"
          className="palapa_svg__prefix__cls-1"
          data-name="Trazado 23839"
        />
        <g
          id="palapa_svg__prefix__Grupo_1669"
          data-name="Grupo 1669"
          transform="translate(88.033 29.338)"
        >
          <path
            id="palapa_svg__prefix__Trazado_23842"
            d="M115.563 100.086h-20.8a.376.376 0 01-.376-.376V87.842a.376.376 0 01.376-.376h20.8a.376.376 0 01.376.376V99.71a.376.376 0 01-.376.376zm-20.423-.751h20.048V88.218H95.14z"
            className="palapa_svg__prefix__cls-1"
            data-name="Trazado 23842"
            transform="translate(-94.388 -87.466)"
          />
        </g>
      </g>
    </svg>
  )
}
