import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const InformationAlert = (title,message, callback = () => {},outside = () => {}) => {
  confirmAlert({
    onClickOutside: () => {outside()},
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <p className="poppins-semi-bold text-redaria-black text-center" style={{ fontSize:27 }}>{title}</p>
          <p className="poppins-regular text-redaria-black text-center px-5" style={{ fontSize:21 }}>{message}</p>

          <div className="flex flex-row mt-5">
            <button
              className="redaria-button-white mx-5"
              onClick={() => {
                callback();
                onClose();
              }}
            >
            Aceptar
            </button>
          </div>
        </div>
      );
    }
  });
};

