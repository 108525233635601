import React from 'react';

export default function Bathrooms() {
  return (
    <svg viewBox='0 0 22 20' className="w-7">
      <g>
        <g
          id="bathroom_svg__prefix__bathroom_1_"
          data-name="bathroom (1)"
          transform="translate(0 -22.261)"
        >
          <g
            id="bathroom_svg__prefix__Grupo_1579"
            data-name="Grupo 1579"
            transform="translate(0 22.261)"
          >
            <g id="bathroom_svg__prefix__Grupo_1578" data-name="Grupo 1578">
              <path
                id="bathroom_svg__prefix__Trazado_23703"
                d="M21.525 29.914h-.481v-5.74a1.913 1.913 0 10-3.827 0v.061a1.914 1.914 0 00-1.429 1.849v.481a.478.478 0 00.478.478h2.864a.478.478 0 00.478-.478v-.481a1.914 1.914 0 00-1.435-1.85v-.06a.957.957 0 111.913 0v5.74H.478a.478.478 0 00-.478.478v3.815a6.238 6.238 0 005.752 6.212v1.453a.478.478 0 00.478.478h1.913a.478.478 0 00.478-.478v-1.435h5.729v1.435a.478.478 0 00.478.478h1.913a.478.478 0 00.478-.478v-1.6A6.241 6.241 0 0022 34.207v-3.815a.478.478 0 00-.475-.478zm-2.873-3.83h-1.908a.954.954 0 111.907 0zM7.665 41.394h-.957v-.957h.957zm8.6 0h-.957v-.957h.957zm4.783-9.566H5.238a.478.478 0 100 .957h15.808v1.423a5.28 5.28 0 01-5.274 5.274H6.23a5.28 5.28 0 01-5.273-5.275v-1.423h1.411a.478.478 0 100-.957H.957v-.957h20.09z"
                className="bathroom_svg__prefix__cls-1"
                data-name="Trazado 23703"
                transform="translate(0 -22.261)"
              />
            </g>
          </g>
          <g
            id="bathroom_svg__prefix__Grupo_1581"
            data-name="Grupo 1581"
            transform="translate(16.263 27.523)"
          >
            <g id="bathroom_svg__prefix__Grupo_1580" data-name="Grupo 1580">
              <path
                id="bathroom_svg__prefix__Trazado_23704"
                d="M378.913 144.7a.478.478 0 00-.478.478v.957a.478.478 0 10.957 0v-.957a.478.478 0 00-.479-.478z"
                className="bathroom_svg__prefix__cls-1"
                data-name="Trazado 23704"
                transform="translate(-378.435 -144.696)"
              />
            </g>
          </g>
          <g
            id="bathroom_svg__prefix__Grupo_1583"
            data-name="Grupo 1583"
            transform="translate(18.176 27.523)"
          >
            <g id="bathroom_svg__prefix__Grupo_1582" data-name="Grupo 1582">
              <path
                id="bathroom_svg__prefix__Trazado_23705"
                d="M423.435 144.7a.478.478 0 00-.478.478v.957a.478.478 0 10.957 0v-.957a.478.478 0 00-.479-.478z"
                className="bathroom_svg__prefix__cls-1"
                data-name="Trazado 23705"
                transform="translate(-422.957 -144.696)"
              />
            </g>
          </g>
          <g
            id="bathroom_svg__prefix__Grupo_1585"
            data-name="Grupo 1585"
            transform="translate(3.324 31.828)"
          >
            <g id="bathroom_svg__prefix__Grupo_1584" data-name="Grupo 1584">
              <path
                id="bathroom_svg__prefix__Trazado_23706"
                d="M78.173 245.01a.478.478 0 10.14.338.482.482 0 00-.14-.338z"
                className="bathroom_svg__prefix__cls-1"
                data-name="Trazado 23706"
                transform="translate(-77.357 -244.87)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export function BathroomsGrey({className}) {
  return (
    <svg viewBox='0 0 19.091 14.318' className={className ? className : "w-7"}>
      <g data-name="Grupo 1437">
        <path
          d="M17.9 3.977h-.4V1.591a1.591 1.591 0 0 0-3.182 0v.4a.4.4 0 0 0 .4.4.4.4 0 0 0 .4-.4v-.4a.8.8 0 0 1 1.591 0v2.386H1.193A1.19 1.19 0 0 0 .8 6.29v1.266a5.167 5.167 0 0 0 2.386 4.35v2.014a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .356-.22l.7-1.4a5.207 5.207 0 0 0 .535.028h7.159a5.2 5.2 0 0 0 .535-.028l.7 1.4a.4.4 0 0 0 .356.22h.8a.4.4 0 0 0 .4-.4v-2.013a5.167 5.167 0 0 0 2.386-4.35V6.29a1.19 1.19 0 0 0-.4-2.313zm-13.125.8H8.75v3.905l-3.977-.663zm-3.977.4a.4.4 0 0 1 .4-.4h2.779v.8H1.193A.4.4 0 0 1 .8 5.171zm3.334 8.352h-.155v-1.201a5.132 5.132 0 0 0 .642.215zm10.985 0h-.152l-.49-.98a5.14 5.14 0 0 0 .642-.215zM17.5 7.557a4.38 4.38 0 0 1-4.375 4.375H5.966a4.38 4.38 0 0 1-4.375-4.375V6.364h2.386v1.989a.4.4 0 0 0 .332.392l4.773.8a.41.41 0 0 0 .065.005.4.4 0 0 0 .4-.4V6.364H17.5v1.193zm.4-1.989H9.546v-.8H17.9a.4.4 0 1 1 0 .8z"
          data-name="Trazado 5528"
          fill="#123"
        />
      </g>
    </svg>
  )
}