/* eslint-disable */
import axios from 'axios';

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export default class GooglePlacesApi {

  static getPlace(query = '') {
    
    const url = `/place/autocomplete/json?input=${query}&components=country:mx&key=${GOOGLE_PLACES_API_KEY}`;

    return axios.get(url).then((result) => result.data.predictions[0].place_id).then((placeId) => {
        const placeDetailsUrl = `/place/details/json?placeid=${placeId}&fields=geometry&components=country:mx&key=${GOOGLE_PLACES_API_KEY}`;
        return axios.get(placeDetailsUrl).then((result) => result);
      }).catch((error) => {
        throw error;
      });
  }

}
