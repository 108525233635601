import React, { PureComponent } from 'react'
import FlatList from 'flatlist-react';
import Property from './../../components/Archive/Property';
import PropertiesApi from '../../api/PropertiesApi';
import { ErrorAlert, InformationAlert } from '../../lib/alerts';
import EmptyList from '../EmptyList/EmptyList';

export default class MyArchive extends PureComponent {


  setAsAvailable = (id,type) => {

    if(type === 'property'){
      let formData = new FormData();
      formData.append('status', 0);
      PropertiesApi.update(id, formData).then(result => {
        return InformationAlert('Listo', 'Tu propiedad se ha marcado como disponible', 
        () => {this.props.refetch()},
        () => {this.props.refetch()},
        );
      }).catch(error => {
        ErrorAlert('Hubo un error. Intenta de nuevo más tarde');
      });
   }
  }

  removeArchive = (id) => {
   
    if(id !== 0){
      let formData = new FormData();
      formData.append('status', 0);
      PropertiesApi.removeArchive(id).then(result => {
        return InformationAlert('Listo', 'Tu propiedad se ha eliminado del archivo', () => {
          const properties = [...this.props.properties];
  
          this.props.setState({
            archiveProperties: properties.filter(property => Number(property.id) !== Number(id))
          });
        });
      }).catch(error => {
        ErrorAlert('Hubo un error. Intenta de nuevo más tarde');
      });
   }
  }

  _renderProperty = (item,index) => (
    <Property
      key={index}
      user={item.attributes.uploadedBy.data}
      property={item}
      city={item.attributes.address.city}
      dealType={item.attributes.dealType}
      imageUrl={item.attributes.images[0].path}
      numberOfPitches={item.attributes.numberOfPitches}
      price={item.attributes.price}
      propertyType={item.attributes.type}
      status={item.attributes.status}
      setAsAvailable={() => this.setAsAvailable(item.id,'property')}
      removeArchive={ () => this.removeArchive(item.id)}
    />
  ) 

  render() {
    return (
      <div className="">
        <div className="flex justify-between flex-row items-center" style={{ marginBottom:20 }}>
          <div className="flex flex-row items-center">
            <p className="poppins-medium text-redaria-black ml-3" style={{ fontSize: 27 }}>Archivo</p>
            <div className="border-redaria-black px-4 ml-5" style={{ borderRadius: 18 }}>
              <p className="questrial text-redaria-black" style={{ fontSize: 20 }}>{this.props.properties.length}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap" style={{ marginRight:90 }}>

          <FlatList
            list={this.props.properties}
            renderItem={this._renderProperty}
            renderWhenEmpty={() => <EmptyList description={"Aquí verás tus publicaciones archivadas."}/>}
          />


        </div>
      </div>
    )
  }
}
