import React, { PureComponent } from 'react'
import PropertiesApi from '../../api/PropertiesApi'
import UserRequest from '../../api/UserRequest'
import LoadingScreen from '../../components/Loading/LoadingScreen'
import NeedProfileScreen from '../../components/NeedProfileScreen/NeedProfileScreen'
import  {default as MyPropertiesComponent} from '../../components/Profile/MyProperties'
import Search from '../../components/Profile/Search'
import { ErrorAlert } from '../../lib/alerts/ErrorAlert'
import { InformationAlert } from '../../lib/alerts/InformationAlert'
import UserCard from '../../components/UserCard/UserCard'
  import MyArchive from '../../components/Profile/MyArchive'
import { connect } from 'react-redux'
import ModalNewProperty from '../../components/ModalNewProperty/ModalNewProperty'
import WelcomeModal from '../../components/WelcomeModal/WelcomeModal'
import LoadingModal from '../../components/Loading/LoadingModal'
import SecureLS from 'secure-ls';
import { deleteUser } from '../../actions/authenticatedUser'
import { clearPremier } from '../../actions/premier'
import AuthenticatedApi from '../../api/AuthenticatedApi'
class MeContainer extends PureComponent {


  state = {
    profile: null,
    loadingData: true,
    isAlreadySearch: false,
    search: '',
    isPropertiesActive: false,
    properties: [],
    PRPSData: [],
    PRPSEmpty: false,
    HiddePRPS: false,
    propertyOptionsId: 0,
    propetyDealType: '',
    archiveProperties:[],
    isNewPropertyModalVisible:false,
    loadingSearch:false
  }

  componentDidMount(){
    this.fetchData()
  }

  componentDidUpdate(prevProps){
    if(prevProps.needsProfileInformation && !this.props.needsProfileInformation)
    {
      this._refetch()
    }
  }



  fetchData = () => {

    Promise.all([
      this.getUser(),
      PropertiesApi.myIndex(),
      PropertiesApi.myArchivedIndex(),
    ]).then(([
      profile,properties,archiveProperties
      ]) => {
        this.setState({
          profile:profile.data,
          properties:properties.data,
          archiveProperties:archiveProperties.data,
          loadingData:false
        })
    })
    .catch( err => console.log(err))

  }

  getUser = async() =>{
    return new Promise((resolve, reject) => {
      UserRequest.profile()
      .then(result => resolve(result))
      .catch(e => {
        
        if(e.response?.status === 401)
        {
          const ls = new SecureLS();
          ls.remove(process.env.REACT_APP_USER_AUTH_KEY)
          ls.remove(process.env.REACT_APP_USER_PROFILE_KEY)
          this.props.dispatch(deleteUser());
          this.props.dispatch(clearPremier());
          AuthenticatedApi.resetPromise()
          this.props.history.replace('/',{needClear:true})
        }
        reject()
      })
    })
  }

  _clearSearch = () => {
    this.setState({
      search:'',
      isAlreadySearch:false,
      PRPSData:[],
      PRPSEmpty:true,
      HiddePRPS:false
    })
  }

  _onSubmitEditingSearch = () => {


    if(this.state.search.length === 0){
      InformationAlert(
        'El campo se encuentra vacio',
        'Debes ingresar un ID de Publicación.',
      );
      return
    }
    this.setState({HiddePRPS:true,loadingSearch:true},()=>{
      UserRequest.searchMyProperties(this.state.search).then( result =>{
        this.setState({
          PRPSData:result.data,
          PRPSEmpty:false,
          isAlreadySearch:true,
          loadingSearch:false
        })
      })
      .catch( error =>{
        ErrorAlert(
          'Hubo un error al buscar. Intenta de nuevo más tarde',
          ()=>{
            this.setState({
              HiddePRPS:false,
              isAlreadySearch:false,
              loadingSearch:false
            })
          }
        );
      })
    })

  }

  _refetch = () => {
    this.setState({loadingData:true},()=>this.fetchData())
  }

  _openNewPropertyModal = () => this.setState({isNewPropertyModalVisible:true})
  _closeNewPropertyModal = () => this.setState({isNewPropertyModalVisible:false})

  setSearch = search => this.setState({search})

  render() {

    const { type } = this.props.match.params;

  if(this.props.needsProfileInformation)
  {
    return <NeedProfileScreen refresh={this._refetch}  description={'Termina tu perfil para administrar tu cuenta.'}/>
  }
    
  if(this.state.loadingData)
    return <LoadingScreen/>
  

  return (
    <div className="pt-10 mb-10 flex flex-row">
      <LoadingModal visible={this.state.loadingSearch}/>
      <WelcomeModal visible={this.props.showWelcome}/>
      {
        !this.state.isNewPropertyModalVisible
        &&
        <React.Fragment>
          <UserCard
            propertiesActive={type === 'properties' ? true : false}
            refresh={this._refetch} 
            profile={this.state.profile}/>
          
          <div className="w-full" style={{ marginLeft:80 }}>


        {
          type === 'properties' &&
          <Search
            isAlreadySearch={this.state.isAlreadySearch}
            isPremier={this.state.profile.attributes.isPremier}
            onSubmitEditing={this._onSubmitEditingSearch}
            clearSearch={this._clearSearch}
            value={this.state.search}
            setSearch={this.setSearch}
            PRPSData={this.state.PRPSData}
            PRPSEmpty={this.state.PRPSEmpty}
            openNewPropertyModal={this._openNewPropertyModal}
            refetch={this._refetch}
            />
        }
          
          {
            type === 'archive' &&
              <MyArchive
                properties={this.state.archiveProperties}
                setState={ s => this.setState(s)}
                refetch={this._refetch}
              />
          }
          {
            type === 'properties' &&
              <MyPropertiesComponent
                hidde={this.state.HiddePRPS}
                isPremier={this.state.profile.attributes.isPremier}
                isActive={this.state.isPropertiesActive}
                onPress={this._toggleProperties}
                properties={this.state.properties}
                refetch={this._refetch}
              />
          }


          </div>

        </React.Fragment>
      }
      {
        this.state.isNewPropertyModalVisible &&
        <ModalNewProperty
          refetch={this._refetch}
          close={this._closeNewPropertyModal}
        />
      }
      
      
    </div>
  )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    needsProfileInformation: state.authenticatedUser?.attributes?.needsProfileInformation,
    showWelcome: state.welcome.show,
  }
}

export default connect(mapStateToProps)(MeContainer)
