import FlatList from 'flatlist-react';
import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import PropertiesApi from '../../api/PropertiesApi';
import LoadingScreen from '../../components/Loading/LoadingScreen';
import AdditionalAmmenities from '../../components/Property/AdditionalAmmenities';
import AmmenitiesList from '../../components/Property/AmmenitiesList';
import BasicAmmenities from '../../components/Property/BasicAmmenities';
import BasicInformation from '../../components/Property/BasicInformation';
import GeneralDetails from '../../components/Property/GeneralDetails';
import LegalStatus from '../../components/Property/LegalStatus';
import Map from '../../components/Property/Map';
import NearbyPlaces from '../../components/Property/NearbyPlaces';
import Separator from '../../components/Property/Separator';
import TimeAndType from '../../components/Property/TimeAndType';
import PropertyHeader from '../../headers/Property/PropertyHeader';
import { ErrorAlert } from '../../lib/alerts/ErrorAlert';
import { getPremierDays, getPremierStauts } from '../../selectors/premier';
import { default as PropertyCard } from '../../components/Profile/Property'
import LoadingComponent from '../../components/Loading/LoadingComponent';
import ShareButton from '../../components/Property/ShareButton';
import TemporaryLinksApi from '../../api/TemporaryLinksApi';
import OptionsPropertyModal from '../../components/Profile/OptionsPropertyModal';
import OptionsButton from '../../components/Property/OptionsButton';
import ShareModal from '../../components/ShareModal/ShareModal';

export class Property extends PureComponent {


  constructor(props: any) {
    super(props);

    this.state = {
      chat: {},
      data: {},
      properties: [],
      loadingData: true,
      loadingProperty: false,
      id: null,
      showOptions: false,
      shareModalVisible: false,
      topHeader:false
    }
  }

  needProfilemodalRef = React.createRef()


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.id) {
      const { id: propertyId } = this.props.match.params;
      const { id: oldPropertyId } = prevProps.match.params

      if (Number(propertyId) !== Number(oldPropertyId) && !this.state.loadingData) {
        this.fetchProperty(propertyId)
      }
    }
  }

  header = React.createRef()

  handleScroll = () => {
    const h = this.header
    if(h.current)
    {
      console.log(this.header.current?.getBoundingClientRect().top)
      if(this.header.current?.getBoundingClientRect().top <= 0)
      {
        this.setState({topHeader:true})
      }
      else
      {
        this.setState({topHeader:false})
      }

    }
  }

  componentDidMount() {

    this.fetchData();

    
    
    window.addEventListener("scroll",this.handleScroll);

  }

  markSolicitudeAsRead = (property_id, solicitude_id) => {

    PropertiesApi.recommendedSolicitudesMarkAsRead(property_id, solicitude_id).then(r => {
    })
  }

  fetchData() {
    const { id } = this.props.match.params;
    this.setState({ loadingData: true }, () => {
      Promise.all([
        PropertiesApi.get(id),
        PropertiesApi.myIndex()
      ]).then(([property, properties]) => {
        const attributes = property.data.attributes
        const { name, phone: userPhone, id: userId } = attributes.uploadedBy.data

        const ps = properties.data.filter( p => Number(p.id) !== Number(property.data.id))

        this.setState({
          id: property.data.id,
          properties: ps,
          data: attributes,
          showOptions: false,
          chat: {
            name,
            userPhone,
            userId
          },
          loadingData: false
        });
      })
        .catch(err => {
          ErrorAlert('', () => { console.log(err) });
        });
    })


  }

  fetchProperty = (id) => {
    this.setState({ loadingProperty: true }, () => {
      PropertiesApi.get(id).then(property => {
        const attributes = property.data.attributes
        const { name, phone: userPhone, id: userId } = attributes.uploadedBy.data

        this.setState({
          id: property.data.id,
          data: attributes,
          showOptions: false,
          chat: {
            name,
            userPhone,
            userId
          },
          loadingProperty: false
        });
      })
        .catch(err => {
          ErrorAlert('', () => { console.log(err) });
        });
    })
  }

  _refetch = () => {
    this.setState({ loadingData: true }, () => this.fetchData())
  }


  _renderProperty = (item, index) => {


    return (
      <PropertyCard
        marginRight={0}
        user={item.attributes.uploadedBy.data}
        key={'property-list' + String(item.id)}
        isPremier={this.props.isPremier}
        property={item}
        refetch={this._refetch}
      />
    );
  }

  openShare = () => {
    const { id: propertyId } = this.props.match.params;
    const propertyArray = [propertyId];
    TemporaryLinksApi.create(propertyArray).then(result => {
      this.setState({
        url: result.data.attributes.url,
        shareModalVisible: true
      })
    }).catch(error => {
      ErrorAlert('Hubo un error generando el link. Intenta de nuevo más tarde');
    });
  }

  _openOptions = () => this.setState({ showOptions: !this.state.showOptions })
  _closeOptions = () => this.setState({ showOptions: false })





  render() {

    const { id: propertyId } = this.props.match.params;

    if (!this.state.loadingData) {
      return (
        <div className="ml-10" style={{ marginTop: 85 }}>
          <div className="flex">
            <div className="child">

              {
                this.state.loadingProperty
                  ? <LoadingComponent />
                  :
                  <React.Fragment>

                    <div style={{ marginBottom: 23 }}>
                      <TimeAndType
                        newRecommended={false}
                        createdAt={this.state.data.createdAt}
                        type={this.state.data.type}
                        dealType={this.state.data.dealType} />
                    </div>
                    <PropertyHeader
                      data={this.state.data}
                      images={this.state.data.images}
                      navigation={this.props.navigation}
                      numberOfPitches={this.state.data.numberOfPitches}
                    />
                    <Separator />
                    <BasicInformation
                      id={propertyId}
                      propertyType={this.state.data.type}
                      dealType={this.state.data.dealType}
                      state={this.state.data.address.state}
                      city={this.state.data.address.city}
                      neighborhood={this.state.data.address.neighborhood}
                      price={this.state.data.price}
                      maintenanceFee={this.state.data.maintenanceFee}
                    />
                    <p className="poppins-semi-bold text-redaria-black mt-5" style={{ fontSize: 21 }}>Descripción:</p>
                    <p className="text-redaria-black questrial mt-2" style={{ fontSize: 19 }}>
                      {this.state.data.description}
                    </p>
                    <Separator />
                    <AmmenitiesList
                      ammenities={this.state.data.ammenities}
                      landArea={this.state.data.landArea}
                      buildingArea={this.state.data.buildingArea}
                      propertyType={this.state.data.type}
                    />
                    <Separator />
                    <LegalStatus
                      value={this.state.data.legalStatus}
                    />
                    <Separator />
                    <GeneralDetails
                      age={this.state.data.age}
                      ammenities={this.state.data.ammenities}
                      floors={this.state.data.floors}
                      propertyType={this.state.data.propertyType}
                    />


                    <Separator />
                    {
                      this.state.data.ammenities.length > 0 &&
                      <AdditionalAmmenities
                        ammenities={this.state.data.ammenities}
                      />
                    }
                    {
                      this.state.data.type !== 'Local' && this.state.data.type !== 'Oficina' && this.state.data.type !== 'Bodega' &&
                      <BasicAmmenities
                        ammenities={this.state.data.ammenities}
                      />
                    }
                    {
                      this.state.data.nearbyPlaces.length > 0 &&
                      <div>
                        <NearbyPlaces
                          nearbyPlaces={this.state.data.nearbyPlaces}
                        />
                        <Separator />
                      </div>
                    }
                    <Map
                      city={this.state.data.address.city}
                      state={this.state.data.address.state}
                      neighborhood={this.state.data.address.neighborhood}
                      latitude={this.state.data.latitude}
                      longitude={this.state.data.longitude}
                    />
                  </React.Fragment>
              }

            </div>
            <div className="child controls-panel">
              <div className="content-wrapper">
                <div className="content-scroll" ref={this.header}>
                  <div  className="flex flex-col items-center">
                    <div className={`bg-white flex flex-col items-center justify-between ${this.state.topHeader && 'fixed top-0 z-30'}`} style={{ paddingTop: 23, paddingLeft: 35, paddingRight: 35, width: 426, marginBottom: 30, paddingBottom: 23, borderRadius: 10 }}>
                      <div className="w-full" style={{ marginBottom: 15 }}>
                        <ShareButton openShare={this.openShare} />
                      </div>
                      <OptionsButton openOptions={this._openOptions} />
                      <div className="relative w-full">
                        {
                          this.state.showOptions &&
                          <OptionsPropertyModal
                            bottom={-200}
                            propertyType={this.state.data.type}
                            status={this.state.data.status}
                            dealType={this.state.data.dealType}
                            refetch={this._refetch}
                            goHomeWhenArcive={true}
                            id={propertyId}
                            close={this._closeOptions}
                          />
                        }
                      </div>
                    </div>
                    {
                      this.state.topHeader && <div style={{ height:150 }}/>
                    }

                    <ShareModal
                      url={this.state.url}
                      visible={this.state.shareModalVisible}
                      messageFacebook={this.state.messageShare}
                      messageWhatsapp={this.state.messageShare}
                      close={() => this.setState({ shareModalVisible: false })}
                    />

                    <div className="flex flex-col" style={{ paddingRight: 35, width: 426 }}>
                      <p className="poppins-semi-bold text-redaria-black text-left" style={{ fontSize: 21, marginBottom: 19 }}>Otras Propiedades</p>
                    </div>

                    <div className="flex flex-col">
                      <FlatList
                        list={this.state.properties}
                        renderItem={this._renderProperty}
                        renderWhenEmpty={() => <div></div>}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return <LoadingScreen />
    }
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    isPremier: getPremierStauts(state),
    premierDays: getPremierDays(state),
    userId: Number(state.authenticatedUser.id),
    needsProfileInformation: state.authenticatedUser.attributes.needsProfileInformation,
  }
}

export default connect(mapStateToProps)(Property)