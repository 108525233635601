import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const ErrorAlert = (message, callback = () => {}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <p className="poppins-semi-bold text-redaria-black text-center" style={{ fontSize:27 }}>Error</p>
          {
            Array.isArray(message)
            ?
            message.map( m => 
              <p className="poppins-regular text-redaria-black text-center px-5" style={{ fontSize:21 }}>{m}</p>)
            :
            <p className="poppins-regular text-redaria-black text-center px-5" style={{ fontSize:21 }}>{message}</p>
          }

          <div className="flex flex-row mt-10">
            <button
              className="redaria-button-white mx-5"
              onClick={() => {
                callback();
                onClose();
              }}
            >
            Aceptar
            </button>
          </div>
        </div>
      );
    }
  });
};


