import React, { PureComponent } from 'react'

export default class EmptyCertificate extends PureComponent {
  render() {
    return (
      <div className="rounded flex items-center justify-center" style={{ width:79,height:79 }}>
      </div>
    )
  }
}
