import React, { PureComponent } from 'react'
import { FacebookShareButton } from "react-share";
import { CloseDark } from '../../lib/Icon/icons/Close';
import { FacebookSimple } from '../../lib/Icon/icons/Facebook';
import Clipboard from 'react-clipboard.js';
import Copy from '../../lib/Icon/icons/Copy';

export default class ShareModal extends PureComponent {

  state = {
    error:false,
    copy:false
  }

  onSuccess = () => this.setState({copy:true})

  onError = () => this.setState({error:true})

  render() {
    const {visible,messageFacebook,messageWhatsapp,close,url} = this.props
    if (!visible)
      return null
    return (
      <div onClick={close} className="fixed z-10  top-0 right-0 left-0 bottom-0 flex justify-center items-center" style={{ backgroundColor:"rgba(100, 107, 115, 0.6)" }}>

        <div onClick={ e => e.stopPropagation()} className="bg-white flex flex-col" 
          style={{ 
            borderRadius:9,
            width:628,
            paddingLeft:39,
            paddingRight:39,
            paddingTop:26,
            paddingBottom:26
            }}>
          <div className="flex flex-row justify-end" style={{ marginBottom:10}}>
            <div onClick={close} className="cursor-pointer">
              <CloseDark style={{widht:20,height:20}}/>
            </div>
          </div>
          <p className="poppins-semi-bold text-redaria-black mb-5" style={{ fontSize:21 }}>Compartir en:</p>
          <FacebookShareButton url={this.props.url} quote={messageFacebook}>
            <div className="w-full flex flex-row justify-center items-center focus:outline-none " style={{ height:57, borderRadius:9,borderColor:"#4C6B8A",borderWidth:0.5,marginBottom:15 }}>
              <FacebookSimple style={{widht:36,height:36,marginRight:22}}/>
              <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Facebook</p>
            </div>
          </FacebookShareButton>
          
            <Clipboard onError={this.onError} onSuccess={this.onSuccess} data-clipboard-text={`${messageWhatsapp}${url}`}>
              <div className="w-full flex flex-row justify-center items-center focus:outline-none " style={{ height:57, borderRadius:9,borderColor:"#4C6B8A",borderWidth:0.5,marginBottom:15 }}>
                <Copy style={{widht:36,height:36,marginRight:22,marginLeft:13}}/>
                <p className="poppins-medium text-redaria-black" style={{ fontSize:21 }}>Copiar Liga</p>
              </div>
            </Clipboard>
          {
            this.state.copy &&
            <span className="text-center poppins-medium">Texto copiado</span>
          }
          
          {
            this.state.error &&
            <React.Fragment>
              <p className="poppins-medium">Ocurrio un error al copiar, puede hacerlo manualmente con el siguiente texto:</p>
              <div className="bg-gray-300">
                <p className="break-words ">{`${messageWhatsapp}${url}`}</p>
              </div>
            </React.Fragment>
          }

        </div>

      </div>
    )
  }
}
