import React, {PureComponent} from 'react';
import LabeledIcon from './LabeledIcon';
import { SelectInput } from './SelectInput';



export class AmmenityDropdown extends PureComponent {
  render() {
    return(
      <div className="mt-10 w-full">
        <LabeledIcon
          icon={this.props.icon}
          label={this.props.label}
        />
        <div className="mt-2 w-full">
          <SelectInput
            options={this.props.options}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            value={this.props.value}
          />
        </div>
      </div>
    );
  }
}
